import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputField from '../../../components/inputField'
import { themeColor } from '../../../config'
import RadioButton from '../../../components/RadioButton'
import { EditTicketSchema } from '../../../utils/Schemas'
import TextareaField from '../../../components/TextareaField'
import { get } from "lodash";
import { useDispatch, useSelector } from 'react-redux'
import { GetSingleTicketAction, SINGLE_TICKET, UpdateTicketAction, addTicketAction } from '../../../store/actions/ticketAction'
import { dateTimeFormatFunction } from '../../../utils/dateFormat'
import SelectField from '../../../components/SelectField'
import moment from 'moment'
import { GetTeamAndVenuesAction } from '../../../store/actions/miqaatAction'
import PageTitle from '../../../components/PageTitle'

const AddEditTicket = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [uploadFile, setUploadFile] = useState([])
  const [uploadedFile, setUploadedFile] = useState(0)
  const [ticketPriority, setTicketPriority] = useState('')
  const [ticketStatus, setTicketStatus] = useState('')
  const [teamList, setTeamList] = useState([])
  const [venuesList, setVenueList] = useState([])
  const ticketData = useSelector((state) => state.ticketreducer)
  const dropDownList = useSelector((state) => state.miqaatreducer.dropdownOptions);
  const navigate = useNavigate()
  const [fieldValues, setFieldValues] = useState({
    Title: '',
    CreatedBy: '',
    CreatedOn: '',
    Description: '',
    Priority: '',
    Status: ''
  })

  useEffect(() => {
    if (params?.ticketId)
      dispatch(GetSingleTicketAction(params?.ticketId))
  }, [params])

  useEffect(() => {
    setVenueList(dropDownList.venues)
    setTeamList(dropDownList.teams)
  }, [dropDownList])

  useEffect(() => {
    if (params?.id) {
      dispatch(GetTeamAndVenuesAction(params?.id, setLoader))
    }
  }, [params])

  useEffect(() => {
    if (params?.ticketId) {
      setUploadFile(get(ticketData, 'singleTicket.images'))
      setTicketPriority(get(ticketData, 'singleTicket.priorityNo'))
      setTicketStatus(get(ticketData, 'singleTicket.statusNo'))
      setValue('CreatedOn', moment(get(ticketData, 'singleTicket.insertedAt')).toDate())
      setValue('Title', get(ticketData, 'singleTicket.name'))
      setValue('CreatedBy', get(ticketData, 'singleTicket.generatedByDetail.fullName'))
      setValue('Description', get(ticketData, 'singleTicket.additionalInfo'))
      setValue('venue', {
        label: get(ticketData, 'singleTicket.venueName'),
        value: get(ticketData, 'singleTicket.venueName')
      })
      setValue('team', {
        label: get(ticketData, 'singleTicket.teamName'),
        value: get(ticketData, 'singleTicket.teamName')
      })
      
    } else {
      setTicketPriority(0)
      setTicketStatus(0)
    }
  }, [params, ticketData])

  const { handleSubmit, register, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(EditTicketSchema)
  })

  const uploadMultipleFiles = async (e) => {
    const fileObj = e.target.files;
    const fileArray = [];
    // Define a function that reads a file and returns a promise
    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve(reader.result.toString());
        };
      });
    };
    // Use Promise.all to read all files concurrently
    const promises = Array.from(fileObj).map(file => readFile(file));

    try {
      const results = await Promise.all(promises);
      fileArray.push(...results);
      const newA = fileArray.map((imageUrl, index) => {
        return imageUrl.replace(/^data:image\/[a-z]+;base64,/, "")
      }
      )
      setUploadFile([...uploadFile, ...newA]);
    } catch (error) {
      console.error("Error reading files:", error);
    }
  };

  const deleteImage = (url) => {
    const newData = []
    uploadFile && uploadFile.length && uploadFile.forEach((item, index) => {
      if (index !== url) {
        newData.push(item)
      }
    })
    setUploadFile(newData)
  }
  useEffect(() => {
    if (uploadFile) {
      setUploadedFile(uploadFile?.length)
    }
  }, [uploadFile])

  const handleBack = () => {
    dispatch({
      type: SINGLE_TICKET,
      singleTicket: {}
    })
  }

  const handleRadioChange = (priority, value) => {
    setTicketPriority(parseInt(value))
  }

  const handleRadioChangeStatus = (status, value) => {
    setTicketStatus(parseInt(value))
  }

  const getIdFromSelectedValue = (selectedValue, option) => {
    const selectedOption = option === 'venue' ? venuesList?.find(option => option.value === selectedValue): teamList?.find(option => option.value === selectedValue);
    if (selectedOption) {
      return selectedOption.id
    }
    return null;
  }

  const submitAction = (data) => {
    setLoader(true)
    let requestedBody = {
      name: data?.Title,
      AdditionalInfo: data?.Description,
      MiqaatId: params?.id,
      images: uploadFile,
      priority: ticketPriority,
      status: ticketStatus
    }
    if (params?.ticketId) {
      requestedBody.Id = get(ticketData, 'singleTicket.id')
      requestedBody.VenueId = get(ticketData, 'singleTicket.venueId')
      requestedBody.teamId = getIdFromSelectedValue(data.team.label, 'team')
      dispatch(UpdateTicketAction(requestedBody, navigate, setLoader))
    } else {
      requestedBody.venueId = getIdFromSelectedValue(data.venue.label, 'venue')
      requestedBody.teamId = getIdFromSelectedValue(data.team.label, 'team')
      dispatch(addTicketAction(requestedBody, navigate, setLoader))
    }
  }
  const inputHandler = (e) => {
    setFieldValues({
      ...fieldValues,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <PageTitle link={`/view-miqaat/${params?.id}/ticket`} title={`${params?.ticketId ? 'EDIT TICKET' : 'ADD TICKET'}`} onClick={handleBack}/>
            {fieldValues && ticketData?.loading
              ? <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
              </div>
              : <div className="form-wrapper">
                <form onSubmit={handleSubmit(submitAction)}>
                  <InputField
                    fieldPlaceholder={'Enter Ticket Title'}
                    name='Title'
                    fieldType={'text'}
                    fieldLabel={'Ticket Title :'}
                    error={errors.Title?.message}
                    register={{ ...register('Title') }}
                    handleChange={inputHandler}
                  />
                  <SelectField fieldLabel={'Zone:'} options={venuesList} name={'venue'}
                    placeholder={'Select Zone'} register={{ ...register('venue') }}
                    error={errors.venue?.message} control={control} disable={params.ticketId ? true : false} />

                  <SelectField fieldLabel={'Assigned to:'} options={teamList} name={'team'}
                    placeholder={'Select Team'} register={{ ...register('team') }}
                    error={errors.team?.message} control={control} />

                  <TextareaField fieldPlaceholder={'Enter Text Here'}
                    fieldLabel={'Additional Information :'} name={'Description'}
                    error={errors.Description?.message}
                    register={{ ...register('Description') }}
                  />

                  <div className="radiobtn-wrapper">
                    <p className='radio-para'>Priority :</p>
                    <div className="radiobtn-group">
                      <RadioButton checked={ticketPriority === 0 ? true : false} label={'None'} name={'Priority'} id="none" handleChange={(e) => handleRadioChange('priority', e.target.value)} value={0} />
                      <RadioButton checked={ticketPriority === 1 ? true : false} label={'Low'} name={'Priority'} id="low" handleChange={(e) => handleRadioChange('priority', e.target.value)} value={1} />
                      <RadioButton checked={ticketPriority === 2 ? true : false} id="mid" handleChange={(e) => handleRadioChange('priority', e.target.value)} label={'Medium'} name={'Priority'} value={2} />
                      <RadioButton checked={ticketPriority === 3 ? true : false} id="high" handleChange={(e) => handleRadioChange('priority', e.target.value)} label={'High'} name={'Priority'} value={3} />
                    </div>
                  </div>

                  <div className="radiobtn-wrapper">
                    <p className='radio-para'>Status :</p>
                    <div className="radiobtn-group" >
                      <RadioButton checked={ticketStatus === 0 ? true : false} handleChange={(e) => handleRadioChangeStatus('status', e.target.value)} label={'Pending'} name={'Status'} id={'Pending'} value={0} />
                      <RadioButton checked={ticketStatus === 1 ? true : false} handleChange={(e) => handleRadioChangeStatus('status', e.target.value)} label={'Assigned'} name={'Status'} id={'Assigned'} value={1} />
                      <RadioButton checked={ticketStatus === 2 ? true : false} handleChange={(e) => handleRadioChangeStatus('status', e.target.value)} label={'InProgress'} name={'Status'} id={'InProgress'} value={2} />
                      <RadioButton checked={ticketStatus === 3 ? true : false} handleChange={(e) => handleRadioChangeStatus('status', e.target.value)} label={'Completed'} name={'Status'} id={'Completed'} value={3} />
                      {/* <RadioButton checked={ticketStatus === 'Close'} label={'Close'} name={'status'} id={'Close'} value={2} /> */}
                    </div>
                  </div>

                  <div className="ticket-image add-edit">
                    {uploadFile?.length > 0 && uploadFile.map((url, i) => (
                      <div className='ticket-view-image'>
                        <img src={`data:image/png;base64,${url}`} key={i} alt={uploadFile?.length === 0 ? 'No File Selected' : null} width={150} height={170} />
                      </div>
                    ))}
                  </div>

                  <div className='upload-btn-wrapper'>
                    <input type="file" style={{ position: 'absolute', opacity: '0', width: '150px', padding: '5px', zIndex: '1' }} onChange={(e) => uploadMultipleFiles(e)}
                    />
                    <Button className='image-btn' variant="success" >
                      Upload Images
                    </Button>
                    {
                      uploadedFile ?
                        <p>{uploadedFile} Images Selected</p>
                        : ''
                    }
                  </div>

                  {
                    params.ticketId &&
                    <div className="view-detail">
                      <div className="ticket-detail">
                        <p className='view-heading'>Created By :  </p>
                        <p className='radio-para'> {get(ticketData, 'singleTicket.generatedByDetail.fullName')}</p>
                      </div>
                      <div className="ticket-detail">
                        <p className='view-heading'>Created At : </p>
                        <p className='radio-para'>  {dateTimeFormatFunction(moment(get(ticketData, 'singleTicket.insertedAt')).toDate())}</p>
                      </div>
                    </div>
                  }

                  <div className="btn-wrapper">
                    <Link to={`/view-miqaat/${params?.id}/ticket`} onClick={handleBack} ><Button variant="danger">Cancel</Button></Link>
                    <Button variant="success" type='submit' disabled={loader} >{params?.ticketId ? 'Update' : 'Add'}{
                      loader
                        ? <div className='loader-wrapper btn-loader'>
                          <Spinner animation="border" variant="white" />
                        </div>
                        : ''
                    }</Button>
                  </div>
                </form>
              </div>
            }
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddEditTicket
