import React from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { themeColor } from '../config';
import PageTitle from '../components/PageTitle';

const CookiePolicy = () => {
  return (
    <div className='main-wrapper'>
        <div className="container">
          <PageTitle link='/' title='Cookie Policy' />
            
        </div>
    </div>
  )
}

export default CookiePolicy