import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText, Grid } from '@mui/material';
import dayjs from "dayjs";
import { capitalize } from 'lodash';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
export default function ArrivalDeparture({ options, type, isRequired, handleChange, startDate, endDate, endDatePicker, disable, setDisableNext, endDateHandleChange, ...rest }) {
    const [arrivalDate, setArrivalDate] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const [error1, setError1] = useState('');
    const [errorIndex, setErrorIndex] = useState(-1); // Initialize with -1
    const [minMsg, setMinMsg] = useState(false);
    const [error, setError] = useState([]);
    const formattedDate = dayjs().format('YYYY/MM/DD');

    useEffect(() => {
        setDepartureDate(moment(options[1].value, 'DD/MM/YYYY'))
        setArrivalDate(moment(options[0].value, 'DD/MM/YYYY'))
    }, [options])

    const errorMessage = React.useMemo(() => {
        switch (error) {
            case 'maxDate':
                return 'Departure date must be after the arrival date';

            case 'minDate':
                return minMsg ? 'Date should not be less than today' : 'Date should not be greater than departure';
            case 'invalidDate':
                return 'Your date is not valid';
            default:
                return '';
        }
    }, [error, minMsg]);

    const validateDeparture = () => {
        // Extract date portions from departureDate and arrivalDate
        const departureDateOnly = new Date(departureDate);
        departureDateOnly.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00.000)

        const arrivalDateOnly = new Date(arrivalDate);
        arrivalDateOnly.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00.000)

        const formatedDate = new Date(formattedDate);
        formatedDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00.000)
        if (arrivalDateOnly && arrivalDateOnly < formatedDate) {
            setError('minDate');
            setErrorIndex(0); // Assuming index 1 corresponds to the departure date picker
            setMinMsg(true)
            setDisableNext(true)
        }

        else if (departureDate && arrivalDate && departureDateOnly < arrivalDateOnly) {
            setError('maxDate');
            setErrorIndex(1); // Assuming index 1 corresponds to the departure date picker
            setDisableNext(true)
        } else {
            setError('');
            setErrorIndex(-1);
            setDisableNext(false)
        }
    };
    const checkDate = (date) => {


        const dateOnly = new Date(date);
        dateOnly.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00.000)

        const formatedDate = new Date(formattedDate);
        formatedDate.setHours(0, 0, 0, 0); // Set time to midnight (00:00:00.000)
        if (date > formatedDate) return true;
        else return false
    }

    useEffect(() => {
        validateDeparture();
    }, [departureDate, arrivalDate]);
    const parseDateValue = (val) => {
        if (val) {
            const parts = val.split('/');
            if (parts.length === 3) {
                const [day, month, year] = parts;
                const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
                return dayjs(isoDate);
            }
            return null;
        } else {
            return null;
        }
    }
    const classes = useStyles();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid spacing={2}>
                {options?.map((option, index) => {
                    return (<><DatePicker
                        key={index}
                        sx={{
                            width: '100%',
                            marginLeft: '0!important'

                        }}

                        label={capitalize(option.placeholder)}
                        value={parseDateValue(option.value)}
                        onChange={(val) => {
                            handleChange(val, index, type);
                            if (index) setDepartureDate(val)
                            else setArrivalDate(val)
                            setErrorIndex(index);
                        }}
                        onError={(newError) => setError(newError)}
                        slotProps={{
                            textField: {
                                helperText: (isRequired && option?.required && (!option.value || option.value === 'Invalid date')) ? <FormHelperText className={classes.noMargin} sx={{ color: '#d32f2f', marginLeft: '0px' }}>
                                    This field is required
                                </FormHelperText> : (errorIndex === index ? <FormHelperText sx={{ color: '#d32f2f' }} className={classes.noMargin}>
                                    {errorMessage}
                                </FormHelperText> : null),
                            },
                        }}
                        minDate={index === 1 ? (options[0].value && options[0].value !== 'Invalid date' && checkDate(arrivalDate) ? dayjs(arrivalDate).startOf('day') : dayjs(formattedDate).startOf('day')) : dayjs(formattedDate).startOf('day')}
                        maxDate={index === 0 ? dayjs(departureDate).endOf('day') : null}
                        format="DD/MM/YYYY"
                    />
                    </>)
                })}
            </Grid>
        </LocalizationProvider>
    );
}
const useStyles = makeStyles((theme) => ({
    noMargin: {
        marginLeft: 0,
    },
}));