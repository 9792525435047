import React, { useEffect, useState } from 'react'
import PageTitle from '../../components/PageTitle'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FilterData from '../../components/FilterData'
import AppModal from '../../components/Modal'
import { baseUrl, themeColor } from '../../config'
import { IoMdAddCircle } from 'react-icons/io'
import { MdDeleteOutline, MdModeEdit } from 'react-icons/md'
import placeholderUserImg from "../../assets/images/profile.png";
import InputField from '../../components/inputField'
import { GetFetch } from '../../utils/fetchUrl'
import { useDispatch } from 'react-redux'
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert'
import { Spinner } from 'react-bootstrap'

const Management = () => {
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [managementData, setManagementData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [filterValues, setFilterValue] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isDisabledClearBtn, setIsDisabledClearBtn] = useState(true)
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const getFilteredData = (filterData) => {
    setFilteredData(filterData)
  }

  const fetchData = () => {
    setLoader(true);
    GetFetch(`/api/management`, dispatch, navigate).then((response) => {
      if (response.status == 200) {
        setManagementData(response.data);
      }
      else {
        showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
      }
      setLoader(false);
    }).catch((err) => {
      console.log('error', err);
      showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
      setLoader(false);
    })
  }

  const showErrorAlert = (msg) => {
    dispatch({
      type: ALERT_ERROR,
      payload: msg
    })
  }

  const showSuccessAlert = (msg) => {
    dispatch({
      type: ALERT_SUCCESS,
      payload: msg
    })
  }

  const handleFilterChange = (keyName, value) => {
    setFilterValue({
      ...filterValues,
      [keyName]: value,
    });
  };

  const confirDelete = () => {
    setShowModal(false)
  }

  const handleClearFilter = () => {
    setFilterValue({})
    setIsDisabledClearBtn(true)
    document.querySelectorAll('input').forEach((input) => (input.value = null));
    document.querySelectorAll('select').forEach((select) => (select.value = ''));
  }

  const handleImageError = (event) => {
    event.target.src = placeholderUserImg;
  };

  const columns2 = [
    {
      name: "Photo",
      cell: (row) => (
        <img
          className="user-profile"
          alt="profile-img"
          src={`${baseUrl}/api/Medias/${row.photo}`}
          onError={handleImageError}
          />
      ),
      minWidth: "90px",
      maxWidth: "90px",
    },
    {
      name: <FilterData columnName='Full Name' keyName='name' filterType='text' handleFilterChange={handleFilterChange} />,
      selector: (row) => row?.name ? row?.name : ' ',
      sortable: true,
      minWidth: '220px',
      maxWidth: '220px'
    },
    {
      name: <FilterData columnName='Designation' keyName='designation' filterType='text' handleFilterChange={handleFilterChange} />,
      selector: (row) => row?.designation || ' ',
      sortable: true,
      minWidth: '180px',
      maxWidth: '180px'
    },
    {
      name: "Department",
      selector: (row) => '-',
      sortable: true,
      minWidth: '180px',
      maxWidth: '180px'
    },
    {
      name: <FilterData columnName='Priority' keyName='priority' filterType='number' handleFilterChange={handleFilterChange} />,
      selector: (row) => row?.priority ? row?.priority : ' ',
      minWidth: '160px',
      maxWidth: '160px'
    },
    {
      name: <FilterData columnName='Short Description' keyName='description' filterType='text' handleFilterChange={handleFilterChange} />,
      selector: (row) => row?.description || ' ',
      sortable: true,
      minWidth: '180px',
      maxWidth: '180px'
    },
    {
      name: 'Action',
      selector: (row) =>
        (row.id ?
          <div style={{ display: 'flex' }}>
            <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}`, marginRight: '6px' }} to={`/view-miqaat/${params?.id}/ticket/edit/${row.id}`} ><MdModeEdit/></Link>
            <button className='action-btn note-error' style={{ color: 'red', background: `${themeColor.lightGreen}`, marginRight: '6px' }} onClick={() => {}} ><MdDeleteOutline/></button>
          </div> : ' '),
      minWidth: '140px',
      maxWidth: '140px'
    }
  ]

  useEffect(() => {
    fetchData();
  }, [])
  
  return (
    <div className='main-wrapper'>
      {loader ?
        <div className='loader-wrapper'>
          <Spinner animation="border" variant="success" />
        </div>
        :
        <div className="container">
          <PageTitle link={`/`} title='Management' />
            <div className="table-wrapper miqaat action-table">
              <div style={{borderBottom: '1px solid #cccccc'}}>
                <header className='table-head' style={{justifyContent: 'flex-end'}}>
                  <div>
                  <InputField className={'search-field'} fieldPlaceholder={'Search'} fieldType={'text'} value={search}
                  handleChange={(e) => setSearch(e.target.value)} />
                  <div>
                    <button className='icon-btn dark' disabled={isDisabledClearBtn} style={{ backgroundColor: isDisabledClearBtn ? '#f5f5f5' : `${themeColor.primaryColor}`, color: isDisabledClearBtn ? '#ccc' : '' }} onClick={handleClearFilter}>Clear Filter</button>
                    <Link to={`/management/add`} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}`, height: 'fit-content' }}><IoMdAddCircle />Add</Link>
                  </div>
                  </div>
                </header>
                <FilterData
                    data={managementData || []}
                    columns={columns2}
                    filterValues={filterValues}
                    getFilteredData={getFilteredData}
                    noDataColKey='name'
                  />
                    </div>
                    {
                      filteredData?.length > 0 &&
                      <div className='table-footer'>
                        <p className="note">To scroll the report horizontally, press the Shift key and scroll using your mouse's scroll wheel.</p>
                        <span>rows: {filteredData.length}</span>
                      </div>
                    }
              </div>
            <AppModal handleClose={() => setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
            handleAction={confirDelete} actionBtnText={'Confirm'}/>
        </div>
      }
    </div>
  )
}

export default Management