import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import InputField from '../../components/inputField'
import { AddEditMiqaatSchema } from '../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import SelectField from '../../components/SelectField'
import RadioButton from '../../components/RadioButton'
import { miqaatTableData, jamaatOptions, jamiatOptions, utcMinutes, utcHours, utcPlusOrMinus } from '../../appContent'
import { GetFetch } from '../../utils/fetchUrl'
import { useDispatch, useSelector } from 'react-redux'
import { AddMiqaatAction, GetSingleMiqaatAction, SINGLE_MIQAAT, UpdateMiqaatAction } from '../../store/actions/miqaatAction'
import { ALERT_ERROR } from '../../store/reducers/alert'
import dayjs from 'dayjs'
import SeparateDateTimePicker from '../../components/SeparateDateTimePicker'
import PageTitle from '../../components/PageTitle'
import { GrClose } from 'react-icons/gr'

const AddEditMiqaat = () => {
  const [miqaatStatus, setMiqaatStatus] = useState(1)
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [btnLoader, setBtnLoader] = useState(false)
  const [jamaatDetails, setJamaatDetails] = useState([]);
  const [jamiatDetails, setJamiatDetails] = useState([]);
  const [jamaats, setJamaats] = useState();
  const [jamiats, setJamiats] = useState();
  const [openSignaturePopup, setOpenSignaturePopup] = useState(false);
  const [signature, setSignature] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({
    miqaatStart: '',
    miqaatEnd: '',
    regStart: '',
    regEnd: '',
  })
  const [showSignatureError, setShowSignatureError] = useState(false)
  const [showRegStartError, setShowRegStartError] = useState(false)
  const [showRegEndError, setShowRegEndError] = useState(false)
  const [showMiqaatStartError, setShowMiqaatStartError] = useState(false)
  const [showMiqaatEndError, setShowMiqaatEndError] = useState(false)
  const miqaatData = useSelector((state) => state.miqaatreducer)
  const [miqaatDateNTime, setMiqaatDateNTime] = useState({
    start: null,
    end: null
  })
  const [regDateNTime, setRegDateNTime] = useState({
    start: null,
    end: null
  })

  const { handleSubmit, register, watch, reset, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(AddEditMiqaatSchema)
  })

  const miqaatStartDateTimeChange = (e, type) => {
    const dateNTime = new Date(e);
    if (type === 'date') {
      dateNTime.setDate(new Date(e).getDate())
    } else {
      dateNTime.setHours(new Date(e).getHours())
      dateNTime.setMinutes(new Date(e).getMinutes())
    }
    if (miqaatDateNTime.end && dateNTime.getDate() === new Date(miqaatDateNTime.end).getDate() && dateNTime.getTime() > new Date(miqaatDateNTime.end).getTime()) {
      setShowMiqaatStartError(true)
      setFieldErrors(prev => ({
        ...prev,
        miqaatStart: 'Miqaat Start Date & Time should be less than Miqaat End Date & Time'
      }))
    } else {
      setShowMiqaatStartError(false)
      setFieldErrors(prev => ({
        ...prev,
        miqaatStart: ''
      }))
    }
    setMiqaatDateNTime(prev => ({
      ...prev,
      start: dayjs(dateNTime)
    }))
  }

  const miqaatEndDateTimeChange = (e, type) => {
    const dateNTime = new Date(e);
    if (type === 'date') {
      dateNTime.setDate(new Date(e).getDate())
    } else {
      dateNTime.setHours(new Date(e).getHours())
      dateNTime.setMinutes(new Date(e).getMinutes())
    }
    if (miqaatDateNTime.start && dateNTime.getDate() === new Date(miqaatDateNTime.start).getDate() && dateNTime.getTime() < new Date(miqaatDateNTime.start).getTime()) {
      setShowMiqaatEndError(true)
      setFieldErrors(prev => ({
        ...prev,
        miqaatEnd: 'Miqaat End Date & Time should be greater than Miqaat Start Date & Time'
      }))
    } else {
      setShowMiqaatEndError(false)
      setFieldErrors(prev => ({
        ...prev,
        miqaatEnd: ''
      }))
    }
    setMiqaatDateNTime(prev => ({
      ...prev,
      end: dayjs(dateNTime)
    }))
  }

  const regStartDateTimeChange = (e, type) => {
    const dateNTime = new Date(e);
    if (type === 'date') {
      dateNTime.setDate(new Date(e).getDate())
    } else {
      dateNTime.setHours(new Date(e).getHours())
      dateNTime.setMinutes(new Date(e).getMinutes())
    }
    if (regDateNTime.start && dateNTime.getDate() === new Date(regDateNTime.end).getDate() && dateNTime.getTime() > new Date(regDateNTime.end).getTime()) {
      setShowRegStartError(true)
      setFieldErrors(prev => ({
        ...prev,
        regStart: 'Registration Start Date & Time should be less than Registration End Date & Time'
      }))
    } else {
      setShowRegStartError(false)
      setFieldErrors(prev => ({
        ...prev,
        regStart: ''
      }))
    }
    setRegDateNTime(prev => ({
      ...prev,
      start: dayjs(dateNTime)
    }))
  }

  const regEndDateTimeChange = (e, type) => {
    const dateNTime = new Date(e);
    if (type === 'date') {
      dateNTime.setDate(new Date(e).getDate())
    } else {
      dateNTime.setHours(new Date(e).getHours())
      dateNTime.setMinutes(new Date(e).getMinutes())
    }
    if (regDateNTime.end && dateNTime.getDate() === new Date(regDateNTime.start).getDate() && dateNTime.getTime() < new Date(regDateNTime.start).getTime()) {
      setShowRegStartError(true)
      setFieldErrors(prev => ({
        ...prev,
        regStart: 'Registration End Date & Time should be Greater than Registration Start Date & Time'
      }))
    } else {
      setShowRegStartError(false)
      setFieldErrors(prev => ({
        ...prev,
        regStart: ''
      }))
    }
    setRegDateNTime(prev => ({
      ...prev,
      end: dayjs(dateNTime)
    }))
  }

  const formatDate = date => {
    const padZero = value => (value < 10 ? `0${value}` : value);
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = padZero(formattedDate.getMonth() + 1);
    const day = padZero(formattedDate.getDate());
    const hours = padZero(formattedDate.getHours());
    const minutes = padZero(formattedDate.getMinutes());
    const seconds = padZero(formattedDate.getSeconds());
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const submitAction = (data) => {
    if (!miqaatDateNTime.start || !miqaatDateNTime.end || !regDateNTime.start || !regDateNTime.end) {
      dispatch({
        type: ALERT_ERROR,
        payload: "Please fillout all the fields",
      });
    }else if (!selectedSignature){
      setShowSignatureError(true)
    }
    else if (!showMiqaatEndError && !showMiqaatStartError && !showRegStartError && !showRegEndError && !showSignatureError) {
      setShowSignatureError(false)
      setFieldErrors({
        miqaatStart: '',
        miqaatEnd: '',
        regStart: '',
        regEnd: '',
      })
      let miqaatData = {
        name: data?.Miqaat,
        dateFrom: formatDate(miqaatDateNTime.start),
        dateTo: formatDate(miqaatDateNTime.end),
        jamiatId: data?.Jamiat?.value,
        jamaatId: data?.Jamaat?.value,
        headITSID: data?.MiqaatHead,
        timeZone: data && `${data.utcPlusMinus.value}${data.utcHour.value}:${data.utcMinute.value}`,
        hrHeadITSID: data?.MiqaatHrHead,
        status: miqaatStatus,
        regEndDate: formatDate(regDateNTime.end),
        regStartDate: formatDate(regDateNTime.start),
        Signature: '',
        SignatureId: selectedSignature ? selectedSignature?.id : null
      }
      if (params?.id) {
        miqaatData.id = params?.id
        dispatch(UpdateMiqaatAction(miqaatData, navigate))
      } else {
        dispatch(AddMiqaatAction(miqaatData, navigate))
      }
      reset()
    }
  }

  useEffect(() => {
    if (params?.id) {
      dispatch(GetSingleMiqaatAction(params?.id))
    } else {
      setValue('utcPlusMinus', utcPlusOrMinus[0])
      setValue('utcHour', utcHours[4])
      setValue('utcMinute', utcMinutes[1])
    }
  }, [params])


  useEffect(() => {
    fetchJamaat();
    fetchJamiat();
    fetchSignature();
  }, [])

  useEffect(() => {
    if ( params?.id && miqaatData?.singleMiqaat && Object.keys(miqaatData?.singleMiqaat).length > 0) {
      let match;
      if (miqaatData?.singleMiqaat?.timeZone) {
        match = miqaatData?.singleMiqaat?.timeZone?.match(/^([+-])(\d{2}):(\d{2})$/);
      }
      const [sign, hours, minutes] = match ? match.slice(1) : '';
      setValue('Miqaat', miqaatData?.singleMiqaat?.name)
      setValue('utcPlusMinus', miqaatData?.singleMiqaat?.timeZone ? { label: sign, value: sign } : { label: '', value: '' })
      setValue('utcHour', miqaatData?.singleMiqaat?.timeZone ? { label: hours, value: hours } : { label: '', value: '' })
      setValue('utcMinute', miqaatData?.singleMiqaat?.timeZone ? { label: minutes, value: minutes } : { label: '', value: '' })
      setMiqaatDateNTime({
        start: dayjs(new Date(miqaatData?.singleMiqaat?.dateFrom)),
        end: dayjs(new Date(miqaatData?.singleMiqaat?.dateTo))
      })
      // setValue('DateFrom', miqaatData?.singleMiqaat?.dateFrom)
      // setValue('DateTo', miqaatData?.singleMiqaat?.dateTo)
      setRegDateNTime({
        start: dayjs(new Date(miqaatData?.singleMiqaat?.regStartDate)),
        end: dayjs(new Date(miqaatData?.singleMiqaat?.regEndDate))
      })
      // setValue('RegDateFrom', miqaatData?.singleMiqaat?.regStartDate)
      // setValue('RegDateTo', miqaatData?.singleMiqaat?.regEndDate)
      setValue('Jamiat', { value: miqaatData?.singleMiqaat?.jamiatId, label: miqaatData?.singleMiqaat?.jamiatId > 0 ? miqaatData?.singleMiqaat?.jamiat : 'All Select' })
      setValue('Jamaat', { value: miqaatData?.singleMiqaat?.jamaatId, label: miqaatData?.singleMiqaat?.jamaatId > 0 ? miqaatData?.singleMiqaat?.jamaat : 'All Select' })
      setValue('MiqaatHead', miqaatData?.singleMiqaat?.headITSID)
      setValue('MiqaatHrHead', miqaatData?.singleMiqaat?.hrHeadITSID)
      setMiqaatStatus(miqaatData?.singleMiqaat?.status)
    }
  }, [miqaatData])

  useEffect(()=>{
    if(signature && miqaatData?.singleMiqaat?.signatureId){
      const sign = signature.filter((item) => item.id === miqaatData?.singleMiqaat?.signatureId)
      setSelectedSignature(sign[0]);
    }
  }, [signature, miqaatData])

  const fetchJamaat = () => {
    GetFetch(`/api/Jamaat`).then((response) => {
      if (response.status == 200) {
        setJamaatDetails(response.data);
      }

    }).catch((err) => {
      console.log('error', err);
    })
  }

  const fetchJamiat = () => {
    GetFetch(`/api/Jamiat`, dispatch, navigate).then((response) => {
      if (response.status == 200) {
        setJamiatDetails(response.data);
      }

    }).catch((err) => {
      console.log('error', err);
    })
  }
  const fetchSignature = () => {
    GetFetch(`/api/miqaats/Signature`).then((response) => {
      if (response.status == 200) {
        setSignature(response.data);
      }

    }).catch((err) => {
      console.log('error', err);
    })
  }

  useEffect(() => {
    if (jamiatDetails) {
      let dataJamiat = []
      jamiatDetails.map((jamiat) => (
        dataJamiat.push({ value: jamiat.jamiatId, label: jamiat.name })
      ))
      dataJamiat.unshift({ value: -1, label: "All Select" })
      setJamiats(dataJamiat)
    }
  }, [jamiatDetails])

  useEffect(() => {
    if (jamaatDetails) {
      let dataJamaat = []
      jamaatDetails.map((jamaat) => (
        dataJamaat.push({ value: jamaat.jamaatId, label: jamaat.name })
      ))
      dataJamaat.unshift({ value: -1, label: "All Select" })
      let data = watch("Jamiat")
      if (data == undefined || data == []) {
        setJamaats(undefined)
      } else {
        setJamaats(dataJamaat)
      }
    }
  }, [jamaatDetails])

  useEffect(() => {
    let data = watch("Jamiat")
    if (data == undefined || data == []) {
      setJamaats(undefined)
    }

    if (data) {
      let filteredJamaats = []
      filteredJamaats.unshift({ value: -1, label: "All Select" })
      // data.map((filterJ)=>{
      jamaatDetails.map((item) => {
        if (data.label == item.jamiat) {
          filteredJamaats.push({ value: item.jamaatId, label: item.name })
        }
      })
      // if(filterJ.value == -1){
      //     setValue("Jamiat",filterJ)
      // }
      // })

      { data == undefined || data == [] ? setJamaats(undefined) : setJamaats(filteredJamaats) }
    }
  }, [watch("Jamiat")])

  const handleBack = () => {
    dispatch({
      type: SINGLE_MIQAAT,
      singleMiqaat: {}
    })
  }

  useEffect(()=>{
    if(selectedSignature){
      setShowSignatureError(false)
    }
  }, [selectedSignature])

  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <PageTitle link={`/all-miqaat`} title={`${params?.id ? 'Edit' : 'Add'} Miqaat`} onClick={handleBack}/>
            {miqaatData.loading ?
              <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
              </div> :
              <div className="form-wrapper">
                <form onSubmit={handleSubmit(submitAction)}>
                  <InputField fieldPlaceholder={'Enter Miqaat Name'} name={'Miqaat'} fieldType={'text'} fieldLabel={'Miqaat Name:'}
                    error={errors.Miqaat?.message} register={{ ...register('Miqaat') }} />

                  <SeparateDateTimePicker
                    label={'Miqaat Start Date & Time'}
                    value={miqaatDateNTime.start}
                    handleOnChange={miqaatStartDateTimeChange}
                    name={'DateFrom'}
                    maxTime={miqaatDateNTime.end}
                    maxDate={miqaatDateNTime.end}
                    showError={showMiqaatStartError}
                    setShowError={setShowMiqaatStartError}
                    fieldError={fieldErrors.miqaatStart}
                    maxDateError='Miqaat Start Date should be less than Miqaat End Date'
                  />

                  <SeparateDateTimePicker
                    label={'Miqaat End Date & Time'}
                    value={miqaatDateNTime.end}
                    handleOnChange={miqaatEndDateTimeChange}
                    name={'DateTo'}
                    minTime={miqaatDateNTime.start}
                    minDate={miqaatDateNTime.start}
                    showError={showMiqaatEndError}
                    setShowError={setShowMiqaatEndError}
                    fieldError={fieldErrors.miqaatEnd}
                    minDateError='Miqaat end date should be greater than Miqaat Start Date'
                  />

                  <SelectField isMulti={false} fieldLabel={'Jamiat:'} options={jamiats} name={'Jamiat'}
                    placeholder={'Select Jamiat'} register={{ ...register('Jamiat') }}
                    error={errors.Jamiat?.message} control={control} />

                  <SelectField isMulti={false} fieldLabel={'Jamaat:'} options={jamaats} name={'Jamaat'}
                    placeholder={'Select Jamaat'} control={control} error={errors.Jamaat?.message} register={{ ...register('Jamaat') }} />

                  <SeparateDateTimePicker
                    label={'Registration Start Date & Time'}
                    value={regDateNTime.start}
                    handleOnChange={regStartDateTimeChange}
                    name={'RegDateFrom'}
                    maxTime={regDateNTime.end}
                    maxDate={regDateNTime.end}
                    showError={showRegStartError}
                    setShowError={setShowRegStartError}
                    fieldError={fieldErrors.regStart}
                    maxDateError='Registration Start Date should be less than Registration End Date'
                  />

                  <SeparateDateTimePicker
                    label={'Registration End Date & Time'}
                    value={regDateNTime.end}
                    handleOnChange={regEndDateTimeChange}
                    name={'RegDateTo'}
                    minTime={regDateNTime.start}
                    minDate={regDateNTime.start}
                    showError={showRegEndError}
                    setShowError={setShowRegEndError}
                    fieldError={fieldErrors.regEnd}
                    minDateError='Registration end date should be greater than Registration Start Date'
                  />

                  <InputField fieldPlaceholder={'Enter Miqaat Head ITS ID'} name={'MiqaatHead'} fieldType={'text'} fieldLabel={'Miqaat Head ITS ID:'}
                    error={errors.MiqaatHead?.message} register={{ ...register('MiqaatHead') }} />

                  <InputField fieldPlaceholder={'Enter Miqaat HR Head ITS ID'} name={'MiqaatHrHead'} fieldType={'text'} fieldLabel={'Miqaat HR Head ITS ID:'}
                    error={errors.MiqaatHrHead?.message} register={{ ...register('MiqaatHrHead') }} />
                  <div className='field'><label>Select Timezone:</label></div>
                  <div className='venue-field-wrapper' style={{ marginTop: '-12px', gridTemplateColumns: '50px auto auto 190px' }}>
                    <h5 style={{ color: '#696969' }}>UTC</h5>
                    <SelectField isMulti={false} fieldLabel={''} options={utcPlusOrMinus} name={'utcPlusMinus'} style={{ marginTop: '-5px' }}
                      placeholder={''} control={control} error={errors.utcPlusMinus?.message} register={{ ...register('utcPlusMinus') }} />

                    <SelectField isMulti={false} fieldLabel={''} options={utcHours} name={'utcHour'} style={{ marginTop: '-5px' }}
                      placeholder={'Select Hour'} control={control} error={errors.utcHour?.message} register={{ ...register('utcHour') }} />

                    <SelectField isMulti={false} fieldLabel={''} options={utcMinutes} name={'utcMinute'} style={{ marginTop: '-5px' }}
                      placeholder={'Select Minute'} control={control} error={errors.utcMinute?.message} register={{ ...register('utcMinute') }} />
                  </div>
                  <div className="field">
                    <p>Signature</p>
                    <button type='button' className='field-btn' onClick={()=> setOpenSignaturePopup(true)}>Choose</button>
                    {
                      selectedSignature &&
                      <div className="upload_image_wrapper">
                        <img src={`data:image/png;base64,${selectedSignature.image}`} alt='signature' width={"250px"} />
                        <button onClick={() => setSelectedSignature(null)}>Remove</button>
                      </div>
                    }
                    {showSignatureError && <p className='error-message' style={{color: 'red', fontSize: '13px', marginTop: '10px'}}>This field is required</p>}
                  </div>
                  <div className="field status-field">
                    <label>Status:</label>
                    <div className="flex">
                      <RadioButton label={'Active'} name={'active'} id={'active'} value={1} checked={miqaatStatus === 1} handleChange={(e) => setMiqaatStatus(1)} />
                      <RadioButton label={'Inactive'} name={'Inactive'} id={'Inactive'} value={0} checked={miqaatStatus === 0} handleChange={(e) => setMiqaatStatus(0)} />
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <Link to='/all-miqaat' onClick={handleBack}><Button variant="danger">Cancel</Button></Link>
                    <Button variant="success" type='submit' disabled={btnLoader} >Save{
                      btnLoader
                        ? <div className='loader-wrapper btn-loader'>
                          <Spinner animation="border" variant="white" />
                        </div>
                        : ''
                    }</Button>
                  </div>
                </form>
              </div>
            }
          </Col>
        </Row>
      </div>
      {openSignaturePopup ?
          <div className="popup-wrapper">
            <div className="popup-container view-user">
              <div className="popup-head">
                <h4 style={{ textAlign: 'left' }}>Choose Signature</h4>
              </div>
              <div className="popup-filter">
                <div className="signature-wrapper">
                  {
                    signature && signature.length > 0 &&
                    signature.map((item)=> (
                      <div className='signature'>
                        <button onClick={()=> {setSelectedSignature({...item}); setOpenSignaturePopup(false)}} className={selectedSignature?.id === item?.id ? 'active' : ''}>
                          <img src={`data:image/png;base64,${item.image}`} alt='signature'/>
                          <p>{item.name}</p>
                        </button>
                      </div>
                    ))
                  }
                </div>
              </div>
              <button className='close-btn' onClick={() => setOpenSignaturePopup(false)}><GrClose /></button>
            </div>
          </div>
          : ''
        }
    </div>
  )
}

export default AddEditMiqaat
