import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminRoleAccess from '../pages/ACCESS_CONTROL/AdminRoleAccess';
import Dashboard from '../pages/Dashboard';
import ItsId from '../pages/COMMUNICATION/ItsId';
import JamaatJamiat from '../pages/COMMUNICATION/JamaatJamiat';
import Login from '../pages/Login';
import Position from '../pages/COMMUNICATION/Position';
import Profile from '../pages/Profile';
import RoleManagement from '../pages/ACCESS_CONTROL/RoleManagement';
import SyncUserInfo from '../pages/USER/SyncUserInfo';
import ProtectedRoutes from './ProtectedRoutes';
import PageNotFound from '../pages/PageNotFound';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import CookiePolicy from '../pages/CookiePolicy';
import Reports from '../pages/REPORT/Reports';
import CreateRole from '../pages/ACCESS_CONTROL/CreateRole';
import Template from '../pages/SETTINGS/Template';
import AddEditTemplate from '../pages/SETTINGS/AddEditTemplate';
import UnregisteredUser from '../pages/UnregisteredUser';
import AssignRole from '../pages/ACCESS_CONTROL/AssignRole';
import GenderWiseReports from '../pages/REPORT/GenderWiseReport';
import JamiatWiseReports from '../pages/REPORT/JamiatWiseReport';
import PositionWiseReports from '../pages/REPORT/PositionWiseReport';
import NationalityWiseReport from '../pages/REPORT/NationalityWiseReport';
import PageNotAccessible from '../pages/PageNotAccessible';
import EmailLog from '../pages/COMMUNICATION/EmailLog';
import EmailLogById from '../pages/COMMUNICATION/EmailLogById';
import ViewMiqaat from '../pages/MIQAAT/ViewMiqaat'
import Miqaat from '../pages/MIQAAT/Miqaat'
import ViewQuestion from '../pages/MIQAAT/QUESTION/ViewQuestion'
import AddQuestion from '../pages/MIQAAT/QUESTION/AddQuestion'
import EditQuestion from '../pages/MIQAAT/QUESTION/EditQuestion'
import ViewMiqaatReport from '../pages/MIQAAT/ViewMiqaatReport';

import ViewTeam from '../pages/MIQAAT/TEAM/ViewTeam'
import AddEditTeam from '../pages/MIQAAT/TEAM/AddEditTeam'

import DailyQuestion from '../pages/MIQAAT/QUESTIONAIRE/questionarie';

import AddEditMiqaat from '../pages/MIQAAT/AddEditMiqaat';
import Venue from '../pages/MIQAAT/Venue/Venue';
import AddEditVenue from '../pages/MIQAAT/Venue/AddEditVenue';
import ByTeam from '../pages/MIQAAT/COMMUNICATION/ByTeam';
import ByVenue from '../pages/MIQAAT/COMMUNICATION/ByVenue';
import ViewDepartment from '../pages/MIQAAT/DEPARTMENT/ViewDepartment';
import AddEditDepartment from '../pages/MIQAAT/DEPARTMENT/AddEditDepartment';
import ViewTasks from '../pages/MIQAAT/TEAM/ViewTasks';
import AddEditTask from '../pages/MIQAAT/TEAM/AddEditTask';
import Ticket from '../pages/MIQAAT/TICKET/Ticket'
import ViewTicket from '../pages/MIQAAT/TICKET/ViewTicket';
import AddEditTicket from '../pages/MIQAAT/TICKET/AddEditTicket';
import CancelRegistration from '../pages/MIQAAT/ATTENDEES/CancelRegistration';
import RegisterForMiqaat from '../pages/MIQAAT/ATTENDEES/RegisterForMiqaat';
import Designation from '../pages/MIQAAT/Designation';
import KhidmatguzarOnDuty from '../pages/MIQAAT/ATTENDEES/KhidmatguzarOnDuty';
import Attendance from '../pages/MIQAAT/Attendance';
import Equipment from '../pages/MIQAAT/EQUIPMENT/Equipment';
import AddEditEquipment from '../pages/MIQAAT/EQUIPMENT/AddEditEquipment';
import InventoryByDate from '../pages/MIQAAT/EQUIPMENT/InventorybyDate';
import InventoryByProduct from '../pages/MIQAAT/EQUIPMENT/InventoryByProduct';
import SyncUserByJamaatJamiat from '../pages/USER/SyncUserByJamaatJamiat';
import SyncUserByITS from '../pages/USER/SyncUserByITS';
import ViewUser from '../pages/USER/ViewUser';
import AddUser from '../pages/USER/AddUser';
import InventoryReport from '../pages/MIQAAT/EQUIPMENT/InventoryReport';
import InventoryByVenue from '../pages/MIQAAT/EQUIPMENT/InventoryByVenue';
import Purchase from '../pages/MIQAAT/EQUIPMENT/Purchase';
import AddEditPurchase from '../pages/MIQAAT/EQUIPMENT/AddEditPurchase';
import ProductInOut from '../pages/MIQAAT/EQUIPMENT/ProductInOut';
import MiqaatReport from '../pages/MIQAAT/MiqaatReport/Report';
import AttendeesReport from '../pages/MIQAAT/MiqaatReport/AttendeesReport';
import ManualAttendance from '../pages/MIQAAT/ManualAttendance';
import CitiesList from '../pages/CITIES/CitiesList';
import SyncUntaggedMember from '../pages/USER/SyncUntaggedMember';
import GeneralStatisics from '../pages/REPORT/GeneralStatisics';
import ProfileStatistics from '../pages/REPORT/ProfileStatistics';
import MiqaatStatistics from '../pages/REPORT/MiqaatStatistics';
import TagUser from '../pages/USER/TagUser';
import LanguagesList from '../pages/LANGUAGES/languageList';
import SkillSetList from '../pages/SKILLSETS/skillsets';
import SOPList from '../pages/SOP/sop';
import TaskReport from '../pages/MIQAAT/TEAM/TaskReport';
import Management from '../pages/MANAGEMENT_CRUD/Management';
import Task from '../pages/MIQAAT/TEAM/Task';
import AddEditManagement from '../pages/MANAGEMENT_CRUD/AddEditManagement';

const AppRoutes = () => {
    let loginState = useSelector((state) => state.login);
    const dashboardD = useSelector(state => state.dashboard);
    const [show404Page, setShow404Page] = useState(false);
    const [routes, setRoutes] = useState({
        publicRoutes: [
            {
                path: '/profile/:token/:its',
                element: <Profile />
            },
            {
                path: '/profile',
                element: <Profile />
            },
            {
                path: '/privacy-policy',
                element: <PrivacyPolicy />
            },
            {
                path: '/cookie-policy',
                element: <CookiePolicy />
            },
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '/reports/general',
                element: <GeneralStatisics />
            },
            {
                path: '/reports/profile',
                element: <ProfileStatistics />
            },
            {
                path: '/reports/miqaat',
                element: <MiqaatStatistics />
            },
            {
                path: '/sync-user/untagged',
                element: <SyncUntaggedMember />
            },
            {
                path: '/miqaat-registration/:id',
                element: < DailyQuestion />
            },
            {
                path: '/tag-users-its',
                element: < TagUser />
            },
            {
                path: '/management',
                element: < Management />
            },
            {
                path: '/management/add',
                element: < AddEditManagement />
            },
            {
                path: '/management/edit/:id',
                element: < AddEditManagement />
            },
        ],
        privateRoutes: []
    })

    useEffect(() => {
        setTimeout(() => {
            setShow404Page(true);
        }, 2600);

    }, [])

    useEffect(() => {
        if (dashboardD?.length > 0) {
            let a = [];
            for (let i = 0; i < dashboardD?.length; i++) {
                if (dashboardD[i].name && dashboardD[i].name === 'User') {
                    if (dashboardD[i].childrens?.length > 0) {
                        for (let j = 0; j < dashboardD[i].childrens?.length; j++) {
                            if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "View User") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/user/view-users', element: <ViewUser /> }]
                                } else {
                                    a = [...a, { path: '/user/view-users', element: <PageNotAccessible /> }]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Add User") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/user/add', element: <AddUser /> }]
                                }
                                else {
                                    a = [...a, { path: '/user/add', element: <PageNotAccessible /> }]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Sync User Information") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/sync-user', element: <SyncUserInfo /> }]
                                }
                                else {
                                    a = [...a, { path: '/sync-user', element: <PageNotAccessible /> }]
                                }
                                if (dashboardD[i].childrens[j].childrens?.length > 0) {
                                    for (let k = 0; k < dashboardD[i].childrens[j].childrens?.length; k++) {
                                        if (dashboardD[i].childrens[j].childrens[k].name === 'BY ITS ID(s)') {
                                            if (dashboardD[i].childrens[j].childrens[k].allow) {
                                                a = [...a, { path: '/sync-user/its', element: <SyncUserByITS /> }]
                                            }
                                            else {
                                                a = [...a, { path: '/sync-user/its', element: <PageNotAccessible /> }]
                                            }
                                        }
                                        else if (dashboardD[i].childrens[j].childrens[k].name?.match("BY JAMIAT/JAMAAT")) {
                                            if (dashboardD[i].childrens[j].childrens[k].allow) {
                                                a = [...a, { path: '/sync-user/jamaat-jamiat', element: <SyncUserByJamaatJamiat /> }]
                                            }
                                            else {
                                                a = [...a, { path: '/sync-user/jamaat-jamiat', element: <PageNotAccessible /> }]
                                            }
                                        }
                                        else if (dashboardD[i].childrens[j].childrens[k].name?.match("Sync untagged member")) {
                                            if (dashboardD[i].childrens[j].childrens[k].allow) {
                                                a = [...a, { path: '/sync-user/untagged', element: <SyncUntaggedMember /> }]
                                            }
                                            else {
                                                a = [...a, { path: '/sync-user/untagged', element: <PageNotAccessible /> }]
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                else if (dashboardD[i].name && dashboardD[i].name === 'Communication') {
                    if (dashboardD[i].childrens?.length > 0) {
                        for (let j = 0; j < dashboardD[i].childrens?.length; j++) {
                            if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "ITS ID(s)") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/its-id', element: <ItsId /> }]
                                }
                                else {
                                    a = [...a, { path: '/its-id', element: <PageNotAccessible /> }]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Jamiat/Jamaat") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/jamaat-jamiat', element: <JamaatJamiat /> }]
                                }
                                else {
                                    a = [...a, { path: '/jamaat-jamiat', element: <PageNotAccessible /> }]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Position") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/position', element: <Position /> }]
                                }
                                else {
                                    a = [...a, { path: '/position', element: <PageNotAccessible /> }]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Email Log") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/email-log', element: <EmailLog /> }, {
                                        path: '/email-log/:id',
                                        element: <EmailLogById />
                                    },]
                                }
                                else {
                                    a = [...a, { path: '/email-log', element: <PageNotAccessible /> }]
                                }
                            }
                        }
                    }
                }
                else if (dashboardD[i].name && dashboardD[i].name === 'Report') {
                    if (dashboardD[i].childrens?.length > 0) {
                        for (let j = 0; j < dashboardD[i].childrens?.length; j++) {
                            if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Nationality wise") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/reports/nationalitywise', element: <NationalityWiseReport /> }]
                                }
                                else {
                                    a = [...a, { path: '/reports/nationalitywise', element: <PageNotAccessible /> }]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Gender wise") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/reports/genderwise', element: <GenderWiseReports /> }]
                                }
                                else {
                                    a = [...a, { path: '/reports/genderwise', element: <PageNotAccessible /> }]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Jamiat wise") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/reports/jamiatwise', element: <JamiatWiseReports /> }]
                                }
                                else {
                                    a = [...a, { path: '/reports/jamiatwise', element: <PageNotAccessible /> }]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Position wise") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/reports/positionwise', element: <PositionWiseReports /> }]
                                }
                                else {
                                    a = [...a, { path: '/reports/positionwise', element: <PageNotAccessible /> }]
                                }
                            }
                        }
                    }
                }
                else if (dashboardD[i].name && dashboardD[i].name === 'Access Control') {
                    if (dashboardD[i].childrens?.length > 0) {
                        for (let j = 0; j < dashboardD[i].childrens?.length; j++) {
                            if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Role Management") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, {
                                        path: '/access-control/role-management',
                                        element: <RoleManagement />
                                    },
                                    {
                                        path: '/access-control/create-role',
                                        element: <CreateRole />
                                    },
                                    {
                                        path: '/access-control/role-management/:id',
                                        element: <AdminRoleAccess />
                                    },]
                                } else {
                                    a = [...a, {
                                        path: '/access-control/role-management',
                                        element: <PageNotAccessible />
                                    },
                                    {
                                        path: '/access-control/create-role',
                                        element: <PageNotAccessible />
                                    },
                                    {
                                        path: '/access-control/role-management/:id',
                                        element: <PageNotAccessible />
                                    },]
                                }
                            }
                            else if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Assign Role") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, { path: '/access-control/assign-role', element: <AssignRole /> },]
                                }
                                else {
                                    a = [...a, { path: '/access-control/assign-role', element: <PageNotAccessible /> }]
                                }
                            }
                        }
                    }
                }
                else if (dashboardD[i].name && dashboardD[i].name === 'Settings') {
                    if (dashboardD[i].childrens?.length > 0) {
                        for (let j = 0; j < dashboardD[i].childrens?.length; j++) {
                            if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "Email Template") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, {
                                        path: '/template',
                                        element: <Template />
                                    },
                                    {
                                        path: '/template/edit/:id',
                                        element: <AddEditTemplate />
                                    },
                                    {
                                        path: '/template/add',
                                        element: <AddEditTemplate />
                                    },]
                                } else {
                                    a = [...a, {
                                        path: '/template',
                                        element: <PageNotAccessible />
                                    },
                                    {
                                        path: '/template/edit/:id',
                                        element: <PageNotAccessible />
                                    },
                                    {
                                        path: '/template/add',
                                        element: <PageNotAccessible />
                                    },]
                                }
                            }
                        }
                    }
                } else if (dashboardD[i].name && dashboardD[i].name === 'Miqaat') {
                    // if(dashboardD[i].allow)
                    a = [...a,
                    { path: '/all-miqaat', element: <Miqaat /> },
                    { path: '/view-miqaat/:id', element: <ViewMiqaat /> },
                    { path: '/view-miqaat/add', element: <AddEditMiqaat /> },
                    { path: '/view-miqaat/edit/:id', element: <AddEditMiqaat /> },
                    { path: '/view-miqaat/:id/view-question', element: <ViewQuestion /> },
                    { path: '/view-miqaat/:id/view-question/add', element: <AddQuestion /> },
                    { path: '/view-miqaat/:id/view-question/edit/:questionId', element: <EditQuestion /> },
                    { path: '/view-miqaat/:id/view-team', element: <ViewTeam /> },
                    { path: '/view-miqaat/:id/view-team/add', element: <AddEditTeam /> },
                    { path: '/view-miqaat/:id/view-team/edit/:teamId', element: <AddEditTeam /> },
                    { path: '/view-miqaat/:id/zone', element: <Venue /> },
                    { path: '/view-miqaat/:id/zone/add', element: <AddEditVenue /> },
                    { path: '/view-miqaat/:id/zone/edit/:venueId', element: <AddEditVenue /> },
                    { path: '/view-miqaat/:id/communication/by-team', element: <ByTeam /> },
                    { path: '/view-miqaat/:id/communication/by-zone', element: <ByVenue /> },
                    { path: '/view-miqaat/:id/view-department', element: <ViewDepartment /> },
                    { path: '/view-miqaat/:id/view-department/add', element: <AddEditDepartment /> },
                    { path: '/view-miqaat/:id/view-department/edit/:departmentId', element: <AddEditDepartment /> },
                    { path: '/view-miqaat/:id/tasks', element: <Task /> },
                    // { path: '/view-miqaat/:id/tasks/add', element: <AddEditTask /> },
                    // { path: '/view-miqaat/:id/tasks/edit/:taskId', element: <AddEditTask /> },
                    { path: '/view-miqaat/:id/ticket', element: <Ticket /> },
                    { path: '/view-miqaat/:id/ticket/:ticketId', element: <ViewTicket /> },
                    { path: '/view-miqaat/:id/ticket/edit/:ticketId', element: <AddEditTicket /> },
                    { path: '/view-miqaat/:id/ticket/add', element: <AddEditTicket /> },
                    { path: '/view-miqaat-report/:id', element: <ViewMiqaatReport /> },
                    { path: '/view-miqaat/:id/designation', element: <Designation /> },
                    { path: '/view-miqaat/:id/attendees', element: <KhidmatguzarOnDuty /> },
                    { path: '/view-miqaat/:id/attendance/mark-manual', element: <ManualAttendance /> },
                    { path: '/view-miqaat/:id/attendees/register', element: <RegisterForMiqaat /> },
                    { path: '/view-miqaat/:id/attendees/unregister', element: <CancelRegistration /> },
                    { path: '/view-miqaat/:id/attendance', element: <Attendance /> },
                    { path: '/view-miqaat/:id/products', element: <Equipment /> },
                    { path: '/view-miqaat/:id/product/edit/:productId', element: <AddEditEquipment /> },
                    { path: '/view-miqaat/:id/product/add', element: <AddEditEquipment /> },
                    { path: '/view-miqaat/:id/purchase', element: <Purchase /> },
                    { path: '/view-miqaat/:id/purchase/add', element: <AddEditPurchase /> },
                    { path: '/view-miqaat/:id/purchase/edit/:purchaseId', element: <AddEditPurchase /> },
                    { path: '/view-miqaat/:id/product/in-out', element: <ProductInOut /> },
                    { path: '/view-miqaat/:id/inventory-report/', element: <InventoryReport /> },
                    { path: '/view-miqaat/:id/inventory-report/by-date', element: <InventoryByDate /> },
                    { path: '/view-miqaat/:id/inventory-report/by-product', element: <InventoryByProduct /> },
                    { path: '/view-miqaat/:id/inventory-report/by-zone', element: <InventoryByVenue /> },
                    { path: '/view-miqaat/:id/report', element: <MiqaatReport /> },
                    { path: '/view-miqaat/:id/report/attendees', element: <AttendeesReport /> },
                    { path: '/view-miqaat/:id/task-report', element: <TaskReport /> },
                    { path: '/view-miqaat/:id/task-report', element: <TaskReport /> },
                    ]
                } else if (dashboardD[i].name && dashboardD[i].name === 'CRUD') {
                    if (dashboardD[i].childrens?.length > 0) {
                        for (let j = 0; j < dashboardD[i].childrens?.length; j++) {
                            if (dashboardD[i].childrens[j]?.name && dashboardD[i].childrens[j]?.name === "City") {
                                if (dashboardD[i].childrens[j]?.allow) {
                                    a = [...a, {
                                        path: '/cities',
                                        element: <CitiesList />
                                    }, {
                                        path: '/language',
                                        element: <LanguagesList />
                                    },
                                    {
                                        path: '/skills',
                                        element: <SkillSetList />
                                    },
                                    {
                                        path: '/sop',
                                        element: <SOPList />
                                    }]
                                } else {
                                    a = [...a, {
                                        path: '/cities',
                                        element: <PageNotAccessible />
                                    },]
                                }
                            }
                        }
                    }
                }
                //  else if (dashboardD[i].name && dashboardD[i].name === 'CRUD') {
                //     a = [...a,
                //     { path: '/cities', element: <CitiesList /> },]
                // }
            }
            setRoutes({ publicRoutes: routes.publicRoutes, privateRoutes: a });
        }
    }, [dashboardD])



    return (
        <Routes>
            <>
                {routes?.publicRoutes?.map((publicRoute) => (
                    <Route key={publicRoute.path} path={publicRoute.path} element={publicRoute.element} />
                ))}
                {routes.privateRoutes?.map((privateRoute) => (
                    <Route key={privateRoute.path} element={<ProtectedRoutes route={privateRoute.path} />}>
                        <Route path={privateRoute.path} element={privateRoute.element} />
                    </Route>
                ))}

                {loginState.login ?
                    (
                        <>
                            <Route path='/' element={<Dashboard />} />
                            {show404Page ?
                                <Route path='*' element={<PageNotFound />} /> : ''
                            }
                        </>
                    )
                    :
                    (
                        <>
                            <Route path='/' element={<Login />} />
                            <Route path='/login' element={<Login />} />
                            <Route path='*' element={<UnregisteredUser />} />
                        </>
                    )}
            </>

        </Routes>
    )

}
export default AppRoutes