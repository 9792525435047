import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdDeleteOutline, MdModeEdit } from 'react-icons/md'
import { Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import InputField from '../../../components/inputField'
import { ProductInData } from '../../../appContent'
import { themeColor } from '../../../config'
import AppModal from '../../../components/Modal'
import { dateTimeFormatFunction } from '../../../utils/dateFormat'
import PageTitle from '../../../components/PageTitle'

const Purchase = () => {
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [loader, setLoader] = useState(false)
  const params = useParams()
  const [showModal, setShowModal] = useState(false)
  const [deleteProductId, setDeleteProductId] = useState('')

  const columns = [
    {
      name: 'Product Name',
      selector: (row) => row.name
    },
    {
        name: 'Purchase Date',
        selector: (row) => dateTimeFormatFunction(row.insertedAt)
    },
    {
        name: 'Quantity',
        selector: (row) => row.quantity
    },
    {
      name: 'Actions',
      cell: (row) => <p className='action-btn-wrapper'><Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/${params.id}/purchase/edit/${row.id}`} ><MdModeEdit/></Link> <button className='action-btn note-error' style={{ background: `${themeColor.lightGreen}`, color: 'red' }} onClick={()=>deleteVenue(row.id)}><MdDeleteOutline/></button></p>
    }
  ]

  useEffect(() => {
    setLoader(true)
  }, [])

  useEffect(() => {
    if (ProductInData.length > 0) {
      setFilteredData(ProductInData)
    }
  }, [ProductInData])

  const deleteVenue = (id) => {
    setDeleteProductId(id)
    setShowModal(true)
  }
  const confirDelete = () => {
    // let data = {
    //   id: deleteProductId,
    //   miqaatId: params?.id 
    // }
    // dispatch(DeleteVenueAction(data))
    setShowModal(false)
  }

  useEffect(() => {
    if (search.length > 0) {
      const result = filteredData?.filter((team) => {
        return team.name.toLowerCase().match(search.toLowerCase())
      })
      setFilteredData(result)
    } else {
      setFilteredData(ProductInData)
    }
  }, [search])

  useEffect(() => {
    if (filteredData?.length > 0) {
      setLoader(false)
    }
  }, [filteredData])

  return (
    <div className='main-wrapper'>
        <div className="container">
          <PageTitle link={`/view-miqaat/${params.id}`} title='Purchases' />
            {loader
              ? <div className='loader-wrapper'>
              <Spinner animation="border" variant="success" />
            </div>
              : <div className="table-wrapper role-management-table">
                <DataTable data={filteredData} columns={columns}
                fixedHeader fixedHeaderScrollHeight='482px'
                subHeader pagination
                subHeaderComponent={
                  <div>
                    <InputField className={'search-field'} fieldPlaceholder={'Search Products'} fieldType={'text'} value={search}
                      handleChange={(e) => setSearch(e.target.value)} />
                      <Link to={`/view-miqaat/${params.id}/purchase/add`} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}` }}><IoMdAddCircle />New Purchase</Link>
                  </div>} subHeaderAlign="left"/>
              </div>
            }

            <AppModal handleClose={()=>setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
          handleAction={confirDelete} actionBtnText={"Confirm"}/>
        </div>
    </div>
  )
}

export default Purchase
