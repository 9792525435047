import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { HiUpload } from 'react-icons/hi';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import { MdOutlineSimCardDownload } from 'react-icons/md';
import { yupResolver } from "@hookform/resolvers/yup";
import { useCSVReader } from 'react-papaparse';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import * as yup from "yup";
import { themeColor } from '../../config';
import TextareaField from '../../components/TextareaField';
import { PostFetch } from '../../utils/fetchUrl';
import fileUrl from '../../assets/files/Sample.csv';
import { useDispatch } from 'react-redux';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import Checkbox from '../../components/Checkbox';
import { convertItsToArray } from '../../utils/helper';
import PageTitle from '../../components/PageTitle';
import { exportExcelFile } from '../../components/ExportExcelFile';
import { CSVLink } from 'react-csv';

const AddUser = () => {
    const [addUserStatus, setAddUserStatus] = useState({
        syncedSuccessfulCount: 0,
        taggedCount: 0,
        invalidCount: 0,
        invalidITSIds: [],
        taggedITSIds: [],
        untaggedCount: 0,
        untaggedITSIds: [],
        errorCount: 0,
        errorITSIds: [],
        skippedITSIds: [],
        skippedCount: 0
    });
    const [itsId, setItsId] = useState();
    const [ITSIds, setITSIds] = useState({
        'ItsIds': []
    });
    const [successPopup, setSuccessPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [CSVDetails, setCSVDetails] = useState();
    const [CSVIts, setCSVIts] = useState();
    const [disableField, setDisableField] = useState(false);
    const [progressStatus, setProgressStatus] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [idError, setIdError] = useState(false)
    const [skipExisting, setSkipExisting] = useState(false)
    const [openITSDropDown, setOpenITSDropDown] = useState({
        untagged: false,
        invalid: false,
        skipped: false,
        tagged: false
    });

    const { CSVReader } = useCSVReader();
    const dispatch = useDispatch();

    const addUserApi = (data, type) => {
        return new Promise((resolve, reject) => {
            setLoader(true);
            PostFetch('/api/user/syncByITS', data, "POST")
                .then((response) => {
                    if (response.status === 200) {
                        setAddUserStatus(prev => ({
                            syncedSuccessfulCount: prev?.syncedSuccessfulCount + response.data.syncedSuccessfulCount,
                            taggedCount: prev?.taggedCount + response.data.taggedCount,
                            taggedITSIds: prev?.taggedITSIds?.concat(response.data.taggedITSIds),
                            untaggedCount: prev?.untaggedCount + response.data.untaggedCount,
                            untaggedITSIds: prev?.untaggedITSIds?.concat(response.data.untaggedITSIds),
                            errorCount: prev?.errorCount + response.data.errorCount,
                            errorITSIds: prev?.errorITSIds?.concat(response.data.errorITSIds),
                            skippedCount: prev?.skippedCount + response.data.skippedCount,
                            skippedITSIds: prev?.skippedITSIds?.concat(response.data.skippedITSIds),
                            invalidCount: prev?.invalidCount + response.data.invalidCount,
                            invalidITSIds: prev?.invalidITSIds.concat(response.data.invalidITSIds),
                        }));
                        setSuccessPopup(true);
                        setLoader(false);
                        setITSIds({ ItsIds: [] })
                        showSuccessAlert(`Sucessfully Synced`)
                        resolve();
                    }
                    else {
                        showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                        setLoader(false);
                        setITSIds({ ItsIds: [] })
                        reject();
                    }
                }).catch((error) => {
                    console.log('error', error);
                    showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
                    setITSIds({ ItsIds: [] })
                    setLoader(false);
                    resolve()

                })
        })
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    let i = 0;
    const limit = 50;

    const addUser = () => {
        const slicedData = ITSIds.ItsIds.slice(i, i + limit);

        if (slicedData.length > 0) {
            let bodyData = {
                ItsIds: slicedData,
                skipExisting: skipExisting
            }

            addUserApi(bodyData).then(() => {
                setShowProgressBar(true);
                setProgressStatus(parseInt((i + slicedData.length) * 100 / ITSIds.ItsIds.length));

                i += limit;
                if (i < ITSIds.ItsIds.length) {
                    addUser();
                } else {
                    setProgressStatus(100);
                }
            });
        }
    };

    const schema = yup
        .object().shape({
            ItsIds: yup.string()
                .required('This field is required')
                .matches(/^[0-9 ,'\n']+$/, "Must be only digits")
        })
        .required("required");
    const { handleSubmit, register, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const submitAction = async (data) => {
        setIdError(false)
        { itsId == "" ? setIdError(true) : setIdError(false) }
        setAddUserStatus({
            syncedSuccessfulCount: 0,
            taggedCount: 0,
            taggedITSIds: [],
            untaggedCount: 0,
            untaggedITSIds: [],
            errorCount: 0,
            errorITSIds: [],
            skippedCount: 0,
            invalidCount: 0,
            invalidITSIds: [],
            skippedITSIds: []
        });
        const numArr = await convertItsToArray(itsId, setIdError);
        setITSIds({
            'ItsIds': numArr
        });
    }

    const csvSubmitAction = () => {
        setAddUserStatus({
            syncedSuccessfulCount: 0,
            taggedCount: 0,
            taggedITSIds: [],
            untaggedCount: 0,
            untaggedITSIds: [],
            errorCount: 0,
            errorITSIds: [],
            skippedCount: 0,
            invalidCount: 0,
            invalidITSIds: [],
            skippedITSIds: []
        });

        if (CSVIts && CSVIts.length > 1) {
            let CsvList = []
            CSVIts && CSVIts.map((item, i) => {
                if (item.toString() != 'NaN') {
                    if (item.toString().length == 8) {
                        CsvList.push(item)
                    }
                }
            })
            setITSIds({
                'ItsIds': CsvList
            });
        }
    }

    const downloadCsv = () => {
        const fileName = fileUrl.split('/').pop();
        const aTag = document.createElement('a');
        aTag.href = fileUrl;
        aTag.setAttribute('download', fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    useEffect(() => {
        if (CSVDetails) {
            const arrayToString = function (array) {
                const str = array.map(a => a[0]).join(', ');
                const numArr = str.split(',').map(str => parseInt(str.trim()));
                return numArr;
            };
    
            const str = arrayToString(CSVDetails.data);
            setCSVIts(str);
        }
    }, [CSVDetails]);

    useEffect(() => {
        if (!idError) {
            if (ITSIds.ItsIds && ITSIds.ItsIds.length > 0) {
                addUser();
                setItsId('');
                setIdError(false)
            }
        }

    }, [ITSIds.ItsIds]);

    return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7}>
                        <PageTitle link='/dashboard' title='Add User' />
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <TextareaField fieldPlaceholder={"Enter ITS ID(s)"}
                                    fieldLabel={'ITS ID(s):'} name={'ItsIds'} disable={disableField}
                                    error={errors.ItsIds?.message} register={{ ...register("ItsIds") }}
                                    value={itsId} handleChng={(e) => setItsId(e.target.value)} />
                                {idError ?
                                    <h6 className="note" style={{ color: "red" }}>ITS Ids should be of 8 digits</h6>
                                    : ''
                                }
                                <h6 className="note" style={{ color: "green" }}>Press Enter After Every ITS ID</h6>
                                <p style={{ margin: '30px 0', textAlign: 'center', fontWeight: '500', }}>OR</p>

                                <CSVReader
                                    onUploadAccepted={(results) => {
                                        setCSVDetails(results);
                                        setDisableField(true);
                                        setItsId('');
                                    }}
                                >
                                    {({
                                        getRootProps,
                                        acceptedFile,
                                        ProgressBar,
                                        getRemoveFileProps,
                                    }) => (
                                        <>
                                            <div className='file-wrapper'>
                                                <button type='button' className='uploadBtn' style={{ color: `${themeColor.primaryColor}`, backgroundColor: `${themeColor.lightGreen}` }}
                                                    {...getRootProps()} ><HiUpload /> Upload CSV File</button>
                                                {!acceptedFile ? setDisableField(false) : setDisableField(true)}
                                                {acceptedFile ?
                                                    <div className='d-flex justify-content-center align-items-center my-4' >
                                                        <div className='file-name' style={{ color: `${themeColor.secondaryColor}` }}>
                                                            {acceptedFile && acceptedFile.name}
                                                        </div>
                                                        <Button variant='danger' type='button' {...getRemoveFileProps()} >
                                                            Remove
                                                        </Button>
                                                    </div> : ''}
                                                <ProgressBar />
                                            </div>
                                        </>
                                    )}
                                </CSVReader>
                                <div className="download-btn" style={{ color: `${themeColor.primaryColor}` }}>
                                    <MdOutlineSimCardDownload />
                                    <button className="link-btn" style={{ color: `${themeColor.primaryColor}` }} type='button' onClick={downloadCsv}>Download Sample Csv</button>
                                </div>
                                <Checkbox id='skipExisting' label={"Skip Existing"} greenCheckbox={true} checked={skipExisting} handleChange={() => setSkipExisting(!skipExisting)} />
                                <div className="btn-wrapper">
                                    <Link to='/dashboard'><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type={!disableField ? 'submit' : 'button'} {...(disableField ? { onClick: csvSubmitAction } : '')} disabled={loader} >Add {
                                        loader ?
                                            <div className='loader-wrapper btn-loader'>
                                                <Spinner animation="border" variant="white" />
                                            </div> : ''
                                    }</Button>
                                </div>
                            </form>
                        </div>
                        {
                            showProgressBar && loader ?
                                <div className="progressBar-wrapper">
                                    <ProgressBar animated now={progressStatus} label={`${progressStatus}%`} />
                                </div> : ''
                        }
                        {successPopup && !loader ?
                            addUserStatus ?
                                <div className="status-wrapper" style={{ color: `${themeColor.secondaryColor}` }}>
                                    <div className="status-container">
                                        {addUserStatus.syncedSuccessfulCount > 0 ?
                                            <div className="details">
                                                <p><b style={{ color: `${themeColor.primaryColor}` }}>{addUserStatus.syncedSuccessfulCount}</b> {addUserStatus.syncedSuccessfulCount > 1 ? 'Members' : 'Member'} Successfully Synced</p>
                                            </div>
                                            : ''}

                                        {addUserStatus.taggedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, tagged: !openITSDropDown.tagged })}><b style={{ color: `${themeColor.primaryColor}` }}>{addUserStatus.taggedCount}</b> Tagged {addUserStatus.taggedCount > 1 ? 'ITS Ids' : 'ITS Id'} {openITSDropDown.tagged ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.tagged && 'open'}`}>
                                                    <p>
                                                        {addUserStatus?.taggedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                    <CSVLink className='csv-btn' data={addUserStatus?.taggedITSIds.join() || ''} filename='Tagged ITS Ids'><MdOutlineSimCardDownload /></CSVLink>
                                                </div>
                                            </div>
                                            : ""}

                                        {addUserStatus.untaggedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, untagged: !openITSDropDown.untagged })}><b style={{ color: `${themeColor.primaryColor}` }}>{addUserStatus.untaggedCount}</b> {addUserStatus.untaggedCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Untagged {openITSDropDown.untagged ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.untagged && 'open'}`}>
                                                    <p>
                                                        {addUserStatus?.untaggedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                    <CSVLink className='csv-btn' data={addUserStatus?.untaggedITSIds.join() || ''} filename='UnTagged ITS Ids'><MdOutlineSimCardDownload /></CSVLink>
                                                </div>
                                            </div>
                                            : ""}
                                        {addUserStatus.invalidCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, invalid: !openITSDropDown.invalid })}><b style={{ color: `${themeColor.primaryColor}` }}>{addUserStatus.invalidCount}</b> {addUserStatus.invalidCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid {openITSDropDown.invalid ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.invalid && 'open'}`}>
                                                    <p>
                                                        {addUserStatus?.invalidITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                    <CSVLink className='csv-btn' data={addUserStatus?.invalidITSIds.join() || ''} filename='Invalid ITS Ids'><MdOutlineSimCardDownload /></CSVLink>
                                                </div>
                                            </div>
                                            : ""}
                                        {addUserStatus.skippedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, skipped: !openITSDropDown.skipped })}><b style={{ color: `${themeColor.primaryColor}` }}>{addUserStatus.skippedCount}</b> {addUserStatus.skippedCount > 1 ? 'ITS Ids' : 'ITS Id'} Skipped {openITSDropDown.skipped ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.skipped && 'open'}`}>
                                                    <p>
                                                        {addUserStatus?.skippedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                    <CSVLink className='csv-btn' data={addUserStatus?.skippedITSIds.join() || ''} filename='Skipped ITS Ids'><MdOutlineSimCardDownload /></CSVLink>
                                                </div>
                                            </div>
                                            : ""}
                                        {addUserStatus.errorCount > 0 ?
                                            <div className="details">
                                                <p><b style={{ color: `${themeColor.primaryColor}` }}>{addUserStatus.errorCount}</b> {addUserStatus.errorCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid</p>
                                            </div>
                                            : ""}
                                        {addUserStatus.errorCount === 0 && addUserStatus.untaggedCount === 0 && addUserStatus.syncedSuccessfulCount === 0 && addUserStatus.skippedCount === 0 && addUserStatus.taggedCount === 0 ?
                                            <div className="details">
                                                <p>No ITS Id Added</p>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                                : ''
                            : ''
                        }
                    </Col>
                </Row>
            </div>


        </div>
    )
}

export default AddUser