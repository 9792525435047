import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { baseUrl } from "../config";
import { ALERT_ERROR } from "../store/reducers/alert";

const modules = {
  toolbar: [
    // ... (your existing toolbar configurations)
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    [{ 'color': ['#E7825E'] }, { 'background': [] }],
    ["link"],
    ["clean"],
    ["image"],
  ],
};

const TextEditor = ({ label, name, error, control, onChange }) => {
  const [quill, setQuill] = useState(null);
  const [firstTimeRender, setFirstTimeRender] = useState(false);
  const quillEditor = quill?.getEditor();
  const dispatch = useDispatch()

  // const resizeFile = (file) => new Promise(resolve => {
  //   Resizer.imageFileResizer(file, 560, 1000, 'JPEG', 100, 0,
  //     uri => {
  //       resolve(uri);
  //     }, 'file');
  // });

  useEffect(() => {
    if (quill) {
      const toolbar = quill.getEditor().getModule("toolbar");

      if (toolbar) {
        const imageButton = toolbar.container.querySelector(".ql-image");

        if (imageButton) {
          imageButton.addEventListener("mousedown", handleImageButtonClick);
        }
      }
    }

    return () => {
      // Cleanup event listener on component unmount
      if (quill) {
        const toolbar = quill.getEditor().getModule("toolbar");

        if (toolbar) {
          const imageButton = toolbar.container.querySelector(".ql-image");

          if (imageButton) {
            imageButton.removeEventListener("mousedown", handleImageButtonClick);
          }
        }
      }
    };
  }, [quill]);

  const handleImageButtonClick = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async (changeEvent) => {
      const file = changeEvent.target.files[0];
      if (file) {
        handleImageUpload(file);
      }
    };
  };

  quillEditor?.keyboard.addBinding({
    key: 'Enter',
    handler: function (range, context) {
      // Prevent the default behavior of the Enter key
      return true;
    },
  });

  const handleImageUpload = async (file) => {
    var fileName = file.name;

    fileName = fileName.replace(/\s+/g, '');

    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    const fileNameWithoutExtension = fileName.split(".")[0];
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "gif" || extFile == "png" || extFile == "webp") {
      const token = Cookies.get('token');
      const formData = await new FormData();
      formData.append("FileName", fileNameWithoutExtension);
      formData.append("FileType", `image/${extFile}`);
      formData.append("File", file);
      formData.append("FolderName", 'emailTemplate');
      try {
        const data = await fetch(`${baseUrl}/api/Medias`, {
          method: "post",
          headers: {
            "Authorization": `Bearer ${token}`,
            // "Content-Type": "multipart/form-data"
          },
          body: formData,
        });

        const uploadedImage = await data.json();
        if (uploadedImage.fileName) {
          insertImage(uploadedImage)
        }

      } catch (error) {
        dispatch({
          type: ALERT_ERROR,
          payload: "Image upload failed",
        });
      }
    } else {
      dispatch({
        type: ALERT_ERROR,
        payload: "Select a image file",
      });
    }
  };

  const insertImage = (uploadedImage) => {
    if (quill) {
      const range = quillEditor.getSelection();
      const imagePath = `${baseUrl}/api/Medias/${uploadedImage.fileName}`;
      if (range) {
        quillEditor.insertEmbed(range.index, 'image', imagePath, 'user');
      } else {
        quillEditor.insertEmbed(quillEditor.getLength(), 'image', imagePath, 'user');
      }
    }
  };
  const convertClassesToInlineStyles = useCallback(async (content) => {
    if (quill) {
      const quillEditor = quill.getEditor();
      const editorContent = quillEditor.root;

      const alignCenterElements = editorContent.querySelectorAll('.ql-align-center');
      const alignRightElements = editorContent.querySelectorAll('.ql-align-right');
      const imageElem = editorContent.querySelectorAll(['img']);
      const paraElem = editorContent.querySelectorAll(['p']);

      alignCenterElements.forEach(element => {
        element.style.textAlign = 'center';
      });
      alignRightElements.forEach(element => {
        element.style.textAlign = 'right';
      });
      imageElem.forEach(element => {
        element.style.maxWidth = '100%';
      });
      paraElem?.forEach(element => {
        element.style.margin = '0';
        if (!element.classList || element.classList.length === 0) {
          element.style.textAlign = 'left';
        }
      });
      let htmlString = editorContent.innerHTML;
      onChange(htmlString.trim() === '<p></p>' || htmlString.trim() === '<p><br></p>' || htmlString.trim() === '<br>' || htmlString.trim() === '<p style="margin: 0px; text-align: left;"><br></p>' ? '' : htmlString)
    } else {
      onChange(content);
    }
  }, [quill, onChange]);

  return (
    <>
      <div className="field text-editor">
        {label ?
          <label>{label}</label>
          : ''
        }
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => {
            const handleChange = async (content) => {
              convertClassesToInlineStyles(content)
            };
            return (
              <>
                <ReactQuill
                  modules={{
                    ...modules,
                    toolbar: {
                      container: modules.toolbar,
                    },
                  }}
                  formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'align', 'link', 'image', 'color', 'background', 'code-block']}
                  value={value}
                  theme="snow"
                  onChange={handleChange}
                  placeholder="Content goes here..."
                  ref={(el) => setQuill(el)}
                />
                {/* <div dangerouslySetInnerHTML={{ __html: value }} /> */}
              </>
            )
          }}
        />
        {error ? <p className="error-message">{error}</p> : ""}
      </div>
    </>
  );
};

export default TextEditor;
