import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const AppModal = ({handleClose, showModal, title, hasBody, content, handleAction, actionBtnText,disable,cancelModal,cancelText}) => {
  return (
      <Modal centered show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
            {title ? <Modal.Title>{title}</Modal.Title>: ''}
        </Modal.Header>
        {hasBody? 
            <Modal.Body>
                {content}
            </Modal.Body>
         : ''}
        

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>{cancelText || 'Cancel'}</Button>
          {cancelModal ? 
            <Button disabled={disable?.length ? false:true} variant="success" onClick={handleAction}>{actionBtnText}</Button>
            : actionBtnText && <Button variant="success" onClick={handleAction}>{actionBtnText}</Button>
          }
        </Modal.Footer>
      </Modal>
  )
}

export default AppModal