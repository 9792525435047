import {
    COMMUNICATION_LOADING, COMMUNICATION_FAIL, ADD_COMMUNICATION
  } from "../actions/communicationAction";
  
  // export const CANCEL_EDIT_FORM = "CANCEL_EDIT_FORM";
  
  const initialState = {
    communicationData: [],
    // singleDepartment: {},
    loading: false,
    // deleteDepartment: false,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case COMMUNICATION_LOADING:
        return {
          ...state,
          loading: true,
        //   deleteDepartment: false,
        };
  
      case ADD_COMMUNICATION:
        return {
          ...state,
          // communicationData: action.payload,
          loading: false,
        };

      case COMMUNICATION_FAIL:
        return {
          ...state,
          loading: false,
        };
  
    //   case SINGLE_DEPARTMENT:
    //     return {
    //       ...state,
    //       singleDepartment: action.payload,
    //       loading: false,
    //     };
  
      //   case CANCEL_EDIT_FORM:
      //     return {
      //       ...state,
      //       singleDepartment: {},
      //     };
  
      case "USER_LOGOUT":
        return (state = initialState);
      default: {
        return state;
      }
    }
  };
  