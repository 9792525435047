import { ATTENDEES_DATA, REPORT_LOADING } from "../actions/MiqaatReportAction";

const initialState = {
    attandeesData: [],
    loading: false,
}

export default (state = initialState, action) =>{
    switch (action.type) {
        case REPORT_LOADING:
        return {
          ...state,
          loading: true,
          deleteProduct: false,
        };

        case ATTENDEES_DATA:
        return {
            ...state,
            attandeesData: action.payload,
            loading: false,
        };

        default: {
            return state;
        }   
}}