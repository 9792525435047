import DataTable from "react-data-table-component";
import { users, emailUsers } from '../../appContent'
import { Link, useNavigate } from "react-router-dom";
import { themeColor } from "../../config";
import { IoIosArrowBack } from "react-icons/io";
import { TbFileExport } from "react-icons/tb";
import InputField from "../../components/inputField";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { useDispatch } from "react-redux";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../store/reducers/alert";
import { Spinner } from "react-bootstrap";
import { GrFormView } from "react-icons/gr";
import moment from 'moment';
import PageTitle from "../../components/PageTitle";
import { MdDeleteOutline } from "react-icons/md";
import AppModal from "../../components/Modal";

const EmailLog = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');
  const [filterData, setFilterData] = useState([])
  const [emailLogUsers, setEmailLogUsers] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [deleteLogId, setDeleteLogId] = useState();

  const showErrorAlert = (msg) => {
    dispatch({
      type: ALERT_ERROR,
      payload: msg
    })
  }
  const fetchEmailUsersDetails = () => {
    setLoader(true);
    GetFetch(`/api/EmailLogs`, dispatch, navigate).then((response) => {
      if (response.status == 200) {
        setEmailLogUsers(response.data);
      }
      else {
        showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
      }
      setLoader(false);
    }).catch((err) => {
      console.log('error', err);
      showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
      setLoader(false);
    })
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  }

  useEffect(() => {
    fetchEmailUsersDetails()
  }, [])

  const getDetailsById = (id) => {
    navigate(`/email-log/${id}`)
  }

  const showSuccessAlert = (msg) => {
    dispatch({
      type: ALERT_SUCCESS,
      payload: msg
    })
  }

  const DeleteLog = (id) => {
    return new Promise((resolve, reject) => {
      PostFetch(`/api/EmailLogs/DeleteById/${id}`, "", 'DELETE').then((response) => {
        if (response.status == 200) {
          showSuccessAlert("EmailLog deleted successfully");
          resolve();
        }
        else {
          showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
          reject();
        }
        setLoader(false);
      }).catch((err) => {
        console.log('error', err);
        showErrorAlert(err.data.msg ? err.data.msg : "Something Went Wrong");
        setLoader(false);
        reject();
      })
    })
  }

  const deleteLog = (id) => {
    setShowModal(true);
    setDeleteLogId(id);
  }

  const confirDelete = () => {
    setShowModal(false);
    DeleteLog(deleteLogId).then(() => {
      fetchEmailUsersDetails();
    })
  }

  const columns = [
    {
      name: 'Sender ITS ID',
      selector: (row) => row.senderITSID ? row.senderITSID : ' ',
      sortable: true,
      minWidth: '130px',
      maxWidth: '130px'
    },
    {
      name: "Sender's Name",
      selector: (row) => row.senderFullName ? row.senderFullName : ' ',
      sortable: true,
      minWidth: '330px',
      maxWidth: '330px'
    },
    {
      name: 'Title',
      selector: (row) => row.subject ? row.subject : ' ',
      minWidth: '380px',
      maxWidth: '380px'
    },
    {
      name: 'Total Sent',
      selector: (row) => row.submittedSuccessfullyCount ? row.submittedSuccessfullyCount : ' ',
      sortable: true,
      minWidth: '130px',
      maxWidth: '130px'
    },
    {
      name: 'Created At',
      selector: (row) => row.logDate ? moment(row.logDate + "Z").format("lll") : ' ',
      sortable: true,
      minWidth: '200px',
      maxWidth: '200px'
    },
    {
      name: 'View',
      cell: (row) =>
        row.id ?
          <p className='action-btn-wrapper'><button className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} onClick={() => getDetailsById(row.id)} ><GrFormView /></button> <button className='action-btn' style={{ color: 'red', background: `${themeColor.lightGreen}` }} onClick={() => deleteLog(row.id)}><MdDeleteOutline /></button></p>
          : ' '
    },
    // {
    //   name: 'Download',
    //   cell: (row) =>
    //   emailUsers && emailUsers.length ?
    //   <CSVLink data={[row]}   filename={"Email-log.csv"}>
    //   <button className='icon-btn-profile' style={{justifyContent: "left"}}  > <TbFileExport/></button>
    // </CSVLink> :"",
    //     minWidth: '110px',
    //     maxWidth: '110px'
    // },
  ]

  const onSearch = async (e) => {
    setSearch(e.target.value)
    if (emailLogUsers && emailLogUsers.length) {
      const updateData = await emailLogUsers.filter((item, i) => item?.senderFullName?.toLowerCase()?.includes(e.target.value.toLowerCase())
        || item?.senderITSID?.toString()?.includes(e.target.value)
      )
      if (updateData.length) {
        setFilterData(updateData)
      } else {
        setFilterData([{ "senderFullName": "No Data Found" }])
      }
    }
  }

  return (
    <div className="main-wrapper">
      {loader ?
        <div className='loader-wrapper'>
          <Spinner animation="border" variant="success" />
        </div>
        :
        <div className="container">
          <PageTitle link='/dashboard' title='Email Log' />
          {emailLogUsers?.length ?
            <>
              <div className="table-wrapper view-user-table">
                <DataTable data={filterData?.length ? filterData : emailLogUsers} columns={columns}
                  pagination fixedHeader fixedHeaderScrollHeight='540px' dense
                  subHeader subHeaderComponent={
                    <div>
                      <InputField fieldPlaceholder={"Search by ITS/Name"} fieldType={'text'}
                        value={search} handleChange={onSearch} />
                    </div>}
                />
              </div>
            </>
            :
            <>
              <div className="table-wrapper view-user-table">
                <DataTable data={[{ "senderFullName": "No Data Found" }]} columns={columns}
                  pagination fixedHeader fixedHeaderScrollHeight='540px' dense
                  subHeader
                />
              </div>
            </>
          }
        </div>
      }
      <AppModal handleClose={() => setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
        handleAction={confirDelete} actionBtnText={"Confirm"} />
    </div>
  )

}
export default EmailLog;