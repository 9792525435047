
import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const getMiqaatData = () => (dispatch) => {
    dispatch({
        type: MIQAAT_LOADING,

    });
    GetFetch(`/api/Miqaats/getLiveMiqaats`, dispatch)
        .then((response) => {

            var data = response.data;
            if (response.status === 200) {
                dispatch({
                    type: ALL_MIQAAT_DATA,
                    payload: data,
                });
            } else {
                dispatch({
                    type: LOADING_FALSE,
                });
                dispatch({
                    type: ALERT_ERROR,
                    payload: "Something went wrong. Please try again later.",
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: LOADING_FALSE,
            });
            dispatch({
                type: MIQAAT_FAIL,
            });
            dispatch({
                type: ALERT_ERROR,
                payload: "Something went wrong. Please try again later.",
            });
        });
};
export const getSingleMiqaat = (id) => (dispatch) => {

    dispatch({
        type: MIQAAT_LOADING,

    });
    GetFetch(`/api/MiqaatReg/getRegisterDetail?miqaatId=${id}`)
        .then((response) => {

            var data = response.data;
            if (response.status === 200) {
                dispatch({
                    type: SINGLE_MIQAAT_DATA,
                    payload: data,
                });
            } else {
                dispatch({
                    type: MIQAAT_FAIL,
                });
                dispatch({
                    type: ALERT_ERROR,
                    payload: "Something went wrong. Please try again later.",
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: MIQAAT_FAIL,
            });
            dispatch({
                type: ALERT_ERROR,
                payload: "Something went wrong. Please try again later.",
            });
        });
};
export const addMiqaatQuestion = (data) => (dispatch, navigate) => {
    dispatch({
        type: MIQAAT_LOADING,

    });
    PostFetch(`/api/MiqaatReg/register`, data, 'POST')
        .then((response) => {
            if (response.status == 200) {
           
                dispatch({
                    type: LOADING_FALSE,

                });
                dispatch({
                    type: ALERT_SUCCESS,
                    payload: response.data.msg,
                });
                dispatch(getMiqaatData(data.miqaatId))
            } else {
                dispatch({
                    type: ALERT_ERROR,
                    payload: response.data.msg,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: MIQAAT_FAIL,
            });
            dispatch({
                type: ALERT_ERROR,
                payload: error?.data?.msg || "Something went wrong. Please try again later.",
            });
        });
};

export const cancelRegistration = (data) => (dispatch, navigate) => {
    dispatch({
        type: MIQAAT_LOADING,
    });
    PostFetch(`/api/MiqaatReg/cancel`, data, 'POST')
        .then((response) => {
            if (response.status == 200) {
           
                dispatch({
                    type: LOADING_FALSE,
                });
                dispatch({
                    type: ALERT_SUCCESS,
                    payload: response.data.msg,
                });
                dispatch(getMiqaatData(data.miqaatId))
              
            } else {
                dispatch({
                    type: ALERT_ERROR,
                    payload: response.data.msg,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: MIQAAT_FAIL,
            });
            dispatch({
                type: ALERT_ERROR,
                payload: "Something went wrong. Please try again later.",
            });
        });
};
export const ALL_MIQAAT_DATA = "ALL_MIQAAT_DATA";
export const MIQAAT_LOADING = "MIQAAT_LOADING";
export const LOADING_FALSE = "LOADING_FALSE";

export const MIQAAT_FAIL = "MIQAAT_FAIL";
export const SINGLE_MIQAAT_DATA = "SINGLE_MIQAAT_DATA";




