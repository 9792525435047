import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale,BarElement } from 'chart.js';
import { Pie, Bar, Line } from 'react-chartjs-2';
import { themeColor } from '../../config';
import { GetFetch } from '../../utils/fetchUrl';
import { Spinner } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';

const JamiatWiseReports = () => {
  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale,BarElement);
  const options = {
    responsive: true,
  }

  const [jamiatData,setJamiatData] = useState(null)
  const [loader,setLoader] = useState(false);
  const [listcount,setListCount] = useState([])

  const fetchJamiat = () => {
    setLoader(true)
    GetFetch(`/api/Reports/JamiatWise`).then((response) => {
        if (response.status == 200){
          setJamiatData(response.data);
        }
        else {
            console.log('Something went wrong');
          }
        setLoader(false);
    }).catch((err)=>{
        console.log('error', err);
        setLoader(false);
    })
  }

  useEffect(()=>{
    fetchJamiat()
  },[])

  useEffect(() => {
    if (jamiatData?.labels) {
      let lenArr = []
      let len = Math.ceil(jamiatData.labels.length / 10);
      for (let i = 0; i < len; i++) {
        lenArr.push(i*10)
      }
      setListCount(lenArr)
    }
  }, [jamiatData])

  const list = (item) => {
      return (
        <div className="head report-grid" >
          {jamiatData && jamiatData.labels ?
            jamiatData.labels.slice(item,item+10).map((label, i) => (
              <div  className='chart-count' style={{ color: `${themeColor.secondaryColor}` }}>
                <b>{label || '-'} :  </b>
                <p style={{ marginLeft: "10px" }}>{jamiatData.datasets[0].data[item+i]}</p>
              </div>
            ))
            : null}
        </div>
      )
  }

  return ( 
    <div className='main-wrapper'>
         {loader ?
            <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
            </div>
            :
            <div className="container ">
              <PageTitle link='/dashboard' title='JAMIAT WISE REPORT' />
                <div className="country-bar chart">
                        {jamiatData  && <Bar data={jamiatData} options={options} />}
                </div>
                <div className='chart-report' style={{background: `${themeColor.lightGreen}`,marginRight:"20px",width:"100%"}}>
                  <h5 style={{color: `${themeColor.secondaryColor}`}}>REPORT :</h5 >
                  <div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between' }} className='chart-details'>
                    {listcount.map((item)=>(
                      list(item)
                    ))
                    }
                  </div>                   
                </div>
            </div>
          }
    </div>
  )
}

export default JamiatWiseReports;