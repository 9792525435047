import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { MdModeEdit } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';
import { IoMdAddCircle } from 'react-icons/io';
import DataTable from 'react-data-table-component';
import InputField from '../../components/inputField';
import { themeColor } from '../../config';
import AppModal from '../../components/Modal';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { Button, Spinner } from 'react-bootstrap';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { dateFormatFunction } from '../../utils/dateFormat';
import PageTitle from '../../components/PageTitle';
import SelectField from '../../components/SelectField';
import Select from 'react-select';
import { FiPrinter } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddEditCities } from '../../utils/Schemas';

const CitiesList = () => {
    const [addEditLoader, setAddEditLoader] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [deleteCityId, setDeleteCityId] = useState();
    const [showAddSection, setShowAddSection] = useState(false);
    const [countries, setCountries] = useState([])
    const [isCityPopupOpen, setIsCityPopupOpen] = useState(false)
    const [cities, setCities] = useState([])
    const [filteredCities, setFilteredCities] = useState([])
    const [states, setStates] = useState([])
    const [statesOptions, setStatesOptions] = useState([])
    const [selectedCountry, setSelectedCountry] = useState({})
    const [selectedState, setSelectedState] = useState({})
    const [editCityId, setEditCityId] = useState('');
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { handleSubmit, register, reset, formState: { errors }, control, setValue, watch } = useForm({
        resolver: yupResolver(AddEditCities),
        defaultValues: {
            country: '',
            Name: ''
        }

    });
    const handleCountryChange = async (country) => {
        if (country) {
            setSelectedCountry(country)
            setLoader(true)
            GetFetch(`/api/Cities?countryId=${country?.id}`, dispatch, navigate).then((response) => {
                if (response.status == 200) {
                    setCities(response?.data || []);
                    setFilteredCities(response?.data || [])
                    setValue('Name', '')
                    setValue('state', '')
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                }
            }).finally(() => setLoader(false))
            GetFetch(`/api/States?id=${country?.id}`, dispatch, navigate).then((response) => {
                if (response.status == 200) {
                    setStates(response?.data);
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                }
            }).finally(() => setLoader(false))
        }
    }
    const handleStateChange = (state) => {
        if (state) {
            setSelectedState(state)
        }
    }

    useEffect(() => {
        if (watch('state')) {
            setSelectedState(watch('state'))
        }
    }, [watch('state')])


    const fetchCountries = () => {
        GetFetch('/api/Countries').then((response) => {
            if (response.status == 200) {
                setCountries(response.data);
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
        })
    }

    const DeleteTemplate = (id) => {
        return new Promise((resolve, reject) => {
            PostFetch(`/api/Cities/${id}`, "", 'DELETE').then((response) => {
                if (response.status == 200) {
                    showSuccessAlert("Successfully Deleted");
                    resolve();
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    reject();
                }
                setLoader(false);
            }).catch((err) => {
                console.log('error', err);
                showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
                setLoader(false);
                reject();
            })
        })
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const handleClose = () => {
        setIsCityPopupOpen(false)
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    const deleteCity = (id) => {
        setShowModal(true);
        setDeleteCityId(id);
    }

    const confirDelete = () => {
        setShowModal(false);
        DeleteTemplate(deleteCityId).then(() => {
            handleCountryChange(selectedCountry);
        })
    }

    const editCity = (city) => {
        setIsCityPopupOpen(true)
        setEditCityId(city.id)
        setValue('Name', city.name)
        setValue('state', { value: city.stateId, label: city.stateName })
    }

    useEffect(() => {
        if (states) {
            let data = []
            states.map((item) => (
                data.push({ value: item.id, label: item.name })
            ))
            setStatesOptions(data)
        }
    }, [states])

    const columns = [
        {
            name: 'City Name',
            selector: (row) => row.name ? row.name : ' ',
            // minWidth: '240px',
            sortable: true,
        },
        {
            name: 'State Name',
            selector: (row) => row.stateName ? row.stateName : ' ',
            // minWidth: '140px',
            // maxWidth: '200px'
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row) =>
                row.id ?
                <p className='action-btn-wrapper'>
                    <button
                        className='action-btn'
                        style={{ color: '#696969', background: `${themeColor.lightGreen}` }}
                        onClick={() => editCity(row)}>
                        <MdModeEdit />
                    </button>
                    <button
                        className='action-btn'
                        style={{ color: 'red', background: `${themeColor.lightGreen}` }}
                        onClick={() => deleteCity(row.id)}>
                        <MdDeleteOutline />
                    </button>
                </p> : '',
            // minWidth: '130px',
            // maxWidth: '180px'
        },
    ]


    useEffect(() => {
        fetchCountries();
    }, [])

    useEffect(() => {
        if (selectedCountry) {
            reset();
            setSelectedState({})
        }
    }, [selectedCountry])

    const onSubmit = async (values) => {
        setAddEditLoader(true);
        const body = { Name: values.Name, StateId: selectedState.value, CountryId: selectedCountry.id }
        if (editCityId) {
            await PostFetch(`/api/Cities/${editCityId}`, body, "PUT").then((response) => {
                if (response.status === 200) {
                    showSuccessAlert('City Updated Successfully');
                    setIsCityPopupOpen(false)
                    setSelectedState({});
                    reset();
                    setEditCityId('')
                    setSearch('')
                    handleCountryChange(selectedCountry);
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    setAddEditLoader(false);
                }
                setAddEditLoader(false);
            }).catch((error) => {
                console.log('error', error);
                showErrorAlert(error.data.msg ? error.data.msg : "Something Went Wrong");
                setAddEditLoader(false);
            })
        }
        else {
            await PostFetch('/api/Cities/', body, "POST").then((response) => {
                if (response.status === 200) {
                    showSuccessAlert('City Added Successfully');
                    setIsCityPopupOpen(false)
                    setSearch('')
                    setSelectedState({});
                    reset();
                    handleCountryChange(selectedCountry);
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    setAddEditLoader(false);
                }
                setAddEditLoader(false);
            }).catch((error) => {
                console.log('error', error);
                showErrorAlert(error.data.msg ? error.data.msg : "Something Went Wrong");
                setAddEditLoader(false);
            })
        }
    }

    const cancel = () => {
        setEditCityId('')
        setValue('Name', '')
        setValue('state', undefined)
        setShowAddSection(false)
    }
    const onSearch = (e) => {
        setSearch(e.target.value);
        filterCities(e.target.value);
    }
    const filterCities = (searchTerm) => {
        if (cities && cities?.length) {
            const filterData = cities?.filter((item) => {
                return Object.keys(item).some((key) =>
                    item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            if (filterData.length) {
                setFilteredCities(filterData)
            } else {
                setFilteredCities([{ "name": "No Data Found" }])
            }
        }
    }
    return (
        <>
            <div className='main-wrapper' >
                <div className='container'>
                    <PageTitle link='/dashboard' title='Cities' />
                    <div className="two_col">
                        <div className="table-wrapper action-table">
                            <DataTable
                                data={filteredCities || [{ "name": "No Data Found" }]}
                                columns={columns}
                                subHeader
                                progressPending={loader}
                                progressComponent={<Spinner animation="border" variant="success" />}
                                fixedHeader fixedHeaderScrollHeight='482px'
                                pagination
                                subHeaderComponent={
                                    <div className='crud-head'>
                                        <div className="field">
                                            <label>Select Country</label>
                                            <Select
                                                options={countries}
                                                value={selectedCountry || {}}
                                                onChange={handleCountryChange}
                                                getOptionLabel={option => option?.name}
                                                getOptionValue={option => option}
                                                placeholder='Select Country'
                                            />
                                        </div>
                                        <InputField
                                            fieldPlaceholder={"Search by City/State"}
                                            fieldType={'text'}
                                            value={search}
                                            handleChange={onSearch}
                                        />
                                        <button
                                            className="icon-btn dark"
                                            style={{ background: `${themeColor.primaryColor}` }}
                                            onClick={() => setShowAddSection(true)}
                                        >
                                            <IoMdAddCircle /> Add City
                                        </button>
                                    </div>
                                }
                                subHeaderAlign="left"
                            />
                        </div>
                        <AppModal handleClose={() => setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
                            handleAction={confirDelete} actionBtnText={"Confirm"} />

                        <div className={`details ${showAddSection && 'active'}`} >
                            <h4 className='primary-heading'> {editCityId ? 'Edit City' : 'Add City'}</h4>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="field">

                                    <SelectField fieldLabel={'State:'} options={statesOptions} name={'state'}
                                        placeholder={'Select State'} value={watch('state')} register={{ ...register("state") }}
                                        error={errors.state?.message} control={control} />

                                </div>
                                <InputField
                                    fieldPlaceholder={'Enter City Name'}
                                    name={'Name'}
                                    fieldType={'text'}
                                    fieldLabel={'City:'}
                                    error={errors.Name?.message}
                                    register={{ ...register('Name') }} />
                                <Button
                                    variant="success"
                                    type='submit'
                                    style={{ marginTop: '24px', marginRight: '10px' }}
                                    disabled={!selectedCountry?.id || addEditLoader}
                                >
                                    Submit
                                    {
                                        addEditLoader
                                            ? <div className='loader-wrapper btn-loader'>
                                                <Spinner animation="border" variant="white" />
                                            </div>
                                            : ''
                                    }
                                </Button>
                                <Button
                                    variant="danger"
                                    color='error'
                                    onClick={cancel}
                                    style={{ marginTop: '24px' }}
                                >

                                    Cancel
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default CitiesList