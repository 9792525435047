import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RxDashboard } from 'react-icons/rx';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { FiEdit, FiPhone } from 'react-icons/fi';
import { BiEnvelope } from 'react-icons/bi';
import { BiLogOut } from 'react-icons/bi';
import { isEmpty, get } from 'lodash'
import { setLogin, setProfileData } from '../store/actions/login';
import { profileUrl, themeColor } from '../config';
import { GetFetch, PostFetch } from '../utils/fetchUrl';
import placeholderUserImg from '../assets/images/profile.png';
import AppModal from '../components/Modal';
import { ALERT_ERROR, ALERT_SUCCESS } from '../store/reducers/alert';
import { GrClose } from 'react-icons/gr';
import InputField from '../components/inputField';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import MiqaatCard from './MIQAAT/QUESTIONAIRE/MiqaatCard';
import { getMiqaatData } from '../store/actions/getMiqaatData';
import RatingsTable from '../components/RatingsTable';

const Profile = () => {
    const [userDetails, setUserDetails] = useState({});
    const [additionalDetails, setAdditionalDetails] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [token, setToken] = useState();
    const [userItsId, setUserItsId] = useState();
    const [userDetailsLength, setUserDetailsLength] = useState();
    const [currentMiqaats, setCurrentMiqaats] = useState({})
    const [showPlaceholderImg, setShowPlaceholderImg] = useState(false);
    const [editUserDetails, setEditUserDetails] = useState(false);
    const [disableUpdate, setDisableUpdtae] = useState(true)
    const MiqaatState = useSelector((state) => state.dailyquestion)

    const params = useParams();
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.login);
    const navigate = useNavigate();

    const editValidation = yup.object().shape({
        email: yup.mixed().required("Email Required"),
        mobileNumber: yup.string().required("Mobile Number Required"),
    }).required("required");

    const { handleSubmit, register, watch, reset, setValue, formState: { errors }, control } = useForm({
        resolver: yupResolver(editValidation),
    });

    useEffect(() => {
        if (token && userItsId) {
            dispatch(getMiqaatData())
        }
    }, [token])

    useEffect(() => {
        if (!isEmpty(get(MiqaatState, 'miqaatData'))) {
            setCurrentMiqaats(get(MiqaatState, 'miqaatData'))
        }
    }, [get(MiqaatState, 'miqaatData')])

    const fetchUserDetails = () => {
        setLoader(true);
        GetFetch(`/api/User/profile`, dispatch, navigate).then((response) => {
            if (response.status == 200) {
                setUserDetails(response.data);
                dispatch(setProfileData(response.data))
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                setErrMsg('Something went wrong');
            }
            setLoader(false);
        }).catch((err) => {
            console.log('error', err);
            setLoader(false);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
        })
    }
    const fetchAditionalDetails = () => {
        setLoader(true);
        GetFetch(`/api/User/getProfileDetail?ITSID=${userItsId}`, dispatch, navigate).then((response) => {
            if (response.status == 200) {
                setAdditionalDetails(response.data);
                dispatch(setProfileData(response.data))
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                setErrMsg('Something went wrong');
            }
            setLoader(false);
        }).catch((err) => {
            console.log('error', err);
            setLoader(false);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
        })
    }

    const logout = () => {
        Cookies.remove('token');
        Cookies.remove('userItsId');
        dispatch(setLogin(false));
        navigate('/', { replace: true });
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    useEffect(() => {
        if (params.token && params.its) {
            Cookies.set('token', params.token, { expires: 1 });
            Cookies.set('userItsId', params.its, { expires: 1 });
        }
        dispatch(setLogin(true));
        setToken(Cookies.get('token'));
        setUserItsId(Cookies.get('userItsId'));
        navigate('/profile');
    }, [])

    useEffect(() => {
        if (loginState && token && userItsId) {
            fetchUserDetails();
            fetchAditionalDetails();
        }
    }, [userItsId])

    useEffect(() => {
        if (userDetails) {
            setUserDetailsLength(Object.keys(userDetails).length);
        }
    }, [userDetails])

    const submitAction = (data) => {
        const formData = data
        PostFetch('api/edit-profile', formData, "POST").then((response) => {
            setLoader(true);
            if (response.status === 200) {
                dispatch({
                    type: ALERT_SUCCESS,
                    payload: "Updated Successfully"
                })
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                setLoader(false);
            }
            setLoader(false);
        }).catch((error) => {
            console.log('error', error);
            showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
            setLoader(false);
        })
    }

    useEffect(() => {
        if (watch('email') && watch('mobileNumber').length >= 10) {
            setDisableUpdtae(false)
        } else {
            setDisableUpdtae(true)
        }
    }, [watch('email'), watch('mobileNumber')])

    return (
        <div className='main-wrapper profile-wrapper'>
            <div className="container-fluid">
                {loader ?
                    <div className='loader-wrapper'>
                        <Spinner animation="border" variant="success" />
                    </div>
                    :
                    <div className="profile-content-wrapper">
                        <Row className='justify-content-center'>
                            <Col lg={4} style={{padding: '0 20px'}}>
                                <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}` }}>Profile</h2>
                                {(userDetailsLength > 0 && Object.keys(additionalDetails).length > 0) ?
                                    <>
                                        <div className="profile-head">
                                            <img src={`${profileUrl}${userDetails.itsid}.png`} onError={(e) => e.target.src = placeholderUserImg} alt="profile" />
                                            <div className="details">
                                                <b style={{ color: `${themeColor.primaryColor}` }}>{userDetails.fullName ? userDetails.fullName : '-'}</b>
                                                <p>{userDetails.itsid ? userDetails.itsid : '-'}</p>
                                            </div>
                                        </div>
                                        <div className='pofile-details'>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>PERSONAL INFORMATION</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Mobile No.:</b>
                                                    {userDetails.mobNo ? <a href={`tel:${userDetails.mobNo}`}><p>{userDetails.mobNo}</p></a> : <p>-</p>}
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Watsapp No.:</b>
                                                    {userDetails.whatsApp_No ? <a href={`tel:${userDetails.whatsApp_No}`}><p>{userDetails.whatsApp_No}</p></a> : <p>-</p>}
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Email:</b>
                                                    {userDetails.email ? <a href={`tel:${userDetails.email}`}><p>{userDetails.email}</p></a> : <p>-</p>}
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Age:</b>
                                                    <p>{userDetails.age ? userDetails.age : '-'} years</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Gender:</b>
                                                    <p>{userDetails.gender ? userDetails.gender : '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Qualification:</b>
                                                    <p>{userDetails.qualification ? userDetails.qualification : '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Vatan:</b>
                                                    <p>{userDetails.vatan ? userDetails.vatan : '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Nationality:</b>
                                                    <p>{userDetails.nationality ? userDetails.nationality : '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>JAMAAT INFORMATION</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Jamiat:</b>
                                                    <p>{userDetails.jamiat ? userDetails.jamiat : '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Jamaat:</b>
                                                    <p>{userDetails.jamaat ? userDetails.jamaat : '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>ITS No. :</b>
                                                    <p>{userDetails.itsid ? userDetails.itsid : '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Position:</b>
                                                    <p>{userDetails.positionName ? userDetails.positionName : '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>OCCUPATION</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Occupation:</b>
                                                    <p>{additionalDetails.occupationName || '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Type:</b>
                                                    <p>{additionalDetails.subOccupationName || '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>EDUCATION</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Qualification:</b>
                                                    <p>{additionalDetails.qualStageName || '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Graduation Name:</b>
                                                    <p>{additionalDetails.qualGraduateName || '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Post Graduation Name:</b>
                                                    <p>{additionalDetails.qualPostGraduateName || '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Doctorate:</b>
                                                    <p>{additionalDetails.qualPhdName || '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Education Specialization in Graduation:</b>
                                                    <p>{additionalDetails.graduationSpecialisations.length > 0 ?
                                                        additionalDetails.graduationSpecialisations.map((item)=> `${item.name}, `)
                                                     : '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Education Specialization in Post Graduation:</b>
                                                    <p>{additionalDetails.postGraduationSpecialisations && additionalDetails.postGraduationSpecialisations.length > 0 ?
                                                        additionalDetails.postGraduationSpecialisations.map((item)=> `${item.name}, `)
                                                     : '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>DIPLOMA</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <p>{additionalDetails.diploma || '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>ASBAAQ</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <p>{additionalDetails.asbaaqName || '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>SKILL SET</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <p>{additionalDetails.skills.length > 0 ?
                                                        additionalDetails.skills.map((item)=> `${item.name}, `)
                                                     : '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>Languages</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <p>{additionalDetails.languages.length > 0 ?
                                                        additionalDetails.languages.map((item)=> `${item.name}, `)
                                                     : '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>Current Location</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>Country</b>
                                                    <p>{additionalDetails.countryName || '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>State</b>
                                                    <p>{additionalDetails.stateName || '-'}</p>
                                                </div>
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <b>City</b>
                                                    <p>{additionalDetails.cityName || '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-info-wrapper">
                                            <div className="head">
                                                <h4>Burhani Foundation</h4>
                                            </div>
                                            <div className="info-wrapper">
                                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                                    <p>{additionalDetails.nazafatProjects.length > 0 ?
                                                        additionalDetails.nazafatProjects.map((item)=> `${item.name}, `)
                                                     : '-'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </>
                                    : ''
                                }
                            </Col>
                            <Col lg={8} style={{padding: '0 20px'}}>
                                <div className="app-head" style={{ display: 'block', marginBottom: '15px' }}>
                                    <h5 className='primary-heading' style={{ color: `${themeColor.primaryColor}`, marginBottom: '20px' }}>Current Miqaat</h5>
                                    <div className='current-miqaat-wrapper' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between" }}>
                                        {currentMiqaats && currentMiqaats.length > 0 ?
                                            <MiqaatCard token={token} userItsId={userItsId} currentMiqaats={currentMiqaats} />
                                            :
                                            <p style={{ fontSize: '13px', marginTop: '12px' }}>No active miqaat found</p>
                                        }
                                    </div>
                                </div>
                                {
                                    (additionalDetails.ratings && additionalDetails.ratings.length > 0) ?
                                    <div className='user-ratings-wrapper'>
                                        <h5 className='primary-heading' style={{ color: `${themeColor.primaryColor}`, marginBottom: '10px' }}>History</h5>
                                        <RatingsTable data={additionalDetails.ratings} />
                                    </div>
                                    : ''
                                }
                                <div className="btn-wrapper user">
                                    <button className='icon-btn' style={{ color: `${themeColor.primaryColor}` }} onClick={() => setShowModal(true)}><BiLogOut /> Logout</button>
                                    <Link to='/dashboard' className='icon-btn dark' style={{ background: `${themeColor.primaryColor}` }}><RxDashboard />Go To Dashboard</Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
            </div>

            <AppModal handleClose={() => setShowModal(false)} showModal={showModal} title={'Confirm Logout'}
                handleAction={logout} actionBtnText={"Logout"} />
            {editUserDetails ?
                <div className="popup-wrapper">
                    <div className="popup-container">
                        <div className="popup-head">
                            <h4>Update Profile</h4>
                            <button className='close-btn' onClick={() => setEditUserDetails(false)}><GrClose /></button>
                        </div>
                        <div className="filter-wrapper">
                            <form onSubmit={handleSubmit(submitAction)} >
                                <InputField fieldPlaceholder={"Enter Email"} fieldType={'text'}
                                    fieldLabel={'Email:'} error={errors.email?.message}
                                    register={{ ...register("email") }} name={'email'} />
                                <InputField fieldPlaceholder={"Enter Phone Number"} fieldType={'text'}
                                    fieldLabel={'Mobile Number :'} error={errors.mobileNumber?.message}
                                    register={{ ...register("mobileNumber") }} name={'mobileNumber'} />
                                <Button disabled={disableUpdate} variant='success' style={{ marginTop: '30px', width: '100%' }} type='submit'  >Update</Button>
                            </form>
                        </div>
                    </div>
                </div>
                : null
            }

            {errMsg.length > 0 ?
                <div className="loader-wrapper">
                    <h4>{errMsg}</h4>
                    <div className="btn-wrapper">
                        <Link to='/dashboard' className='icon-btn dark' style={{ background: `${themeColor.primaryColor}` }}><RxDashboard />Go To Dashboard</Link>
                    </div>
                </div>

                : ''
            }
        </div>

    )
}

export default Profile;