import React from 'react'

const RadioButton = ({label, name, checked, handleChange, greenRadioButton, disabled, id,value,defaultChecked}) => {
  return (
    <div className={`radio-btn ${greenRadioButton? 'green' : ''}`}>
        <input id={id} type="radio" name={name} value={value} disabled={disabled} defaultChecked={defaultChecked} checked={checked} onChange={handleChange} />
        {label ? 
          <label htmlFor={id} >{label}</label>
          : ''
        }
        
    </div>
  )
}

export default RadioButton