import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { themeColor } from '../../../config'
import InputField from '../../../components/inputField'
import { AddEditProductInOutSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ProductInData } from '../../../appContent'
import SelectField from '../../../components/SelectField'
import { dateFormatFunction } from '../../../utils/dateFormat'
import BasicDateTimePicker from '../../../components/DateNTimePicker'
import dayjs from 'dayjs'
import PageTitle from '../../../components/PageTitle'

const AddEditPurchase = () => {
  const params = useParams()
  const [btnLoader, setBtnLoader] = useState(false)
  const [fieldValues, setFieldValues] = useState()

  const { handleSubmit, register, reset, formState: { errors }, setValue, control } = useForm({
    resolver: yupResolver(AddEditProductInOutSchema)
  })

  const products = [
    {value: 1, label: 'Vacuum cleaner'},
    {value: 2, label: 'Laptop'},
    {value: 3, label: 'Waste Bin'},
  ]

  const submitAction = (data) => {
    setBtnLoader(false)
    reset()
  }
  useEffect(() => {
    if (params?.purchaseId) {
      const id = params.purchaseId - 1
      setFieldValues(ProductInData[id])
    }else{
      setValue('dateNTime', dayjs(new Date()))
    }
  }, [params])
  useEffect(() => {
    if (fieldValues) {
      setValue('product', {value: fieldValues?.productId, label: fieldValues?.name})
      setValue('dateNTime', dayjs(fieldValues?.insertedAt))
      setValue('ItsId', fieldValues?.ItsId)
      setValue('quantity', fieldValues?.quantity)
    }
  }, [fieldValues])

  return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7} sm={12}>
                      <PageTitle link={`/view-miqaat/${params.id}/product/out`} title={`${params?.purchaseId ? 'Edit' : 'New'} Purchase`} />
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <SelectField fieldLabel="Product" options={products} name={'product'}
                                placeholder="Select Product" register={{ ...register('product') }}
                                error={errors.product?.message} control={control} />

                                <BasicDateTimePicker
                                    label='Purchase Date and Time'
                                    name='dateNTime'
                                    error={errors.dateNTime?.message} control={control}
                                />
                                
                                <InputField fieldPlaceholder={'Enter Quantity'} name={'quantity'} fieldType={'number'} fieldLabel={'Quantity'}
                                error={errors.quantity?.message} register={{ ...register('quantity') }} />


                                <div className="btn-wrapper">
                                    <Link to={`/view-miqaat/${params.id}/purchase`}><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type='submit' disabled={btnLoader} >Save{
                                        btnLoader
                                          ? <div className='loader-wrapper btn-loader'>
                                            <Spinner animation="border" variant="white" />
                                        </div>
                                          : ''
                                    }</Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
  )
}

export default AddEditPurchase
