import React from 'react'

const Checkbox = ({label, name, checked, handleChange, greenCheckbox, disabled, id, className}) => {
  return (
    <div className={`checkbox ${greenCheckbox? 'green' : ''} ${className}`}>
        <input id={id} type="checkbox" name={name} disabled={disabled} checked={checked} onChange={(e)=>handleChange(e)} />
        {label ? 
          <label htmlFor={id} >{label}</label>
          : ''
        }
        
    </div>
  )
}

export default Checkbox