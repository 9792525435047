import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdDeleteOutline, MdModeEdit, MdOutlineTaskAlt } from 'react-icons/md'
import { GrFormView, GrClose } from 'react-icons/gr'
import { Button, Spinner } from 'react-bootstrap'
import { themeColor } from '../../../config'
import DataTable from 'react-data-table-component'
import InputField from '../../../components/inputField'
import { venueData } from '../../../appContent'
import ReactToPrint, { useReactToPrint } from 'react-to-print'
import QRCode from 'qrcode.react';
import { useDispatch, useSelector } from 'react-redux'
import { AllVenueAction, DeleteVenueAction } from '../../../store/actions/venueAction'
import { SINGLE_TEAM } from '../../../store/actions/teamAction'
import AppModal from '../../../components/Modal'
import nazafatLogo from '../../../assets/images/Logo_Nazafat.png'
import PageTitle from '../../../components/PageTitle'

const Venue = () => {
  let printRef = useRef();
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [deleteVenueId, setDeleteVenueId] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [viewVenueId, setViewVenueId] = useState()
  const [viewVenueDetails, setViewVenueDetails] = useState()
  const [openViewVenuePopup, setOpenViewVenuePopup] = useState(false)
  const venuesDatas = useSelector((state) => state.venuereducer)

  const [openViewQr, setOpenViewQr] = useState({
    name: '',
    permission: false,
    imgSrc: '',
    value: '',
    miqaatId: '',
    radius: ''
  })

  const columns = [
    {
      name: 'Name',
      selector: (row) => row?.name,
      // maxWidth: '78%'
    },
    {
      name: 'QR Code',
      selector: (row) =>
        <button className='action-btn qr' onClick={() => setOpenViewQr({
          name: row.name,
          permission: true,
          imgSrc: venueData[0]?.QrCode,
          value: row.id,
          miqaatId: params?.id,
        })}><img src={venueData[0]?.QrCode} alt="qr" /></button>,
      maxWidth: '140px'
    },
    {
      name: 'Actions',

      // <button className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} onClick={() => { setViewVenueId(row.id); setOpenViewVenuePopup(true) }}><GrFormView /></button>
      cell: (row) => <p className='action-btn-wrapper'> <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/${params?.id}/zone/edit/${row.id}`} ><MdModeEdit /></Link><button className='action-btn' style={{ color: 'red', background: `${themeColor.lightGreen}` }} onClick={() => deleteVenue(row.id)}><MdDeleteOutline /></button> </p>,
      maxWidth: '120px'

      // cell: (row) => <p className='action-btn-wrapper'><button className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} onClick={() => { setViewVenueId(row.id); setOpenViewVenuePopup(true) }}><GrFormView/></button>
      //  <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/venue/edit/${row.id}`} ><MdModeEdit/></Link>
      // <button className='action-btn' style={{ color: 'red', background: `${themeColor.lightGreen}` }}><MdDeleteOutline/></button> </p>

    }
  ]

  useEffect(() => {
    dispatch({
      type: SINGLE_TEAM,
      singleVenue: {}
    })
    dispatch(AllVenueAction(params?.id, navigate))
  }, [params])


  useEffect(() => {
    if (venuesDatas?.venusData?.length > 0) {
      setFilteredData(venuesDatas?.venusData)
    } else {
      setFilteredData(venuesDatas?.venusData)
    }
  }, [venuesDatas?.venusData])

  useEffect(() => {
    if (search.length > 0) {
      const result = venuesDatas?.venusData?.filter(data => Object.keys(data).some(key => {
        return String(data['name']).toLowerCase().includes(search.toLowerCase())
      }))
      setFilteredData(result)
    } else {
      setFilteredData(venuesDatas?.venusData)
    }
  }, [search])


  useEffect(() => {
    if (venuesDatas?.venusData) {
      const result = venuesDatas?.venusData && venuesDatas?.venusData?.filter((venue) => {
        return venue.id && venue.id.toString().match(viewVenueId && viewVenueId.toString())
      })
      setViewVenueDetails(result)
    }
  }, [viewVenueId])

  const downloadQRCode = useReactToPrint({
    content: () => printRef.current,
  });

  // const downloadQRCode = () => {
  //   const qrCodeURL = document.getElementById('qr-popup')
  //     // .toDataURL("image/png")
  //     // .replace("image/png", "image/octet-stream");
  //   let aEl = document.createElement("a");
  //   aEl.href = qrCodeURL;
  //   aEl.download = "QR_Code.png";
  //   document.body.appendChild(aEl);
  //   aEl.click();
  //   document.body.removeChild(aEl);
  // }

  const deleteVenue = (id) => {
    setDeleteVenueId(id)
    setShowModal(true)
  }

  const confirDelete = () => {
    let data = {
      id: deleteVenueId,
      miqaatId: params?.id
    }
    dispatch(DeleteVenueAction(data))
    setShowModal(false)
  }

  return (
    <div className='main-wrapper'>
      <style>
        {`@media print {
          @page {
            size: Potrait; 
            margin: 15px;
          }
          .popup-container {
            width: 100% !important;
          }
          .popup-head h4{
            text-align: center;
            width: 100%
          }
          .qr-wrapper canvas{
            width: 400px !important;
            height: 400px !important;
          }
          .qr-wrapper .logo{
            width: 56px !important;
            height: 56px !important;
          }
          .popup-container{
            max-height: unset;
            height: 100%;
          }
          .popup-container .details{
            margin-top: 200px;
          }
          }`
        }
      </style>
      <div className="container">
        <PageTitle link={`/view-miqaat/${params?.id}`} title='ZONE' />
        {venuesDatas?.loading
          ? <div className='loader-wrapper'>
            <Spinner animation="border" variant="success" />
          </div>
          : <div className="table-wrapper role-management-table venue">
              <header style={{marginBottom: '16px'}}>
                <div>
                <InputField className={'search-field'} fieldPlaceholder={'Search Zone'} fieldType={'text'} value={search}
                  handleChange={(e) => setSearch(e.target.value)} />
                <Link to={`/view-miqaat/${params?.id}/zone/add`} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}` }}><IoMdAddCircle />Add Zone</Link>
                </div>
              </header>
              <div className="table-footer">
                <p className="note">
                  To scroll the report horizontally, press the Shift key and
                  scroll using your mouse's scroll wheel.
                </p>
                <span>Count: {filteredData?.length}</span>
              </div>
              <DataTable data={filteredData} columns={columns}
              // fixedHeader fixedHeaderScrollHeight='482px'
              // subHeader
               subHeaderAlign="left" />
          </div>
        }

        {openViewVenuePopup && viewVenueDetails && viewVenueDetails.length > 0
          ? <div className="popup-wrapper">
            <div className="popup-container">
              <div className="popup-head">
                <h4>{viewVenueDetails[0].name}</h4>
              </div>
              <div className="details">
                <div className="details-block">
                  <h5>Venue Address:</h5>
                  <p>{viewVenueDetails[0].address}</p>
                </div>
                <div className="details-block">
                  <h5> Assigned Teams:</h5>
                  {viewVenueDetails[0].assigned?.map((team) => (
                    <div className="flex" key={team.label}>
                      <MdOutlineTaskAlt />
                      <p>{team.label}</p>
                    </div>
                  ))}

                </div>
              </div>
              <button className='close-btn' onClick={() => setOpenViewVenuePopup(false)}><GrClose /></button>
            </div>
          </div>
          : ''
        }
        {
          openViewQr?.permission
            ? <div className="popup-wrapper">
              <div className="popup-container" ref={printRef}>
                <div className="popup-head">
                  <h4>{openViewQr.name}</h4>
                </div>
                <div className="details">
                  <div className="qr-wrapper">
                    {/* <img ref={el => (componentRef = el)} src={openViewQr.imgSrc} alt="qr" /> */}
                    <QRCode
                      id="qrCodeEl"
                      size={250}
                      value={`"venueId-${openViewQr?.value} miqaatId- ${openViewQr?.miqaatId}"`}
                    />
                    <img className='logo' src={nazafatLogo} alt="logo" />
                  </div>
                </div>
                <div className="print-btn-wrapper">
                  <Button variant={'success'} onClick={downloadQRCode}>Print</Button>
                  {/* <ReactToPrint
                    trigger={() => {
                      return <Button variant={'success'}>Print</Button>
                    }}
                    content={() => componentRef}
                    documentTitle='Print Qr Code'
                    pageStyle='print'
                  /> */}
                </div>
                <button className='close-btn' onClick={() => setOpenViewQr({
                  permission: false
                })}><GrClose /></button>
              </div>
            </div>
            : ''
        }

        <AppModal handleAction={confirDelete} handleClose={() => setShowModal(false)} hasBody={true} content='Are you sure, You want to delete Zone?' showModal={showModal} title={'Confirm Delete'} actionBtnText={"Confirm"} />
      </div>
    </div>
  )
}

export default Venue
