import {
    QUESTION_LOADING,
    ALL_QUESTIONS,
    QUESTION_FAIL,
    ADD_QUESTION,
    DELETE_QUESTION,
    UPDATE_QUESTION,
    SINGLE_QUESTION,
    REMOVE_QUESTIONS
  } from "../actions/questionAction";
  
  // export const CANCEL_EDIT_FORM = "CANCEL_EDIT_FORM";
  
  const initialState = {
    questionData:[],
    questionnaireList: [],
    singleQuestion: {},
  
    loading: false,
    deleteQuestion: false,
  };
  
  export default (state = initialState, action) => {
    // console.log("---action", action)
    switch (action.type) {
      case QUESTION_LOADING:
        return {
          ...state,
          loading: true,
          deleteQuestion: false,
        };
  
      case ALL_QUESTIONS:
        return {
          ...state,
          questionData: action.payload,
          loading: false,
        };
  
      case ADD_QUESTION:
        return {
          ...state,
          questionData: action.payload,
          loading: false,
        };

      case REMOVE_QUESTIONS:
        return {
          questionData: [],
          loading: true,
        };
      case DELETE_QUESTION:
        return {
          ...state,
          loading: false,
          deleteQuestion: true,
        };
  
      case QUESTION_FAIL:
        return {
          ...state,
          loading: false,
        };
  
        case SINGLE_QUESTION:
        return {
          ...state,
          singleQuestion: action.payload,
          loading: false,
        };
  
    //   case CANCEL_EDIT_FORM:
    //     return {
    //       ...state,
    //       singleQuestion: {},
    //     };
  
      case UPDATE_QUESTION:
        return {
          ...state,
          loading: false,
        };
  
      case "USER_LOGOUT":
        return (state = initialState);
      default: {
        return state;
      }
    }
  };
  