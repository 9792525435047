import React from 'react';
import './style.css';
import nazafatLogo from '../assets/images/Logo_Nazafat.png';
import minalImamLogo from '../assets/images/minal-iman-logo.png';
import healthLogo from '../assets/images/health-logo.png'

const Header = () => {
  return (
    <div className="header">
        <div className='header_wrapper' style={{background: `url(/images/header-top-bg.png)`}}>
            <div className="container">
                <img src={`${nazafatLogo}`} alt="logo" />
                <img src={`${minalImamLogo}`} alt="logo" />
                <img src={`${healthLogo}`} alt="logo" />
            </div>
        </div>
    </div>
  )
}

export default Header