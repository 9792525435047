

// export const CANCEL_EDIT_FORM = "CANCEL_EDIT_FORM";

import { ALL_MIQAAT_DATA, MIQAAT_LOADING, MIQAAT_FAIL, SINGLE_MIQAAT_DATA, LOADING_FALSE } from "../actions/getMiqaatData";

const initialState = {
    miqaatData: [],
    single_miqaat: {},

    loading: false,
    deleteMiqaat: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        //   case QUESTION_LOADING:
        //     return {
        //       ...state,
        //       loading: true,
        //       deleteQuestion: false,
        //     };

        case ALL_MIQAAT_DATA:
            return {
                ...state,
                miqaatData: action.payload,
                loading: false,
            };

        case SINGLE_MIQAAT_DATA:
            return {
                ...state,
                single_miqaat: action.payload,
                loading: false,
            };

        case MIQAAT_LOADING:
            return {
                ...state,
                loading: true,
            };
        case LOADING_FALSE:
            return {
                ...state,
                loading: false,
            };
        case MIQAAT_FAIL:
            return {
                ...state,
                loading: false,
            };
        case "USER_LOGOUT":
            return (state = initialState);
        default: {
            return state;
        }
    }
};
