import {
  DEPARTMENTS_LOADING, ALL_DEPARTMENTS, DEPARTMENT_FAIL, ADD_DEPARTMENT, DELETE_DEPARTMENT, UPDATE_DEPARTMENT, SINGLE_DEPARTMENT
} from "../actions/departmentAction";

// export const CANCEL_EDIT_FORM = "CANCEL_EDIT_FORM";

const initialState = {
  departmentData: [],
  singleDepartment: {},
  loading: false,
  deleteDepartment: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DEPARTMENTS_LOADING:
      return {
        ...state,
        loading: true,
        deleteDepartment: false,
      };

    case ALL_DEPARTMENTS:
      return {
        ...state,
        departmentData: action.payload,
        loading: false,
      };

    case ADD_DEPARTMENT:
      return {
        ...state,
        // departmentData: action.payload,
        loading: false,
      };

    case DELETE_DEPARTMENT:
      return {
        ...state,
        loading: false,
        deleteDepartment: true,
      };

    case DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SINGLE_DEPARTMENT:
      return {
        ...state,
        singleDepartment: action.payload,
        loading: false,
      };

    //   case CANCEL_EDIT_FORM:
    //     return {
    //       ...state,
    //       singleDepartment: {},
    //     };

    case UPDATE_DEPARTMENT:
      return {
        ...state,
        singleDepartment: action.payload,
        loading: false,
      };

    case "USER_LOGOUT":
      return (state = initialState);
    default: {
      return state;
    }
  }
};
