import { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useTheme } from '@mui/material/styles';
import InputField from '../../../../components/inputField';
import { FormHelperText } from '@mui/material';
export default function RadioButtonInput({ options, type, id, singleMiqaat, defaultValue, handleChange, isRequired, value, label, otherFieldValue, setOtherFieldValue }) {
    const theme = useTheme();
    const [fIndex, setFIndex] = useState();

    const handleInputChange = (e) => {
        let fVal = [...otherFieldValue];
        fVal[fIndex].val = e;
        setOtherFieldValue(fVal);
    }

    useEffect(() => {
        if (options && options.length > 0) {
            let fVal = [...otherFieldValue]
            options.map((option) => {
                if (option.value && option.value.toLowerCase().includes('other')) {
                    if (value?.toLowerCase().includes('other') && !otherFieldValue.some(item => item.id === id)) {
                        fVal = [
                            ...fVal,
                            {id: id, val: value?.replace('other: ', '')}
                        ]
                    }else if(!otherFieldValue.some(item => item.id === id)){
                        fVal = [
                            ...fVal,
                            {id: id, val: ''}
                        ]
                    }
                }
            })
            setOtherFieldValue(fVal)
        }
    }, [singleMiqaat])

    useEffect(()=>{
        if(otherFieldValue){
            const index = otherFieldValue.findIndex(item => item.id === id);
            setFIndex(index)
        }
    }, [otherFieldValue])

    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={defaultValue}
                value={typeof value === 'string' && value?.toLowerCase().includes('other') ? 'Other' : value}
                onChange={(val) => handleChange(val, '', type)}

            >
                {options?.map((option, i) => (
                    <>
                        <FormControlLabel key={i} value={option.placeholder} control={<Radio
                        />} label={option.placeholder} />
                        {
                            option.placeholder.toLowerCase() === "other" &&  
                            <>
                                {typeof value === 'string' && value?.toLowerCase().includes('other') &&
                                    <InputField key={i} handleChange={(e)=>{handleChange('other',i,type, e.target.value); handleInputChange(e.target.value)}}  val={otherFieldValue[fIndex]?.val ? otherFieldValue[fIndex].val : ''} fieldType={'text'} />
                                }
                            </> 
                        }

                    </>
                ))}
                {isRequired ? <FormHelperText
                    sx={{ color: '#d32f2f' }}
                >
                    This field is required
                </FormHelperText> : null}
            </RadioGroup>
        </FormControl>
    );
}
