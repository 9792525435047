import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import SelectField from '../../components/SelectField';
import { themeColor } from '../../config';
import { GetFetch } from '../../utils/fetchUrl';
import { PostFetch } from '../../utils/fetchUrl';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { setDashboardData } from '../../store/actions/login';
import PageTitle from '../../components/PageTitle';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const SyncUserByJamaatJamiat = () => {
    const dashboardData = useSelector((state) => state.login.dashboard);
    const [jamaats, setJamaats] = useState();
    const [jamiats, setJamiats] = useState();
    const [jamaatDetails, setJamaatDetails] = useState();
    const [jamiatDetails, setJamiatDetails] = useState();
    const [dashboardD, setDashboardD] = useState([])
    const [jamaatId, setJamaatId] = useState({
        'JamaatIds': []
    });
    const [syncUserStatus, setSyncUserStatus] = useState();
    const [successPopup, setSuccessPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [jamaatDetailsByJamiat, setJamaatDetailsByJamiat] = useState([]);
    const [selectedJamiat, setSelectedJamiat] = useState([]);
    const [selectedJamaat, setSelectedJamaat] = useState([]);
    const [idError, setIdError] = useState(false)
    const [jamiatError, setJamiatError] = useState(false)
    const [allSelectData, setAllSelectDashData] = useState({})
    const [profileData, setProfileData] = useState({})
    const [openITSDropDown, setOpenITSDropDown] = useState({
        untagged: false,
        invalid: false,
        skipped: false,
        tagged: false
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const schemaJamaat = yup.object().shape({
        Jamaat: yup.mixed().required("Jamaat Required"),
        Jamiat: yup.mixed().required("Jamiat Required"),
    }).required("required");
    const { handleSubmit, register, reset, watch, setValue, formState: { errors }, control } = useForm({
        resolver: yupResolver(schemaJamaat),
    });

    const syncUser = () => {
        setBtnLoader(true);
        PostFetch('/api/user/sync', jamaatId, "POST").then((response) => {
            if (response.status === 200) {
                setSyncUserStatus(response.data);
                setSuccessPopup(true);
                showSuccessAlert(`Sucessfully Synced`);
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                setBtnLoader(false);
            }
            setBtnLoader(false);
        }).catch((error) => {
            console.log('error', error);
            showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
            setBtnLoader(false);
        })

    }

    useEffect(() => {
        setLoader(true);
        GetFetch(`/api/Dashboard`, dispatch, navigate).then((response) => {
            if (response.status == 200) {
                setDashboardD(response.data);
                dispatch(setDashboardData(response.data));
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
            setLoader(false);
        }).catch((err) => {
            console.log('error', err);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
            setLoader(false);
        })
    }, [])

    useEffect(() => {
        if (dashboardData && dashboardData.length) {
            dashboardData.map((item, i) => {
                if (item.name == "Communication") {
                    item && item.childrens && item.childrens.map((childItem, i) => {
                        if (childItem.name == "Jamiat/Jamaat") {
                            setAllSelectDashData(childItem)
                        }
                    })
                }
            })
        }
    }, [dashboardData])

    const fetchUserDetails = () => {
        GetFetch(`/api/User/profile`).then((response) => {
            if (response.status == 200) {
                setProfileData(response.data);
            }
            else {
                showErrorAlert('Something went wrong');
            }
        }).catch((err) => {
            console.log('error', err);
            showErrorAlert(err)
        })
    }

    const fetchJamaat = () => {

        GetFetch(`/api/Jamaat`).then((response) => {
            if (response.status == 200) {
                setJamaatDetails(response.data);
            }
        }).catch((err) => {
            console.log('error', err);
        })
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    const getJamaatByJamiat = (id) => {
        GetFetch(`/api/Jamaat?jamiatId=${id}`).then((response) => {
            if (response.status == 200) {
                setJamaatDetailsByJamiat(response.data);
            }

        }).catch((err) => {
            console.log('error', err);
        })
    }

    const fetchJamiat = () => {

        GetFetch(`/api/Jamiat`).then((response) => {
            if (response.status == 200) {
                setJamiatDetails(response.data);
            }
        }).catch((err) => {
            console.log('error', err);
        })
    }
    const resetFields = () => {
        reset();
        setSelectedJamiat([]);
        setSelectedJamaat([])
    }
    const submitAction = () => {
        if (jamaatId && jamaatId.JamaatIds && jamaatId.JamaatIds.length > 0) {
            if (!idError) {
                syncUser();
            }
        }
    }
    const syncUserStatusDetails = (a) => {
        if (syncUserStatus && a && a.length > 0) {
            return a.map((its) => { return its + ' ' })
        }
        else {
            return '-'
        }
    }

    useEffect(() => {
        fetchJamaat();
        fetchJamiat();
        fetchUserDetails();
    }, [])

    useEffect(() => {
        if (jamaatDetails) {
            let dataJamaat = []
            if (allSelectData.jamiatPermission == "All") {
                jamaatDetails.map((jamaat) => (
                    dataJamaat.push({ value: jamaat.jamaatId, label: jamaat.name })
                ))
                dataJamaat.unshift({ value: -1, label: "All Select" })
            } else if (allSelectData.jamiatPermission == "Own") {
                if (profileData && profileData.jamiat) {
                    jamaatDetails.map((jamiat, i) => {
                        if (jamiat.jamaatId == profileData.jamaatId) {
                            dataJamaat.push({ value: jamiat.jamaatId, label: jamiat.name })
                            setValue("Jamaat", { value: jamiat.jamaatId, label: jamiat.name })
                        }
                    })
                }
            }
            else if (allSelectData.jamiatPermission == "Selected") {
                let jamiatArray = allSelectData.jamiatIds
                jamiatArray && jamiatArray.map((id, i) => {
                    jamaatDetails && jamaatDetails.map((jamaat, i) => {
                        if (jamaat.jamiatId == id) {
                            dataJamaat.push({ value: jamaat.jamaatId, label: jamaat.name })
                        }
                    })
                })
            }
            let data = watch("Jamiat")
            if (data == undefined || data == []) {
                setJamaats(undefined)
            } else {
                setJamaats(dataJamaat)
            }
        }
    }, [jamaatDetails, profileData])

    useEffect(() => {
        if (jamiatDetails) {
            let dataJamiat = []
            if (allSelectData.jamiatPermission == "All") {
                jamiatDetails.map((jamiat) => (
                    dataJamiat.push({ value: jamiat.jamiatId, label: jamiat.name })
                ))
                dataJamiat.unshift({ value: -1, label: "All Select" })
            }
            else if (allSelectData.jamiatPermission == "Own") {
                if (profileData && profileData.jamiat) {
                    jamiatDetails.map((jamiat, i) => {
                        if (jamiat.name == profileData.jamiat) {
                            dataJamiat.push({ value: jamiat.jamiatId, label: jamiat.name })
                            setValue("Jamiat", { value: jamiat.jamiatId, label: jamiat.name })
                        }
                    })
                }
            }
            else if (allSelectData.jamiatPermission == "Selected") {
                let jamiatArray = allSelectData.jamiatIds
                jamiatArray && jamiatArray.map((id, i) => {
                    jamiatDetails && jamiatDetails.map((jamiat) => {
                        if (jamiat.jamiatId == id) {
                            dataJamiat.push({ value: jamiat.jamiatId, label: jamiat.name })
                        }
                    })

                })
                dataJamiat.unshift({ value: -1, label: "All Select" })
            }
            setJamiats(dataJamiat)
        }
    }, [jamiatDetails, profileData])

    useEffect(() => {
        let data = watch("Jamiat")
        if (data == undefined || data == []) {
            setJamaats(undefined)
        }
        if (data && data.value != -1) {
            setValue('Jamaat', [])
        }
        if (data && data.length > 0) {
            setJamiatError(false)
            let filteredJamaats = []
            if (allSelectData.jamiatPermission == "Selected") {
                let jamaatIds = []
                data && data.map((data, i) => {
                    if (data.value == -1) {
                        jamaats && jamaats.map((jamaats, i) => {
                            jamaatIds.push(jamaats.value)
                        })
                    }
                })
                setJamaatId({
                    'JamaatIds': jamaatIds
                });
            }
            filteredJamaats.unshift({ value: -1, label: "All Select" })
            data.map((filterJ) => {
                jamaatDetails.map((item) => {
                    if (filterJ.label == item.jamiat) {
                        filteredJamaats.push({ value: item.jamaatId, label: item.name })
                    }
                })
                if (filterJ.value == -1) {
                    setValue("Jamiat", filterJ)
                }
            })
            { data == undefined || data == [] ? setJamaats(undefined) : setJamaats(filteredJamaats) }

        }
        else if (jamaatDetails) {

            let dataJamaat = []
            if (allSelectData.jamiatPermission == "Selected") {
                let jamiatArray = allSelectData.jamiatIds
                jamiatArray && jamiatArray.map((id, i) => {
                    jamaatDetails && jamaatDetails.map((jamaat, i) => {
                        if (jamaat.jamiatId == id) {
                            dataJamaat.push({ value: jamaat.jamaatId, label: jamaat.name })
                        }
                    })
                })
            } else {
                jamaatDetails.map((jamaat) => (
                    dataJamaat.push({ value: jamaat.jamaatId, label: jamaat.name })
                ))
                if (allSelectData.jamaatPermission == "All") {
                    dataJamaat.unshift({ value: -1, label: "All Select" })
                }
            }
            if (data && data.length) {
                setJamaats(dataJamaat)
                setJamiatError(false)
            } else if (data && data.value == -1) {
                setJamaats(dataJamaat)
                setJamiatError(false)
            }
            else {
                setJamaats(undefined)
            }
        }
    }, [watch("Jamiat")])

    useEffect(() => {
        let data = watch("Jamaat")
        if (data && data.value == -1) {
            setIdError(false)
            if (allSelectData.jamiatPermission == "Selected") {
                let watchJamiat = watch("Jamiat")
                if (watchJamiat && watchJamiat.value == -1) {
                    setJamaatId({
                        'JamaatIds': jamaatId.JamaatIds
                    });
                } else {
                    let allId = []
                    jamaats && jamaats.map((id, i) => {
                        if (id.value != -1) {
                            allId.push(id.value)
                        }
                    })
                    setJamaatId({
                        'JamaatIds': allId
                    });
                }
            } else {
                let watchJamiat = watch("Jamiat")
                if (watchJamiat && watchJamiat.value == -1) {
                    setJamaatId({
                        'JamaatIds': [-1]
                    });
                } else {
                    let allId = []
                    jamaats && jamaats.map((id, i) => {
                        if (id.value != -1) {
                            allId.push(id.value)
                        }
                    })
                    setJamaatId({
                        'JamaatIds': allId
                    });
                }
            }
        } else {
            if (data && !data.length) {
                setIdError(true)
            }
            let Id = []
            data && data.length > 0 && data.map((item, i) => {
                setIdError(false)
                Id.push(item.value)
                if (item.value == -1) {
                    setValue("Jamaat", item)
                }
                setJamaatId({
                    'JamaatIds': Id
                });
            })
        }
    }, [watch("Jamaat")])

    return (
        <div>
            <div className='main-wrapper'>
                <div className="container">
                    <Row className='justify-content-center'>
                        <Col lg={7}>
                            <PageTitle link='/sync-user' title='BY JAMIAT/JAMAAT' />
                            <div className="form-wrapper">
                                <form onSubmit={handleSubmit(submitAction)} >
                                    <SelectField isMulti={true} fieldLabel={'Jamiat:'} options={jamiats} name={`Jamiat`}
                                        placeholder={'Select Jamiat'} register={{ ...register("Jamiat") }}
                                        error={errors.Jamiat?.message} control={control} />
                                    <SelectField isMulti={true} fieldLabel={'Jamaat:'} options={jamaats} name={'Jamaat'}
                                        placeholder={'Select Jamaat'} register={{ ...register("Jamaat") }}
                                        error={errors.Jamaat?.message} control={control} />
                                    {idError && !errors.Jamaat ?
                                        <span className="note" style={{ color: "red" }}>Please Select Jamaat</span> : ''
                                    }
                                    <div className="btn-wrapper">
                                        <Link to='/sync-user'><Button variant="danger">Cancel</Button></Link>
                                        <Button variant="success" type='submit' disabled={btnLoader}>Sync{
                                            btnLoader ?
                                                <div className='loader-wrapper btn-loader'>
                                                    <Spinner animation="border" variant="white" />
                                                </div> : ''
                                        }</Button>
                                    </div>
                                </form>
                            </div>
                            {successPopup && !loader ?
                                syncUserStatus ?
                                    <div className="status-wrapper" style={{ color: `${themeColor.secondaryColor}` }}>
                                        <div className="status-container">
                                            {syncUserStatus.syncedSuccessfulCount > 0 ?
                                                <div className="details">
                                                    <p><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.syncedSuccessfulCount}</b> {syncUserStatus.syncedSuccessfulCount > 1 ? 'Members' : 'Member'} Successfully Synced</p>
                                                </div>
                                                : ''}

                                            {syncUserStatus.taggedCount > 0 ?
                                                <div className="details">
                                                    <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, tagged: !openITSDropDown.tagged })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.taggedCount}</b> Tagged {syncUserStatus.taggedCount > 1 ? 'ITS Ids' : 'ITS Id'} {openITSDropDown.tagged ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                    <div className={`its-ids ${openITSDropDown.tagged && 'open'}`}>
                                                        <p>
                                                            {syncUserStatus?.taggedITSIds?.map((item) => (
                                                                <span>{item}, </span>
                                                            ))}
                                                        </p>
                                                    </div>
                                                </div>
                                                : ""}

                                            {syncUserStatus.untaggedCount > 0 ?
                                                <div className="details">
                                                    <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, untagged: !openITSDropDown.untagged })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.untaggedCount}</b> {syncUserStatus.untaggedCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Untagged {openITSDropDown.untagged ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                    <div className={`its-ids ${openITSDropDown.untagged && 'open'}`}>
                                                        <p>
                                                            {syncUserStatus?.untaggedITSIds?.map((item) => (
                                                                <span>{item}, </span>
                                                            ))}
                                                        </p>
                                                    </div>
                                                </div>
                                                : ""}
                                            {syncUserStatus.invalidCount > 0 ?
                                                <div className="details">
                                                    <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, invalid: !openITSDropDown.invalid })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.invalidCount}</b> {syncUserStatus.invalidCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid {openITSDropDown.invalid ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                    <div className={`its-ids ${openITSDropDown.invalid && 'open'}`}>
                                                        <p>
                                                            {syncUserStatus?.invalidITSIds?.map((item) => (
                                                                <span>{item}, </span>
                                                            ))}
                                                        </p>
                                                    </div>
                                                </div>
                                                : ""}
                                            {syncUserStatus.skippedCount > 0 ?
                                                <div className="details">
                                                    <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, skipped: !openITSDropDown.skipped })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.skippedCount}</b> {syncUserStatus.skippedCount > 1 ? 'ITS Ids' : 'ITS Id'} Skipped {openITSDropDown.skipped ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                    <div className={`its-ids ${openITSDropDown.skipped && 'open'}`}>
                                                        <p>
                                                            {syncUserStatus?.skippedITSIds?.map((item) => (
                                                                <span>{item}, </span>
                                                            ))}
                                                        </p>
                                                    </div>
                                                </div>
                                                : ""}
                                            {syncUserStatus.errorCount > 0 ?
                                                <div className="details">
                                                    <p><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.errorCount}</b> {syncUserStatus.errorCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid</p>
                                                </div>
                                                : ""}
                                            {syncUserStatus.errorCount === 0 && syncUserStatus.untaggedCount === 0 && syncUserStatus.syncedSuccessfulCount === 0 && syncUserStatus.skippedCount === 0 && syncUserStatus.taggedCount === 0 ?
                                                <div className="details">
                                                    <p>No ITS Id Added</p>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                    : 'Something went wrong'
                                : ''
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default SyncUserByJamaatJamiat