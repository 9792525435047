import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { themeColor } from '../../../config'
import InputField from '../../../components/inputField'
import { AddEditTeamSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import SelectField from '../../../components/SelectField'
// import { miqaatTableData, venueOptions } from '../../../appContent'
import TextareaField from '../../../components/TextareaField'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from "lodash";
import { AddTeamAction, GetSingleTeamAction, SINGLE_TEAM, UpdateTeamAction } from '../../../store/actions/teamAction'
import { AllVenueAction } from '../../../store/actions/venueAction'
import { handleChangeMemberITS } from '../../../utils/helper'
import PageTitle from '../../../components/PageTitle'

const AddEditTeam = () => {
  // const [miqaatStatus, setMiqaatStatus] = useState(0)
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [fieldValues, setFieldValues] = useState()
  const [venuesList, SetVenueList] = useState([])
  const teamData = useSelector((state) => state.teamreducer)
  const venusData = useSelector((state) => state.venuereducer)
  const { handleSubmit, register, reset, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(AddEditTeamSchema)
  })

  const submitAction = (data) => {

    let newData = {
      miqaatId: params?.id,
      name: data.Team,
      leadITSID: data.TeamLead,
      venues: handleVenueSelect(data),
      // tasks:  [{name: 'Plantation'},{name: 'Kitchen Cleaning'},{name: 'Masjid Safai'}],
      memberITSIDs: handleChangeMemberITS(data)
    }
    if (params?.teamId) {
      let updateData = { ...newData, id: params?.teamId }
      dispatch(UpdateTeamAction(updateData, params?.id, navigate))
    } else {
      dispatch(AddTeamAction(newData, params?.id, navigate))
    }
    // setBtnLoader(false)
  }

  const handleVenueSelect = (data) => {
    if (data) {
      let venueData = []
      data?.Venue?.map((res) => {
        venueData.push(res.id)
      })
      return venueData
    }
  }

  useEffect(() => {
    dispatch(AllVenueAction(params?.id, navigate))
  }, [])

  useEffect(() => {
    if (!isEmpty(get(venusData, 'venusData'))) {
      let newVenue = []
      venusData?.venusData.map((res) => {
        let data = {
          id: res.id,
          label: res.name,
          value: res.name
        }
        newVenue.push(data)
      })
      // newVenue.unshift({ label: "All Select", value: -1 })
      SetVenueList(newVenue)
    }
  }, [get(venusData, 'venusData'), params?.teamId])

  useEffect(() => {
    if (params?.teamId) {
      dispatch(GetSingleTeamAction(params?.teamId))
    }
  }, [params?.teamId])

  useEffect(() => {
    if (params?.teamId && teamData.singleTeam) {
      setFieldValues(teamData.singleTeam)
    }
  }, [params])

  useEffect(() => {
    if (params?.teamId && teamData && teamData?.singleTeam) {
      let newVenue = []
      teamData?.singleTeam?.venues?.map((res) => {
        let obj = {
          id: res.id,
          label: res.name,
          value: res.name
        }
        newVenue.push(obj)
      })
      var alertString;
      let memberIts = []

      teamData?.singleTeam?.members?.map((data) => {
        memberIts.push(data.itsid)
      })
      let its = memberIts
      for (var i = 0; i < its?.length; i++) {
        if (i == 0) {
          alertString = its[i]
        } else {
          alertString = alertString + '\n' + its[i];
        }
      }
      setValue('Team', teamData?.singleTeam?.name)
      setValue('Venue', newVenue)
      setValue('TeamLead', teamData?.singleTeam?.leadITSID)
      setValue('ItsIds', alertString)
    }
  }, [teamData, params?.id])
  // useEffect(() => {
  //   reset(fieldValues)
  // }, [fieldValues, reset])
  const handleBack = () => {
    dispatch({
      type: SINGLE_TEAM,
      singleVenue: {}
    })
  }
  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <PageTitle link={`/view-miqaat/${params?.id}/view-team`} title={`${params?.teamId ? 'Edit' : 'Add'} Team`} onClick={handleBack} />
            {teamData.loading ?
              <div className='loader-wrapper'>
                <Spinner animation="border" variant="succeszs" />
              </div> : <div className="form-wrapper">
                <form onSubmit={handleSubmit(submitAction)}>
                  <SelectField isMulti={true} fieldLabel={'Zone:'} options={venuesList} name={'Venue'}
                    placeholder={'Select Zone'} register={{ ...register('Venue') }}
                    error={errors.Venue?.message} control={control} />
                  <InputField fieldPlaceholder={'Enter Team Name'} name={'Team'} fieldType={'text'} fieldLabel={'Team Name:'}
                    error={errors.Team?.message} register={{ ...register('Team') }} />
                  <InputField fieldPlaceholder={'Enter Team Lead ITS'} name={'TeamLead'} fieldType={'text'} fieldLabel={'Team Lead ITS IDs:'}
                    error={errors.TeamLead?.message} register={{ ...register('TeamLead') }} />

                  <TextareaField fieldPlaceholder={'Enter ITS ID(s)'}
                    fieldLabel={'Team Member ITS ID(s):'} name={'ItsIds'}
                    error={errors.ItsIds?.message} register={{ ...register('ItsIds') }} />
                  <h6 className="note" style={{ color: "green" }}>Press Enter After Every ITS ID</h6>
                  <div className="btn-wrapper">
                    <Link to={`/view-miqaat/${params?.id}/view-team`} onClick={handleBack}><Button variant="danger">Cancel</Button></Link>
                    <Button variant="success" type='submit' disabled={teamData.loading} >Save{
                      teamData.loading
                        ? <div className='loader-wrapper btn-loader'>
                          <Spinner animation="border" variant="white" />
                        </div>
                        : ''
                    }</Button>
                  </div>
                </form>
              </div>}

          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddEditTeam
