
const initialState = {
    login: false,
    dashboard:[],
    profileData:{}
 
  };

const login = (state = initialState, action) => {
    switch(action.type) {
        case 'SETLOGIN': {
            return {
              ...state,
              login: action.payload,
            };
          }
        case 'SETDASHBOARD': {
            return {
              ...state,
              dashboard: action.payload,
            };
          }
        case 'SETPROFILE': {
            return {
              ...state,
              profileData: action.payload,
            };
          }
        default : return state;
    }
}

export default login;