import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FiZoomIn } from "react-icons/fi";
import PageTitle from '../../components/PageTitle'
import ApexChartsComponent from '../../components/ApexChartsComponent'
import { Spinner } from 'react-bootstrap';
import { GetFetch } from '../../utils/fetchUrl'
import { GrClose } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TbFileExport } from 'react-icons/tb';
import { themeColor } from '../../config';
import { toJpeg } from 'html-to-image';

const GeneralStatisics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const printRef = useRef();
  const [reportData, setReportData] = useState(null)
  const [loader,setLoader] = useState(false);
  const [printIdCardLoading, setPrintIdCardLoading] = useState(false);
  const [zoomInChart, setZoomInChart] = useState({
    type: '',
    show: false,
    data: '',
    title: ''
  });

  const customLegendFormatter = (seriesName, opts) => {
    return `
          <div class="legend-item-label">${seriesName}</div>
          <div class="legend-item-value">${opts.w.globals.series[opts.seriesIndex]}</div>
      `;
  };
  const customRadialLegendFormatter = (seriesName, opts, series) => {
    return `
          <div class="legend-item-label">${seriesName}</div>
          <div class="legend-item-value">${series[opts.seriesIndex]} (${opts.w.globals.series[opts.seriesIndex]}%)</div>
      `;
  };

  const [chartData, setChartData] = useState({
    taggedUntaggedData:{
      series: [],
        options: {
          chart: {
              type: 'pie',
          },
          colors:['#D44F3A', '#7F4DC3', '#2499C3'],
          tooltip: {
            y: {
              formatter: function(value, opts) {
                const total = opts.config.series.reduce((acc, num) => acc + num, 0)
                return Math.round((value / total) * 100) + "%";
              }
            }
          },
          labels: [],
          dataLabels: {
            enabled: false
          },
          legend: {
            position: 'bottom',
            formatter: customLegendFormatter,
          },
      },
    },
    deviceInfoData: {
      series: [],
      options: {
        chart: {
          type: 'donut',
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          colors: ['#fff']
        },
        colors:['#FF91A9', '#7F4DC3', '#3E98B5'],
        labels: [],
        yaxis:{
          show: false
        },
        tooltip: {
          y: {
            formatter: function(value, opts) {
              const total = opts.config.series.reduce((acc, num) => acc + num, 0)
              return Math.round((value / total) * 100) + "%";
            }
          }
        },
        legend: {
          position: 'right',
          formatter: customLegendFormatter,
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    },
    genderRatioData:{
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        colors:['#FF91A9', '#7F4DC3', '#3E98B5'],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
                formatter: function (val){
                  return `${val}`
                }
              },
              total: {
                show: true,
                label: 'Total',
                formatter: function (w) {
                  return ''
                }
              }
            }
          }
        },
        labels: [],
      },
    },
    jamiatData:{
      series: [{
          name: 'Jamiat Ratio',
          data:  []
      }],
      options:{
          chart: {
              height: 350,
              type: 'bar',
          },
          dataLabels:{
            enabled: false
          },
          plotOptions: {
              bar: {
                  borderRadius: 5,
              }
          },
          xaxis: {
              categories: [],
              labels: {
                style: {
                  fontSize: '10px'
                }
              },
              crosshairs: {
              fill: {
                  type: 'gradient',
                  gradient: {
                  colorFrom: '#D8E3F0',
                  colorTo: '#BED1E6',
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5,
                  }
              }
              },
              tooltip: {
              enabled: true,
              }
          },
      }
    },
    emailSendData:{
      series: [{
        name: "Email Sent",
        data: []
      }],
      options: {
        chart: {
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        labels: [],
        xaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
            style: {
              fontSize: '11px'
            }
          },
        }
      },
    },
    miqaatCreateData: {
      series: [{
          name: "Miqaat",
          data: []
      }],
      options: {
        chart: {
          type: 'line',
        },
        // stroke: {
        //   curve: 'smooth'
        // },
        yaxis: {
          min: 0,
          tickAmount: 4,
        },
        xaxis: {
          labels: {
            rotate: -25,
            rotateAlways: true,
            style: {
              fontSize: '11px'
            }
          },
          categories: [],
        }
      },
    },
  })

  const fetchReportData = () => {
    setLoader(true)
    GetFetch(`/api/Reports/GeneralStats`, dispatch, navigate).then((response) => {
        if (response.status == 200){
          setReportData(response.data); 
          setTimeout(() => {
            setLoader(false)
          }, 1500);
        }
        else {
            console.log('Something went wrong');
            setLoader(false)
          }
    }).catch((err)=>{
        console.log('error', err);
        setLoader(false);
    })
  }

  useEffect(()=>{
    fetchReportData()
  },[])

  useEffect(()=>{
    if(reportData){
      const genderSum = reportData?.genderRatioData.series.reduce((acc, num) => acc + num, 0);
      const genderSeries = reportData?.genderRatioData.series.map(value => isNaN((value / genderSum) * 100) ? 0 : Math.round((value / genderSum) * 100));
      setChartData({
        taggedUntaggedData:{
          ...chartData.taggedUntaggedData,
          series: [...reportData?.taggedUntaggedData.series],
          options: {
            ...chartData.taggedUntaggedData.options,
            labels: [...reportData?.taggedUntaggedData.labels]
          }
        },
        genderRatioData:{
          ...chartData.genderRatioData,
          series: [...genderSeries],
          options: {
            ...chartData.genderRatioData.options,
            labels: [...reportData?.genderRatioData.labels],
            legend: {
              ...chartData.genderRatioData.options.legend,
              formatter: (seriesName, opts) => customRadialLegendFormatter(seriesName, opts, reportData?.genderRatioData.series)
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  ...chartData.genderRatioData.options.plotOptions.radialBar.dataLabels,
                  value: {
                    fontSize: '16px',
                    formatter: function (val, opts) {
                      const index = opts.config.series.indexOf(parseInt(val));
                      return reportData?.genderRatioData.series[index]
                    }
                  },
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      return genderSum
                    }
                  }
                }
              }
            }
          }
        },
        jamiatData: {
          ...chartData.jamiatData,
          series:[...reportData?.jamiatData.series],
          options: {
            ...chartData.jamiatData.options,
            xaxis:{
              ...chartData.jamiatData.options.xaxis,
              categories: [...reportData?.jamiatData.categories]
            }
          }
        },
        emailSendData: {
          ...chartData.emailSendData,
          series:[{
            name: "Email Sent",
            data: [...reportData?.emailSendData.series]
          }],
          options: {
            ...chartData.emailSendData.options,
            labels: [...reportData?.emailSendData.labels]
          }
        },
        miqaatCreateData: {
          ...chartData.miqaatCreateData,
          series:[...reportData?.miqaatCreateData.series],
          options: {
            ...chartData.miqaatCreateData.options,
            xaxis:{
              ...chartData.miqaatCreateData.options.xaxis,
              categories: [...reportData?.miqaatCreateData.categories]
            }
          }
        },
        deviceInfoData:{
          ...chartData.deviceInfoData,
          series: [...reportData?.deviceInfoData.series],
          options: {
            ...chartData.deviceInfoData.options,
            labels: [...reportData?.deviceInfoData.labels]
          }
        },
      })
    }
  }, [reportData])

  const handlePrint = useCallback(async() => {
    setPrintIdCardLoading(true);
    if (printRef.current === null) {
        return
    }
    setTimeout(() => {
        toJpeg(printRef.current, { cacheBust: true })
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'General Stats';
                link.href = dataUrl;
                link.click();
                setPrintIdCardLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setPrintIdCardLoading(false);
            });
    }, 500);
  }, [printRef]);

    return (
        <div className='main-wrapper'>
            {
              printIdCardLoading &&
              <div className='loader-wrapper overlay'>
                  <Spinner animation="border" variant="success" />
                  <p style={{ color: '#fff' }}>Loading...</p>
              </div>
            }
            <div className="container ">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <PageTitle link='/dashboard' title='GENERAL STATISTICS' />
                  <button className='icon-btn dark' style={{ backgroundColor: `${themeColor.primaryColor}`, display:'flex' }} onClick={handlePrint}> <TbFileExport />{printIdCardLoading ? 'Print...' : "Print"}</button>
                </div>
                {loader ?
                  <div className='loader-wrapper'>
                      <Spinner animation="border" variant="success" />
                  </div>
                  :
                <div className={`general-chart-wrapper ${printIdCardLoading && 'print'}`} ref={printRef}>
                    <div className='column flex'>
                        <div className='inner-col'>
                            <div className="count-card" style={{ background: 'linear-gradient(82.64deg, #3E98B5 1.22%, #89B9D4 55.75%, #89B9D4 71.34%, #3E98B5 98.6%)', color: 'white' }}>
                                <i class="fa-solid fa-users"></i>
                                <div>
                                    <b>{reportData?.userCount}</b>
                                    <p>Total Numbers of Users</p>
                                </div>
                            </div>
                            <div className="chart-box tag-untag">
                                <h4>Tag/Untag</h4>
                                <button className='back-btn zoom' onClick={()=> setZoomInChart({
                                  type: 'pie',
                                  show: true,
                                  data: chartData?.taggedUntaggedData,
                                  title: 'Tag/Untag'
                                })}><FiZoomIn /></button>
                                <div className="chart-container">
                                    <ApexChartsComponent series={chartData?.taggedUntaggedData.series} options={chartData?.taggedUntaggedData.options} type='pie' height='100%' width='100%' />
                                </div>
                            </div>
                        </div>
                        <div className='inner-col full-height'>
                            <div className="chart-box">
                                <h4>Gender ratio</h4>
                                <button className='back-btn zoom' onClick={()=> setZoomInChart({
                                  type: 'radialBar',
                                  show: true,
                                  data: chartData?.genderRatioData,
                                  title: 'Gender ratio'
                                })}><FiZoomIn /></button>
                                <div className="chart-container" style={{marginTop: '30px', height: 'calc(100% - 50px)'}}>
                                    <ApexChartsComponent series={chartData?.genderRatioData.series} options={chartData?.genderRatioData.options} type='radialBar' height='100%' width='100%' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column full-height">
                      <div className="chart-box">
                            <h4>Total Email Sent</h4>
                            <button className='back-btn zoom' onClick={()=> setZoomInChart({
                              type: 'area',
                              show: true,
                              data: chartData?.emailSendData,
                              title: 'Total Email Sent'
                            })}><FiZoomIn /></button>
                            <div className="chart-container">
                                <ApexChartsComponent series={chartData?.emailSendData.series} options={chartData?.emailSendData.options} type='area' height='100%' width='100%' />
                            </div>
                        </div>
                    </div>
                    <div className="column full-height">
                      <div className="chart-box">
                            <h4>Jamiat Ratio</h4>
                            <button className='back-btn zoom' onClick={()=> setZoomInChart({
                              type: 'bar',
                              show: true,
                              data: chartData?.jamiatData,
                              title: 'Jamiat Ratio'
                            })}><FiZoomIn /></button>
                            <div className="chart-container">
                                <ApexChartsComponent series={chartData?.jamiatData.series} options={chartData?.jamiatData.options} type='bar' height='100%' width='100%' />
                            </div>
                        </div>
                    </div>
                    <div className="column verticle-flex">
                        <div className="chart-box">
                            <h4>Total Miqaat created</h4>
                            <button className='back-btn zoom' onClick={()=> setZoomInChart({
                              type: 'line',
                              show: true,
                              data: chartData?.miqaatCreateData,
                              title: 'Total Miqaat created'
                            })}><FiZoomIn /></button>
                            <div className="chart-container">
                                <ApexChartsComponent series={chartData?.miqaatCreateData.series} options={chartData?.miqaatCreateData.options} type='line' height='100%' width='100%' />
                            </div>
                        </div>
                        <div className="chart-box">
                            <h4>Device Info / Installed app user</h4>
                            <button className='back-btn zoom' onClick={()=> setZoomInChart({
                              type: 'donut',
                              show: true,
                              data: chartData?.deviceInfoData,
                              title: 'Device Info / Installed app user'
                            })}><FiZoomIn /></button>
                            <div className="chart-container">
                                <ApexChartsComponent series={chartData?.deviceInfoData.series} options={chartData?.deviceInfoData.options} type='donut' height='100%' width='100%' />
                            </div>
                        </div>
                    </div>
                </div>
              }
            </div>
            {zoomInChart.show ?
              <div className="popup-wrapper">
                <div className="popup-container chart-popup">
                  <div className="popup-head">
                    <h4 style={{ textAlign: 'left' }}>{zoomInChart.title}</h4>
                  </div>
                  <div style={{ height: 'calc(100% - 64px)' }}>
                    <ApexChartsComponent type={zoomInChart.type}
                    height='100%'
                    width='100%'
                    // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                    series={zoomInChart.data.series} 
                    options={zoomInChart.data.options} />
                  </div>
                  <button className='close-btn' onClick={() => setZoomInChart({
                    show: false,
                    type: '',
                    data: '',
                    title: ''
                  })}><GrClose /></button>
                </div>
              </div>
            : ''
          }
        </div>
    )
}

export default GeneralStatisics