import React from 'react'
import { Controller } from 'react-hook-form';
import Select from 'react-select';

const SelectField = ({fieldLabel, options, name, placeholder, error, register, control,disable, isMulti, value, handleChange, style}) => {
  return (
    <div className="field" onClick={(e)=> e.stopPropagation()} style={style}>
        {fieldLabel ? <label>{fieldLabel}</label> : ''}
        {!handleChange &&  control ? 
          <>
            <Controller
            name={name}
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
            })=>(
              <Select options={options} className='select-field' placeholder={placeholder}
              value={value} onChange={onChange} isDisabled={disable} isMulti={isMulti ? true : false} name={name}
              />
            )}
            />
            {error?<p className='error-message'>{error}</p>:''}
          </>
          :
            <Select 
            onClick={(e)=> e.stopPropagation()}
            options={options} className='select-field' placeholder={placeholder}
            value={value} onChange={handleChange} isDisabled={disable} isMulti={isMulti ? true : false} name={name}
            />
        }
        
    </div>
  )
}

export default SelectField