import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { themeColor } from '../config';
import dashboardBg from '../assets/images/dashboard_bg.jpg';
const Login = () => {
  return (
    <div className='main-wrapper login-page' style={{background: `url(${dashboardBg})`}}>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={4} className='login-col'>
                    <a href='https://www.its52.com/Login.aspx?OneLogin=NAZAFAT'>
                        <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>ITS AUTHENTICATION</h2>
                        <div className='center-text-wrapper' style={{color: `${themeColor.secondaryColor}`}}>
                            <p>Click here to proceed to ITS One Login Authentication</p>
                        </div>
                    </a>
                    <div className="link-wrapper">
                        <a href='https://nazafat.com/privacy-policy' target="_blank" style={{color: `${themeColor.secondaryColor}`}}>Privacy Policy</a>
                        <a href='https://nazafat.com/privacy-policy' target="_blank" style={{color: `${themeColor.secondaryColor}`}}>Cookie Policy</a>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Login