import dayjs from 'dayjs';
import moment from 'moment';

export const newDateFormatFunction = (date) => {
    return moment(date).format("DD/MM/YYYY")

}
export const dateFormatFunction = (date) => {
    return moment(date).format("DD/MM/YYYY")

}
export const dateNTimeFormatFunction = (date) => {
    return moment(date).format('DD/MM/YYYY, h:mm:ss a')

}
// export const dateTimeFormatFunction = (date) => {
//     const dateObject = new Date(date);
//     const istTimeInMillis = dateObject.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000);
//     const istDateObject = new Date(istTimeInMillis);

//   const options = {
//     year: 'numeric',
//     month: 'long',
//     day: 'numeric',
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit',
//     hour12: true,
//     timeZoneName: 'short',
//   };

//   const formattedDateTime = istDateObject.toLocaleDateString('en-US', options);
//   return formattedDateTime
// }
export const dateTimeFormatFunction = (date, format24) => {
    const dateObject = new Date(date);
    const istTimeInMillis = dateObject.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000);
    const istDateObject = new Date(istTimeInMillis);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: format24 ? false : true,
    };

    const formattedDateTime = istDateObject.toLocaleDateString('en-US', options)

    return formattedDateTime;
}
export const fieldDateTimeFormatFunction = (date) => {
    const dateObject = new Date(date);
    const istTimeInMillis = dateObject.getTime() + (5 * 60 * 60 * 1000) + (30 * 60 * 1000);
    const istDateObject = new Date(istTimeInMillis);

    return istDateObject;
}