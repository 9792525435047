import React from 'react';
import {CgLogIn} from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { themeColor } from '../config';

const PageNotAccessible = () => {
  return (
    <div className='main-wrapper' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <h2 style={{color: `${themeColor.primaryColor}`, fontSize: '28px', marginBottom: '20px'}}>Page not Accessible</h2>
        <Link to='/' className='icon-btn dark' style={{background: `${themeColor.primaryColor}`}}><CgLogIn/> Dashboard</Link>
    </div>
  )
}

export default PageNotAccessible;