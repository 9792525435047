import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from 'dayjs';
import { themeColor } from '../../config';
import TextareaField from '../../components/TextareaField';
import { PostFetch } from '../../utils/fetchUrl';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch, useSelector } from 'react-redux';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import SeparateDateTimePicker from '../../components/SeparateDateTimePicker';
import { AllVenueAction } from '../../store/actions/venueAction';
import SelectField from '../../components/SelectField';
import { ManualAttendanceSchema } from '../../utils/Schemas';
import { convertItsToArray } from '../../utils/helper';
import PageTitle from '../../components/PageTitle';

const ManualAttendance = () => {
    const params = useParams();
    const navigate = useNavigate()
    const [itsId,setItsId] = useState();
    const [loader, setLoader] = useState(false);
    const [idError,setIdError] = useState(false)
    const [dateNTime, setDateNTime] = useState();
    const [dateNTimeError, setDateNTimeError] = useState();
    const [venuesList, setVenueList] = useState([])
    const venusData = useSelector((state) => state.venuereducer)

    const dispatch = useDispatch();

    const { handleSubmit, register, reset,watch,setValue, control,formState: {errors} } = useForm({
        resolver: yupResolver(ManualAttendanceSchema),
    });

    const markAttandance = (values, Its) => {
        if(Its.length === 0) return;
        else if(Its.length > 0){
            setLoader(true);
            let data = {
                ITSIDs: Its,
                MiqaatId: parseInt(params?.id),
                VenueId: values?.Venue?.value,
                date: new Date(dateNTime).toISOString(),
            }
            PostFetch('/api/attendances/addMultiple', data, "POST").then((response)=>{
                if(response.status === 200) {
                    showSuccessAlert('Attendance marked successfully');
                    setItsId('');
                    setDateNTime(null)
                    setValue('Venue', '')
                }
                else {
                    showErrorAlert(response.data.msg || "Something Went Wrong");
                    setLoader(false);
                }
                setLoader(false);
            }).catch((error)=>{
                console.log('error', error);
                showErrorAlert(error.data.title || error.data.msg || "Something Went Wrong");
                setLoader(false);
            })
        }
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }
    const handleDateNTimeChange = (e, type) => {
        const date = new Date(e);
        if(type === 'date'){
          date.setDate(new Date(e).getDate())
        }else{
          date.setHours(new Date(e).getHours())
          date.setMinutes(new Date(e).getMinutes())
        }
        setDateNTime(dayjs(date))
      }

    const submitAction = async(data) => {
        setIdError(false)
        const numArr = await convertItsToArray(itsId, setIdError);
        if(!itsId){
            setIdError(true)
        } else if(!dateNTime){
            dispatch({
                type: ALERT_ERROR,
                payload: "Please fillout all the fields",
            });
        } else if(!idError && !dateNTimeError && itsId){
            markAttandance(data, numArr);
        }
    }

    useEffect(() => {
        dispatch(AllVenueAction(params?.id), navigate)
      }, [])

      useEffect(() => {
        if (venusData) {
          let newVenueList = venusData.venusData.map((res) => ({
              label: res.name,
              value: res.id
          }))
          setVenueList(newVenueList)
        }
      }, [venusData])

  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7}>
                    <PageTitle link={`/view-miqaat/${params?.id}`} title='Mark Manual Attendance' />
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(submitAction)}>
                            <TextareaField fieldPlaceholder={"Enter ITS ID(s)"} 
                                fieldLabel={'ITS ID(s):'} name={'ItsIds'} classNm={'no-margin'}
                                error={errors.ItsIds?.message} register={{...register("ItsIds")}} 
                                value={itsId}  handleChng={(e)=>setItsId(e.target.value)}
                            />
                            <br/>
                            {idError ?
                                <h6 className="note" style={{color: "red"}}>ITS Ids should be of 8 digits</h6>:''
                            }
                            <h6 className="note" style={{color: "green"}}>Press Enter After Every ITS ID</h6>

                            <SelectField fieldLabel={'Zone:'} options={venuesList} name={'Venue'}
                            placeholder={'Select Zone'} register={{ ...register('Venue') }}
                            error={errors.Venue?.message} control={control} />

                            <SeparateDateTimePicker
                                label={'Select Date & Time'}
                                value={dateNTime}
                                handleOnChange={handleDateNTimeChange}
                                name={'RegDateTo'}
                                showError={dateNTimeError}
                                setShowError={setDateNTimeError}
                                // fieldError={fieldErrors}
                            />
                            <div className="btn-wrapper">
                                <Link to={`/view-miqaat/${params?.id}`}><Button variant="danger">Cancel</Button></Link>
                                <Button variant="success" type='submit' disabled={loader} >Add{
                                    loader ? 
                                    <div className='loader-wrapper btn-loader'>
                                        <Spinner animation="border" variant="white" />
                                    </div>: ''
                                }</Button>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>
        
    </div>
  )
}

export default ManualAttendance