import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GrFormView } from 'react-icons/gr';
import { GrClose } from 'react-icons/gr';
import { TbFileExport } from 'react-icons/tb'
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Switch, Tooltip } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import TuneIcon from '@mui/icons-material/Tune';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IoClose } from "react-icons/io5";
import { BiChevronDown } from "react-icons/bi";
import { MdKeyboardArrowUp } from "react-icons/md";
import { baseUrl, profileUrl, themeColor } from '../../config';
import InputField from '../../components/inputField';
import { GetFetch } from '../../utils/fetchUrl';
import { ALERT_ERROR } from '../../store/reducers/alert';
import placeholderUserImg from "../../assets/images/profile.png";
import { exportExcelFile } from '../../components/ExportExcelFile';
import PageTitle from '../../components/PageTitle';
import axios from 'axios';
import Cookies from 'js-cookie';
import { shiftKeyOneStepAbove } from '../../utils/helper';
import { setLogin } from '../../store/actions/login';
import { dateNTimeFormatFunction } from '../../utils/dateFormat';

const ViewUser = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openViewUserPopup, setOpenViewUserPopup] = useState(false);
  const [viewUserDetails, setViewUserDetails] = useState();
  const [search, setSearch] = useState('');
  const [viewUserId, setViewUserId] = useState();
  const [usersDetails, setUsersDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jamaats, setJamaats] = useState();
  const [jamiats, setJamiats] = useState();
  const [jamiatDetails, setJamiatDetails] = useState();
  const [jamaatDetails, setJamaatDetails] = useState();
  const [filterValues, setFilterValue] = useState({});
  const [filterValuesData, setFilterValuesData] = useState({});
  const [isDisabledFilterBtn, setIsDisabledFilterBtn] = useState(true)
  const [totalCount, setTotalCount] = useState(0);
  const [processedData, setProcessedData] = useState([])
  const [openFilterPopup, setOpenFilterPopup] = useState({
    showDataFilter: false,
    showColumnFilter: false
  });
  const [showClearFilterBtn, setShowClearFilterBtn] = useState(false)
  const [isShowPhoto, setIsShowPhoto] = useState(false)
  const [excelColumns, setExcelColumns] = useState();
  const [filterColumnOptions, setFilterColumnOptions] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [columnOption, setColumnOption] = useState();
  const [tableColumns, setTableColumns] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [isShowTagged, setIsShowTagged] = useState(false)

  const showErrorAlert = (msg) => {
    dispatch({
      type: ALERT_ERROR,
      payload: msg
    })
  }
  const handleFilterChange = async (filterVal) => {
    let data
    data = usersDetails.filter((item) => {
      return Object.keys(filterVal).every((key) => {
        if (item.hasOwnProperty(key) && filterVal[key].length > 0) {
          if (key === 'jamiat') {
            const jamiatNames = filterVal[key].flatMap(val =>
              jamiatDetails.filter(jamiat => jamiat.jamiatId === val).map(jamiat => jamiat.name.toLowerCase())
            );
            return jamiatNames.some(jamiatName => item[key].toLowerCase() === jamiatName);
          } else {
            return (
              filterVal[key].some(filterValue =>
                filterValue === "" ? item[key].toLowerCase() === filterValue.toLowerCase() : item[key]?.toLowerCase().includes(filterValue.toLowerCase())
              )
            )
          };
        }
        return true;
      });
    });
    let values = Object.keys(filterValues).map((key) => filterValues[key]);

    values = values.flat().filter((item) => item !== undefined);
    setShowClearFilterBtn(values.length > 0)
    setFilteredData([...data]);
    setSearch('');
    let columnData = columnOption
    columnOption.forEach((item, index) => {
      columnData[index] = { ...columnData[index], search: '' }
    })
    setColumnOption(columnData)
    setJamiats(
      jamiatDetails.map((jamiat) => (
        { value: jamiat.jamiatId, label: jamiat.name }
      ))
    )
    setJamaats(
      jamaatDetails.map((jamaat) => (
        { value: jamaat.name, label: jamaat.name }
      ))
    )
    let options = JSON.parse(localStorage.getItem('filterOptions'));
    setFilterOptions({ ...options });
    setOpenFilterPopup({
      showColumnFilter: false,
      showDataFilter: false,
    })
  };

  useEffect(() => {
    if (columnOption && usersDetails) {
      let data = columnOption?.map((col) => {
        if (col.visible) {
          if (!isShowPhoto && (col.columnName === 'userPhoto' || col.columnName === 'photo')) {
            return {}; // Skip photo columns if isShowPhoto is false
          } else if (isShowPhoto && (col.columnName === 'userPhoto' || col.columnName === 'photo')) {
            return {
              name: 'Photo',
              cell: (row) => loading ? 
              (<p className='loading-photo '>...</p>) : 
              (usersDetails && usersDetails.length > 0 ? 
                <img className='user-profile' src={row[col.columnName] ? `data:image/png;base64,${row[col.columnName]}` : placeholderUserImg} /> : 
                ""), 
            minWidth: '80px', 
            maxWidth: '80px'
            }
          } else if (col.columnName === 'itsid') {
            return {
              name: col?.title,
              cell: (row) => <button className='link-btn' onClick={() => {
                setViewUserId(row.itsid);
                setOpenViewUserPopup(true);
              }}>{row[col.columnName]}</button>,
              minWidth: '120px',
              maxWidth: '120px'
            }
          } else {
            return {
              name: col?.title,
              selector: (row) => row[col.columnName] ? Array.isArray(row[col.columnName]) ? row[col.columnName].map((item) => item.name + ' ,') : row[col.columnName] : ' ',
              sortable: true,
              minWidth: '140px',
            }

          }
        }
      })
      data = data?.filter((item) => item !== undefined)
      const index = data?.findIndex(item => item?.name === 'Photo');
      if (index !== -1) {
        let item = data.splice(index, 1)[0];
        data.unshift(item);
      }
      data?.push({
        name: 'Action',
        cell: (row) =>
          usersDetails && usersDetails.length ?
            <button className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} onClick={() => {
              setViewUserId(row.itsid);
              setOpenViewUserPopup(true);
            }} ><GrFormView /></button> : "",
        minWidth: '90px',
        maxWidth: '90px'
      })
      setTableColumns(data);

      let excelCol = columnOption?.map((col) => {
        if (col.visible) {
          if (col.columnName === 'userPhoto' || col.columnName === 'photo') {
            return {
              header: 'Photo',
              key: col.columnName,
              width: 10
            }
          } else {
            return {
              header: col.title,
              key: col.columnName,
              width: 10
            }
          }
        }
      })
      excelCol = excelCol?.filter((item) => item !== undefined)
      const i = excelCol?.findIndex(item => item.header === 'Photo');
      if (i !== -1) {
        let item = excelCol?.splice(i, 1)[0];
        excelCol?.unshift(item);
      }
      setExcelColumns(excelCol)
      setFilterColumnOptions(columnOption)
    }
  }, [columnOption, usersDetails])

  const handleCloseFilterPopup = () => {
    setOpenFilterPopup({
      showColumnFilter: false,
      showDataFilter: false
    })
    let columnData = columnOption
    columnOption.forEach((item, index) => {
      columnData[index] = { ...columnData[index], search: '' }
    })
    setColumnOption(columnData)
    setJamiats(
      jamiatDetails.map((jamiat) => (
        { value: jamiat.jamiatId, label: jamiat.name }
      ))
    )
    setJamaats(
      jamaatDetails.map((jamaat) => (
        { value: jamaat.name, label: jamaat.name }
      ))
    )
    let options = JSON.parse(localStorage.getItem('filterOptions'));
    setFilterOptions({ ...options });
  }

  const handleRemoveFilter = (key, val) => {
    if(key){
      const filterVal = filterValues[key].filter((item) => item !== val);
      const filters = {...filterValues, [key]: [...filterValues[key].filter((item) => item !== val)]};
      setFilterValue({...filterValues, [key]: [...filterVal]})
      setFilterValuesData({...filterValuesData, [key]: [...filterVal]});
      handleFilterChange(filters);
    }
  }

  const fetchDataChunk = async (authToken, pageNo, isShow, showTagged) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/User/view-v2`,
        {
          PageNo: pageNo,
          Limit: (500),
          WithPhoto: isShow,
          WithUntagged: showTagged || false
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          }
        }
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.data.msg ? response.data.msg : "Something Went Wrong");
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {

    const fetchData = async () => {
      setLoader(true);
      if (usersDetails.length <= totalCount) {
        
        try {
          const authToken = Cookies.get("token");
          const responseData = await fetchDataChunk(authToken, 1, isShowPhoto);
          let data = responseData.data;

          data = data.map((item) => {
            for (const key in item) {
              if (Array.isArray(item[key])) {
                item[key] = item[key].map((subItem) => subItem.name).join(', ');
              }
            }
            return item;
          });
          let filterColumnKeys
          responseData.columns?.map((item) => {
            if (item.filterable && item.columnName !== "itsid" && item.columnName !== "fullName") {
              filterColumnKeys = { ...filterColumnKeys, [item.columnName]: [] }
            }
          })
          const columnData = responseData.columns.map((item) => ({ ...item, open: false, search: '' }))
          let index = columnData.findIndex(obj => obj.columnName === 'itsid');
          if (index !== -1) {
            let [objectToMove] = columnData.splice(index, 1);
            columnData.unshift(objectToMove);
          }
          setUsersDetails([...responseData.data]);

          setFilteredData([...data]);
          setTotalCount(responseData.totalCount);
          setColumnOption(columnData);
          setFilterValue(filterColumnKeys);
          setFilterValuesData(filterColumnKeys);
          setLoader(false);
          let totalRunCount = Math.ceil(responseData.totalCount / ( isShowPhoto ?200:500));
          for (let i = 2; i <= totalRunCount; i++) {
            const responseData = await fetchDataChunk(authToken, i, isShowPhoto);
            let usersData = responseData.data;
            usersData = usersData.map((item) => {
              for (const key in item) {
                if (Array.isArray(item[key])) {
                  item[key] = item[key].map((subItem) => subItem.name).join(', ');
                }
              }
              return item;
            })

            setUsersDetails(prevData => [...prevData, ...responseData.data]);

            setFilteredData(prevData => [...prevData, ...responseData.data]);
          }
        } catch (error) {
          if (error.response.status === 401) {
            navigate('/login', { replace: true });
            dispatch(setLogin(false));
          } else {
            console.error('Error fetching data:', error);
            showErrorAlert(error.message ? error.message : "Something Went Wrong");
          }
        }
      }
    };

    if (!loader) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (usersDetails.length > 0 && usersDetails.length === totalCount) {
      const d = new Date();
      const expirationDate = localStorage.getItem('expirationDate')
      const options = localStorage.getItem('filterOptions');
      if (options && expirationDate && d === new Date(expirationDate)) {
        setFilterOptions(JSON.parse(options))
      } else {
        let filterColumnKeys = columnOption.map(item => item.filterable && item.columnName !== 'age' && item.columnName !== 'fullName' && item.columnName !== 'itsid' ? item.columnName : undefined)
          .filter((item) => item !== undefined);

        let result = {};

        filterColumnKeys.forEach(key => {

          let values = usersDetails.flatMap(obj => {
            const value = obj[key];

            if (typeof value === 'string' && value.includes(", ")) {


              return value.split(", ").map(item => item.trim());
            } else if (Array.isArray(value)) {

              return value.map(item => typeof item === 'string' ? item.trim() : item);
            } else if (value !== undefined && value !== null) {

              return [typeof value === 'string' ? value.trim() : value];
            } else {

              return [];
            }
          });
          const uniqueValues = Array.from(new Set(values));
          result[key] = uniqueValues;
        });
        result = shiftKeyOneStepAbove(result, 'jamiat');
        setFilterOptions(result);
        localStorage.setItem('filterOptions', JSON.stringify(result));
        localStorage.setItem('expirationDate', d.toString());
      }
      setIsDisabledFilterBtn(false)
    }
  }, [usersDetails])

  const fetchJamaat = () => {

    GetFetch(`/api/Jamaat`).then((response) => {
      if (response.status == 200) {
        setJamaatDetails(response.data);
      }

    }).catch((err) => {
      console.log('error', err);
    })
  }
  const fetchJamiat = () => {

    GetFetch(`/api/Jamiat`).then((response) => {
      if (response.status == 200) {
        setJamiatDetails(response.data);
      }

    }).catch((err) => {
      console.log('error', err);
    })
  }

  const handleClearFilter = () => {
    let data = usersDetails.map((item) => {
      for (const key in item) {
        if (Array.isArray(item[key])) {
          item[key] = item[key].map((subItem) => subItem.name).join(', ');
        }
      }
      return item;
    });
    setFilteredData(data);
    setSearch('');
    setOpenFilterPopup({
      showColumnFilter: false,
      showDataFilter: false,
    })
    const columnData = columnOption.map((item) => ({ ...item, open: false, search: '' }))
    setColumnOption(columnData)
    let filterVal = filterValues;
    Object.keys(filterValues).map((key) => { filterVal[key] = [] })
    setFilterValue({ ...filterVal });
    setFilterValuesData({ ...filterVal });
    let options = JSON.parse(localStorage.getItem('filterOptions'));
    setFilterOptions({ ...options });
    setShowClearFilterBtn(false)
    document.querySelectorAll('input').forEach((input) => (input.value = null));
    document.querySelectorAll('select').forEach((select) => (select.value = ''));
  }


  useEffect(() => {
    if (usersDetails) {
      const result = usersDetails && usersDetails.filter((user) => {
        return user.itsid && user.itsid.toString().match(viewUserId && viewUserId.toString())
      });
      setViewUserDetails(result);
    }
  }, [viewUserId])

  useEffect(() => {
    // fetchUsersDetails(data);
    fetchJamaat();
    fetchJamiat();
  }, []);

  useEffect(() => {
    if (jamiatDetails) {
      setJamiats(
        jamiatDetails.map((jamiat) => (
          { value: jamiat.jamiatId, label: jamiat.name }
        ))
      )
    }
  }, [jamiatDetails])

  useEffect(() => {
    if (jamaatDetails) {
      setJamaats(
        jamaatDetails.map((jamaat) => (
          { value: jamaat.name, label: jamaat.name }
        ))
      )
    }
  }, [jamaatDetails])


  const onColumnChange = (e, col) => {
    let data = filterColumnOptions?.map((item) => {
      if (col.columnName === item.columnName) {
        return {
          ...item,
          visible: e.target.checked
        }
      }
      return item
    });
    setFilterColumnOptions([...data])
  }

  const openAccordion = (index) => {
    let columnData = columnOption;
    columnData[index] = { ...columnData[index], open: !columnData[index].open };
    setColumnOption([...columnData])
  }

  const onFilterSearchChange = (val, index) => {
    let columnData = columnOption;
    columnData[index] = { ...columnData[index], search: val };
    setColumnOption([...columnData])
    if (columnOption[index].columnName === 'jamiat') {
      let data = jamiatDetails.filter(item => item.name.toLowerCase().includes(val.toLowerCase())).map(item => ({ value: item.jamiatId, label: item.name }));
      data = data.filter((item) => item !== undefined)
      setJamiats(data);
    } else if (columnOption[index].columnName === 'jamaat') {
      let data = jamaatDetails.filter(item => item.name.toLowerCase().includes(val.toLowerCase())
      ).map(item => ({ value: item.name, label: item.name }));
      data = data.filter((item) => item !== undefined)
      setJamaats(data);
    } else {
      let options = JSON.parse(localStorage.getItem('filterOptions'));
      if (options) {
        options = options[columnOption[index].columnName];
        options = options.filter((item) => item.toLowerCase().includes(val.toLowerCase()))
        setFilterOptions({ ...filterOptions, [columnOption[index].columnName]: [...options] });
      }
    }
  }

  const onFilterChange = (key, item) => {
    let filterVal = { ...filterValues };
    const newValue = (typeof item === 'string' ? item.toLowerCase() : item)
    let newArray = filterValues[key].filter(val => val !== newValue);
    setFilterValue({ ...filterVal, [key]: filterValues[key].includes(newValue) ? newArray : [...newArray, newValue] });
  }

  const applyColumnFilter = () => {
    setColumnOption([...filterColumnOptions]);
    setOpenFilterPopup({
      showColumnFilter: false,
      showDataFilter: false,
    })
  }

  useEffect(() => {
    const data = filteredData?.filter(item => {
      return item.itsid.toString().toLowerCase().includes(search.toLowerCase()) ||
        item.fullName.toString().toLowerCase().includes(search.toLowerCase());
    });
    setProcessedData(data);
  }, [search])

  useEffect(() => {
    const data = filteredData.map((item) => ({
      ...item,
      updatedAt: item.updatedAt ? dateNTimeFormatFunction(item.updatedAt) : item.updatedAt,
      insertedAt: item.insertedAt ? dateNTimeFormatFunction(item.insertedAt) : item.insertedAt,
      lastActive: item.lastActive ? dateNTimeFormatFunction(item.lastActive) : item.lastActive,
    }));
    setProcessedData([...data])
  }, [filteredData])

  useEffect(() => {
    if (filterValues.jamiat && filterValues.jamiat.length > 0) {
      let data = jamaatDetails.filter(item => {
        return filterValues.jamiat.includes(item.jamiatId);
      }).map(item => {
        return { value: item.name, label: item.name };
      });
      data = data.filter((item) => item !== undefined)
      setJamaats(data);
      setFilterValue({
        ...filterValues,
        jamaat: []
      })
    } else {
      let data = jamaatDetails?.map(item => {
        return { value: item.name, label: item.name };
      });
      setJamaats(data)
    }
  }, [filterValues.jamiat])

  const resetFilter = (value) => {
    const data = { ...filterValues, [value]: [] };
    setFilterValue(data);
    let columnData = columnOption
    columnOption.forEach((item, index) => {
      if (item.columnName === value) {
        columnData[index] = { ...columnData[index], search: '' }
      }
    })
    setColumnOption(columnData)
    if (value === 'jamiat') {
      setJamiats(
        jamiatDetails.map((jamiat) => (
          { value: jamiat.jamiatId, label: jamiat.name }
        ))
      )
    } else if (value === 'jamaat') {
      setJamaats(
        jamaatDetails.map((jamaat) => (
          { value: jamaat.name, label: jamaat.name }
        ))
      )
    } else {
      let options = JSON.parse(localStorage.getItem('filterOptions'));
      if (options) {
        options = options[value];
        setFilterOptions({ ...filterOptions, [value]: [...options] });
      }
    }
  }

  const fetchPhoto = async (isShow, showTagged) => {
    if (usersDetails.length <= totalCount) {
      try {
        setLoading(true);
        setIsDisabledFilterBtn(true)
        const authToken = Cookies.get("token");
        const responseData = await fetchDataChunk(authToken, 1, isShow, showTagged);
        let data = responseData.data;

        data = data.map((item) => {
          for (const key in item) {
            if (Array.isArray(item[key])) {
              item[key] = item[key].map((subItem) => subItem.name).join(', ');
            }
          }
          return item;
        });
        let filterColumnKeys
        responseData.columns?.map((item) => {
          if (item.filterable && item.columnName !== "itsid" && item.columnName !== "fullName") {
            filterColumnKeys = { ...filterColumnKeys, [item.columnName]: [] }
          }
        })
        const columnData = responseData.columns.map((item) => ({ ...item, open: false, search: '' }))
        let index = columnData.findIndex(obj => obj.columnName === 'itsid');
        if (index !== -1) {
          let [objectToMove] = columnData.splice(index, 1);
          columnData.unshift(objectToMove);
        }
        setUsersDetails([...responseData.data]);
        setFilteredData([...responseData.data]);
        setTotalCount(responseData.totalCount);
        setColumnOption(columnData);
        setFilterValue(filterColumnKeys);
        setFilterValuesData(filterColumnKeys);
        setLoader(false);

        let totalRunCount = Math.ceil(responseData.totalCount /( isShow ?200:500));
        for (let i = 2; i <= totalRunCount; i++) {
          const responseData = await fetchDataChunk(authToken, i, isShow, showTagged);
          let usersData = responseData.data;
          usersData = usersData.map((item) => {
            for (const key in item) {
              if (Array.isArray(item[key])) {
                item[key] = item[key].map((subItem) => subItem.name).join(', ');
              }
            }
            return item;
          })

          setUsersDetails(prevData => [...prevData, ...responseData.data]);

          setFilteredData(prevData => [...prevData, ...responseData.data]);
        }

        setLoading(false)
        setIsDisabledFilterBtn(false)
      } catch (error) {
        setLoader(false);
        setIsDisabledFilterBtn(false)
        setLoading(false)
        if (error.response.status === 401) {
          navigate('/login', { replace: true });
          dispatch(setLogin(false));
        } else {
          console.error('Error fetching data:', error);
          showErrorAlert(error.message ? error.message : "Something Went Wrong");
        }
      }
    }
  };

  const toggleButton = () => {
    setIsShowPhoto(!isShowPhoto)
    fetchPhoto(!isShowPhoto)
  }

  const toggleShowTagged = () => {
    setIsShowTagged(!isShowTagged)
    fetchPhoto(false, !isShowTagged)
  }

  if (loader) {
    return (
      <>
        <div className='loader-wrapper'>
          <Spinner animation="border" variant="success" />
        </div>
      </>
    )
  }
  else {
    return (
      <div className="main-wrapper">

        <div className="container-fluid view-user-container">       
          <div className="table-header-wrapper">
            <PageTitle link='/dashboard' title='VIEW USER' />

            <div className="table-header">
 
              <div className='filter-options-wrapper'>

                {
                  Object.keys(filterValuesData).map((key) => (
                    <>
                      {
                        filterValuesData[key].length > 0 &&
                        <>
                          {
                            filterValuesData[key].map((val) => {
                              if (key === 'jamiat') {
                                const index = jamiatDetails.findIndex(obj => obj.jamiatId === val);
                                return (
                                  <span>{jamiatDetails[index].name} <IconButton onClick={()=> handleRemoveFilter(key, val)}><IoClose /></IconButton></span>
                                )
                              } else if(key === "isTagged"){
                                return ""
                              } else {
                                return (
                                  <span>{typeof val === 'string' ? (val === "" || val === "\u0000" ? '(N/A)' : <>{val} <IconButton onClick={()=> handleRemoveFilter(key, val)}><IoClose /></IconButton></>) : <>{val} <IconButton onClick={()=> handleRemoveFilter(key, val)}><IoClose /></IconButton></>} </span>
                                )
                              }
                            })
                          }
                        </>

                      }
                    </>
                  ))}
              </div>
              {
                isDisabledFilterBtn ?
                  <>
                    <div></div>
                    <Spinner animation="border" variant="success" size='sm' />
                  </>
                  :
                  <>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup style={{display: 'flex', alignItems: 'center', gap: '20px', flexDirection: 'row'}}>
                      <FormControlLabel
                          control={
                            <div style={{ width: '100%' }}>
                              <Switch
                                checked={isShowTagged}
                                onChange={toggleShowTagged}
                                color="primary"
                                classes={{
                                  switchBase: 'switchBaseClass',
                                  checked: 'checkedClass',
                                  track: 'trackClass',
                                }}
                              />
                              <div style={{ minWidth: '100px', maxWidth: '80px', display: 'flex', alignItems: 'center' }}>
                                <div style={{ textAlign: 'center',fontSize:'10px',width:'100%', display:'flex' }}>
                                  {isShowTagged ? "Show Tagged" : "Show Un-Tagged"}
                                </div>
                              </div>
                            </div>
                          }
                        />
                        <FormControlLabel
                          control={
                            <div style={{ width: '100%' }}>
                              <Switch
                                checked={isShowPhoto}
                                onChange={toggleButton}
                                color="primary"
                                classes={{
                                  switchBase: 'switchBaseClass',
                                  checked: 'checkedClass',
                                  track: 'trackClass',
                                }}
                              />
                              <div style={{ minWidth: '60px', maxWidth: '80px', display: 'flex', alignItems: 'center' }}>
                              <div style={{ textAlign: 'center',fontSize:'10px',width:'100%', display:'flex' }}>
                                {isShowPhoto ? "Hide Photo" : "Show Photo"}
                              </div>
                            </div>
                            </div>
                          }
                        />
                      </FormGroup>
                    </FormControl>
                    <InputField className={'search-field'} fieldPlaceholder={"Search ITS ID / Name"} fieldType={'text'} value={search}
                      handleChange={(e) => setSearch(e.target.value)} />
                    <div className="btn-wrapper">
                      <>
                        <button disabled={isDisabledFilterBtn} className='icon-btn dark' style={{ backgroundColor: `${themeColor.primaryColor}`, display:'flex' }} onClick={() => exportExcelFile(processedData, excelColumns, 'User-Details')}> <TbFileExport />Download</button>
                        <Box className="icon-btn-wrapper" display='flex'>
                          <Tooltip title="Data Filter">
                            <IconButton disabled={isDisabledFilterBtn} onClick={() => { setOpenFilterPopup({ showDataFilter: true, showColumnFilter: false }); setFilterValue(filterValuesData) }}>
                              <FilterAltOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Column Filter">
                            <IconButton onClick={() => setOpenFilterPopup({ showDataFilter: false, showColumnFilter: true })}>
                              <TuneIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </>
                    </div>
                  </>
              }
            </div>
          </div>

          <div className="table-wrapper view-user-table">

            <DataTable
              data={processedData?.length > 0 ? processedData : [{ fullName: "No Data Found" }]}
              columns={tableColumns}
              fixedHeader={true}
              fixedHeaderScrollHeight='534px'
              paginationRowsPerPageOptions={[10, 20, 30, 50, 75, 100]}
              pagination
              subHeaderAlign="left" />
          </div>

          {openFilterPopup.showColumnFilter ?
            <div className="popup-wrapper">
              <div className="popup-container view-user column-filter">
                <div className="popup-head">
                  <h4 style={{ textAlign: 'left' }}>Column Filter</h4>
                </div>
                <div className="column-filter-wrapper">
                  {
                    filterColumnOptions?.map((col) => (
                      <FormControlLabel control={<Checkbox checked={col.visible} iconStyle={{ color: '#148a4d' }} onChange={(e) => onColumnChange(e, col)} />} label={col.title} />
                    ))
                  }
                </div>
                <button className='close-btn' onClick={() => setOpenFilterPopup({
                  showColumnFilter: false,
                  showDataFilter: false,
                })}><GrClose /></button>
                <Button variant="success" onClick={applyColumnFilter}>Apply</Button>
              </div>
            </div>
            : ''
          }
          {openFilterPopup.showDataFilter ?
            <div className="popup-wrapper">
              <div className="popup-container view-user-filter">
                <div className="popup-head">
                  <h4 style={{ textAlign: 'left' }}>Filter</h4>
                  {
                    showClearFilterBtn &&
                    <button className='link-btn' style={{ backgroundColor: 'none' }} onClick={handleClearFilter}>Clear Filter</button>
                  }
                </div>
                <div className="popup-filter">
                  <div className="user-filter-wrapper">
                    {
                      Object.keys(filterOptions).map((key) => {
                        let jsxElements = [];
                        columnOption?.forEach((item, index) => {
                          if (item.columnName === 'jamiat' && key === 'jamiat' && item.visible === true) {
                            jsxElements.push(
                              <div className='user-filter' key={key}>
                                <button className='accordion-btn' onClick={() => { openAccordion(index) }}>
                                  <p>{item.title}</p>
                                  {
                                    item.open ?
                                      <MdKeyboardArrowUp />
                                      :
                                      <BiChevronDown />
                                  }
                                </button>

                                {
                                  item.open &&
                                  <div className='filter-option column-filter-wrapper'>
                                    <p>No of filter selected : {filterValues[key]?.length}</p>
                                    <div className='filter-head'>
                                      <InputField className={'search-field'} fieldPlaceholder={"Search"} fieldType={'text'} value={item.search}
                                        handleChange={(e) => onFilterSearchChange(e.target.value, index)} style={{ marginBottom: '8px' }} />
                                      <button className='link-btn' onClick={() => resetFilter(item.columnName)}>Reset</button>
                                    </div>
                                    {
                                      jamiats.length > 0 ?
                                        <>
                                          {
                                            jamiats.map((jamiat) => (
                                              <FormControlLabel control={<Checkbox color="primary" checked={filterValues[key]?.includes(jamiat.value)} onChange={(e) => onFilterChange(key, jamiat.value)} />} label={jamiat.label} />
                                            ))}
                                        </>
                                        :
                                        <>
                                          <p style={{ textAlign: 'center', margin: '16px 0' }}>No result found</p>
                                        </>
                                    }
                                  </div>
                                }
                              </div>
                            );
                          } else if (item.columnName === 'jamaat' && key === 'jamaat' && item.visible === true) {
                            jsxElements.push(
                              <div className='user-filter' key={key}>
                                <button className='accordion-btn' onClick={() => { openAccordion(index) }}>
                                  <p>{item.title}</p>
                                  {
                                    item.open ?
                                      <MdKeyboardArrowUp />
                                      :
                                      <BiChevronDown />
                                  }
                                </button>
                                {
                                  item.open &&
                                  <div className='filter-option column-filter-wrapper'>
                                    <p>No of filter selected : {filterValues[key]?.length}</p>
                                    <div className='filter-head'>
                                      <InputField className={'search-field'} fieldPlaceholder={"Search"} fieldType={'text'} value={item.search}
                                        handleChange={(e) => onFilterSearchChange(e.target.value, index)} style={{ marginBottom: '8px' }} />
                                      <button className='link-btn' onClick={() => resetFilter(item.columnName)}>Reset</button>
                                    </div>
                                    {
                                      jamaats.length > 0 ?
                                        <>
                                          {
                                            jamaats.map((jamaat) => (
                                              <FormControlLabel control={<Checkbox color="primary" checked={filterValues[key]?.includes(jamaat.value.toLowerCase())} onChange={(e) => onFilterChange(key, jamaat.value.toLowerCase())} />} label={jamaat.label} />
                                            ))}
                                        </>
                                        :
                                        <>
                                          <p style={{ textAlign: 'center', margin: '16px 0' }}>No result found</p>
                                        </>
                                    }
                                  </div>
                                }
                              </div>
                            );
                          } else if(key === "isTagged" && item.columnName === key){

                          } else if (item.columnName !== 'jamaat' && item.columnName !== 'jamiat' && item.columnName === key && item.visible === true) {
                            jsxElements.push(
                              <div className='user-filter' key={key}>
                                <button className='accordion-btn' onClick={() => { openAccordion(index) }}>
                                  <p>{item.title}</p>
                                  {
                                    item.open ?
                                      <MdKeyboardArrowUp />
                                      :
                                      <BiChevronDown />
                                  }
                                </button>
                                {
                                  item.open &&
                                  <div className='filter-option column-filter-wrapper'>
                                    <p>No of filter selected : {filterValues[key]?.length}</p>
                                    <div className='filter-head'>
                                      <InputField className={'search-field'} fieldPlaceholder={"Search"} fieldType={'text'} value={item.search}
                                        handleChange={(e) => onFilterSearchChange(e.target.value, index)} style={{ marginBottom: '8px' }} />
                                      <button className='link-btn' onClick={() => resetFilter(item.columnName)}>Reset</button>
                                    </div>
                                    {
                                      filterOptions[key].length > 0 ?
                                        <>
                                          {
                                            filterOptions[key].map((option) => (
                                              <FormControlLabel control={<Checkbox color="primary" checked={filterValues[key]?.includes(typeof option === 'string' ? option.toLowerCase() : option)} onChange={(e) => onFilterChange(key, typeof option === 'string' ? option.toLowerCase() : option)} />} label={typeof option === 'string' ? (option === "" || option === "\u0000" ? '(N/A)' : option) : option} />
                                            ))}
                                        </>
                                        :
                                        <>
                                          <p style={{ textAlign: 'center', margin: '16px 0' }}>No result found</p>
                                        </>
                                    }
                                  </div>
                                }
                              </div>
                            );
                          }
                        });
                        return jsxElements;
                      })
                    }

                  </div>
                  <div className="btn-wrapper">
                    <Button variant="success" onClick={() => {handleFilterChange(filterValues); setFilterValuesData({ ...filterValues });}}>Apply</Button>
                    <Button variant="danger" onClick={() => handleCloseFilterPopup()}>Cancel</Button>
                  </div>
                </div>

              </div>
            </div>
            : ''
          }
          {openViewUserPopup && viewUserDetails && viewUserDetails.length > 0 ?
            <div className="popup-wrapper">
              <div className="popup-container view-user">
                <div className="profile-image">
                  {/* <img src={`${profileUrl}${viewUserDetails[0].itsid}.png`} onError={(e) => e.target.src = placeholderUserImg} alt="profile" /> */}
                  <img className='user-profile' src={viewUserDetails[0].photo ? `data:image/png;base64,${viewUserDetails[0].photo}` : placeholderUserImg} alt="profile" />
                </div>
                <div className="popup-head">
                  <div className='details'>
                    <p>{viewUserDetails[0].fullName ? viewUserDetails[0].fullName : '-'}</p>
                    <p style={{ color: `${themeColor.primaryColor}` }}>{viewUserDetails[0].itsid ? viewUserDetails[0].itsid : '-'}</p>
                  </div>
                </div>
                <div className="user-details">
                  <div>
                    <b>Email ID:</b>
                    <a href={`mailto:${viewUserDetails[0].email}`}>{viewUserDetails[0].email}</a>
                  </div>
                  <div>
                    <b>Phone No.:</b>
                    <a href={`tel:${viewUserDetails[0].mobNo}`}>{viewUserDetails[0].mobNo}</a>
                  </div>
                  <div>
                    <b>Jamiat:</b>
                    <p>{viewUserDetails[0].jamiat ? viewUserDetails[0].jamiat : '-'}</p>
                  </div>
                  <div>
                    <b>Jamaat:</b>
                    <p>{viewUserDetails[0].jamaat ? viewUserDetails[0].jamaat : '-'}</p>
                  </div>
                  <div>
                    <b>Position:</b>
                    <p>{viewUserDetails[0].positionName ? viewUserDetails[0].positionName : '-'}</p>
                  </div>
                  <div>
                    <b>Device Type:</b>
                    <p>{viewUserDetails[0].deviceType ? viewUserDetails[0].deviceType : '-'}</p>
                  </div>
                  <div>
                    <b>App Version:</b>
                    <p>{viewUserDetails[0].appVersion ? viewUserDetails[0].appVersion : '-'}</p>
                  </div>
                  <div>
                    <b>Last Active:</b>
                    <p>{viewUserDetails[0].lastActive ? dateNTimeFormatFunction(viewUserDetails[0].lastActive) : '-'}</p>
                  </div>
                </div>
                <button className='close-btn' onClick={() => setOpenViewUserPopup(false)}><GrClose /></button>
              </div>
            </div>
            : ''
          }

        </div>
      </div>
    )

  }
}

export default ViewUser;