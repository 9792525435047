import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { MdDeleteOutline, MdModeEdit, MdOutlineTaskAlt } from 'react-icons/md'
import { FiPrinter } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';
import { GrFormView, GrClose } from 'react-icons/gr'
import { Spinner } from 'react-bootstrap'
import { FiPhone } from 'react-icons/fi'

import { TbLocation } from 'react-icons/tb'
import { profileUrl, themeColor } from '../../../config'
import DataTable from 'react-data-table-component'
import InputField from '../../../components/inputField'
import placeholderUserImg from '../../../assets/images/profile.png'
// import { teamData.teamData } from '../../../appContent'
import { useDispatch, useSelector } from 'react-redux'
import { AllTeamAction, DeleteTeamAction, GetSingleTeamAction } from '../../../store/actions/teamAction'
import AppModal from '../../../components/Modal'
import PageTitle from '../../../components/PageTitle';

const ViewTeam = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [popupLoading, setPopupLoader] = useState(false)
  const [deleteTeamId, setDeleteTeamId] = useState('')
  const [loader, setLoader] = useState(true)
  const [viewTeamId, setViewTeamId] = useState()
  const [viewTeamDetails, setViewTeamDetails] = useState({})
  const [openViewTeamPopup, setOpenViewTeamPopup] = useState(false)
  const teamData = useSelector((state) => state.teamreducer);
  const printRef = useRef();

  const columns = [
    {
      name: 'Team Name',
      selector: (row) => row?.name
    },
    {
      name: 'Team Lead',
      selector: (row) => row?.leadITSID
    },
    {
      name: 'Total Members',
      selector: (row) => row?.noOfMembers
    },
    {
      name: 'Venues',
      selector: (row) => row?.venues.join(", ")
    },
    {
      name: 'Actions',
      cell: (row) => <p className='action-btn-wrapper'>
        <button className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} onClick={() => handleViewTeam(row?.id)}><GrFormView />
        </button>
        <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/${params?.id}/view-team/edit/${row.id}`} ><MdModeEdit /></Link>
        <button className='action-btn' style={{ color: 'red', background: `${themeColor.lightGreen}` }} onClick={() => deleteTeam(row.id)}><MdDeleteOutline /></button>
      </p>
    }
  ]

  useEffect(() => {
    dispatch(AllTeamAction(params?.id, navigate))
  }, [])
  useEffect(() => {
    setLoader(true)
  }, [])

  useEffect(() => {
    if (teamData?.teamData?.length > 0) {
      const data = teamData?.teamData?.map((item)=>({...item, venues: item.venues.map((venue)=> venue.name)}))
      setFilteredData(data)
    } else {
      setFilteredData(teamData?.teamData)
    }
  }, [teamData?.teamData])

  useEffect(() => {
    if (search?.length > 0) {
      const result = teamData?.teamData?.filter(data => Object.keys(data).some(key => {
        return String(data['name']).toLowerCase().includes(search.toLowerCase())
      }))

      setFilteredData(result)
    } else {
      setFilteredData(teamData.teamData)
    }
  }, [search])

  useEffect(() => {
    if (filteredData?.length > 0) {
      setLoader(false)
    } else {
      setTimeout(() => {
        setLoader(false)
      }, 1000);
    }
  }, [filteredData])

  // useEffect(() => {
  //   console.log("----openViewTeamPopup", openViewTeamPopup)
  //   if (openViewTeamPopup) {
  //     const result = teamData?.teamData && teamData?.teamData?.filter((teamInfo) => {
  //       return teamInfo.id && teamInfo.id.toString().match(viewTeamId && viewTeamId.toString())
  //     })
  //   }
  // }, [viewTeamId])

  useEffect(() => {
    if (teamData?.singleTeam) {
      setPopupLoader(false)
      setViewTeamDetails(teamData?.singleTeam)
    } else {
      setViewTeamDetails({})
    }
  }, [teamData?.singleTeam])

  const handleViewTeam = (id) => {

    setPopupLoader(true)
    setOpenViewTeamPopup(true)
    dispatch(GetSingleTeamAction(id))
  }

  const deleteTeam = (id) => {
    setDeleteTeamId(id)
    setShowModal(true)
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const confirDelete = () => {
    let data = {
      id: deleteTeamId
    }
    dispatch(DeleteTeamAction(data, params?.id))
    setShowModal(false)
  }

  return (
    <div className='main-wrapper'>
      <style>
        {`@media print {
  @page {
    size: Potrait; 
    margin: 15px;
  }
  .popup-container {
    width: auto !important;
    page-break-before: always;
}
   }`
        }
      </style>
      <div className="container">
        <PageTitle link={`/view-miqaat/${params?.id}`} title='VIEW TEAM' />
        {loader
          ? <div className='loader-wrapper'>
            <Spinner animation="border" variant="success" />
          </div>
          : <div className="table-wrapper role-management-table">
              <header>
                  <div>
                    <InputField className={'search-field'} fieldPlaceholder={'Search Team'} fieldType={'text'} value={search}
                      handleChange={(e) => setSearch(e.target.value)} />
                    <Link to={`/view-miqaat/${params?.id}/view-team/add`} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}` }}><IoMdAddCircle />Add Team</Link>
                </div>
              </header>
              <div className="table-footer">
                <p className="note">
                  To scroll the report horizontally, press the Shift key and
                  scroll using your mouse's scroll wheel.
                </p>
                <span>Count: {filteredData?.length}</span>
              </div>
            <DataTable data={filteredData} columns={columns}
              // fixedHeader fixedHeaderScrollHeight='482px'
              subHeaderAlign="left" />
          </div>
        }

        {
          popupLoading ?
            <div className='loader-wrapper'>
              <Spinner animation="border" variant="success" />
            </div> :
            <>
              {openViewTeamPopup
                ? <div className="popup-wrapper">
                  <div className="popup-container" ref={printRef}>
                    <div className="popup-head" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <h4>{viewTeamDetails?.name}</h4>
                      <button className='icon-btn dark' style={{ backgroundColor: `${themeColor.primaryColor}` }} onClick={() => handlePrint()}> <FiPrinter />Print</button>
                    </div>
                    <div className="details">
                      <div className="details-block">
                        <h5>Team Lead:</h5>
                        {
                          viewTeamDetails?.leadDetail ?
                            <div className="flex">
                              <img src={`data:image/png;base64,${viewTeamDetails?.leadDetail?.photo}`} onError={(e) => { e.target.src = placeholderUserImg }} alt="profile" />
                              <div className="info">
                                <p>{viewTeamDetails?.leadDetail?.fullName ? viewTeamDetails?.leadDetail?.fullName : '-'}</p>
                                <a href={`tel:${viewTeamDetails?.leadDetail.mobNo}`}><FiPhone /> {viewTeamDetails?.leadDetail?.mobNo ? viewTeamDetails?.leadDetail?.mobNo : '-'}</a>
                              </div>
                            </div>
                            :
                            <div className="flex">
                              <p>No Team Lead</p>
                            </div>
                        }
                      </div>
                      <div className="details-block">
                        <h5>Team Members:</h5>
                        {viewTeamDetails?.members?.map((member) => (
                          <div className="flex" key={member?.name}>
                            <img src={`data:image/png;base64,${member?.photo}`} onError={(e) => { e.target.src = placeholderUserImg }} alt="profile" />
                            <div className="info">
                              <p>{member?.fullName}</p>
                              <a href={`tel:${member?.mobNo}`}><FiPhone /> {member?.mobNo ? member?.mobNo : '-'}</a>
                            </div>
                          </div>
                        ))}

                      </div>
                      <div className="details-block">
                        <h5>Zone(s) Assigned:</h5>
                        {viewTeamDetails?.venues?.length > 0 ? <>
                          {viewTeamDetails?.venues?.map((venue) => (
                            <div className="flex" key={venue?.name}>
                              <TbLocation />
                              <div>
                                <p>{venue?.name}</p>
                                <p style={{ color: '#9FA6B2' }}>{venue?.locationDescr}</p>

                              </div>
                            </div>
                          ))}
                        </> :
                          <div className="flex" >
                            <p>No Zone is Assigned</p>
                          </div>
                        }


                      </div>
                      {/* <div className="details-block">
                      <h5>Task(s) Assigned:</h5>
                      {viewTeamDetails[0].tasks?.map((task) => (
                        <div className="flex" key={task.name}>
                          <MdOutlineTaskAlt/>
                          <p>{task.name}</p>
                        </div>
                      ))}

                    </div> */}
                    </div>
                    <button className='close-btn' onClick={() => setOpenViewTeamPopup(false)}><GrClose /></button>
                  </div>
                </div>
                : ''
              }
            </>}

        <AppModal handleAction={confirDelete} handleClose={() => setShowModal(false)} hasBody={true} content='Are you sure, You want to delete Team?' showModal={showModal} title={'Confirm Delete'} actionBtnText={"Confirm"} />
      </div>
    </div>
  )
}

export default ViewTeam
