import React, { useEffect, useRef, useState } from 'react'
import Cookies from "js-cookie";
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import PageTitle from '../../components/PageTitle'
import InputField from '../../components/inputField'
import { Link, useNavigate } from 'react-router-dom'
import TextareaField from '../../components/TextareaField'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ManagementSchema } from '../../utils/Schemas'
import { GetFetch, PostFetch } from '../../utils/fetchUrl'
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert'
import { useDispatch } from 'react-redux'
import { baseUrl } from '../../config'

const AddEditManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const fileInputRef = useRef();
  const [loader, setLoader] = useState(false);
  const [itsFieldError, setItsFieldError] = useState('');
  const [searchLoader, setSearchLoader] = useState(false);
  const [fileName, setFileName] = useState('');
  const [userDetails, setUserDetails] = useState();
  const [preview, setPreview] = useState();
  const [fileError, setFileError] = useState();

  const { handleSubmit, register, reset, setValue, watch, formState: { errors }, control } = useForm({
      resolver: yupResolver(ManagementSchema),
  });

  const showErrorAlert = (msg) => {
    dispatch({
      type: ALERT_ERROR,
      payload: msg
    })
  }

  const showSuccessAlert = (msg) => {
    dispatch({
      type: ALERT_SUCCESS,
      payload: msg
    })
  }

  const fetchUserById = () => {
    if(watch('ItsId')?.length === 8 ){
      setSearchLoader(true);
      setItsFieldError('');
      GetFetch(`/api/User/${watch('ItsId')}`, dispatch, navigate).then((response) => {
        if (response.status == 200) {
          setUserDetails(response.data);
        }
        else {
          showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
        }
        setSearchLoader(false);
      }).catch((err) => {
        console.log('error', err);
        showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
        setSearchLoader(false);
      })
      setTimeout(() => {
        setSearchLoader(false)
      }, 1000);
    } else{
      setItsFieldError('Please Enter Valid ITS Id')
    }
  }

  const handleFileChange = async (file) => {
    var fileName = file.name;
    fileName = fileName.replace(/\s+/g, '');

    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    const fileNameWithoutExtension = fileName.split(".")[0];
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "gif" || extFile == "png" || extFile == "webp") {
      const token = Cookies.get('token');
      const formData = await new FormData();
      formData.append("FileName", fileNameWithoutExtension);
      formData.append("FileType", `image/${extFile}`);
      formData.append("File", file);
      formData.append("FolderName", 'management');
      try {
        const data = await fetch(`${baseUrl}/api/Medias`, {
          method: "post",
          headers: {
            "Authorization": `Bearer ${token}`,
            // "Content-Type": "multipart/form-data"
          },
          body: formData,
        });

        const uploadedImage = await data.json();
        if (uploadedImage.fileName) {
          setPreview(`${baseUrl}/api/Medias/${uploadedImage.fileName}`)
          setFileName(uploadedImage.fileName)
        }

      } catch (error) {
        dispatch({
          type: ALERT_ERROR,
          payload: "Image upload failed",
        });
      }
    } else {
      dispatch({
        type: ALERT_ERROR,
        payload: "Select a image file",
      });
    }
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  const submitAction = (val) => {
    if(preview){
      setFileError('');
      setLoader(true);
      const body = {
        "name" : val.Name,
        "designation" : val.Designation,
        "description" : val.About,
        "priority" : val.Priority,
        "department" : val.Department,
        "photo" : fileName
      }

      PostFetch('/api/management', body, "POST").then((response) => {
        if (response.status === 200) {
          showSuccessAlert('Management Added Successfully');
          reset();
          setPreview('')
          setFileName('')
        }
        else {
          showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
        }
        setLoader(false);
      }).catch((error) => {
        console.log('error', error);
        showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
        setLoader(false);
      })
    } else{
      setFileError('Please select a image');
    }
  }

  const fetchImage = async(itsid) => {
    const response = await fetch(`${baseUrl}/api/User/GetUserPhoto?ITSID=${itsid}`);
    const blob = await response.blob();
    const type = blob.type.split('/')[1];
    const file = new File([blob], `${itsid}.${type}`, { type: blob.type });
    handleFileChange(file);
  }

  useEffect(() => {
    if(userDetails){
      setValue('Name', userDetails.fullName);
      setPreview(`${baseUrl}/api/User/GetUserPhoto?ITSID=${watch('ItsId')}`);
      fetchImage(watch('ItsId'))
    }
  }, [userDetails])

  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <PageTitle link={`/management`} title='Add Management' />
            <div className="form-wrapper">
              <form onSubmit={handleSubmit(submitAction)}>
                <div className='search-its-field'>
                    <InputField fieldPlaceholder={"ITS Id"} name={'ItsId'} fieldType={'text'} fieldLabel={'ItsId:'}
                    error={itsFieldError || errors.ItsId?.message} register={{ ...register("ItsId") }} />
                    <Button variant="success" type='button' disabled={searchLoader} onClick={fetchUserById} style={{display: 'flex'}}>Search{
                        searchLoader ?
                            <div className='loader-wrapper btn-loader'>
                                <Spinner animation="border" variant="white" />
                            </div> : ''
                    }</Button>
                </div>
                <InputField fieldPlaceholder={"Name"} name={'Name'} fieldType={'text'} fieldLabel={'Name:'}
                  error={errors.Name?.message} register={{ ...register("Name") }} />
                  
                <div className='fields_two_col'>
                  <div>
                    <InputField fieldPlaceholder={"Designation"} name={'Designation'} fieldType={'text'} fieldLabel={'Designation:'}
                    error={errors.Designation?.message} register={{ ...register("Designation") }} />

                    <InputField fieldPlaceholder={"Department"} name={'Department'} fieldType={'text'} fieldLabel={'Department:'}
                    error={errors.Department?.message} register={{ ...register("Department") }} />

                    <InputField fieldPlaceholder={"Priority"} name={'Priority'} fieldType={'number'} fieldLabel={'Priority:'}
                    error={errors.Priority?.message} register={{ ...register("Priority") }} />
                  </div>
                  <div className='file_input'>
                    <input name='File' type='file' accept="image/*" onChange={(e)=>handleFileChange(e.target.files[0])} ref={fileInputRef} style={{display: 'none'}}/>
                    <div
                      onClick={handleBoxClick}
                      className='file_preview'
                    >
                      {preview && <img src={preview} alt="profile_image" />}
                    </div>
                    <p>Change / upload image</p>
                    <p>{fileError}</p>
                  </div>
                </div>


                <TextareaField fieldPlaceholder={"Enter message"}
                    fieldLabel={'About:'} name={'About'} error={errors.About?.message} register={{ ...register("About") }} />

                <div className="btn-wrapper">
                  <Link to={`/management`}><Button variant="danger">Cancel</Button></Link>
                  <Button variant="success" type='submit' disabled={loader} >Add{
                        loader ?
                            <div className='loader-wrapper btn-loader'>
                                <Spinner animation="border" variant="white" />
                            </div> : ''
                    }</Button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddEditManagement