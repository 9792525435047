import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const communicationByVenue = (sendByVenueData) => (dispatch) => {
  dispatch({
    type: COMMUNICATION_LOADING,
  });
  PostFetch("/api/Communications/sendEmailByVenue", sendByVenueData, 'POST')
    .then((response) => {
      var data = response.data;
      if (response.status === 200) {
          dispatch({
            type: ADD_COMMUNICATION,
            payload: {}
          })
          dispatch({
              type: ALERT_SUCCESS,
              payload: "Email send successfully",
            });
            // history(`/view-miqaat/${miqaatId}/view-department`);
      } else {
        dispatch({
          type: COMMUNICATION_FAIL,
          payload: "",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: COMMUNICATION_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const communicationByTeam = (sendByTeamData) => (dispatch) => {
    dispatch({
      type: COMMUNICATION_LOADING,
    });
    PostFetch("/api/Communications/sendEmailByTeam", sendByTeamData, 'POST')
      .then((response) => {
        var data = response.data;
        if (response.status === 200) {
            dispatch({
                type: ADD_COMMUNICATION,
                payload: {}
            })
            dispatch({
                type: ALERT_SUCCESS,
                payload: "Email send successfully",
              });
              // history(`/view-miqaat/${miqaatId}/view-department`);
        } else {
          dispatch({
            type: COMMUNICATION_FAIL,
            payload: "",
          });
          dispatch({
            type: ALERT_ERROR,
            payload: data.error,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: COMMUNICATION_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: error.data.msg || "Something went wrong. Please try again later.",
        });
      });
  };


export const COMMUNICATION_LOADING = "COMMUNICATION_LOADING";
export const COMMUNICATION_FAIL = "COMMUNICATION_FAIL";
export const ADD_COMMUNICATION = "ADD_COMMUNICATION";
// export const SINGLE_COMMUNICATION = "SINGLE_COMMUNICATION";



