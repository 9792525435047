import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { themeColor } from '../../../config'
import InputField from '../../../components/inputField'
import { AddEditEquipmentSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { EquipmentTableData } from '../../../appContent'
import { AddProductAction, GetSingleProuctAction, UpdateProductAction } from '../../../store/actions/Inventory'
import PageTitle from '../../../components/PageTitle'

const AddEditEquipment = () => {
  const dispatch = useDispatch();
  const params = useParams()
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState(false)
  const singleProduct = useSelector(state => state.productReducer)

  const { handleSubmit, register, reset, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(AddEditEquipmentSchema)
  })

  const submitAction = (data) => {
    const body = {
      Name: data.name
    }
    if (params?.productId) {
      let body2 = {
        ...body,
        id: params?.productId
      }
      dispatch(UpdateProductAction(params?.productId, body2, params?.id, navigate))

    } else {
      dispatch(AddProductAction(body, params?.id, navigate))
    }
    setBtnLoader(false)
    reset()
  }
  useEffect(() => {
    if (params?.productId) {
      dispatch(GetSingleProuctAction(params?.productId))
    }
  }, [params])
  useEffect(() => {
    if (singleProduct && params?.productId) {
      setValue('name', singleProduct?.singleProduct?.name)
    }
  }, [singleProduct])
  return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7} sm={12}>
                      <PageTitle link={`/view-miqaat/${params.id}/products`} title='Product' />
                        {singleProduct?.loading
                          ? <div className='loader-wrapper'>
                            <Spinner animation="border" variant="success" />
                          </div>
                          :
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <InputField fieldPlaceholder={'Enter Product Name'} name={'name'} fieldType={'text'} fieldLabel={'Product:'}
                                error={errors.name?.message} register={{ ...register('name') }} />

                                <div className="btn-wrapper">
                                    <Link to={`/view-miqaat/${params.id}/products`}><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type='submit' disabled={btnLoader} >Save{
                                        btnLoader
                                          ? <div className='loader-wrapper btn-loader'>
                                            <Spinner animation="border" variant="white" />
                                        </div>
                                          : ''
                                    }</Button>
                                </div>
                            </form>
                        </div>
                      }
                    </Col>
                </Row>
            </div>
        </div>
  )
}

export default AddEditEquipment
