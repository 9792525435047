import { ATTENDEES, LOADING, LOADING_FALSE } from "../actions/attendees";

  const initialState = {
    attendees: [],
    loading:false
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
  
      case ATTENDEES:
        return {
          ...state,
          attendees: action.payload,
          loading:false
        };
      case LOADING:
        return {
          ...state,
          loading:true,
        };
      case LOADING_FALSE:
        return {
          ...state,
          loading:false,
        };
        default: {
            return state;
      }
    }
  };
  