import React, { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import { FaFileDownload } from "react-icons/fa";
import { MdDeleteOutline, MdModeEdit } from 'react-icons/md';
import Cookies from "js-cookie";
import DataTable from 'react-data-table-component';
import InputField from '../../components/inputField';
import { baseUrl, themeColor } from '../../config';
import AppModal from '../../components/Modal';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { Button, Spinner } from 'react-bootstrap';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {  AddEditSOP } from '../../utils/Schemas';
import { IoMdAddCircle } from 'react-icons/io';

const SOPList = () => {
    const [addEditLoader, setAddEditLoader] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [deleteSopId, setDeleteSopId] = useState();
    const [sopData, setSopData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [editSopId, setEditSopSetId] = useState('');
    const [search, setSearch] = useState('');
    const [pdfUrl, setPdfUrl] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [file, setFile] = useState();
    const [fieldError, setFieldError] = useState();
    const [showAddSection, setShowAddSection] = useState(false);


    const { handleSubmit, register, reset, formState: { errors }, control, setValue, watch } = useForm({
        resolver: yupResolver(AddEditSOP),
        defaultValues: {
            sopFile: '',
            Name: ''
        }

    });

    const fetchSops = () => {
        GetFetch('/api/SOP').then((response) => {
            if (response.status == 200) {
                setSopData(response.data);
                setFilteredData(response.data)
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
        })
    }

    const DeleteTemplate = (id) => {
        return new Promise((resolve, reject) => {
            PostFetch(`/api/SOP/${id}`, "", 'DELETE').then((response) => {
                if (response.status == 200) {
                    showSuccessAlert("Successfully Deleted");
                    resolve();
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    reject();
                }
                setLoader(false);
            }).catch((err) => {
                showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
                setLoader(false);
                reject();
            })
        })
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }

    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    const deleteSop = (id) => {
        setShowModal(true);
        setDeleteSopId(id);
    }

    const confirDelete = () => {
        setShowModal(false);
        DeleteTemplate(deleteSopId).then(() => {
            setValue('Name', '')
            setFile('');
            setEditSopSetId('')
            setPdfUrl('')
            fetchSops()
        })
    }

    const editSop = (row) => {
        setEditSopSetId(row.id)
        setPdfUrl(row.mediaUrl)
        setValue('Name', row.mediaName)
    }

    const columns = [
        {
            name: 'Media Name',
            selector: (row) => row.mediaName ? row.mediaName : '-',
            minWidth: '400px',
            sortable: true,
        },
        {
            name: 'Media Type',
            selector: (row) => row.mediaType ? row.mediaType : '-',
            // minWidth: '240px',
            sortable: true,
        },

        {
            name: 'Actions',
            cell: (row) =>
                row.id &&
                <p className='action-btn-wrapper'>
                    <a
                        target='_blank'
                        className='action-btn'
                        style={{ color: '#696969', background: `${themeColor.lightGreen}` }}
                        href={row?.mediaUrl || ''}
                        rel="noreferrer"
                    >
                        <FaFileDownload />
                    </a>
                    <button
                        className='action-btn'
                        style={{ color: '#696969', background: `${themeColor.lightGreen}` }}
                        onClick={() => editSop(row)}>
                        <MdModeEdit />
                    </button>
                    <button
                        className='action-btn'
                        style={{ color: 'red', background: `${themeColor.lightGreen}` }}
                        onClick={() => deleteSop(row.id)}>
                        <MdDeleteOutline />
                    </button>
                </p>
            // minWidth: '130px',
            // maxWidth: '180px'
        },
    ]


    useEffect(() => {
        fetchSops();
    }, [])

    const onSubmit = async (values) => {
        const token = Cookies.get('token');
        setAddEditLoader(true);
        if(file){
            const formData = new FormData();
            formData.append('File', file);
            formData.append('MediaName', values.Name);
            setFieldError('');
            try {
                const data = await fetch(`${baseUrl}/api/SOP`, {
                  method: "post",
                  headers: {
                    "Authorization": `Bearer ${token}`,
                    // "Content-Type": "multipart/form-data"
                  },
                  body: formData,
                });
                if(data.status === 200){
                    showSuccessAlert('SOP Added Successfully');
                    setAddEditLoader(false);
                    fetchSops();
                    setValue('Name', '')
                    setFile('')
                } else{
                    showErrorAlert("Something Went Wrong");
                }
            } catch (error) {
                showErrorAlert(error.data.msg ? error.data.msg : "Something Went Wrong");
                setAddEditLoader(false);
            }
        } else if(pdfUrl){
            const body = {
                "Id": editSopId,
                "MediaName": values.Name
            }
            PostFetch(`/api/SOP`, body, "PUT").then((response) => {
                if (response.status === 200 || response.status === 202) {
                    showSuccessAlert('SOP Updated Successfully');
                    setAddEditLoader(false);
                    fetchSops();
                    setValue('Name', '')
                    setFile('')
                    setPdfUrl('')
                    setEditSopSetId('');
                } else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                }
            }).catch((error) => {
                console.log('error', error);
                showErrorAlert(error.data.msg ? error.data.msg : "Something Went Wrong");
            })
        } else{
            setFieldError('This field is required');
        }
    }

    const cancel = () => {
        setEditSopSetId('')
        setValue('Name', '')
        setValue('sopFile', '')
        setFile('');
        setPdfUrl('');
        setShowAddSection(false)
    }
    const onSearch = (e) => {
        setSearch(e.target.value);
        filterCities(e.target.value);
    }
    const filterCities = (searchTerm) => {
        if (sopData && sopData?.length) {
            const filterData = sopData?.filter((item) => {
                return Object.keys(item).some((key) =>
                    item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            if (filterData.length) {
                setFilteredData(filterData)
            } else {
                setFilteredData([{ "mediaName": "No Data Found" }])
            }
        }
    }

    return (
        <>
            <div className='main-wrapper container' >
                <PageTitle link='/dashboard' title='SOP' />
                <div className="two_col" >
                    <div className="table-wrapper action-table">

                        <DataTable
                            data={filteredData || [{ "Name": "No Data Found" }]}
                            columns={columns}
                            subHeader
                            progressPending={loader}
                            progressComponent={<Spinner animation="border" variant="success" />}
                            fixedHeader fixedHeaderScrollHeight='482px'
                            pagination
                            subHeaderComponent={
                                <div>
                                    <InputField
                                        fieldPlaceholder={"Search by Name"}
                                        fieldType={'text'}
                                        value={search}
                                        handleChange={onSearch}
                                    />
                                    <button
                                        className="icon-btn dark"
                                        style={{ background: `${themeColor.primaryColor}` }}
                                        onClick={() => setShowAddSection(true)}
                                    >
                                        <IoMdAddCircle /> Add SOP
                                    </button>
                                </div>
                            }
                            subHeaderAlign="left"
                        />
                    </div>
                    <AppModal handleClose={() => setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
                        handleAction={confirDelete} actionBtnText={"Confirm"} />
                    
                    <div className={`details ${showAddSection && 'active'}`}>
                        <h4 className='primary-heading'> {editSopId ? 'Edit SOP' : 'Add SOP'}</h4>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputField
                                fieldPlaceholder={'Enter SOP Name'}
                                name={'Name'}
                                fieldType={'text'}
                                fieldLabel={'Name:'}
                                error={errors?.Name?.message}
                                register={{ ...register('Name') }} 
                            />
                            {
                                pdfUrl &&
                                <iframe src={pdfUrl} width="100%" height="240px" title="PDF Viewer" className='sop_pdf'></iframe>
                            }
                            {
                                !pdfUrl &&
                                <div className='file_input'>
                                    <InputField
                                        fieldPlaceholder={'Import SOP File'}
                                        name={'sopFile'}
                                        fieldType={'file'}
                                        fieldLabel={'SOP File:'}
                                        accept='application/pdf'
                                        error={fieldError}
                                        value=""
                                        handleChange={(e)=> setFile(e.target.files[0])}
                                    />
                                    <p>{file?.name || ""}</p>
                                </div>
                            }

                            <Button
                                variant="success"
                                type='submit'
                                style={{ marginTop: '24px', marginRight: '10px' }}
                                disabled={addEditLoader}
                            >
                                Submit
                                {
                                    addEditLoader
                                        ? <div className='loader-wrapper btn-loader'>
                                            <Spinner animation="border" variant="white" />
                                        </div>
                                        : ''
                                }
                            </Button>
                            <Button
                                variant="danger"
                                color='error'
                                onClick={cancel}
                                style={{ marginTop: '24px' }}
                            >
                                Cancel
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )

}

export default SOPList