import Cookies from 'js-cookie';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppAlert from './components/Alert';
import Footer from './components/Footer';
import Header from './components/Header';
import AppRoutes from './routes';
import { setDashbaordData } from './store/actions/DashboardData';
import { setLogin } from './store/actions/login';
import { ALERT_ERROR } from './store/reducers/alert';
import { GetFetch } from './utils/fetchUrl';

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    if(Cookies.get('token')){
      dispatch(setLogin(true));
      GetFetch(`/api/Dashboard`).then((response) => {
        if (response.status == 200){
          dispatch(setDashbaordData(response.data));
        }
        else {
          showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
        }
      }).catch((err)=>{
        if(err == '401 Unauthorized'){
          navigate('/login', {replace: true});
        }
        dispatch(setLogin(false));
        showErrorAlert(err);
      })
    }
    else{
      navigate('/login', {replace: true});
      dispatch(setLogin(false));
    }
  },[])

  const showErrorAlert = (msg) => {
    dispatch({
        type: ALERT_ERROR,
        payload: msg
    })
}
  
  return (
    <div className='app'>
        <Header/>
        <AppAlert/>
        <AppRoutes />
        <Footer/>
    </div>
  )
}

export default App