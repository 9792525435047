const initialState = {};

const dashboard = (state = initialState, action) => {
    switch(action.type) {
        case 'SETDASHBOARDDATA' : return state = action.payload;
        // case 'DELETETOKEN' : return state.token = '';
        default : return state;
    }
}

export default dashboard;