import * as yup from "yup";
import { emailRegex } from "../appContent";
import { matchIsValidTel } from "mui-tel-input";

export const SendEmailItsSchema = yup.object().shape({
  ItsIds: yup.string()
    .required('This field is required')
    .matches(/^[0-9,'\n' ]+$/, "Must be only digits"),
  // ItsIds: yup
  //   .string()
  //   .required('This field is required')
  //   .test('space-separated-ids', 'Please enter valid id', value => {
  //     if (!value) return true; // Allow empty value

  //     return value.length === 8 && !value.includes(' ') && /^\d+$/.test(value);
  //   }),
  Message: yup.string().required('This field is required'),
  Template: yup.mixed(),
  Subject: yup.string().required('This field is required')
}).required("required");

export const AddQuestionSchema = yup.object().shape({
  title: yup.string().required('This field is required'),
  type: yup.mixed().required('This field is required'),
  description: yup.string(),
  priority: yup.number().transform((value) => Number.isNaN(value) ? null : value)
    .nullable()

})

export const SendEmailByJamaatJamiatSchema = yup.object().shape({
  Jamiat: yup.mixed().required("Jamiat Required"),
  Jamaat: yup.mixed().required("Jamaat Required"),
  Template: yup.mixed(),
  Position: yup.mixed().required("Position Required"),
  Message: yup.string().required("Message Required"),
  Subject: yup.string().required("Subject Required")
}).required("required");

export const miqaatRegisterSchema = yup.object().shape({
  emailId: yup.string().trim().required("This field is required").matches(emailRegex, 'Must be a valid email'),
  mobNo: yup.string().nullable().test("test-name", 'Enter a valid phone number',
    (value) => {
      if (!value) {
        return false
      }
      if (value) {
        return matchIsValidTel(value)
      }
      return true
    }),
  whatsAppNo: yup.string().nullable().test("test-name", 'Enter a valid phone number',
    (value) => {
      if (!value) {
        return false
      }
      if (value) {
        return matchIsValidTel(value)
      }
      return true
    }),
}).required("required");

export const JamiatPermissionSchema = yup.object().shape({
  Jamiat: yup.mixed(),
  Jamaat: yup.mixed(),
}).required("required");

export const SendEmailByPositionSchema = yup.object().shape({
  Position: yup.mixed().required("Jamaat Required"),
  Template: yup.mixed(),
  Message: yup.string().required("Message Required"),
  Subject: yup.string().required("Subject Required"),
}).required("required");

function isquillempty(value) {
  if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
    return false;
  }
  return true;
}

export const AddEditTemplateSchema = yup.object().shape({
  // htmlBody: yup.string().required('This field is required'),
  htmlBody: yup.string().test(
    'html-body',
    'This field is required',
    (value, context) => isquillempty(value),
  ),
  Name: yup.string().min(3, 'Name must be more than 3 characters').required('This field is required'),
  Subject: yup.string().min(3, 'Subject must be more than 3 characters').required('This field is required')
}).required("required");

export const AssignRoleSchema = yup.object().shape({
  ItsIds: yup.string()
    .required('This field is required')
    .matches(/^[0-9,'\n' ]+$/, "Must be only digits"),
  // ItsIds: yup
  //   .string()
  //   .required('This field is required')
  //   .test('space-separated-ids', 'Please enter valid id', value => {
  //     if (!value) return true; // Allow empty value

  //     return value.length === 8 && !value.includes(' ') && /^\d+$/.test(value);
  //   }),
  Role: yup.mixed().required('This field is required')
}).required("required");


// export const AddEditTeamSchema = yup.object().shape({
// Venue: yup.mixed().required('This field is required'),
// Team: yup.string().required('This field is required'),
// TeamLead: yup.string().required('This field is required').min(8,'Team lead must be 8 characters').max(8, 'Team lead must be 8 characters'),
// ItsIds: yup.string().required('This field is required')
// })
// .matches(/^,?(?:\d{8})?$/, 'Not valid id')

export const DesignationSchema = yup
  .object().shape({
    ItsIds: yup.string()
      .required('This field is required')
      .matches(/^[0-9 ,'\n']+$/, "Must be only digits"),
    designation: yup.string().required('This field is required')
  })
  .required("required");

export const AddEditTeamSchema = yup.object({
  // Venue: yup.mixed().required('This field is required'),
  Venue: yup.mixed(),
  Team: yup.string().trim().required('This field is required'),

  TeamLead: yup.string().required('This field is required').min(8, 'Team lead must be 8 characters').max(8, 'Team lead must be 8 characters').nullable(),
  TeamLead: yup
    .string()
    .required('This field is required')
    .test('space-separated-ids', 'Please enter valid id', value => {
      if (!value) return true; // Allow empty value

      return value.length === 8 && !value.includes(' ') && /^\d+$/.test(value);
    })
    .nullable(),
  ItsIds: yup
    .string()
    .required('This field is required')
    .test('space-separated-ids', 'Please enter valid id', value => {
      if (!value) return true; // Allow empty value
      const ids = value.split('\n');
      let index = ids.length - 1
      for (let i = index; i >= 0; i--) {
        if (ids[i]) break
        if (!ids[i]) {
          ids.pop()
        }
      }
      return ids.every((id) => id.length === 8 && !id.includes(' ') && /^\d+$/.test(id));
    }),

}).required("required")


export const AddEditMiqaatSchema = yup.object().shape({
  Jamiat: yup.mixed().required('This field is required'),
  Jamaat: yup.mixed().required('This field is required'),
  Miqaat: yup.string().trim().required('This field is required'),
  // DateFrom: yup.date('Enter Valid Date').required('This field is required').nullable().default(undefined),
  // DateTo: yup.date('Enter Valid Date').required('This field is required').nullable().default(undefined),
  // RegDateFrom: yup.date('Enter Valid Date').required('This field is required').nullable().default(undefined),
  // RegDateTo: yup.date('Enter Valid Date').required('This field is required').nullable().default(undefined),
  utcPlusMinus: yup.mixed().required('This field is required'),
  utcHour: yup.mixed().required('This field is required'),
  utcMinute: yup.mixed().required('This field is required'),
  MiqaatHead: yup
    .string()
    .required('This field is required')
    .test('space-separated-ids', 'Please enter valid id', value => {
      if (!value) return true; // Allow empty value
      const ids = value.split('\n');
      let index = ids.length - 1
      for (let i = index; i >= 0; i--) {
        if (ids[i]) break
        if (!ids[i]) {
          ids.pop()
        }
      }
      return ids.every((id) => id.length === 8 && !id.includes(' ') && /^\d+$/.test(id));
    }),
  MiqaatHrHead: yup
    .string()
    .required('This field is required')
    .test('space-separated-ids', 'Please enter valid id', value => {
      if (!value) return true; // Allow empty value
      const ids = value.split('\n');
      let index = ids.length - 1
      for (let i = index; i >= 0; i--) {
        if (ids[i]) break
        if (!ids[i]) {
          ids.pop()
        }
      }
      return ids.every((id) => id.length === 8 && !id.includes(' ') && /^\d+$/.test(id));
    }),

})

export const ManualAttendanceSchema = yup.object().shape({
  ItsIds: yup.string()
    .required('This field is required')
    .matches(/^[0-9,'\n' ]+$/, "Must be only digits"),
  Venue: yup.mixed()
    .required('This field is required')
})

export const AddEditVenueSchema = yup.object().shape({
  // Assigned: yup.mixed().required('This field is required'),
  locationDescription: yup.string(),
  name: yup.string().trim().required('This field is required'),
  radius: yup.number().nullable().min(0, "Radius must be a non-negative number")
  // VenueAddress: yup.string().required('This field is required'),
  // zip: yup.string().required('This field is required'),
  // city: yup.string().required('This field is required'),
  // state: yup.string().required('This field is required'),
})

export const ByVenueCommunicationSchema = yup.object().shape({
  venue: yup.mixed().required('This field is required'),
  subject: yup.string().required('This field is required'),
  message: yup.string().required('This field is required'),
}).required("required");

export const ByTeamCommunicationSchema = yup.object().shape({
  team: yup.mixed().required('This field is required'),
  subject: yup.string().required('This field is required'),
  message: yup.string().required('This field is required'),
}).required("required");

export const BroadcastSchema = yup.object().shape({

})

export const AddEditDepartmentSchema = yup.object({
  Name: yup.string().required('This field is required'),
  ItsIds: yup
    .string()
    .required('This field is required')
    .test('space-separated-ids', 'Please enter valid id', value => {
      if (!value) return true; // Allow empty value
      const ids = value.split('\n');
      let index = ids.length - 1
      for (let i = index; i >= 0; i--) {
        if (ids[i]) break
        if (!ids[i]) {
          ids.pop()
        }
      }
      return ids.every((id) => id.length === 8 && !id.includes(' ') && /^\d+$/.test(id))
    }),

  // ItsIds: yup.string().required('This field is required').matches(/(?<!\d)\d{8}(?!\d)?$/, 'Please entry valid id')
}).required("required");

export const AddEditTaskSchema = yup.object().shape({
  TaskName: yup.string().trim().required('This field is required'),
  // team: yup.mixed().required('This field is required'),
})
export const TaskSchema = yup.object().shape({
  link: yup.string().url('Please enter a valid URL').required('This field is required'),
  ItsIds: yup.string()
    .required('This field is required')
    .matches(/^[0-9,'\n' ]+$/, "Must be only digits"),
  // team: yup.mixed().required('This field is required'),
})

export const EditTicketSchema = yup.object().shape({
  team: yup.mixed().required('This field is required'),
  venue: yup.mixed().required('This field is required'),
  Title: yup.string().required('This field is required'),
  Description: yup.string()
})

export const contactDetailsSchema = yup.object().shape({
  emailId: yup.string().trim().required('Email is required').matches(emailRegex, 'Must be a valid email')
})

export const AddEditEquipmentSchema = yup.object().shape({
  name: yup.string().required('This field is required')
})

export const AddEditProductInOutSchema = yup.object().shape({
  zoneFrom: yup.mixed().required('This field is required'),
  zoneTo: yup.mixed().required('This field is required'),
  ItsId: yup.string().required('This field is required').min(8, 'Enter valid ITS ID').max(8, 'Enter valid ITS ID'),
})

export const AddEditCities = yup.object().shape({
  state: yup.object().nullable().required('This field is required'),
  Name: yup.string().required('This field is required')
})
export const AddEditLanguages = yup.object().shape({
  Name: yup.string().required('This field is required')
})
export const AddEditSkill = yup.object().shape({
  Name: yup.string().required('This field is required')
})
export const AddEditSOP = yup.object().shape({
  Name: yup.string().required('This field is required'),
  sopFile: yup.mixed().required('This field is required')
})
export const ManagementSchema = yup.object().shape({
  ItsId: yup.string().required('This field is required').min(8, 'Enter valid ITS ID').max(8, 'Enter valid ITS ID'),
  Name: yup.string().required('This field is required'),
  Designation: yup.string().required('This field is required'),
  Department: yup.string().required('This field is required'),
  Priority: yup.number().transform((value) => Number.isNaN(value) ? null : value).nullable().required('This field is required'),
  About: yup.string()
})