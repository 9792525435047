import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FiPrinter } from "react-icons/fi";
import { TbFileExport } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import placeholderUserImg from "../../../assets/images/profile.png";
import { exportExcelFile } from "../../../components/ExportExcelFile";
import FilterData from "../../../components/FilterData";
import PageTitle from "../../../components/PageTitle";
import { themeColor } from "../../../config";
import { getAllAttendeesAction } from "../../../store/actions/attendees";
import { GetTeamAndVenuesAction } from "../../../store/actions/miqaatAction";
import "../../style.css";
import ExportCardPDF from "./ExportCardPDF";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import ColumnFilter from "../../../components/ColumnFilter";
import { AllQuestionAction } from "../../../store/actions/questionAction";

const KhidmatguzarOnDuty = () => {
  const navigate = useNavigate();
  const params = useParams();
  const printRef = useRef();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filterValues, setFilterValue] = useState([]);
  const [isDisabledClearBtn, setIsDisabledClearBtn] = useState(true);
  const [allAttendees, setAllAttendees] = useState([]);
  const [idCardView, setIdCardView] = useState(false);
  const [columns, setColumns] = useState([]);
  const [initialColumn, setInitialColumn] = useState([]);
  const [isShowPhoto, setIsShowPhoto] = useState(false)
  const [loading, setLoading] = useState(false)
  const dataTableRef = useRef(null);
  const attendeesData = useSelector(
    (state) => state.attendeesReducer.attendees
  );
  const attendeesLoading = useSelector(
    (state) => state.attendeesReducer.loading
  );
  const dropDownOptions = useSelector(
    (state) => state.miqaatreducer.dropdownOptions
  );
  const questionsData = useSelector((state) => state.questionreducer.questionData)
  const [isShowQuestionaire, setIsShowQuestionaire] = useState(false);

  const excelColumns = [
    { header: "Photo", key: "photo", width: 10 },
    { header: "ITS ID", key: "itsid", width: 14 },
    { header: "Full Name", key: "fullName", width: 45 },
    { header: "Designantion", key: "designation", width: 25 },
    { header: "Mobile", key: "mobNo", width: 20 },
    { header: "Whatsapp No.", key: "whatsAppNo", width: 20 },
    { header: "Venue", key: "venue", width: 30 },
    { header: "Team", key: "teams", width: 35 },
  ];

  useEffect(() => {
    if (params?.id) {
      dispatch(getAllAttendeesAction(params?.id, setLoader, navigate, isShowPhoto));
      dispatch(GetTeamAndVenuesAction(params?.id, setLoader));
      dispatch(AllQuestionAction(params?.id))
    }
  }, [params]);

  const getOptions = (key) => {

    if (attendeesData?.length > 0) {
      const skills = [
        ...new Map(
          attendeesData
            .flatMap(att => att?.skills?.map(skill => skill && ({ value: skill?.id, label: skill?.name })))
            .filter(Boolean)
            .map(skill => [skill.value, skill])
        ).values()
      ];

      const languages = [
        ...new Map(
          attendeesData
            .flatMap(att => att?.languages?.map(lang => lang && ({ value: lang?.id, label: lang?.name })))
            .filter(Boolean) // Remove undefined values
            .map(lang => [lang.value, lang]) // Map to entries
        ).values()
      ];


      if (key === 'skills') {
        return skills;
      } else if (key === 'languages') {
        return languages;
      } else {
        return [];
      }
    }
  }
  useEffect(() => {
    setLoading(attendeesLoading)
  }, [attendeesLoading])

  const handleFilterChange = (keyName, value, type) => {
    setFilterValue((previousFilter) => ({
      ...previousFilter,
      [keyName]: type ? { value, type } : value,
    }));
    // setFilterValue({
    //   ...filterValues,
    //   [keyName]: type ? {value, type}: value,
    // });
  };
  const columnsData = [

    {
      name: (
        <FilterData
          columnName="ITS ID"
          keyName="itsid"
          filterType="number"
          handleFilterChange={handleFilterChange}
        />
      ),
      selector: (row) => (row.itsid ? row.itsid : " "),
      minWidth: "140px",
      maxWidth: "160px",
      sortable: true,
    },
    {
      name: (
        <FilterData
          columnName="Full Name"
          keyName="fullName"
          filterType="text"
          handleFilterChange={handleFilterChange}
        />
      ),
      selector: (row) => (row.fullName ? row.fullName : " "),
      minWidth: "350px",
      maxWidth: "400px",
      sortable: true,
    },
    {
      name: (
        <FilterData
          columnName="Designation"
          keyName="designation"
          filterType="text"
          handleFilterChange={handleFilterChange}
        />
      ),
      selector: (row) => (row.designation ? row.designation : " "),
      minWidth: "180px",
      maxWidth: "200px",
    },
    {
      name: (
        <FilterData
          columnName="Mobile no."
          keyName="mobNo"
          filterType="number"
          handleFilterChange={handleFilterChange}
        />
      ),
      selector: (row) => (row.mobNo ? row.mobNo : " "),
      minWidth: "180px",
      maxWidth: "200px",
    },
    {
      name: (
        <FilterData
          columnName="Whatsapp no."
          keyName="whatsAppNo"
          filterType="number"
          handleFilterChange={handleFilterChange}
        />
      ),
      selector: (row) => (row.whatsAppNo ? row.whatsAppNo : " "),
      minWidth: "180px",
      maxWidth: "200px",
    },
    {
      name: (
        <FilterData
          columnName="Zone"
          keyName="venues"
          filterType="select"
          handleFilterChange={handleFilterChange}
          options={dropDownOptions?.venues || []}
          width="90%"
        />
      ),
      selector: (row) => (row.venues ? row.venues.join(", ") : " "),
      minWidth: "250px",
      maxWidth: "300px",
    },
    {
      name: (
        <FilterData
          columnName="Team"
          keyName="teams"
          filterType="select"
          handleFilterChange={handleFilterChange}
          options={dropDownOptions?.teams || []}
          width="90%"
        />
      ),
      selector: (row) => (row.teams ? row.teams.join(", ") : " "),
      minWidth: "250px",
      maxWidth: "300px",
    },
    {
      name: (
        <FilterData
          columnName="Skills"
          keyName="skills"
          filterType="select"
          options={getOptions('skills')}
          handleFilterChange={handleFilterChange}
          width="90%"
        />
      ),
      selector: (row) => row?.skills
        ? row?.skills?.map((skill) => skill?.label).join(", ")
        : " ",
      minWidth: "250px",
      maxWidth: "300px",
    },
    {
      name: (
        <FilterData
          columnName="Qualification"
          keyName="qualification"
          filterType="text"
          handleFilterChange={handleFilterChange}
          width="90%"
        />
      ),
      selector: (row) => row?.qualification
        ? row?.qualification
        : " ",
      minWidth: "250px",
      maxWidth: "300px",
    },

    {
      name: (
        <FilterData
          columnName="Occupations"
          keyName="occupation"
          filterType="text"
          handleFilterChange={handleFilterChange}
          width="90%"
        />
      ),
      selector: (row) => row?.occupation
        ? row?.occupation
        : " ",
      minWidth: "250px",
      maxWidth: "300px",
    },
    {
      name: (
        <FilterData
          columnName="Interest"
          keyName="interest"
          filterType="text"
          handleFilterChange={handleFilterChange}
          width="90%"
        />
      ),
      selector: (row) => row?.interest
        ? row?.interest
        : " ",
      minWidth: "250px",
      maxWidth: "300px",
    },
    {
      name: (
        <FilterData
          columnName="Languages"
          keyName="languages"
          filterType="select"
          handleFilterChange={handleFilterChange}
          options={getOptions('languages')}
          width="90%"
        />
      ),
      selector: (row) => (row?.languages
        ? row.languages.map((language) => language?.label).join(", ")
        : " "),
      minWidth: "250px",
      maxWidth: "300px",
    },
  ];



  useEffect(() => {
    let formattedData = attendeesData.map(attendee => {
      let fieldsToConvert = ['skills', 'languages', 'questions'];
      fieldsToConvert?.forEach(field => {
        if (field && attendee[field]) {
          attendee[field] = attendee[field]?.map(item => ({
            label: item.name,
            value: item.id
          }));
        }
      });
      const newItem = { ...attendee };
      if (attendee.questions) {
        attendee.questions.forEach(question => {
          newItem[question.value] = question.label;
        });
      }
      return newItem;
    });

    setAllAttendees(formattedData);
    if (!isShowPhoto) {
      setColumns(columnsData)
    }
    setInitialColumn(columnsData)
  }, [attendeesData]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleClearFilter = () => {
    setFilterValue([]);
    setIsDisabledClearBtn(true);
    document.querySelectorAll("input").forEach((input) => (input.value = null));
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));
  };

  const getFilteredData = (filterData) => {
    setFilteredData(filterData);
  };

  useEffect(() => {
    const isFilterEmpty = Object.keys(filterValues).every((key) =>
      filterValues[key].type ? false : filterValues[key].trim() === ""
    );
    setIsDisabledClearBtn(isFilterEmpty);
  }, [filterValues]);

  const getSelectOptions = (header) => {
    return { ...header, options: header?.placeholders?.map((option) => ({ value: option, label: option })) }
  }

  const showHideQuestionaire = (isShow) => {

    const questions = attendeesData.flatMap(attendee => attendee.questions);
    const attendeesQuestionIds = questions?.map(question => question?.value) || [];
    const filteredQuestions = questionsData.filter(question => attendeesQuestionIds?.includes(question?.value));
    const questionnaireColumn = filteredQuestions.map((question, index) => ({
      name: question.filter ? (
        <>
          <div className="column-header">
            <Tooltip title={question?.title} aria-label={question?.title}>
              {question?.title}
            </Tooltip>
          </div>
          <ColumnFilter
            type={question.type}
            onChange={(value) => {
              handleFilterChange(question?.id, value, question?.type)
            }} //keyName, value, type
            header={getSelectOptions(question)}
            index={index}
            value={filterValues[index]?.value}
          />
        </>
      ) : (
        <div className="column-header">
          <Tooltip title={question.title} aria-label={question.title}>
            {question.title}
          </Tooltip>
        </div>
      ),
      selector: (row) => {
        return row[question?.id]
        // return questions?.find((ques)=>ques?.value===question?.id)?.label;
      }
    }));

    if (isShow) {
      setColumns([...columns, ...questionnaireColumn]);
    } else {
      const photoColumn = [{
        name: "Photo",
        cell: (row) => (
          <img
            className="user-profile"
            src={
              row.photo
                ? `data:image/png;base64,${row.photo}`
                : placeholderUserImg
            }
          />
        ),
        minWidth: "90px",
        maxWidth: "90px",
      }];
      if (isShowPhoto) {
        setColumns([...photoColumn, ...initialColumn]);
      } else {
        setColumns(initialColumn);
      }
    }

  };

  const toggleButton = () => {
    setIsShowQuestionaire(!isShowQuestionaire);
    // if(isShowQuestionaire){
    //   console.log(filterValues,'filterValues')
    // const isFilterEmpty = Object.keys(filterValues).every((key) =>
    //   filterValues[key].type ? false : filterValues[key].trim() === ""
    // );
    // console.log(isFilterEmpty,'isFilterEmpty')
    // setIsDisabledClearBtn(isFilterEmpty);}

  }

  const toggleButtonShowPhoto = () => {
    setIsShowPhoto(!isShowPhoto);
    const photoColumn = [{
      name: "Photo",
      cell: (row) => (
        <img
          className="user-profile"
          src={

            (row.photo
              ? `data:image/png;base64,${row.photo}`
              : placeholderUserImg)
          }
        />
      ),
      minWidth: "90px",
      maxWidth: "90px",
    }];
    if (!isShowPhoto) {
      // Add the photo column
      dispatch(getAllAttendeesAction(params?.id, setLoader, navigate, !isShowPhoto));
      setColumns([...photoColumn, ...columns]);
    } else {
      // Remove the photo column
      setColumns(columns.filter(column => column.name !== "Photo"));
    }
  };

  useEffect(() => {
    showHideQuestionaire(isShowQuestionaire)
  }, [isShowQuestionaire])
  

  return (
    <div className="main-wrapper">
      <style>
        {`@media print {
  @page {
    margin: 15px;
    size: landscape; / Set page size to landscape /
  }
.input-field {
    display: none;
  }
  // .rdt_TableCell div:first-child {
  //     min-width: 100px;
  //     max-width: 400px;
  //     width: 100px;
  //   }
    .rdt_TableCell{
      min-width: 100px;
      max-width: 150px;
      padding: 6px 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12)
    }
    .rdt_TableRow{
      border: none !important;
      // page-break-inside: avoid;
      page-break-after: always;
    }
    .rdt_TableCol{
      min-width: 100px;
      max-width: 150px;
    }
    img {
      height: 50px;
      width: 50px
    }
    // .print-page{
    //   page-break-inside: avoid;
    // }
   }`}
      </style>

      <div className="container-fluid view-user-container">
        {idCardView ? (
          <ExportCardPDF
            closeCardView={() => setIdCardView(false)}
            userData={filteredData}
            miqaatId={params?.id}
          />
        ) : loader ? (
          <div className="loader-wrapper">
            <Spinner animation="border" variant="success" />
          </div>
        ) : (
          <>
            <div className="table-header-wrapper">
              <PageTitle
                link={`/view-miqaat/${params?.id}`}
                title={idCardView ? "Print ID Cards" : "On Duty Khidmatguzar"}
              />
              {loading ?
                <Spinner animation="border" variant="success" size='sm' />
                : <div className="table-header">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginLeft: "10px",
                    }}
                  >
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isShowPhoto}
                              onChange={toggleButtonShowPhoto}
                              color="primary"
                              classes={{
                                switchBase: 'switchBaseClass',
                                checked: 'checkedClass',
                                track: 'trackClass',
                              }}
                            />
                          }
                          label={isShowPhoto ? "Hide Photo" : "Show Photo"}
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isShowQuestionaire}
                              onChange={toggleButton}
                              color="primary"
                              classes={{
                                switchBase: 'switchBaseClass',
                                checked: 'checkedClass',
                                track: 'trackClass',
                              }}
                            />
                          }
                          label={isShowQuestionaire ? "Hide Questionaire" : "Show Questionaire"}
                        />
                      </FormGroup>
                    </FormControl>
                    {!isDisabledClearBtn && (
                      <button
                        className="icon-btn dark"
                        disabled={isDisabledClearBtn}
                        style={{
                          backgroundColor: isDisabledClearBtn
                            ? "#f5f5f5"
                            : `#df1717`,
                          color: isDisabledClearBtn ? "#070707" : "",
                        }}
                        onClick={handleClearFilter}
                      >
                        Clear Filter
                      </button>
                    )}
                    {allAttendees && allAttendees.length > 0 ? (
                      <>
                        <button
                          className="icon-btn"
                          style={{ color: `${themeColor.primaryColor}` }}
                          onClick={() => setIdCardView(true)}
                        >
                          {" "}
                          <FiPrinter />
                          Print ID Card
                        </button>
                        <button
                          className="icon-btn"
                          style={{ color: `${themeColor.primaryColor}` }}
                          onClick={handlePrint}
                        >
                          {" "}
                          <FiPrinter />
                          Print
                        </button>
                        <button
                          className="icon-btn dark"
                          style={{ backgroundColor: `${themeColor.primaryColor}` }}
                          onClick={() =>
                            exportExcelFile(
                              filteredData,
                              excelColumns,
                              "khidmatGuzar"
                            )
                          }
                        >
                          {" "}
                          <TbFileExport />
                          Download
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>}
            </div>
            <div className="table-wrapper miqaat attandees">
              <div style={{ borderBottom: "1px solid #cccccc" }}>
                <div className="table-footer">
                  <p className="note">
                    To scroll the report horizontally, press the Shift key and
                    scroll using your mouse's scroll wheel.
                  </p>
                  <span>Count: {filteredData?.length}</span>
                </div>
                <div className="print-page" ref={printRef}>

                  <FilterData
                    ref={dataTableRef}
                    data={allAttendees ? allAttendees : []}
                    columns={columns}
                    filterValues={filterValues}
                    getFilteredData={getFilteredData}
                  />

                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default KhidmatguzarOnDuty;
