import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';

export default function SeparateDateTimePicker({label, handleOnChange, name, value, error, disablePast, fieldError, minDate, maxDate, showError, setShowError, maxDateError, minDateError, maxTimeError, minTimeError}) {
  const [errorMsg, setErrorMsg] = React.useState();

  const errorMessage = React.useMemo(() => {
    // console.log(errorMsg)
    switch (errorMsg) {
      case 'maxDate':{
        return maxDateError
      }
      case 'minDate': {
        return minDateError
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      // case 'maxTime':{
      //   return maxTimeError
      // }
      // case 'minTime': {
      //   return minTimeError
      // }

      case 'invalidTime': {
        return 'Your Time is not valid';
      }

      case 'disablePast': {
        return 'Date or Time Belongs to past';
      }

      default: {
        return '';
      }
    }
  }, [errorMsg]);

  React.useEffect(()=>{
    if(setShowError){
      if(errorMessage){
        setShowError(true)
      }else{
        setShowError(false)
      }
    }
  }, [errorMessage])

  return (
    <div className="field date-time-picker" onClick={(e)=> e.stopPropagation()}>
        {label ? <label>{label}</label> : ''}
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'TimePicker']}>
                    <DatePicker
                    name={name}
                    value={value}
                    onChange={(e)=>handleOnChange(e, 'date')}
                    disablePast={disablePast}
                    format="DD/MM/YYYY"
                    minDate={minDate}
                    maxDate={maxDate}
                    onError={(newError) => setErrorMsg(newError)}
                    />
                    <TimePicker
                    ampm={false}
                    name={name}
                    value={value}
                    onChange={(e)=>handleOnChange(e, 'time')}
                    disablePast={disablePast}
                    format="HH/mm"
                    // minTime={minTime}
                    // maxTime={maxTime}
                    onError={(newError) => setErrorMsg(newError)}
                    />
                </DemoContainer>
            </LocalizationProvider>
            {fieldError?<p className='error-message'>{fieldError}</p> : errorMessage ? <p className='error-message'>{errorMessage}</p> : ''}
          </>
    </div>
    
  );
}