import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toJpeg, toPng } from 'html-to-image';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { themeColor } from '../../../config';
import { AttendeesReportAction } from '../../../store/actions/MiqaatReportAction';
import PageTitle from '../../../components/PageTitle';
import { AllMeqaatAction } from '../../../store/actions/miqaatAction';
import { useReactToPrint } from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';
import html2pdf from 'html2pdf.js';

const AttendeesReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const params = useParams()
    const [miqaatName, setMiqaatName] = useState([])
    const attendeesData = useSelector((state) => state.MiqaatReportReducer.attandeesData)
    const loader = useSelector((state) => state.MiqaatReportReducer.loading)
    const miqaatData = useSelector((state) => state.miqaatreducer.miqaatData)
    const printRef = useRef();
    const [printIdCardLoading, setPrintIdCardLoading] = useState(false);

    useEffect(() => {
        if (!miqaatData || miqaatData.length <= 0) {
            dispatch(AllMeqaatAction(navigate))
        }
    }, [miqaatData])

    useEffect(() => {
        if (miqaatData && miqaatData.length > 0) {
            let filterData = miqaatData.filter((data) => {
                if (data.id === parseInt(params?.id)) {
                    return data
                }
            })
            setMiqaatName(filterData)
        }
    }, [params, miqaatData])

    const handlePrint = useCallback(() => {
        setPrintIdCardLoading(true);
        if (printRef.current === null) {
            setPrintIdCardLoading(false);
            return
        }
        setTimeout(() => {
            toJpeg(printRef.current, { cacheBust: true })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = 'AttendeesStats';
                    link.href = dataUrl;
                    link.click();
                    setPrintIdCardLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setPrintIdCardLoading(false);
                });
        }, 500);
    }, [printRef]);

    const totalCount = (data) => data?.reduce((sum, item) => sum + (item.count || 0), 0);

    useEffect(() => {
        dispatch(AttendeesReportAction(params))
    }, [])

    const List = ({ item }) => {
        return (
            <>
                <div className='chart-count' style={{ color: `${themeColor.secondaryColor}` }}>
                    <p>{item.label || 'Unknown'} :  </p>
                    <p style={{ marginLeft: "10px" }}>{item.count}</p>
                </div>
            </>
        )
    }

    return (
        <div className='main-wrapper'>
            {
                printIdCardLoading &&
                <div className='loader-wrapper overlay'>
                    <Spinner animation="border" variant="success" />
                    <p style={{ color: '#fff' }}>Loading...</p>
                </div>
            }
            {loader ?
                <div className='loader-wrapper'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
                <div className="container">
                    <PageTitle link={`/view-miqaat/${params.id}/report`} title={`Attendees Stats (${miqaatName[0]?.name})`} />
                    <div className='icon-btn-wrapper' style={{ textAlign: 'right', marginTop: '20px' }}>
                        <button className='icon-btn' style={{ color: `${themeColor.primaryColor}` }} onClick={handlePrint}> <FiPrinter />Print</button>
                    </div>
                    <div ref={printRef} id='idcards-container' style={{padding: '60px 20px', position:'relative', paddingTop: '34px', background: '#fff'}}>
                        {printIdCardLoading && <div className='green_back top'></div>}
                        <div className='chart-report' style={{ background: `${themeColor.lightGreen}`, marginRight: "20px", width: "100%", marginTop: printIdCardLoading && '0px', boxShadow: printIdCardLoading && 'unset', height: printIdCardLoading && '100%' }}>
                            {printIdCardLoading && <h2 className='primary-heading' style={{ color: `${themeColor.primaryColor}`, marginBottom: '24px' }}>Attendees Stats ({miqaatName[0]?.name})</h2>}
                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between' }} className='chart-details attendees'>
                                <div className='report-grid-wrapper'>
                                    <div className="head report-grid" style={{ boxShadow: printIdCardLoading && 'unset' }} >
                                        <div className="col-head">
                                            <h5>Gender</h5>
                                            <b style={{ marginLeft: "10px", color: themeColor.secondaryColor }}>{totalCount(attendeesData.gender)}</b>
                                        </div>
                                        {
                                            attendeesData.gender?.length > 0 ?
                                                <>
                                                    {attendeesData.gender?.map((item) => (
                                                        <List item={item} />
                                                    ))}
                                                </>
                                                :
                                                <div><p style={{ marginBottom: '0' }}>-</p></div>
                                        }
                                    </div>
                                    <div className="head report-grid" style={{ boxShadow: printIdCardLoading && 'unset' }} >
                                        <div className="col-head">
                                            <h5>Zone</h5>
                                            <b style={{ marginLeft: "10px", color: themeColor.secondaryColor }}>{totalCount(attendeesData.venues)}</b>
                                        </div>
                                        {
                                            attendeesData.venues?.length > 0 ?
                                                <>
                                                    {attendeesData.venues?.map((item) => (
                                                        <List item={item} />
                                                    ))}
                                                </>
                                                :
                                                <div><p style={{ marginBottom: '0' }}>-</p></div>
                                        }
                                    </div>
                                    <div className="head report-grid" style={{ boxShadow: printIdCardLoading && 'unset' }} >
                                        <div className="col-head">
                                            <h5>Designation</h5>
                                            <b style={{ marginLeft: "10px", color: themeColor.secondaryColor }}>{totalCount(attendeesData.designation)}</b>
                                        </div>
                                        {
                                            attendeesData.designation?.length > 0 ?
                                                <>
                                                    {attendeesData.designation?.map((item) => (
                                                        <List item={item} />
                                                    ))}
                                                </>
                                                :
                                                <div><p style={{ marginBottom: '0' }}>-</p></div>
                                        }
                                    </div>
                                </div>
                                <div className='report-grid-wrapper'>
                                    <div className="head report-grid" style={{ boxShadow: printIdCardLoading && 'unset' }} >
                                        <div className="col-head">
                                            <h5>Jamiat</h5>
                                            <b style={{ marginLeft: "10px", color: themeColor.secondaryColor }}>{totalCount(attendeesData.jamiat)}</b>
                                        </div>
                                        {
                                            attendeesData.jamiat?.length > 0 ?
                                                <>
                                                    {attendeesData.jamiat?.map((item) => (
                                                        <List item={item} />
                                                    ))}
                                                </>
                                                :
                                                <div><p style={{ marginBottom: '0' }}>-</p></div>
                                        }
                                    </div>
                                    <div className="head report-grid" style={{ boxShadow: printIdCardLoading && 'unset' }} >
                                        <div className="col-head">
                                            <h5>Department</h5>
                                            <b style={{ marginLeft: "10px", color: themeColor.secondaryColor }}>{totalCount(attendeesData.department)}</b>
                                        </div>
                                        {
                                            attendeesData.department?.length > 0 ?
                                                <>
                                                    {attendeesData.department?.map((item) => (
                                                        <List item={item} />
                                                    ))}
                                                </>
                                                :
                                                <div><p style={{ marginBottom: '0' }}>-</p></div>
                                        }
                                    </div>
                                </div>
                                <div className="head report-grid" style={{ boxShadow: printIdCardLoading && 'unset' }} >
                                    <div className="col-head">
                                        <h5>Teams</h5>
                                        <b style={{ marginLeft: "10px", color: themeColor.secondaryColor }}>{totalCount(attendeesData.teams)}</b>
                                    </div>
                                    {
                                        attendeesData.teams?.length > 0 ?
                                            <>
                                                {attendeesData.teams?.map((item) => (
                                                    <List item={item} />
                                                ))}
                                            </>
                                            :
                                            <div><p style={{ marginBottom: '0' }}>-</p></div>
                                    }
                                </div>

                            </div>
                        </div>
                        {printIdCardLoading && <div className='green_back bottom'></div>}
                    </div>
                </div>
            }
        </div>
    )
}

export default AttendeesReport;