import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { InventoryReportCards } from '../../../appContent';
import { themeColor } from '../../../config';
import Card from '../../../components/Card';
import PageTitle from '../../../components/PageTitle';

const InventoryReport = () => {
    const params = useParams()
  return (
    <>
      <div className='main-wrapper'>
          <div className='container'>
          <PageTitle link={`/view-miqaat/${params.id}`} title='Inventory Report' />
            <div className="card-wrapper">
                {InventoryReportCards.map((cardInfo)=>{
                    return <Card key={cardInfo.optionId} cardDetails={cardInfo} /> 
                  })}
            </div>
          </div>
      </div>
  </>
  )
}

export default InventoryReport