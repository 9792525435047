import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack, IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io'
import dayjs from 'dayjs'
import { get, isEmpty } from "lodash";
import { useDispatch, useSelector } from 'react-redux'
import { themeColor } from '../../../config'
import InputField from '../../../components/inputField'
import { AddEditProductInOutSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ProductInData } from '../../../appContent'
import SelectField from '../../../components/SelectField'
import { dateFormatFunction } from '../../../utils/dateFormat'
import BasicDateTimePicker from '../../../components/DateNTimePicker'
import { AllVenueAction } from '../../../store/actions/venueAction'
import SeparateDateTimePicker from '../../../components/SeparateDateTimePicker'
import PageTitle from '../../../components/PageTitle'

const products = [
  {value: 1, label: 'Vacuum cleaner'},
  {value: 2, label: 'Laptop'},
  {value: 3, label: 'Waste Bin'},
]

const ProductInOut = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const [btnLoader, setBtnLoader] = useState(false)
  const [fieldValues, setFieldValues] = useState()
  const [showError, setShowError] = useState(false);
  const [fieldError, setFieldError] = useState();
  const [addFieldError, setAddFieldError] = useState();
  const [venuesList, SetVenueList] = useState({
    venues: [],
    zoneFrom: [],
    zoneTo: []
  })
  const [addFieldValues, setAddFieldValues] = useState([
    {product: null, quantity: ''}
  ]);
  const [dateNTime, setDateNTime] = useState(null);
  const venusData = useSelector((state) => state.venuereducer);
  const [filteredProducts, setFilteredProducts] = useState(products);

  const { handleSubmit, register, reset, watch, formState: { errors }, setValue, control } = useForm({
    resolver: yupResolver(AddEditProductInOutSchema)
  })

  const handleVenueSelect = (data) => {
    if (data) {
      let venueData = []
      data?.Venue?.map((res) => {
        venueData.push(res.id)
      })
      return venueData
    }
  }

  const dateNTimeChange = (e, type) => {
    const date = dateNTime ? new Date(dateNTime) : new Date(e);
    if(type === 'date'){
        date.setDate(new Date(e).getDate())
    }else{
        date.setHours(new Date(e).getHours())
        date.setMinutes(new Date(e).getMinutes())
    }
    setDateNTime(dayjs(date))
  }

  useEffect(() => {
    dispatch(AllVenueAction(params?.id))
  }, [])
  
  useEffect(() => {
    if (!isEmpty(get(venusData, 'venusData'))) {
      let newVenue = []
      venusData?.venusData.map((res) => {
        let data = {
          id: res.id,
          label: res.name,
          value: res.name
        }
        newVenue.push(data)
      })
      // newVenue.unshift({ label: "All Select", value: -1 })
      SetVenueList({
        venues: newVenue,
        zoneFrom: newVenue,
        zoneTo: newVenue
    })
    }
  }, [get(venusData, 'venusData')])

  const submitAction = (data) => {
    if(!dateNTime){
        setFieldError('Please Select Date and Time');
        setShowError(true)
    }else{
        setShowError(false)
        setBtnLoader(false)
        reset()
    }
  }

  const addField = () => {
    const lastIndex = addFieldValues.length - 1;
    if(addFieldValues[lastIndex].product?.value && addFieldValues[lastIndex].quantity){
      setAddFieldError('')
      setAddFieldValues(prev=>([
        ...prev,
        {product: null, quantity: ''}
      ]))
    }else{
      setAddFieldError('Please select a product or enter quantity')
    }
  }

  const removeField = (item, i) => {
    const lastIndex = addFieldValues.length - 2;
    const values = addFieldValues.filter((a, index)=> index !== i);
    if(addFieldValues[lastIndex].product?.value && addFieldValues[lastIndex].quantity){
      setAddFieldError('')
    }
    setAddFieldValues(values)
  }

  // const filterProductOptions = () => {
  //   addFieldValues
  // }

  const onAddFieldChange = (e, i, type) => {
    const values = addFieldValues;
    if(type === 'quantity'){
      values[i].quantity = e.target.value;
      setAddFieldValues(values);
    }else{
      values[i].product = e;
    }
    setAddFieldValues([...values]);
  }

  useEffect(()=>{
    if(watch('zoneFrom')){
        const zoneToList = venuesList.venues.filter((item)=> item.id !== watch('zoneFrom').id);
        SetVenueList(prev=>({
            ...prev,
            zoneTo: zoneToList
        }))
    }
    if(watch('zoneTo')){
        const zoneFromList = venuesList.venues.filter((item)=> item.id !== watch('zoneTo').id);
        SetVenueList(prev=>({
            ...prev,
            zoneFrom: zoneFromList
        }))
    }
  }, [watch('zoneFrom'), watch('zoneTo')])

  useEffect(() => {
    if (fieldValues) {
      setValue('product', {value: fieldValues?.productId, label: fieldValues?.name})
      setValue('dateNTime', dayjs(fieldValues?.insertedAt))
      setValue('ItsId', fieldValues?.ItsId)
      setValue('quantity', fieldValues?.quantity)
    }
  }, [fieldValues])

  return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7} sm={12}>
                      <PageTitle link={`/view-miqaat/${params.id}`} title='Prdouct In & Out' />
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <InputField fieldPlaceholder={'Enter ITS ID'} name={'ItsId'} fieldType={'text'} fieldLabel={'ITS ID:'}
                                error={errors.ItsId?.message} register={{ ...register('ItsId') }} />
                                
                                <SelectField fieldLabel="From Zone" options={venuesList.zoneFrom} name={'zoneFrom'}
                                placeholder="Select From Zone" register={{ ...register('zoneFrom') }}
                                error={errors.zoneFrom?.message} control={control} />

                                <SelectField fieldLabel="To Zone" options={venuesList.zoneTo} name={'zoneTo'}
                                placeholder="Select To Zone" register={{ ...register('zoneTo') }}
                                error={errors.zoneTo?.message} control={control} />

                                <SeparateDateTimePicker
                                    label={'Product In/Out Date and Time'}
                                    value={dateNTime}
                                    handleOnChange={dateNTimeChange}
                                    name={'dateNTime'}
                                    showError={showError}
                                    setShowError={setShowError}
                                    fieldError={fieldError}
                                />

                                {/* <BasicDateTimePicker
                                  label='Product In Date and Time'
                                  name='dateNTime'
                                  error={errors.dateNTime?.message} control={control}
                                /> */}
                                <div className="add-field-wrapper">
                                  {
                                  addFieldValues.map((item, index)=>(
                                    <div className='two-fields-wrapper'>
                                        <>
                                            <SelectField fieldLabel="Product" options={products} name={'product'}
                                            placeholder="Select Product" value={addFieldValues[index].product} handleChange={(e)=> onAddFieldChange(e, index, 'product')} />
        
                                            <InputField fieldPlaceholder={'Enter Quantity'} name={'quantity'} fieldType={'number'} fieldLabel={'Quantity'}
                                            value={addFieldValues[index].quantity} handleChange={(e)=> onAddFieldChange(e, index, 'quantity')} />
                                            {index > 0 ?
                                              <button type='button' className='remove-btn' onClick={()=> removeField(item, index)}><IoMdRemoveCircle /></button>
                                              : <span></span>
                                            }
                                        </>
                                    </div>
                                    ))
                                  }
                                  {
                                    addFieldError && <p className='error-message'>{addFieldError}</p>
                                  }
                                  <button type='button' className='add-btn' onClick={addField}><IoMdAddCircle />Add Field</button>
                                </div>

                                <div className="btn-wrapper">
                                    <Link to={`/view-miqaat/${params.id}/`}><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type='submit' disabled={btnLoader} >Save{
                                        btnLoader
                                          ? <div className='loader-wrapper btn-loader'>
                                            <Spinner animation="border" variant="white" />
                                        </div>
                                          : ''
                                    }</Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
  )
}

export default ProductInOut
