import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle'
import ApexChartsComponent from '../../components/ApexChartsComponent';
import { GetFetch } from '../../utils/fetchUrl';
import { GrClose } from 'react-icons/gr';
import { FiZoomIn } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { themeColor } from '../../config';
import { TbFileExport } from 'react-icons/tb';
import { toJpeg } from 'html-to-image';

const MiqaatStatistics = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const printRef = useRef();
    const [reportData, setReportData] = useState(null)
    const [loader,setLoader] = useState(false);
    const [printIdCardLoading, setPrintIdCardLoading] = useState(false);
    const [zoomInChart, setZoomInChart] = useState({
        type: '',
        show: false,
        data: '',
        title: ''
      });

    const customLegendFormatter = (seriesName, opts) => {
    return `
            <div class="legend-item-label">${seriesName}</div>
            <div class="legend-item-value">${opts.w.globals.series[opts.seriesIndex]}</div>
        `;
    };
    
    const [chartData, setChartData]= useState({
        totalRegsData: {
            series: [],
            options:{
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 6,
                    }
                },
                dataLabels:{
                    enabled: false
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                          fontSize: '11px'
                        }
                      },
                    crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                        }
                    }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
            }
        },
        ticketsData: {
            series: [],
            options: {
                chart: {
                    type: 'donut',
                },
                colors:['#FF91A9', '#7F4DC3', '#3E98B5'],
                labels: [],
                tooltip: {
                    y: {
                      formatter: function(value, opts) {
                        const total = opts.config.series.reduce((acc, num) => acc + num, 0)
                        return Math.round((value / total) * 100) + "%";
                      }
                    }
                },
                dataLabels: {
                enabled: false
                },
                legend: {
                    position: 'left',
                    formatter: customLegendFormatter,
                },
                responsive: [{
                    breakpoint: 768,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        }
    })

    const fetchReportData = () => {
        setLoader(true)
        GetFetch(`/api/Reports/MiqaatStats`, dispatch, navigate).then((response) => {
            if (response.status == 200){
                setReportData(response.data); 
                setTimeout(() => {
                    setLoader(false)
                }, 1500);
            }
            else {
                console.log('Something went wrong');
                setLoader(false)
                }
        }).catch((err)=>{
            console.log('error', err);
            setLoader(false);
        })
    }
    
    useEffect(()=>{
    fetchReportData()
    },[])

    useEffect(()=>{
        if(reportData){
            setChartData({
                totalRegsData:{
                    series: reportData?.totalRegsData.series,
                    options: {
                        ...chartData.totalRegsData.options,
                        xaxis:{
                            ...chartData.totalRegsData.options.xaxis,
                            categories: [...reportData?.totalRegsData.categories]
                        }
                    }
                },
                ticketsData: {
                    series: [...reportData?.ticketsData.series],
                    options: {
                        ...chartData.ticketsData.options,
                        labels: [...reportData?.ticketsData.labels]
                    }
                }
            })
        }
    }, [reportData])

    const handlePrint = useCallback(async() => {
        setPrintIdCardLoading(true);
        if (printRef.current === null) {
            return
        }
        setTimeout(() => {
            toJpeg(printRef.current, { cacheBust: true })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = 'Miqaat Stats';
                    link.href = dataUrl;
                    link.click();
                    setPrintIdCardLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setPrintIdCardLoading(false);
                });
        }, 500);
      }, [printRef]);

  return (
    <div className='main-wrapper'>
        {
            printIdCardLoading &&
            <div className='loader-wrapper overlay'>
                <Spinner animation="border" variant="success" />
                <p style={{ color: '#fff' }}>Loading...</p>
            </div>
        }
        <div className="container ">
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <PageTitle link='/dashboard' title='MIQAAT STATISTICS' />
                <button className='icon-btn dark' style={{ backgroundColor: `${themeColor.primaryColor}`, display:'flex', marginTop: '0' }} onClick={handlePrint}> <TbFileExport />{printIdCardLoading ? 'Print...' : "Print"}</button>
            </div>
            {
                loader ?
                <div className='loader-wrapper'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
            <div ref={printRef} style={{padding: printIdCardLoading && "40px", background: printIdCardLoading && '#fff'}}>
                <div className="count-card-wrapper" >
                    {
                        reportData?.cardData.map((item)=>(
                        <div className="count-card" style={{ background: '#fff', color: item.color }}>
                            <i class={item.icon}></i>
                            <div>
                                <b>{item.value}</b>
                                <p>{item.label}</p>
                            </div>
                        </div>
                        ))
                    }
                </div>
                <div className={`general-chart-wrapper`} >
                    <div className="column full-height">
                        <div className="chart-box">
                            <h4>Jamiat wise Khidmat guzars</h4>
                            <button className='back-btn zoom' onClick={()=> setZoomInChart({
                                type: 'bar',
                                show: true,
                                data: chartData?.totalRegsData,
                                title: 'Jamiat wise Khidmat guzars'
                            })}><FiZoomIn /></button>
                            <div className="chart-container">
                                <ApexChartsComponent series={chartData?.totalRegsData.series} options={chartData?.totalRegsData.options} type='bar' height='100%' width='100%' />
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="chart-box">
                            <h4>Tickets assigned</h4>
                            <button className='back-btn zoom' onClick={()=> setZoomInChart({
                                type: 'donut',
                                show: true,
                                data: chartData?.ticketsData,
                                title: 'Tickets assigned '
                            })}><FiZoomIn /></button>
                            <div className="chart-container">
                                <ApexChartsComponent series={chartData?.ticketsData.series} options={chartData?.ticketsData.options} type='donut' height='100%' width='100%' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
        {zoomInChart.show ?
            <div className="popup-wrapper">
              <div className="popup-container chart-popup">
                <div className="popup-head">
                  <h4 style={{ textAlign: 'left' }}>{zoomInChart.title}</h4>
                </div>
                <div style={{ height: 'calc(100% - 64px)' }}>
                  <ApexChartsComponent type={zoomInChart.type}
                  height='100%'
                  width='100%'
                  // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                  series={zoomInChart.data.series} 
                  options={zoomInChart.data.options} />
                </div>
                <button className='close-btn' onClick={() => setZoomInChart({
                  show: false,
                  type: '',
                  data: '',
                  title: ''
                })}><GrClose /></button>
              </div>
            </div>
          : ''
        }
    </div>
  )
}

export default MiqaatStatistics