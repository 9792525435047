import {ALL_TEAM, TEAM_LOADING, ADD_TEAM, SINGLE_TEAM, TEAM_FAIL, DELETE_TEAM, UPDATE_TEAM} from "../actions/teamAction";


const initialState = {
    teamData: [],
    singleTeam: {},
    loading: false,
    deleteTeam: false,
}


export default (state = initialState, action) =>{

    switch (action.type) {
        case TEAM_LOADING:
        return {
          ...state,
          loading: true,
        //   deleteQuestion: false,
        };

        case ALL_TEAM:
            return {
                ...state,
                teamData: action.payload,
                loading: false,
              };
        case ADD_TEAM: 
            return {
                ...state,
                teamData: action.payload,
                loading: false,
            }
        
        case SINGLE_TEAM:
            return {
                ...state,
                singleTeam: action.payload,
                loading: false,
            }   
            
        case TEAM_FAIL:
            return {
                ...state,
                loading: false,
            };    

        case DELETE_TEAM:
            return {
                ...state,
                loading: false,
                deleteTeam: true,
            };    

        case UPDATE_TEAM:
            return {
                ...state,
                loading: false,
            };    

        case "USER_LOGOUT":
            return (state = initialState);
        default: {
            return state;
        }      
    }
}