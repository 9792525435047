import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import TextareaField from '../../components/TextareaField'
import { themeColor } from '../../config'
import { DesignationSchema } from '../../utils/Schemas'
import { useDispatch } from 'react-redux'
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert'
import InputField from '../../components/inputField'
import { PostFetch } from '../../utils/fetchUrl'
import PageTitle from '../../components/PageTitle'

const Designation = () => {
    const { reset , handleSubmit, register, formState: { errors } } = useForm({
        resolver: yupResolver(DesignationSchema),
    });
    const params = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [itsId, setItsId] = useState();
    const [idError, setIdError] = useState(false);
    const [loader, setLoader] = useState(false);

    const submitAction = (data) => {
        let error = false;
        let numArr = [];
        if (!itsId) {
            error = true;
        } else {
            const arr = itsId.split("\n");

            arr.forEach((str) => {
                const trimmedStr = str.trim();
                if (trimmedStr.length !== 8 || isNaN(parseInt(trimmedStr))) {
                    error = true;
                } else {
                    numArr.push(parseInt(trimmedStr));
                }
            });
        }
        setIdError(error)
        if (!error) {
            const bodyData = {
                miqaatId: params?.id,
                itsIds: numArr,
                designation: data.designation
            }
            PostFetch('/api/MiqaatReg/designation', bodyData, "PUT").then((response) => {
                setLoader(true);
                if (response.status === 200) {
                    showSuccessAlert(response.data.msg ? response.data.msg : 'Designation assigned successfully!');
                    // navigate(`/view-miqaat/${params?.id}`)
                    setItsId('');
                    reset();
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                }
                setLoader(false);
            }).catch((error) => {
                showErrorAlert(error.data.msg ? error.data.msg : "Something Went Wrong");
                setLoader(false);
            })
        }
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7} sm={12}>
                        <PageTitle link={`/view-miqaat/${params?.id}`} title='Designation' />
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <InputField
                                    fieldPlaceholder={"Enter Designation"}
                                    fieldType={'text'}
                                    fieldLabel={'Designation:'}
                                    error={errors.designation?.message}
                                    register={{ ...register("designation") }}
                                    name={'designation'} />
                                <TextareaField
                                    fieldPlaceholder={"Enter ITS ID(s)"}
                                    fieldLabel={'ITS ID(s):'}
                                    name={'ItsIds'}
                                    error={errors.ItsIds?.message}
                                    register={{ ...register("ItsIds") }}
                                    value={itsId}
                                    handleChng={(e) => setItsId(e.target.value)} />
                                {idError ?
                                    <h6 className="note" style={{ color: "red" }}>ITS Ids should be of 8 digits</h6>
                                    : ''
                                }
                                <h6 className="note" style={{ color: "green" }}>Press Enter After Every ITS ID</h6>

                                <div className="btn-wrapper">
                                    <Link to={`/view-miqaat/${params?.id}`} ><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type='submit' disabled={loader} >Save{
                                        loader ?
                                            <div className='loader-wrapper btn-loader'>
                                                <Spinner animation="border" variant="white" />
                                            </div> : ''
                                    }</Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Designation