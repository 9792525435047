
import * as React from 'react';
import { Box, FormHelperText, TextareaAutosize } from '@mui/material';
import { useState } from 'react';
export default function TextAreaComponent({ options, value, name, handleChange, type, placeholder, disable, isRequired }) {
    return (
        <>
            {type == 'text-area' ?
                <Box>
                    <Box sx={{ marginBottom: '16px' }}>
                        <textarea
                            placeholder={placeholder}
                            aria-label="minimum height"
                            name={name}
                            value={value}
                            onChange={(val) => handleChange(val)}
                            minRows={3}
                            disabled={disable ? true : false}
                            style={{ width: '100%', padding: '6px 12px' }}
                        />
                    </Box>
                </Box>
                :
                <Box>
                    {options?.map((option, i) => (
                        <Box sx={{ marginBottom: '16px' }}>
                            <textarea

                                placeholder={option.placeholder}
                                aria-label="minimum height"
                                name={name}
                                value={option.value}
                                onChange={(val) => handleChange(val, i, type)}
                                minRows={3}
                                style={{ width: '100%', padding: '6px 12px' }}
                            />
                            {
                                isRequired && option?.required && !option.value ? <FormHelperText
                                    sx={{ color: '#d32f2f' }}
                                >
                                    This field is required
                                </FormHelperText> : null
                            }
                            {option.min && option.max ? <FormHelperText
                                sx={{ color: '#3a2f2f', marginTop: '0px' }}
                            >
                                Please enter character between {option.min} and {option.max}
                            </FormHelperText> : null}
                        </Box>
                    ))}
                </Box>
            }
        </>
    );
}