import React, { useState,useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useSelector, useDispatch } from "react-redux";
import { ALERT_HIDE } from "../store/reducers/alert";

const  AppAlert = () => {
    const dispatch = useDispatch();
    const alertState = useSelector((state) => state.alert);
    const [isOpen, setisOpen] = useState(false);
    
    const HideAlert = () => {
        window.setTimeout(() => {
            setisOpen(false);
            dispatch({
                type: ALERT_HIDE,
                payload: "",
            });
        }, 5000);
    };

    const closeAlert = () => {
        setisOpen(false);
        dispatch({
            type: ALERT_HIDE,
            payload: "",
        });
    };

    useEffect(() => {
        if (alertState.success) {
            setisOpen(true);
            HideAlert();
        }
        if (alertState.error) {
            setisOpen(true);
            HideAlert();
        }
    }, [alertState]);

    if(isOpen){
        return (
            <Alert variant={alertState.success ? 'success' : 'danger'} onClose={closeAlert} dismissible>
                <Alert.Heading>{alertState.message}</Alert.Heading>
            </Alert>
        )
    }
    
}

export default AppAlert;