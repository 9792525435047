export const setLogin = (login) => {
    return {
        type: 'SETLOGIN',
        payload: login
    }
}
export const setDashboardData = (data) => {
    return {
        type: 'SETDASHBOARD',
        payload: data
    }
}
export const setProfileData = (data) => {
    return {
        type: 'SETPROFILE',
        payload: data
    }
}

