

import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { themeColor } from '../../../config'
import InputField from '../../../components/inputField'
import { AddQuestionSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import SelectField from '../../../components/SelectField'
import { miqaatTableData, qustionSelectOption } from '../../../appContent'
// import TextareaField from '../../../components/TextareaField'
import QuestionOptions from './component/add-question'
// import { questionData } from './questionJSON'
import EditQuestionOptions from './component/edit-question'
import { useDispatch, useSelector } from 'react-redux'
import { GetSingleQuestionAction, UpdateQuestionAction } from '../../../store/actions/questionAction'
import { useNavigate } from "react-router-dom";
import Checkbox from '../../../components/Checkbox'
import TextareaField from '../../../components/TextareaField'
import { FormHelperText, Stack } from '@mui/material'
import { nestedValidation } from '../../../utils/helper';
import PageTitle from '../../../components/PageTitle'

const EditQuestion = () => {
  // const [miqaatStatus, setMiqaatStatus] = useState(0)
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [btnLoader, setBtnLoader] = useState(false)
  const [loader, setLoader] = useState(true)
  const [fieldValues, setFieldValues] = useState()
  const [selectValue, setSelectValue] = useState('')
  const [values, setValues] = useState({});
  const [options, setOptions] = useState([]);
  const [hiden, setHiden] = useState()
  const [filter, setFilter] = useState()
  const [error, setError] = useState('')
  const [required, setRequired] = useState(false);
  const [maxSelectionValue, setMaxSelectionValue] = useState(0)
  const [disableSubmit, setdisableSubmit] = useState(false)
  const singleQuestionData = useSelector((state) => state.questionreducer.singleQuestion)
  let numberRegex = /^(?!.*[+-])\d+$/;

  const { handleSubmit, register, reset, formState: { errors }, control, setValue, watch } = useForm({
    resolver: yupResolver(AddQuestionSchema)
  })

  const submitAction = (res) => {
    const validate = selectValue?.value !== 'arrival-departure' ? nestedValidation(options, ['placeholder']) : '';
    let optionsData = options;
    if(selectValue?.value === 'timepicker'){
      optionsData = optionsData.map((item) => {
        if(item.placeholder === 'Duration'){
          return {...item, min: item.min.value}
        } else{
          return {...item, required: required}
        }
      })
    }
    if (!error && !validate) {
      let data = {
        ...res,
        priority: res.priority ? parseInt(res.priority) : 0,
        id: params?.questionId,
        miqaatId: params?.id,
        type: res.type.value,
        options: selectValue?.value !== 'arrival-departure' ? optionsData
          : [{
            placeholder: 'Arrival Date',
            required: true
          },
          {
            placeholder: 'Departure Date',
            required: true
          }
          ],
        hidden: hiden == undefined ? false : hiden,
        filter: filter == undefined ? false : filter,
        required: selectValue && selectValue?.value === 'select' || selectValue?.value === 'radio' || selectValue?.value === 'checkbox' || selectValue?.value === 'timepicker' ? required : null
      }
      if (watch('type').value == 'checkbox') {
        data.maxSelection = +maxSelectionValue || 0
      }
      dispatch(UpdateQuestionAction(data, params?.id, navigate))
      setBtnLoader(false)
    }
    // reset()
  }

  useEffect(() => {
    setLoader(true)
    setValue('title', '')
    if (params?.questionId) {
      dispatch(GetSingleQuestionAction(params?.questionId))
    }
  }, [])

  useEffect(() => {
    if (params?.questionId) {
      setValues(singleQuestionData)
      const optionData = singleQuestionData?.options?.map((item) => {
        if(item.placeholder === "Duration"){
          return {...item, min: {value: item.min, label: item.min === 0 ? 'null' : item.min}}
        } else{
          return item
        }
      });
      
      setOptions(optionData)
    }
  }, [params?.questionId, singleQuestionData])

  useEffect(() => {
    if (values) {
      setValue('title', values?.title)
      setValue('description', values?.description)
      let type = {
        label: values?.type,
        value: values?.type
      }
      setHiden(values?.hidden)
      setFilter(values?.filter)
      setRequired(values?.required)
      setValue('type', type)
      setValue('priority', values.priority)
      // if (values?.required && maxSelectionValue < 1 && type?.value === 'checkbox') {
      //   setError('Maximum Selection must be greater than 0')
      // } else {
      //   setError('')
      // }
      if (params?.questionId == values.id && singleQuestionData?.options) {
        setLoader(false)
      }

    }
  }, [values])

  useEffect(() => {
    if (params?.questionId) {
      if (selectValue?.value == watch('type')) {
        setSelectValue({
          label: values?.type,
          value: values?.type
        })
      } else {
        setSelectValue(watch('type'))
      }
    }
  }, [params, watch('type')])

  const validNumber = () => {
    if (maxSelectionValue < 0) {
      return <p className='error-message' style={{ textAlign: 'left',color:'#d32f2f',fontSize:'0.75rem' }} >Must be greater than or equal to 0</p>
    } 
  }

  useEffect(() => {
    if (!numberRegex.test(maxSelectionValue)) {
      setdisableSubmit(true)
    } else {
      setdisableSubmit(false)
    }
  }, [maxSelectionValue])

  useEffect(() => {
    if (singleQuestionData?.maxSelection || singleQuestionData?.maxSelection == null) {
      setMaxSelectionValue(singleQuestionData?.maxSelection || 0)
    }
  }, [singleQuestionData])


  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12} style={{padding: '0'}}>
            <PageTitle link={`/view-miqaat/${params?.id}/view-question`} title={`${params?.id ? 'Edit' : 'Add'} Question`} />
            {loader ?
              <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
              </div>
              :
              <div className="form-wrapper">
                <form onSubmit={handleSubmit(submitAction)}>
                  <TextareaField fieldPlaceholder={'Question Title'} name={'title'} fieldLabel={'Question Title:'}
                    error={errors.title?.message} register={{ ...register('title') }} rows='2' />
                  <TextareaField fieldPlaceholder={'Question Description'} name={'description'} fieldLabel={'Question Description:'}
                    error={errors.description?.message} register={{ ...register('description') }} rows='3' />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="checkboxWrapper question" style={{ width: '45%', paddingTop: '30px' }}>
                      <Checkbox label={"Hidden"} className={'big'} id="hidden" greenCheckbox={true} checked={hiden} handleChange={() => setHiden(!hiden)} />
                      <Checkbox label={"Filter"} className={'big'} id="filter" greenCheckbox={true} checked={filter} handleChange={() => setFilter(!filter)} />
                      {
                        selectValue && selectValue?.value === 'select' || selectValue?.value === 'radio' || selectValue?.value === 'checkbox' || selectValue?.value === 'timepicker' ?
                          <Checkbox label={"Required"} className={'big'} greenCheckbox={true} checked={required} id="required" handleChange={() => {
                            setRequired(!required)
                            // if (!required && maxSelectionValue < 1 && selectValue?.value === 'checkbox') {
                            //   setError("Maximum Selection must be greater than 0");
                            //   setRequired(!required)
                            // } else {
                            //   setError('')
                            //   setRequired(!required)
                            // }

                          }} />
                          : ''
                      }

                    </div>
                    <div style={{ width: '45%' }}>
                      <InputField fieldPlaceholder={'Priority'} fieldType={'number'}
                        fieldLabel={'Priority'} register={{ ...register("priority") }}
                        error={errors.priority?.message} name={'priority'} />
                    </div>
                  </div>
                  {watch('type').value == 'checkbox' ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
                      <SelectField style={{ width: '45%' }} disable={true} fieldLabel={'Question Type'} options={qustionSelectOption} name={'type'}
                        placeholder={'Question Type'} value={watch('type')} register={{ ...register('type') }}
                        error={errors.type?.message} control={control} />

                      <div item className="option-field" style={{ width: '45%' }}>
                        <InputField
                          fieldPlaceholder={'Enter Maximum Selection'}
                          fieldType={'number'}
                          fieldLabel={'Maximum Selection'}
                          className="question_addeditoption_value"
                          value={maxSelectionValue}
                          minValue={true}
                          handleChange={(e) => {
                            setMaxSelectionValue(e.target.value)
                            // if (required && e.target.value < 1 && selectValue?.value === 'checkbox') {

                            //   setError("Maximum Selection must be greater than 0");
                            //   setMaxSelectionValue(e.target.value)
                            // } else {
                            //   setError('')
                            //   setMaxSelectionValue(e.target.value)
                            // }
                          }}
                        />
                        {error && <FormHelperText
                          sx={{ color: '#d32f2f' }}
                        >
                          {error}
                        </FormHelperText>}
                        {validNumber()}
                      </div>
                    </div>
                    :
                    <div style={{ marginBottom: '30px' }}>
                      <SelectField disable={true} fieldLabel={'Question Type'} options={qustionSelectOption} name={'type'}
                        placeholder={'Question Type'} value={watch('type')} register={{ ...register('type') }}
                        error={errors.type?.message} control={control} />
                    </div>
                  }
                  {selectValue?.value !== 'arrival-departure' &&
                    <EditQuestionOptions
                      getValues={(value) => {
                        setOptions(value);
                      }}
                      type={selectValue?.value}
                      setValues={options}
                    />
                  }
                  <div className="btn-wrapper">
                    <Link to={`/view-miqaat/${params?.id}/view-question`}><Button variant="danger">Cancel</Button></Link>
                    <Button variant="success" type='submit' disabled={disableSubmit || btnLoader} >Save{
                      btnLoader
                        ? <div className='loader-wrapper btn-loader'>
                          <Spinner animation="border" variant="white" />
                        </div>
                        : ''
                    }</Button>
                  </div>
                </form>
              </div>
            }
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default EditQuestion
