import React, { useEffect, useState } from 'react'
import { themeColor } from '../../../config'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import SelectField from '../../../components/SelectField'
import { ByTeamCommunicationSchema } from '../../../utils/Schemas'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import TextEditor from '../../../components/TextEditor'
import { venueOptions, teamData, templateOptions } from '../../../appContent'
import { useDispatch, useSelector } from 'react-redux'
import { AllVenueAction } from '../../../store/actions/venueAction'
import { get, isEmpty } from "lodash";
import InputField from '../../../components/inputField'
import { AllTeamAction } from '../../../store/actions/teamAction'
import { communicationByTeam } from '../../../store/actions/communicationAction'
import { handleTeamSelect, replaceStringEmail } from '../../../utils/helper'
import PageTitle from '../../../components/PageTitle'

const ByTeam = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const [btnLoader, setBtnLoader] = useState(false)
  const [venuesList, SetVenueList] = useState([])
  const teamData = useSelector((state) => state.teamreducer)
  const communicationData = useSelector((state) => state.communicationreducer)
  const navigate = useNavigate();

  const { handleSubmit, register, reset, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(ByTeamCommunicationSchema)
  })

  const submitAction = (data) => {
    let sendEmail = {
      MiqaatId: params.id,
      TeamIds: handleTeamSelect(data),
      subject: data.subject,
      htmlBody: replaceStringEmail(data.message)
    }
    dispatch(communicationByTeam(sendEmail))
    reset()
    setValue('team', '')
    setValue('message', '')
    setBtnLoader(false)
  }

  // useEffect(() =>{
  //   dispatch(AllVenueAction(params?.id))
  // }, [])

  useEffect(() => {
    dispatch(AllTeamAction(params?.id, navigate))
  }, [])

  useEffect(() => {
    if (!isEmpty(get(teamData, 'teamData'))) {
      let newVenue = []
      teamData?.teamData.map((res) => {
        let data = {
          id: res.id,
          label: res.name,
          value: res.name
        }
        newVenue.push(data)
      })
      newVenue.unshift({ id: -1, label: "All Select", value: -1 })
      SetVenueList(newVenue)
    }
  }, [get(teamData, 'teamData')])


  return (
    <div className='main-wrapper'>
      <div className="container">

        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <PageTitle link={`/view-miqaat/${params?.id}`} title='By Team' />
            <div className="form-wrapper">
              <form onSubmit={handleSubmit(submitAction)}>

                <SelectField isMulti={true} fieldLabel={'Team:'} options={venuesList} name={'team'}
                  placeholder={'Select Team'} register={{ ...register('team') }}
                  error={errors.team?.message} control={control} />

                {/* <SelectField isMulti={true} fieldLabel={'Template:'} options={templateOptions} name={'Template'}
                            placeholder={'Select Template'} register={{ ...register('Template') }}
                            error={errors.Template?.message} control={control} /> */}
                <InputField fieldPlaceholder={"Enter Subject"} name={'subject'} fieldType={'text'} fieldLabel={'Subject:'}
                  error={errors.subject?.message} register={{ ...register("subject") }} />

                <TextEditor placeholder={'Enter Message'} name={'message'}
                  label={'Message:'} register={{ ...register('message') }}
                  error={errors.message?.message} control={control} onChange={(e) => setValue('message', e)} />

                <div className="btn-wrapper">
                  <Link to={`/view-miqaat/${params?.id}`}><Button variant="danger">Cancel</Button></Link>
                  <Button variant="success" type='submit' disabled={communicationData?.loading} >{communicationData?.loading ? 'Sending' : 'Send'}{
                    communicationData?.loading
                      ? <div className='loader-wrapper btn-loader'>
                        <Spinner animation="border" variant="white" />
                      </div>
                      : ''
                  }</Button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ByTeam
