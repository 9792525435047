import { combineReducers } from 'redux';
import login from './login';
import alert from "./alert";
import dashboard from "./Dashboard";
import questionreducer from './question';
import teamreducer from './team'
import miqaatreducer from './miqaat';
import miqaatreportreducer from './miqaatreport';
import dailyquestion from './dailyquestion';
import venuereducer from './venue'
import departmentreducer from './department'
import taskreducer from './task'
import communicationreducer from './communication'
import ticketreducer from './ticket'
import attendeesReducer from './attendees';
import productReducer from './Inventory';
import MiqaatReportReducer from './MiqaatReportReducer';

const rootReducer = combineReducers({
    login,
    alert,
    dashboard,
    questionreducer,
    miqaatreducer,
    teamreducer,
    miqaatreportreducer,
    dailyquestion,
    venuereducer,
    departmentreducer,
    taskreducer,
    communicationreducer,
    ticketreducer,
    attendeesReducer,
    productReducer,
    MiqaatReportReducer
})
export default rootReducer;