import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const AllTeamAction = (miqaatId, navigate) => async (dispatch) => {
  dispatch({
    type: TEAM_LOADING,
  });
  GetFetch(`/api/teams/get?miqaatId=${miqaatId}`, navigate)
    .then((response) => {
      var data = response.data;
      if (response.status === 200) {
        dispatch({
          type: ALL_TEAM,
          payload: data,
        });
      } else {
        // dispatch({
        //   type: REQUEST_FAIL,
        // });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: ALERT_ERROR,
        payload: "Something went wrong. Please try again later.",
      });
    });
};

export const AddTeamAction = (addQuestionDetails, miqaatId, history) => (dispatch) => {
  dispatch({
    type: TEAM_LOADING,
  });
  PostFetch("/api/teams/add", addQuestionDetails, 'POST')
    .then((response) => {
      var data = response.data;
      if (response.status === 200) {
        dispatch({
          type: ADD_TEAM,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Team added successfully",
        });
        history(`/view-miqaat/${miqaatId}/view-team`);
      } else {
        dispatch({
          type: TEAM_FAIL,
          payload: "",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TEAM_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });

};

export const GetSingleTeamAction = (questionId) => (dispatch) => {
  dispatch({
    type: TEAM_LOADING,
  });
  GetFetch(`/api/teams/getById?id=${questionId}`)
    .then((response) => {
      var SingleVenue = response.data;
      if (response.status === 200) {
        dispatch({
          type: SINGLE_TEAM,
          payload: SingleVenue || "",
        });
      } else {
        dispatch({
          type: TEAM_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TEAM_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const DeleteTeamAction = (id, miqaatId) => (dispatch) => {

  PostFetch('/api/teams/delete', id, 'DELETE')
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_TEAM,
        });
        dispatch(AllTeamAction(miqaatId))
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Team deleted successfully",
        });
      } else {
        dispatch({
          type: TEAM_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TEAM_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const UpdateTeamAction = (updateDetails, miqaatId, history) => (dispatch) => {

  PostFetch('/api/teams/edit', updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202) {
        dispatch({
          type: UPDATE_TEAM,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Team updated successfully",
        });
        history(`/view-miqaat/${miqaatId}/view-team`);
      } else {
        dispatch({
          type: TEAM_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TEAM_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });

};

export const TEAM_LOADING = "TEAM_LOADING";
export const ALL_TEAM = "ALL_TEAM";
// export const QUESTION_ERROR = "QUESTION_ERROR";
export const TEAM_FAIL = "TEAM_FAIL";
export const ADD_TEAM = "ADD_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";
export const SINGLE_TEAM = "SINGLE_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";


