import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { TbWorld } from 'react-icons/tb'
import { themeColor } from '../../../config'

import InputField from '../../../components/inputField'
import { AddEditVenueSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { set, useForm } from 'react-hook-form'
import SelectField from '../../../components/SelectField'
import { venueData, VenueAssignOptions } from '../../../appContent'
import { useDispatch, useSelector } from 'react-redux'
import { AddVenueAction, GetSingleVenueAction, SINGLE_VENUE, UpdateVenueAction } from '../../../store/actions/venueAction'
import PageTitle from '../../../components/PageTitle'

const AddEditVenue = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [btnLoader, setBtnLoader] = useState(false)
  const [venueDetails, setVenueDetails] = useState({
    address: '',
    city: '',
    state: '',
    zip: ''
  })
  const [cordinate, setCordinate] = useState();
  const singleVenue = useSelector((state) => state.venuereducer)

  const { handleSubmit, register, reset, watch, formState: { errors }, control, setValue } = useForm({
    resolver: yupResolver(AddEditVenueSchema)
  })

  const submitAction = (data) => {
    let newData = {
      miqaatId: params?.id,
      name: data.name,
      Latitude: data.latitude || null,
      Longitude: data.longitude || null,
      locationDescr: data.locationDescription,
      Radius: data.radius
    }
    if (params?.venueId) {
      let data = {
        ...newData,
        id: params?.venueId
      }
      dispatch(UpdateVenueAction(data, params?.id, navigate))

    } else {
      dispatch(AddVenueAction(newData, params?.id, navigate))
    }
  }

  useEffect(() => {
    if (params?.venueId) {
      // setLoader(true)
      dispatch(GetSingleVenueAction(params?.venueId))
    }
  }, [params?.venueId])

  useEffect(() => {
    if (singleVenue && singleVenue.singleVenue && params?.venueId ) {
      setValue('name', singleVenue?.singleVenue?.name)
      setValue('longitude', singleVenue?.singleVenue?.longitude)
      setValue('latitude', singleVenue?.singleVenue?.latitude)
      setValue('locationDescription', singleVenue?.singleVenue?.locationDescr)
      setValue('radius', singleVenue?.singleVenue?.radius || undefined)
    } else{
      setValue('radius', null)
    }
  }, [singleVenue])

  const handleBack = () => {
    dispatch({
      type: SINGLE_VENUE,
      singleVenue: {}
    })
  }

  const getLatLong = async () => {
    const url = "https://www.gps-coordinates.net/";
    window.open(url, '_blank');
  }

  useEffect(() => {
    let venueAddress = watch('VenueAddress');
    let venueCity = watch('city');
    let venueState = watch('state');
    let venueZip = watch('zip');
    setVenueDetails({
      address: venueAddress,
      city: venueCity,
      state: venueState,
      zip: venueZip
    })
  }, [watch('VenueAddress'), watch('city'), watch('state'), watch('zip')])

  useEffect(() => {
    if (cordinate) {
      setValue('latLong', `${cordinate.latitude.toFixed(2)}, ${cordinate.longitude.toFixed(2)}`)
    }
  }, [cordinate])

  return (
    <div className='main-wrapper'>
      <div className="container">
        <Row className='justify-content-center'>
          <Col lg={7} sm={12}>
            <PageTitle link={`/view-miqaat/${params?.id}/zone`} title={`${params?.venueId ? 'Edit' : 'Add'} ZONE`} onClick={handleBack}/>
            {singleVenue.loading ?
              <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
              </div> :
              <div className="form-wrapper">
                <form onSubmit={handleSubmit(submitAction)}>
                  <InputField fieldPlaceholder={'Enter Zone Name'} name={'name'} fieldType={'text'} fieldLabel={'Zone Name:'}
                    error={errors.name?.message} register={{ ...register('name') }} />
                  <InputField fieldPlaceholder={'Enter Location Description'} name={'locationDescription'} fieldType={'text'} fieldLabel={'Location Description:'}
                    error={errors.locationDescription?.message} register={{ ...register('locationDescription') }} />

                  <div className='venue-field-wrapper'>

                    <InputField name={'latitude'} fieldType={'text'} fieldLabel={'Latitude:'}
                      error={errors.latitude?.message} register={{ ...register('latitude') }} />

                    <InputField name={'longitude'} fieldType={'text'} fieldLabel={'Longitude:'}
                      error={errors.longitude?.message} register={{ ...register('longitude') }} />

                    <button className='icon-btn dark' type='button' style={{ backgroundColor: `${themeColor.primaryColor}`}} onClick={getLatLong}><TbWorld />Get Latitude Longitude</button>
                  </div>

                  <InputField name={'radius'} fieldType={'number'} fieldLabel={'Radius (in meter):'}
                      error={errors.radius?.message} register={{ ...register('radius') }} />

                  <div className="btn-wrapper">
                    <Link to={`/view-miqaat/${params?.id}/zone`} onClick={handleBack}><Button variant="danger">Cancel</Button></Link>
                    <Button variant="success" type='submit' disabled={btnLoader} >Save{
                      btnLoader
                        ? <div className='loader-wrapper btn-loader'>
                          <Spinner animation="border" variant="white" />
                        </div>
                        : ''
                    }</Button>
                  </div>
                </form>
              </div>}

          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddEditVenue
