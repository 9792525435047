import { ALL_MIQAAT,MIQAAT_LOADING, ADD_MIQAAT,MIQAAT_FAIL, SINGLE_MIQAAT, DELETE_MIQAAT, TEAM_VENUE_OPT } from "../actions/miqaatAction";
  
  // export const CANCEL_EDIT_FORM = "CANCEL_EDIT_FORM";
  
  const initialState = {
    miqaatData:[],
    singleMiqaat: {},
    dropdownOptions: [],
    loading: false,
    deleteMiqaat: false,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case MIQAAT_LOADING:
        return {
          ...state,
          loading: true,
          deleteMiqaat: false,
        };
  
      case ALL_MIQAAT:
        return {
          ...state,
          miqaatData: action.payload,
          loading: false,
        };

      case TEAM_VENUE_OPT:
        return {
          ...state,
          dropdownOptions: action.payload,
          loading: false,
        };
  
      case ADD_MIQAAT:
        return {
          ...state,
          miqaatData: action.payload,
          loading: false,
        };
  
      case DELETE_MIQAAT:
        return {
          ...state,
          loading: false,
          deleteMiqaat: true,
        };
  
      case MIQAAT_FAIL:
        return {
          ...state,
          loading: false,
        };
  
        case SINGLE_MIQAAT:
        return {
          ...state,
          singleMiqaat: action.payload,
          loading: false,
        };
  
    //   case CANCEL_EDIT_FORM:
    //     return {
    //       ...state,
    //       singleQuestion: {},
    //     };
  
    //   case UPDATE_QUESTION:
    //     return {
    //       ...state,
    //       loading: false,
    //     };
  
      case "USER_LOGOUT":
        return (state = initialState);
      default: {
        return state;
      }
    }
  };
  