import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";

export const AttendeesReportAction = (params) => async (dispatch) => {
    dispatch({
      type: REPORT_LOADING,
    });
      GetFetch(`/api/miqaats/getAttendeesStats?miqaatId=${params.id}`)
          .then((response) => {
              var data = response.data;
              if (response.status === 200) {
                  dispatch({
                      type: ATTENDEES_DATA,
                      payload: data,
                  });
              } else {
                  // dispatch({
                  //   type: REQUEST_FAIL,
                  // });
                  dispatch({
                      type: ALERT_ERROR,
                      payload: "Something went wrong. Please try again later.",
                  });
              }
          })
          .catch((error) => {
              dispatch({
                  type: ALERT_ERROR,
                  payload: error.data.msg || "Something went wrong. Please try again later.",
              });
          });
  };

  export const ATTENDEES_DATA = 'ATTENDEES_DATA'
  export const REPORT_LOADING = 'REPORT_LOADING'