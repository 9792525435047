import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const AllMeqaatAction = (navigate) => (dispatch) => {

    GetFetch(`/api/Miqaats`, navigate)
    .then((response) => {
      var data = response.data;
      if (response.status === 200) {
        dispatch({
            type: ALL_MIQAAT,
            payload: data,
          });
      } else {
        // dispatch({
        //   type: REQUEST_FAIL,
        // });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
    //   dispatch({
    //     type: REQUEST_FAIL,
    //   });
      dispatch({
        type: ALERT_ERROR,
        payload: "Something went wrong. Please try again later.",
      });
    });
};

export const AddMiqaatAction = (addMiqaatDetails, history) => (dispatch) => {
  // dispatch({
  //   type: MIQAAT_LOADING,
  // });
    PostFetch("/api/miqaats", addMiqaatDetails, 'POST')
        .then((response) => {
            var data = response.data;
            if (response.status === 200) {
                dispatch({
                    type: ADD_MIQAAT,
                    payload: {},
                });
                dispatch({
                    type: ALERT_SUCCESS,
                    payload: "Miqaat added successfully",
                });
                history(`/all-miqaat`);
            } else {
                dispatch({
                    type: MIQAAT_FAIL,
                    payload: "",
                });
                dispatch({
                    type: ALERT_ERROR,
                    payload: data.error,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: MIQAAT_FAIL,
                payload: "Something went wrong. Please try again later.",
            });
            dispatch({
                type: ALERT_ERROR,
                payload: error.data.msg || "Something went wrong. Please try again later.",
            });
        });

};

export const GetSingleMiqaatAction = (miqaatId) => (dispatch) => {
  dispatch({
    type: MIQAAT_LOADING,
  });
  GetFetch(`/api/miqaats/${miqaatId}`)
    .then((response) => {
      var SingleVenue = response.data;
      if (response.status === 200) {
        dispatch({
          type: SINGLE_MIQAAT,
          payload: SingleVenue || "",
        });
      } else {
        dispatch({
          type: MIQAAT_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MIQAAT_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const GetTeamAndVenuesAction = (miqaatId) => (dispatch) => {
  GetFetch(`/api/miqaats/getFilterData?miqaatId=${miqaatId}`)
    .then((response) => {
      const dropdownOpt = response.data;
      if (response.status === 200) {
        dispatch({
          type: TEAM_VENUE_OPT,
          payload: dropdownOpt || "",
        });
      } 
    })
    .catch((error) => {
     console.log(error)
    });
};

export const UpdateMiqaatAction = (updateDetails, history) => (dispatch) => {
  dispatch({
    type: MIQAAT_LOADING,
  });
  PostFetch('/api/miqaats', updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202) {
        dispatch({
          type: UPDATE_MIQAAT,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Miqaat updated successfully",
        });
        dispatch({
          type: SINGLE_MIQAAT,
          payload: {}
        })
        history(`/all-miqaat`);
      } else {
        dispatch({
          type: MIQAAT_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MIQAAT_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });

};

export const DeleteMiqaatAction = (miqaatId) => (dispatch) => {
  PostFetch(`/api/miqaats/${miqaatId}`, '', 'DELETE')
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_MIQAAT,
        });
        dispatch(AllMeqaatAction())
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Miqaat deleted successfully",
        });
      } else {
        dispatch({
          type: MIQAAT_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MIQAAT_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const MIQAAT_LOADING = "MIQAAT_LOADING";
export const ALL_MIQAAT = "ALL_MIQAAT";
export const MIQAAT_FAIL = "MIQAAT_FAIL";
export const ADD_MIQAAT = "ADD_MIQAAT";
export const DELETE_MIQAAT = "DELETE_MIQAAT";
export const SINGLE_MIQAAT= "SINGLE_MIQAAT";
export const UPDATE_MIQAAT= "UPDATE_MIQAAT";
export const TEAM_VENUE_OPT= "TEAM_VENUE_OPT";


