import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import { themeColor } from "../../config";
import { IoIosArrowBack } from "react-icons/io";
import InputField from "../../components/inputField";
import { useEffect, useRef, useState } from "react";
import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { useDispatch } from "react-redux";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../store/reducers/alert";
import { Col, Container, Row, Spinner, Form } from "react-bootstrap";
import moment from "moment";
import PageTitle from "../../components/PageTitle";
import { Badge, Card, CardContent, IconButton, Typography, Button } from "@mui/material";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { GrClose, GrFormView } from "react-icons/gr";
import SendIcon from '@mui/icons-material/Send';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const EmailLogById = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()
    const [loader, setLoader] = useState(false);
    const [openViewStatus, setOpenViewStatusPopup] = useState(false);
    const [search, setSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [filterData, setFilterData] = useState([])
    const [emailLogData, setEmailLogData] = useState([])
    const [popupDetail, setPopUpDetail] = useState({})
    const [expandedRows, setExpandedRows] = useState({});

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }

    const getDetailsById = (id) => {
        setLoader(true);
        GetFetch(`/api/EmailLogs/${id}`, dispatch, navigate).then((response) => {
            if (response.status == 200) {
                setEmailLogData(response?.data)
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
            setLoader(false);
        }).catch((err) => {
            console.log('error', err);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
            setLoader(false);
        })
    }

    useEffect(() => {
        if (params && params.id) {
            getDetailsById(params.id)
        }
    }, [])

    const columns = [
        {
            name: 'ITS ID',
            selector: (row) => row.itsid ? row.itsid : ' ',
            sortable: true,
            minWidth: '130px',
            maxWidth: '130px'
        },
        {
            name: "Name",
            selector: (row) => row.fullName ? row.fullName : ' ',
            sortable: true,
            minWidth: '330px',
            maxWidth: '330px'
        },
        {
            name: 'Email',
            selector: (row) => row.email ? row.email : ' ',
            minWidth: '380px',
            maxWidth: '380px'
        },
        {
            name: 'Status',
            cell: (row) => <BadgeStatus badgeContent={row?.statusName} color={row.statusName} />,
            minWidth: '380px',
            maxWidth: '380px'
        },
    ]

    const BadgeStatus = ({ badgeContent, color }) => {
        return (
            <div className="badge-anchor">
                {badgeContent && (
                    <span
                        className="badge-content"
                        style={{ backgroundColor: eventColor[color || 'Pending'] }}
                    >
                        {badgeContent}
                    </span>
                )}
            </div>
        );
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
        filterEmailLogs(e.target.value, statusFilter);
    }

    const onStatusButtonClick = (status) => {
        setStatusFilter(status);
        filterEmailLogs(search, status);
    }

    const filterEmailLogs = (searchTerm, status) => {
        if (emailLogData?.emailLogDetails && emailLogData?.emailLogDetails?.length) {
            const filterData = emailLogData?.emailLogDetails?.filter((item, i) =>
                (item?.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item?.itsid.toString().includes(searchTerm)) &&
                (!status || item?.statusName === status)
            )
            if (filterData.length) {
                setFilterData(filterData)
            } else {
                setFilterData([{ "fullName": "No Data Found" }])
            }
        }
    }

    const eventColor = {
        Pending: '#c28c41',
        Processed: "#c28c41",
        Delivered: "#5AC4CF",
        Open: "#3EB480",
        Click: "#B47ACF",
        Bounce: "#AA8B4B",
        Unsubscribe: "#838DA0",
        Reject: "#B15F51",
        Spam: "#EC8B7C",
        Resubscribe: "#716AF2",
        'Email Not Found': '#f73838'
    };

    const eventIcon = {
        Pending: DraftsIcon,
        Processed: ForwardToInboxIcon,
        Delivered: SendIcon,
        Open: DraftsIcon,
        Click: AdsClickIcon,
        Bounce: ReportGmailerrorredIcon,
        Unsubscribe: UnsubscribeIcon,
        Reject: CancelScheduleSendIcon,
        Spam: ReportGmailerrorredIcon,
        Resubscribe: MarkEmailUnreadIcon,
    };

    const EmailStatusSummaryCard = ({ title, count }) => {
        const IconComponent = eventIcon[title];
        return (
            <Card sx={{ maxWidth: 200, minWidth: 50, marginTop: 5 }} >
                <CardContent sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: eventColor[title] || '#fdfaf1', }}>
                    <div className='info-col' style={{ color: 'white' }}>
                        <p> {title} </p>
                    </div>
                    <div className='info-col' style={{ color: 'white', display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                        <p> {count || '-'}</p>
                        {IconComponent && <IconComponent />}
                    </div>
                </CardContent>
            </Card>
        );
    }

    const toggleRowExpand = (rowId) => {
        const newExpandedRows = { ...expandedRows };
        newExpandedRows[rowId] = !expandedRows[rowId];
        setExpandedRows(newExpandedRows);
    };

    const scrollTop = () => {
        const table = document.querySelector(".rdt_Table");
        if (table) {
            table.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    const expandableRowsComponent = ({ data }) => {
        return (
            <>
                {data && (
                    <div className="expandable-row-content">
                        <Row>
                            {data?.events && data?.events?.length > 0 ? data?.events?.map((status, index) => (
                                <Col key={index} xs={12} className="timeline-item">
                                    <div className="timeline-icon" style={{ backgroundColor: eventColor[status?.event] }}>
                                        <i className="status-icon"></i> {/* Replace with appropriate icons */}
                                    </div>
                                    <div className="timeline-content">
                                        <div className="timeline-item-title">{status?.event}</div>
                                        <div className="timeline-item-time">{status?.time ? moment(status?.time).format('lll') : '-'}</div>
                                    </div>
                                    {index !== data?.events?.length - 1 && (
                                        <div className="timeline-divider"></div>
                                    )}
                                </Col>
                            )) : <p style={{ fontSize: '12px' }}>No Event</p>}
                        </Row>
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="main-wrapper">
            {loader ?
                <div className='loader-wrapper'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
                <div className="container">
                    <PageTitle link='/email-log' title={`Email Log ID : ${params.id}`} />
                    <div className=" container emailLog-card" style={{ background: `${themeColor.lightGreen}` }}>
                        <Row className='justify-content-center'>
                            < div className="emailLog-head">
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Sender ITS ID </b>
                                    <p>{emailLogData.itsid ? emailLogData.itsid : '-'}</p>
                                </div>
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Sender Name </b>
                                    <p>{emailLogData.fullName ? emailLogData.fullName : '-'}</p>
                                </div>
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Date/Time </b>
                                    <p>{emailLogData.logDate ? moment(emailLogData.logDate + "Z").format("lll") : '-'}</p>
                                </div>
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Total Sent </b>
                                    <p>{emailLogData.submittedSuccessfullyCount ? emailLogData.submittedSuccessfullyCount : '0'}</p>
                                </div>
                            </div>
                        </Row>
                        <Row className='justify-content-center'>
                            < div className="emailLog-head">
                                <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                    <b>Title :-</b>
                                    <p>{emailLogData.subject ? emailLogData.subject : '-'}</p>
                                </div>
                            </div>
                        </Row>
                        <Row className='justify-content-center bodyWrapper' >
                            <div className='info-col' style={{ color: `${themeColor.secondaryColor}` }}>
                                <b>Body :-</b>
                                <div className="email-log-body" dangerouslySetInnerHTML={{ __html: `${emailLogData.body}` || '-' }}></div>
                            </div>
                        </Row>
                    </div>
                    <div className="email-status-grid-container">
                        {emailLogData?.summery?.map((status, index) => (
                            <EmailStatusSummaryCard key={index} title={status.key} count={status.value} />
                        ))}
                    </div>
                    {emailLogData?.emailLogDetails?.length ?
                        <div className="table-wrapper  miqaat miqaatReport" style={{ fontSize: '12px' }}>
                            <div style={{ display: 'block' }}>
                                <div className="email-status-filter-grid-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <button
                                        className={`switch-button ${!statusFilter ? 'active' : ''}`}
                                        style={{ backgroundColor: !statusFilter ? themeColor.primaryColor : '#e6e6e6' }}
                                        onClick={() => onStatusButtonClick('')}
                                    >
                                        All
                                    </button>
                                    {Object.keys(eventColor).map((status, index) => (
                                        <button
                                            key={index}
                                            style={{ backgroundColor: statusFilter === status ? themeColor.primaryColor : '#e6e6e6' }}
                                            className={`switch-button ${statusFilter === status ? 'active' : ''}`}
                                            onClick={() => onStatusButtonClick(status)}
                                        >
                                            {status}
                                        </button>
                                    ))}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', }} className="table-footer">
                                    <InputField
                                        fieldPlaceholder={"Search by ITS/Name"}
                                        fieldType={'text'}
                                        value={search}
                                        handleChange={onSearch}
                                    />
                                    <span>Count: {filterData?.length > 0 ? filterData?.length : emailLogData?.emailLogDetails?.length}</span>
                                </div>
                            </div>
                            <DataTable
                                data={filterData?.length > 0 ? filterData : emailLogData.emailLogDetails}
                                columns={columns}
                                fixedHeader
                                fixedHeaderScrollHeight='540px'
                                dense
                                expandableRows
                                expandableRowsComponent={expandableRowsComponent}
                                onRowExpandToggled={(row) => toggleRowExpand(row.itsid)}
                            />
                        </div>
                        :
                        <div className="table-wrapper view-user-table">
                            <DataTable data={[{ "fullName": "No Data Found" }]} columns={columns}
                                pagination fixedHeader fixedHeaderScrollHeight='540px' dense
                                subHeader={
                                    <span>Count: {filterData?.length > 0 ? filterData?.length : emailLogData?.emailLogDetails?.length}</span>
                                }
                            />
                        </div>
                    }
                    <div style={{ position: "fixed", bottom: 10, right: 20, marginBottom: "10px" }}>
                        <IconButton aria-label="delete" size="medium" onClick={scrollTop} variant="contained" color="success" style={{ background: "#F2F6EE", boxShadow: '0px 2px 8px rgba(0,0,0,0.2)' }}>
                            <KeyboardArrowUpIcon />
                        </IconButton>
                    </div>
                </div>
            }
        </div>
    )
}
export default EmailLogById;
