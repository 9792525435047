import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const AllDepartmentAction = (miqaatId, navigate) => async (dispatch) => {
      dispatch({
      type: DEPARTMENTS_LOADING,
    });
  GetFetch(`/api/departments/get?miqaatId=${miqaatId}`, dispatch, navigate)
    .then((response) => {
      var data = response.data;
      if (response.status === 200) {
        dispatch({
          type: ALL_DEPARTMENTS,
          payload: data,
        });
      } else {
        // dispatch({
        //   type: REQUEST_FAIL,
        // });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const AddDepartmentAction = (addQuestionDetails, miqaatId, history) => (dispatch) => {
  dispatch({
    type: DEPARTMENTS_LOADING,
  });
  PostFetch("/api/departments/add", addQuestionDetails, 'POST')
    .then((response) => {
      var data = response.data;
      if (response.status === 200) {
        history(`/view-miqaat/${miqaatId}/view-department`);
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Department added successfully",
        });
      } else {
        dispatch({
          type: DEPARTMENT_FAIL,
          payload: "",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: data.error,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DEPARTMENT_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const GetSingleDepartmentAction = (questionId) => (dispatch) => {
  dispatch({
    type: DEPARTMENTS_LOADING,
  });
  GetFetch(`/api/departments/getById?id=${questionId}`)
    .then((response) => {
      var SingleQuestion = response.data;
      if (response.status === 200) {
        dispatch({
          type: SINGLE_DEPARTMENT,
          payload: SingleQuestion || "",
        });
      } else {
        dispatch({
          type: DEPARTMENT_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DEPARTMENT_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const DeleteDepartmentAction = (id, miqaatId) => (dispatch) => {

  PostFetch('/api/departments/delete', id, 'DELETE')
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_DEPARTMENT,
        });
        dispatch(AllDepartmentAction(miqaatId))
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Department deleted successfully",
        });
      } else {
        dispatch({
          type: DEPARTMENT_FAIL,
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DEPARTMENT_FAIL,
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });
};

export const UpdateDepartmentAction = (updateDetails, miqaatId, history) => (dispatch) => {
  PostFetch('/api/departments/edit', updateDetails, 'PUT')
    .then((response) => {
      if (response.status === 202) {
        history(`/view-miqaat/${miqaatId}/view-department`);
        dispatch({
          type: UPDATE_DEPARTMENT,
          payload: {},
        });
        dispatch({
          type: ALERT_SUCCESS,
          payload: "Department updated successfully",
        });
      } else {
        dispatch({
          type: DEPARTMENT_FAIL,
          payload: "Something went wrong. Please try again later.",
        });
        dispatch({
          type: ALERT_ERROR,
          payload: "Something went wrong. Please try again later.",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DEPARTMENT_FAIL,
        payload: "Something went wrong. Please try again later.",
      });
      dispatch({
        type: ALERT_ERROR,
        payload: error.data.msg || "Something went wrong. Please try again later.",
      });
    });

};

export const DEPARTMENTS_LOADING = "DEPARTMENTS_LOADING";
export const ALL_DEPARTMENTS = "ALL_DEPARTMENTS";
export const DEPARTMENT_ERROR = "DEPARTMENT_ERROR";
export const PAGINATE_QUESTIONS = "PAGINATE_QUESTIONS";
export const DEPARTMENT_FAIL = "DEPARTMENT_FAIL";
export const ADD_DEPARTMENT = "ADD_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const SINGLE_DEPARTMENT = "SINGLE_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";


