import { ALL_TASKS, TASK_LOADING, ADD_TASK, TASK_FAIL, SINGLE_TASK, DELETE_TASK} from "../actions/taskAction";


const initialState = {
    taskData: [],
    singleTask: {},
    loading: false,
    deleteTask: false,
}


export default (state = initialState, action) =>{
    switch (action.type) {
        case TASK_LOADING:
        return {
          ...state,
          loading: true,
          deleteTask: false,
        };

        case ALL_TASKS:
            return {
                ...state,
                taskData: action.payload,
                loading: false,
              };
        case ADD_TASK: 
            return {
                ...state,
                taskData: action.payload,
                loading: false,
            }
        
        case SINGLE_TASK:
            return {
                ...state,
                singleTask: action.payload,
                loading: false,
            }    

        case TASK_FAIL:
            return {
            ...state,
            loading: false,
            };

        case DELETE_TASK:
            return {
                ...state,
                loading: false,
                deleteVenue: true,
            };    

        case "USER_LOGOUT":
            return (state = initialState);
        default: {
            return state;
        }      
    }
}