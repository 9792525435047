import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import capitalize from 'lodash'
const SelectComponent = ({
    label,
    handleChange,
    options,
    type,
    name,
    value,
    isRequired,
    ...rest
}) => {
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (

        <FormControl variant="outlined" fullWidth>

            {/* {options.length > 0
                ? options.map((option, i) => (<> */}

            <InputLabel ref={inputLabel} id="demo-simple-select-autowidth-label">
                {label}
            </InputLabel>

            <Select
                label={label}
                labelId={`select-${label}`}
                onChange={handleChange}
                value={value}
                multiple={false}
                {...rest}
            >

                {options?.map((item, i) => (
                    <MenuItem value={item.placeholder} key={i}>
                        {item.placeholder}
                    </MenuItem>
                ))}

            </Select >
            {isRequired ? <FormHelperText
                sx={{ color: '#d32f2f' }}
            >
                This field is required
            </FormHelperText> : null}
        </FormControl >
    );
};

export default SelectComponent;
