import { Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, IconButton } from "@mui/material"
import ProgressMobileStepper from "./input/ProgressBar"
import InputField from "../../../components/inputField";
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { themeColor } from "../../../config";
import { IoIosArrowBack } from "react-icons/io";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { addMiqaatQuestion, getSingleMiqaat } from "../../../store/actions/getMiqaatData";
import moment from "moment";
import { ALERT_ERROR } from "../../../store/reducers/alert";
import { emailRegex } from "../../../appContent";
import PhoneInput from "../../../components/PhoneInput";
import { contactDetailsSchema, miqaatRegisterSchema } from "../../../utils/Schemas";
import AppModal from "../../../components/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from "../../../components/PageTitle";

const DailyQuestion = () => {
    const dispatch = useDispatch();
    const MiqaatState = useSelector((state) => state.dailyquestion)
    const [btnLoader, setBtnLoader] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [singleMiqaat, setSingleMiqaat] = useState({});
    const [loader, setLoader] = useState(false);
    const miqaatId = useParams()?.id || ''
    const navigate = useNavigate();
    const [answerData, setAnswerData] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [fieldError, setFieldError] = useState();
    const [disableNext, setDisableNext] = useState(false);
    const [miqaatPriview, setMiqaatPriview] = useState([]);
    const [otherFieldValue, setOtherFieldValue] = useState([]);

    useEffect(() => {
        dispatch(getSingleMiqaat(miqaatId))
    }, [])

    useEffect(() => {
        setLoader(get(MiqaatState, "loading"));
    }, [get(MiqaatState, "loading")]);

    useEffect(() => {
        if (!isEmpty(get(MiqaatState, 'single_miqaat'))) {
            let answers = []

            MiqaatState.single_miqaat.details?.map(obj => {
                let ans = obj?.answer?.split(',')
                answers.push({ questionId: obj.id, answer: ans || [] })
                obj.options?.map((o, i) => {

                    if (ans && ans.length > 0) {
                        if (ans[i]) {
                            if (obj.type === 'checkbox') {
                                if (ans[i] === 'true' || ans[i].toLowerCase().includes('other'))
                                    o.value = true
                                else o.value = false
                            } else if (obj.type === 'radio') {
                                if (ans[i] && ans[i].toLowerCase().includes('other'))
                                    o.value = 'Other'
                                else
                                    o.value = ans[i]
                            } else if (obj.type === 'datepicker' || obj.type === 'arrival-departure') {
                                o.value = moment(new Date(ans[i])).format("DD/MM/YYYY")
                            }
                            else {
                                o.value = ans[i]
                            }

                        }

                        else {
                            if (obj.type === 'checkbox') {
                                o.value = false
                            } else {
                                o.value = ''
                            }
                        }


                    } else {
                        if (obj.type === 'checkbox') {
                            o.value = false
                        } else {
                            o.value = ''
                        }
                    }
                })

                return obj;
            })
            setAnswerData(answers)
            setSingleMiqaat({ singleMiqaat, ...MiqaatState.single_miqaat });


        } else {
            setSingleMiqaat({});
        }
        // API

    }, [get(MiqaatState, 'single_miqaat'), miqaatId]);

    const { handleSubmit, register, reset, formState: { errors }, control, setValue } = useForm({
        // resolver: yupResolver(contactDetailsSchema),
    })
    const submitAction = (data, submit) => {

        if (singleMiqaat.details && singleMiqaat.details.length > 0) {
            if (activeStep !== data.length + 1) {
                setActiveStep((prevActiveStep) => {
                    return prevActiveStep + 1
                });
            }
            if (activeStep === 0) {
                reset()
                // setValue('Jamiat', '')
                // setValue('Jamaat', '')
                setBtnLoader(false)
                setShowModal(false)
            }
            if (activeStep === data.length) {
                let updatedMiqaatPriview = [...miqaatPriview]
                setShowPreview(true);
                singleMiqaat.details.map((detail) => {
                    if (detail.answer?.toLowerCase().includes('other')) {
                        let fieldVal = detail.answer;
                        fieldVal = fieldVal.replace(/true,/g, '').replace(/false,/g, '').replace(/,/g, '');
                        updatedMiqaatPriview = [
                            ...updatedMiqaatPriview,
                            { id: detail.id, val: fieldVal }
                        ]
                        setMiqaatPriview(updatedMiqaatPriview)
                    }
                })
            }
            if (activeStep === (data.length + 1) && submit) {
                setActiveStep(0);
                setMiqaatPriview()
                let miqaat = singleMiqaat;

                answerData.map((obj) => {
                    if (Array.isArray(obj.answer)) {

                        let checkboxAnswer = singleMiqaat?.details?.filter((miqaatdata) => miqaatdata.id === obj.questionId && miqaatdata.type === 'checkbox')
                        if (checkboxAnswer && checkboxAnswer.length > 0 && checkboxAnswer[0].type === 'checkbox' && checkboxAnswer[0].id === obj.questionId) {
                            let text = '';
                            checkboxAnswer[0].options.map((opt) => {
                                text += (opt.placeholder.toLowerCase().includes('other') ?
                                    (miqaatPriview.map((data) => (data.id === checkboxAnswer[0].id ? data.val : '')))
                                    :
                                    (opt?.value) || false) + ','
                            })
                            text = text.replace(/,+/g, ',').replace(/,$/, '');
                            obj.answer = text
                        }

                        else {
                            let text = obj.answer.toString()
                            obj.answer = text
                        }
                    }
                })
                let obj = {
                    miqaatId: miqaatId,
                    emailId: miqaat.emailId,
                    mobNo: miqaat.mobNo,
                    whatsAppNo: miqaat.whatsAppNo,
                    details: answerData
                }
                dispatch(addMiqaatQuestion(obj, navigate))
                navigate('/')
                setSingleMiqaat({})
                setAnswerData([])
            }
        } else {

            setActiveStep(0);
            let miqaat = singleMiqaat
            answerData.map((obj) => {

                if (Array.isArray(obj.answer)) {
                    let checkboxAnswer = singleMiqaat?.details?.filter((miqaatdata) => miqaatdata.id === obj.questionId && miqaatdata.type === 'checkbox')

                    if (checkboxAnswer && checkboxAnswer.length > 0 && checkboxAnswer[0].type === 'checkbox' && checkboxAnswer[0].id === obj.questionId && obj.answer.length === 0) {
                        let text = ''

                        checkboxAnswer[0].options.map((opt) => {
                            text += opt.value + ','
                        })
                        obj.answer = text
                    }
                    else {

                        let text = obj.answer.toString()
                        obj.answer = text
                    }

                }
                // return obj;
            })
            let obj = {
                miqaatId: miqaatId,
                emailId: miqaat.emailId,
                mobNo: miqaat.mobNo,
                whatsAppNo: miqaat.whatsAppNo,
                details: answerData
            }
            dispatch(addMiqaatQuestion(obj, navigate))
            navigate('/')
            setSingleMiqaat({})
            setAnswerData([])
        }

    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setMiqaatPriview([])
    };


    const handleChange = (name, value) => {
        setSingleMiqaat({ ...singleMiqaat, [name]: value.replace(/\s/g, "") })
    }
    const onInputChange = (e, i, type, other) => {
        let hasOptionAlready = answerData.filter((d) => d.questionId === singleMiqaat.details[activeStep - 1].id);
        let answers = '';
        if (type === 'select') {
            singleMiqaat.details[activeStep - 1].answer = e.target.value ? e.target.value : '-';
            answers = e.target.value ? e.target.value : '-'
            setSingleMiqaat({ ...singleMiqaat, details: singleMiqaat.details })
        }
        else if (type === 'radio') {
            singleMiqaat.details[activeStep - 1].options.map((option, index) => {
                if (e?.target?.value && singleMiqaat.details[activeStep - 1].options[index].placeholder === e.target.value && singleMiqaat.details[activeStep - 1].options[index].placeholder.toLowerCase() !== 'other') {
                    singleMiqaat.details[activeStep - 1].options[index].value = e.target.value;
                    answers = e.target.value
                    singleMiqaat.details[activeStep - 1].answer = e.target.value;
                } else if (e?.target?.value && e?.target?.value.toLowerCase() === 'other' && singleMiqaat.details[activeStep - 1].options[index].placeholder.toLowerCase() === 'other') {
                    singleMiqaat.details[activeStep - 1].options[index].value = singleMiqaat.details[activeStep - 1].options[index].placeholder;
                    answers = otherFieldValue && otherFieldValue.length > 0 ? otherFieldValue.map((f) => singleMiqaat.details[activeStep - 1].id === f.id ? `other: ${f.val}` : 'other: ') : 'other: ';
                    singleMiqaat.details[activeStep - 1].answer = otherFieldValue && otherFieldValue.length > 0 ? otherFieldValue.map((f) => singleMiqaat.details[activeStep - 1].id === f.id ? `other: ${f.val}` : 'other: ').toString().replace(/,other: /g, '').replace(/(other: )+/, 'other: ') : 'other: ';

                } else if (other || e === 'other') {
                    singleMiqaat.details[activeStep - 1].answer = `other: ${other ? other : ''}`;
                    answers = `other: ${other ? other : ''}`
                }
                else {
                    singleMiqaat.details[activeStep - 1].options[index].value = ''
                }
            })
           
            setSingleMiqaat({ ...singleMiqaat, details: singleMiqaat.details })
        }
        else if (type === 'checkbox') {
            let val = false
            if (typeof singleMiqaat.details[activeStep - 1].options[i].value === 'string') {
                if (singleMiqaat.details[activeStep - 1].options[i].value === 'false' || singleMiqaat.details[activeStep - 1].options[i].value == false || singleMiqaat.details[activeStep - 1].options[i].value.toLowerCase().includes('other')) {
                    val = true
                }
                else {
                    val = false
                }
            }
            else {

                val = other && e === 'other' ? true : !singleMiqaat.details[activeStep - 1].options[i].value
            }

            singleMiqaat.details[activeStep - 1].options[i].value = val;

            if (other) {
                singleMiqaat.details[activeStep - 1].answer = `other: ${other ? other : ''}`
                answers = `other: ${other ? other : ''}`

            } else {
                let text = '';
                singleMiqaat.details[activeStep - 1].options.map((op, index) => {
                    if (singleMiqaat.details[activeStep - 1].options[index].value == true && singleMiqaat.details[activeStep - 1].options[index].placeholder.toLowerCase().includes('other')) {
                        var foundItem = otherFieldValue.find(function (item) {
                            return item.id === singleMiqaat.details[activeStep - 1].id;
                        });
                        if (foundItem) {
                            text += otherFieldValue.map((f) => singleMiqaat.details[activeStep - 1].id === f.id && `other: ${f.val},`);
                        } else {
                            text += `other: ,`
                        }
                    } else {
                        text += op.value.toString() + ',';
                    }
                })
                if (text.endsWith(',')) {
                    text = text.slice(0, -1);
                }
                singleMiqaat.details[activeStep - 1].answer = text
                // singleMiqaat.details[activeStep - 1].answer = val.toString();
                // answers = val.toString();
                answers = text
            }
            setSingleMiqaat({ ...singleMiqaat, details: singleMiqaat.details })
            if (!hasOptionAlready.length) {
                let optionRecords = [];
                singleMiqaat.details[activeStep - 1].options.map((option) => {
                    optionRecords.push(i === singleMiqaat.details[activeStep - 1].options[i] ? val : false)
                    optionRecords[i] = other ? `other: ${other}` : val
                });
                answers = optionRecords;
            } else {
                hasOptionAlready[0].answer[i] = other ? `other: ${other}` : val;
                answers = hasOptionAlready[0].answer;

            }
            setSingleMiqaat({ ...singleMiqaat, details: singleMiqaat.details })
        }
        else if (type === 'slider') {
            let value = {
                min: e[0],
                max: e[1]
            }
            singleMiqaat.details[activeStep - 1].options[i].value = e;
            if (!hasOptionAlready.length) {
                let optionRecords = [];
                optionRecords.push(e)
                answers = optionRecords;

            } else {
                hasOptionAlready[0].answer[i] = singleMiqaat.details[activeStep - 1].options[i].value;
                answers = hasOptionAlready[0].answer;
                singleMiqaat.details[activeStep - 1].answer = hasOptionAlready[0].answer.toString()
            }

            // answers = e;
            setSingleMiqaat({ ...singleMiqaat, details: singleMiqaat.details })
        }
        else if (type === 'datepicker' || type === 'arrival-departure') {
            if (e) {
                singleMiqaat.details[activeStep - 1].options[i].value = moment(new Date(e)).format("DD/MM/YYYY")
                if (!hasOptionAlready.length) {
                    let optionRecords = [];
                    optionRecords.push(moment(new Date(e)).format("YYYY/MM/DD"))
                    answers = optionRecords;
                } else {
                    // hasOptionAlready[0].answer[i] = moment(singleMiqaat.details[activeStep - 1].options[i].value).format("DD/MM/YYYY")
                    hasOptionAlready[0].answer[i] = moment(new Date(e)).format("YYYY/MM/DD")
                    answers = hasOptionAlready[0].answer;
                }
            } else {
                singleMiqaat.details[activeStep - 1].options[i].value = null
            }
            setSingleMiqaat({ ...singleMiqaat, details: singleMiqaat.details })
        } else if(type === "timepicker"){
            if (e) {
                // singleMiqaat.details[activeStep - 1].options[i].value = `${new Date(e).getHours()}:${new Date(e).getMinutes().toString().length === 1 ? `0${new Date(e).getMinutes()}` : new Date(e).getMinutes()} ${new Date(e).getHours() > 11 ? 'pm' : 'am'}`
                singleMiqaat.details[activeStep - 1].options[i].value = moment(new Date(e)).format('DD/MM/YYYY HH:mm')
                if (!hasOptionAlready.length) {
                    let optionRecords = [];
                    optionRecords.push(moment(new Date(e)).format('DD/MM/YYYY HH:mm'))
                    answers = optionRecords;
                } else {
                    // hasOptionAlready[0].answer[i] = moment(singleMiqaat.details[activeStep - 1].options[i].value).format("DD/MM/YYYY")
                    hasOptionAlready[0].answer[i] = moment(new Date(e)).format('DD/MM/YYYY HH:mm')
                    answers = hasOptionAlready[0].answer;
                }
            } else {
                singleMiqaat.details[activeStep - 1].options[i].value = null
            }
            setSingleMiqaat({ ...singleMiqaat, details: singleMiqaat.details })
        } else {

            singleMiqaat.details[activeStep - 1].options[i].value = e.target.value;

            if (!hasOptionAlready.length) {
                let optionRecords = [];
                optionRecords.push(e.target.value)
                answers = optionRecords;

            } else {
                hasOptionAlready[0].answer[i] = singleMiqaat.details[activeStep - 1].options[i].value;
                answers = hasOptionAlready[0].answer;
            }
            setSingleMiqaat({
                ...singleMiqaat, details: singleMiqaat.details
            })
        }

        // }
        if (hasOptionAlready && hasOptionAlready.length > 0) {
            answerData[activeStep - 1].answer = answers;
            setAnswerData([...answerData])
        } else {

            setAnswerData([...answerData, { questionId: singleMiqaat.details[activeStep - 1].id, answer: answers }])
        }

    }

    
    const openPoup = () => {
        const newFieldError = {};
        if (!singleMiqaat.whatsAppNo || singleMiqaat.whatsAppNo.length !== 13) {
            newFieldError.whatsAppNo = singleMiqaat.whatsAppNo
                ? 'Enter Valid WhatsApp Number'
                : 'WhatsApp number is required';
        }

        if (!singleMiqaat.mobNo || singleMiqaat.mobNo.length !== 13) {
            newFieldError.mobNo = singleMiqaat.mobNo
                ? 'Enter Valid Mobile Number'
                : 'Mobile number is required';
        }

        if (!singleMiqaat.emailId || !emailRegex.test(singleMiqaat.emailId)) {
            newFieldError.email = singleMiqaat.emailId ? 'Enter a valid email' : 'Email is required';
        }
        if (Object.keys(newFieldError).length === 0) {
            // No validation errors, proceed with your logic
            setShowModal(true);
            setFieldError({});
        } else {
            setFieldError(newFieldError);
        }
    };

    return (
        <>
            <div
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginVertical: 10,
                }}
                className="question-wrapper">
                <Box
                    shadow="3"
                    style={{
                        borderWidth: 1,
                        borderColor: '#ddd',
                        paddingHorizontal: 20,
                        borderRadius: 10,
                        paddingVertical: 10,
                    }}>
                    {activeStep === 0 ?
                        <div className='main-wrapper'>
                            <div className="container">
                                <Row className='justify-content-center'>
                                    <Col lg={7} sm={12}>
                                        <PageTitle link={`/profile`} title='Miqaat Registration' />

                                        <div className="form-wrapper">
                                            {loader ? <div className='loader-wrapper'>
                                                <Spinner animation="border" variant="success" />
                                            </div> :
                                                <form onSubmit={handleSubmit(openPoup)}>
                                                    <InputField fieldPlaceholder={'Enter Email'} name={'emailId'} fieldType={'text'} fieldLabel={'Email'}
                                                        handleChange={(e) => handleChange('emailId', e.target.value)}
                                                        value={singleMiqaat?.emailId}
                                                        error={fieldError?.email ? fieldError?.email : errors.emailId?.message}
                                                    />
                                                    <PhoneInput placeholder='Enter Contact Number' name={'mobNo'} fieldLabel={'Contact Number:'}
                                                        handleChange={(e) => handleChange('mobNo', e)} error={fieldError?.mobNo ? fieldError.mobNo : ''} value={singleMiqaat?.mobNo ? singleMiqaat?.mobNo : ''}
                                                    />
                                                    <PhoneInput placeholder='Enter Watsapp Number' name={'whatsAppNo'} fieldLabel={'Whatsapp Number:'}
                                                        handleChange={(e) => handleChange('whatsAppNo', e)} error={fieldError?.whatsAppNo ? fieldError.whatsAppNo : ''} value={singleMiqaat?.whatsAppNo ? singleMiqaat?.whatsAppNo : ''}
                                                    />
                                                    <div className="btn-wrapper">
                                                        <Link to='/'><Button variant="danger">Cancel</Button></Link>
                                                        <Button variant="success" type='submit' disabled={!singleMiqaat.details} >Next{
                                                            btnLoader
                                                                ? <div className='loader-wrapper btn-loader'>
                                                                    <Spinner animation="border" variant="white" />
                                                                </div>
                                                                : ''
                                                        }</Button>
                                                    </div>
                                                </form>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        :
                        activeStep === (singleMiqaat.details.length + 1) && showPreview ?
                            <Grid sx={{ display: 'flex', justifyContent: 'center', }}>
                                <Card sx={{ width: { xs: '90%', sm: '60%', md: '448px' }, backgroundColor: '#fdfaf1', height: { xs: 'calc(100vh - 200px)', md: 'calc(100vh - 256px)' }, position: 'relative', marginTop: { xs: 2, md: 5 }, minHeight: '320px' }}>
                                    <CardHeader
                                        sx={{ backgroundColor: '#fdfaf1' }}
                                        title="Review"
                                    />
                                    <Divider />
                                    <CardContent sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fdfaf1', }}>
                                        <ProgressMobileStepper
                                            handleBack={handleBack}
                                            handleNext={submitAction}
                                            activeStep={activeStep}
                                            questionsData={singleMiqaat}
                                            preview={true}
                                            setDisableNext={setDisableNext}
                                            //   onInputChange={onInputChange}
                                            miqaatPriview={miqaatPriview}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                            :
                            <Grid sx={{ display: 'flex', justifyContent: 'center', }}>
                                <Card sx={{ width: { xs: '90%', sm: '60%', md: '448px' }, backgroundColor: '#fdfaf1', height: { xs: 'calc(100vh - 200px)', md: 'calc(100vh - 256px)' }, position: 'relative', marginTop: { xs: 2, md: 5 }, minHeight: '320px' }}>
                                    <CardHeader
                                        sx={{ backgroundColor: '#fdfaf1' }}
                                        title="Questions"
                                    />
                                    <Divider />
                                    <CardContent className="question-fieldsWrapper" sx={{ display: 'flex', height: 'calc(100vh - )', justifyContent: 'center', mb: 10, backgroundColor: '#fdfaf1', }}     >
                                        <ProgressMobileStepper
                                            handleBack={handleBack}
                                            handleNext={submitAction}
                                            activeStep={activeStep}
                                            questionsData={singleMiqaat}
                                            onInputChange={onInputChange}
                                            disableNext={disableNext}
                                            setDisableNext={setDisableNext}
                                            steper={true}
                                            otherFieldValue={otherFieldValue}
                                            setOtherFieldValue={setOtherFieldValue}
                                            singleMiqaat={singleMiqaat}
                                        // handleOther={handleOther}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                    }
                    <AppModal handleClose={() => setShowModal(false)} showModal={showModal} title={'Are these details are correct?'}
                        handleAction={submitAction} actionBtnText={"Next"} />
                </Box >
            </div >
        </>)
}
export default DailyQuestion
