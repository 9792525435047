import React, { useEffect, useState } from 'react'
import { useCSVReader } from 'react-papaparse';
import { Button, Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
import { themeColor } from '../../config';
import { PostFetch } from '../../utils/fetchUrl';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import AppModal from '../../components/Modal';
import { HiUpload } from 'react-icons/hi';
import { MdOutlineSimCardDownload } from 'react-icons/md';
import fileUrl from '../../assets/files/Sample.csv';

const TagUser = () => {
    const [tagUserDetails, setTagUserDetails] = useState();
    const [loader, setLoader] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [CSVDetails, setCSVDetails] = useState();
    const [CSVIts, setCSVIts] = useState();
    const [progressStatus, setProgressStatus] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [notInDBCount, setNotInDBCount] = useState(0);
    const [ITSIds, setITSIds] = useState({
        'ListITSID': []
    });

    const { CSVReader } = useCSVReader();

    const dispatch = useDispatch();

    const tagUsersAlvazarat = (data) => {
        return new Promise((resolve, reject) => {
            PostFetch('/api/user/TagAlvazarat', data, "POST").then((response) => {
                if (response.status === 200) {
                    setTagUserDetails(response.data);
                    showSuccessAlert(response.data.msg);
                    setNotInDBCount(prev => prev + response.data.notInDBCount);
                    resolve()
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    setLoader(false);
                    reject()
                }
                setLoader(false);
                setShowModal(false)
            }).catch((error) => {
                if (error) {
                    console.log('error', error);
                    showErrorAlert(error?.data?.title ? error?.data?.title : "Something Went Wrong");
                }
                setLoader(false);
                setShowModal(false)
                reject()
            })
        })
    }

    const csvSubmitAction = () => {
        if (CSVIts && CSVIts.length > 1) {
            let CsvList = []
            CSVIts.forEach((item) => {
                if (item.toString() !== 'NaN' && item.toString().length === 8) {
                    CsvList.push(item);
                }
            });
            setITSIds({...{ 'ListITSID': CsvList }});
        }
    };

    const confirmSync = () => {
        setLoader(true)
        csvSubmitAction()
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    const downloadCsv = () => {
        const fileName = fileUrl.split('/').pop();
        const aTag = document.createElement('a');
        aTag.href = fileUrl;
        aTag.setAttribute('download', fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    let i = 0;
    const limit = 50;

    const tagUserITS = () => {
        const slicedData = ITSIds.ListITSID.slice(i, i + limit);

        if (slicedData.length > 0) {
            let bodyData = {
                ListITSID: slicedData,
            };

            tagUsersAlvazarat(bodyData).then(() => {
                setShowProgressBar(true);
                setProgressStatus(parseInt((i + slicedData.length) * 100 / ITSIds.ListITSID.length));

                i += limit;
                if (i < ITSIds.ListITSID.length) {
                    tagUserITS();
                } else {
                    setProgressStatus(100);
                }
            });
        }
    };

    useEffect(() => {
        if (CSVDetails) {
            const arrayToString = function (array) {
                const str = array.map(a => a[0]).join(', ');
                const numArr = str.split(',').map(str => parseInt(str.trim()));
                return numArr;
            };
    
            const str = arrayToString(CSVDetails.data);
            setCSVIts(str);
        }
    }, [CSVDetails]);
    

    useEffect(() => {
        if (ITSIds.ListITSID && ITSIds.ListITSID.length > 0) {
            tagUserITS();
        }

    }, [ITSIds.ListITSID]);

    return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7}>
                        <PageTitle link='/sync-user' title='Mark Users Presence in Al-vazarat System.' />
                        <div className='tag-user'>
                            <CSVReader
                                onUploadAccepted={(results) => {
                                    setCSVDetails(results);
                                }}
                            >
                                {({
                                    getRootProps,
                                    acceptedFile,
                                    ProgressBar,
                                    getRemoveFileProps,
                                }) => (
                                    <>
                                        <div className='file-wrapper'>
                                            <button type='button' className='uploadBtn' style={{ color: `${themeColor.primaryColor}`, backgroundColor: `${themeColor.lightGreen}` }}
                                                {...getRootProps()} ><HiUpload /> Upload CSV File</button>
                                            {acceptedFile ?
                                                <div className='d-flex justify-content-center align-items-center my-4' >
                                                    <div className='file-name' style={{ color: `${themeColor.secondaryColor}` }}>
                                                        {acceptedFile && acceptedFile.name}
                                                    </div>
                                                    <Button variant='danger' type='button' {...getRemoveFileProps()} >
                                                        Remove
                                                    </Button>
                                                </div> : ''}
                                            <ProgressBar />
                                        </div>
                                    </>
                                )}
                            </CSVReader>
                            <div className="download-btn" style={{ color: `${themeColor.primaryColor}` }}>
                                <MdOutlineSimCardDownload />
                                <button className="link-btn" style={{ color: `${themeColor.primaryColor}` }} type='button' onClick={downloadCsv}>Download Sample Csv</button>
                            </div>
                            {
                                showProgressBar && loader ?
                                    <div className="progressBar-wrapper">
                                        <ProgressBar animated now={progressStatus} label={`${progressStatus}%`} />
                                    </div> : ''
                            }
                            {
                                notInDBCount > 0 &&
                                <div>
                                    <p style={{textAlign: 'center', margin: '24px 0'}}>Users Not In Database: <b>{notInDBCount}</b></p>
                                </div>
                            }
                        </div>
                        <div className="btn-wrapper" style={{ textAlign: 'center', marginBottom: '100px', marginTop: '60px' }}>
                            <Button variant="success" onClick={() => setShowModal(true)} disabled={loader}>Sync{
                                loader ?
                                    <div className='loader-wrapper btn-loader'>
                                        <Spinner animation="border" variant="white" />
                                    </div> : ''
                            }</Button>
                        </div>
                    </Col>
                </Row>
            </div>
            <AppModal handleAction={confirmSync} handleClose={() => setShowModal(false)} hasBody={true} content='Do you want to continue' showModal={showModal} title={'Confirm Tag User To Alvazarat'} actionBtnText={"Confirm"} />
        </div>
    )
}

export default TagUser