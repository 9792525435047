import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextareaField from '../../components/TextareaField';
import { themeColor } from '../../config';
import SelectField from '../../components/SelectField';
import {AssignRoleSchema} from '../../utils/Schemas';
import { useDispatch } from 'react-redux';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { convertItsToArray } from '../../utils/helper';
import PageTitle from '../../components/PageTitle';

const AssignRole = () => {
    const { handleSubmit, register, reset,setValue,watch, formState: {errors}, control } = useForm({
        resolver: yupResolver(AssignRoleSchema),
    });
    const [itsId,setItsId] = useState();
    const [rolesDetails,setRolesDetails] = useState();
    const [roles,setRoles] = useState();
    const [formData, setFormData] = useState({});
    const [sendAssignRoleStatus, setSendAssignRoleStatus] = useState();
    const [loader, setLoader] = useState(false);
    const [idError,setIdError] = useState(false)
    const [roleError,setRoleError] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchRoles = () => {
        GetFetch(`/api/Roles`, dispatch, navigate).then((response) => {
            if (response.status == 200){
              setRolesDetails(response.data);
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
        }).catch((err)=>{
            console.log('error', err);
            showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
        })
      }
      const assignRole = () => {
        setLoader(true);
        PostFetch('/api/Roles/AssignRole', formData, "POST").then((response)=>{
            if(response.status === 200) {
                setSendAssignRoleStatus(response.data);
                showSuccessAlert('Role Assigned Successfully');
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                setLoader(false);
            }
            setLoader(false);
        }).catch((error)=>{
            console.log('error', error);
            showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
            setLoader(false);
        })
    }
      const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
      }
      const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

      const submitAction = async(data) => {
        setIdError(false)
        {itsId == "" ? setIdError(true) : setIdError(false)}
        const numArr = await convertItsToArray(itsId, setIdError);
        
        setFormData({
            RoleId: data.Role.value,
            ITSIDs: numArr,
        })
    }

        useEffect(()=>{   
            if(rolesDetails){
                setRoles(
                    rolesDetails.map((role)=>(
                        {value: role.id, label: role.name}
                    ))
                )
            }
        }, [rolesDetails])

        useEffect(()=>{
            if(!idError){
                if(formData && formData.ITSIDs && formData.ITSIDs.length > 0){
                    assignRole();
                    setItsId('');
                    setValue('Role' ,'');
                    setRoleError(false)
                    setIdError(false)
                }
            }
        }, [formData])

      useEffect(()=>{
        fetchRoles();
      }, []);
    
  return (
    <div className='main-wrapper'>
        <div className="container">
            <Row className='justify-content-center'>
                <Col lg={7} sm={12}>
                    <PageTitle link='/dashboard' title='ASSIGN ROLE' />
                    <div className="form-wrapper">
                        <form onSubmit={handleSubmit(submitAction)}>
                            <TextareaField fieldPlaceholder={"Enter ITS ID(s)"} 
                            fieldLabel={'ITS ID(s):'} name={'ItsIds'} 
                            error={errors.ItsIds?.message}  register={{...register("ItsIds")}} 
                            value={itsId}  handleChng={(e)=>setItsId(e.target.value)}/>
                            {idError ?
                             <h6 className="note" style={{color: "red"}}>ITS Ids should be of 8 digits</h6>
                             :''
                            }
                            <h6 className="note" style={{color: "green"}}>Press Enter After Every ITS ID</h6>

                            <SelectField fieldLabel={'Role:'} options={roles} name={'Role'}
                            placeholder={'Select Options'} register={{...register("Role")}}
                            error={errors.Role?.message} control={control} />
                             {roleError ?
                             <span className="note" style={{color: "red"}}>Please Select Role</span>
                             :''
                            }

                            <div className="btn-wrapper">
                                <Link to='/dashboard'><Button variant="danger">Cancel</Button></Link>
                                <Button variant="success" type='submit' disabled={loader} >Save{
                                    loader ? 
                                    <div className='loader-wrapper btn-loader'>
                                        <Spinner animation="border" variant="white" />
                                    </div>: ''
                                }</Button>
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default AssignRole