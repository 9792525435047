import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle'
import ApexChartsComponent from '../../components/ApexChartsComponent';
import { GetFetch } from '../../utils/fetchUrl';
import { GrClose } from 'react-icons/gr';
import { FiZoomIn } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toJpeg } from 'html-to-image';
import { themeColor } from '../../config';
import { TbFileExport } from 'react-icons/tb';

const ProfileStatistics = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reportData, setReportData] = useState(null)
  const [printIdCardLoading, setPrintIdCardLoading] = useState(false);
  const printRef = useRef();
  const [loader,setLoader] = useState(false);
  const [zoomInChart, setZoomInChart] = useState({
    type: '',
    show: false,
    data: '',
    title: ''
  });

  const customLegendFormatter = (seriesName, opts) => {
    return `
          <div class="legend-item-label">${seriesName}</div>
          <div class="legend-item-value">${opts.w.globals.series[opts.seriesIndex]}</div>
      `;
  };
  const customRadialLegendFormatter = (seriesName, opts, series) => {
    return `
          <div class="legend-item-label">${seriesName}</div>
          <div class="legend-item-value">${series[opts.seriesIndex]} (${opts.w.globals.series[opts.seriesIndex]}%)</div>
      `;
  };

  const [chartData, setChartData] = useState({
    roleData: {
      series: [],
      options: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        // colors:['#FF91A9', '#7F4DC3', '#3E98B5'],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px',
              },
              value: {
                fontSize: '16px',
                formatter: function (val){
                  return `${val}`
                }
              },
              total: {
                show: true,
                label: 'Total',
                formatter: function (w) {
                  return ''
                }
              }
            }
          }
        },
        labels: [],
      },
    },
    profileCompletionPercent: {
      series:[],
      options: {
        chart: {
          type: 'radialBar',
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#eee",
              strokeWidth: '97%',
              margin: 6,
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 0.3,
                blur: 4,
              }
            },
            dataLabels: {
              name: {
                show: true,
                offsetY: 40,
                fontSize: '14px',
                color: '#696969'
              },
              value: {
                offsetY: -30,
                fontSize: '36px',
                fontWeight: 600,
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
        labels: ['Users have completed their profile'],
      },
    },
    languageData:{
      series: [],
      options: {
        chart: {
          type: 'pie',
        },
        labels: [],
        dataLabels:{
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function(value, opts) {
              const total = opts.config.series.reduce((acc, num) => acc + num, 0)
              return Math.round((value / total) * 100) + "%";
            }
          }
        },
        legend: {
          position: 'bottom',
          formatter: customLegendFormatter,
        },
      },
    },
    // isEmailVerified:{
    //   series: [],
    //   options: {
    //     chart: {
    //       type: 'pie',
    //     },
    //     labels: [],
    //     dataLabels:{
    //       enabled: false
    //     },
    //     legend: {
    //       position: 'bottom',
    //       formatter: customLegendFormatter,
    //     },
    //   },
    // },
    isEmailVerified:{
      series: [],
      options: {
        chart: {
          type: 'donut',
        },
        labels: [],
        dataLabels:{
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function(value, opts) {
              const total = opts.config.series.reduce((acc, num) => acc + num, 0)
              return Math.round((value / total) * 100) + "%";
            }
          }
        },
        legend: {
          position: 'bottom',
          formatter: customLegendFormatter,
        },
      },
    },
    asbaaqData:{
      series: [],
      options: {
        chart: {
          type: 'pie',
        },
        labels: [],
        dataLabels:{
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function(value, opts) {
              const total = opts.config.series.reduce((acc, num) => acc + num, 0)
              return Math.round((value / total) * 100) + "%";
            }
          }
        },
        legend: {
          position: 'bottom',
          formatter: customLegendFormatter,
        },
      },
    },
    skillData: {
      series: [],
      options: {
        chart: {
          type: 'polarArea',
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.9
        },
        labels: [],
        yaxis:{
          show: false
        },
        tooltip: {
          y: {
            formatter: function(value, opts) {
              const total = opts.config.series.reduce((acc, num) => acc + num, 0)
              return Math.round((value / total) * 100) + "%";
            }
          }
        },
        legend: {
          position: 'bottom',
          formatter: customLegendFormatter,
        },
      },
    },
    qualStageData: {
      series: [],
      options: {
        chart: {
          type: 'polarArea',
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.9
        },
        labels: [],
        yaxis:{
          show: false
        },
        tooltip: {
          y: {
            formatter: function(value, opts) {
              const total = opts.config.series.reduce((acc, num) => acc + num, 0)
              return Math.round((value / total) * 100) + "%";
            }
          }
        },
        legend: {
          position: 'bottom',
          formatter: customLegendFormatter,
        },
        // theme: {
        //   monochrome: {
        //     enabled: true,
        //     shadeTo: 'light',
        //     shadeIntensity: 0.6
        //   }
        // }, 
      },
    }
  })

  const fetchReportData = () => {
    setLoader(true)
    GetFetch(`/api/Reports/ProfileStats`, dispatch, navigate).then((response) => {
        if (response.status == 200){
          setReportData(response.data); 
          setTimeout(() => {
            setLoader(false)
          }, 1500);
        }
        else {
            console.log('Something went wrong');
            setLoader(false)
          }
    }).catch((err)=>{
        console.log('error', err);
        setLoader(false);
    })
  }

  useEffect(()=>{
    fetchReportData()
  },[])

  useEffect(()=>{
    const roleSum = reportData?.roleData.series.reduce((acc, num) => acc + num, 0);
      const roleSeries = reportData?.roleData.series.map(value => isNaN((value / roleSum) * 100) ? 0 : Math.round((value / roleSum) * 100));
    if(reportData){
      setChartData({
        profileCompletionPercent:{
          ...chartData.profileCompletionPercent,
          series:[reportData?.profileCompletionPercent],
        },
        roleData: {
          ...chartData.roleData,
          series: [...roleSeries],
          options: {
            ...chartData.roleData.options,
            labels: [...reportData?.roleData.labels],
            legend: {
              ...chartData.roleData.options.legend,
              formatter: (seriesName, opts) => customRadialLegendFormatter(seriesName, opts, reportData?.roleData.series)
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  ...chartData.roleData.options.plotOptions.radialBar.dataLabels,
                  value: {
                    fontSize: '16px',
                    formatter: function (val, opts) {
                      const index = opts.config.series.indexOf(parseInt(val));
                      return reportData?.roleData.series[index]
                    }
                  },
                  total: {
                    show: true,
                    label: 'Total',
                    formatter: function (w) {
                      return roleSum
                    }
                  }
                }
              }
            }
          }
        },
        asbaaqData:{
          ...chartData.asbaaqData,
          series: [...reportData?.asbaaqData.series],
          options: {
            ...chartData.asbaaqData.options,
            labels: [...reportData?.asbaaqData.labels]
          }
        },
        isEmailVerified: {
          ...chartData.isEmailVerified,
          series: [...reportData?.isEmailVerified.series],
          options: {
            ...chartData.isEmailVerified.options,
            labels: [...reportData?.isEmailVerified.labels]
          }
        },
        languageData:{
          ...chartData.languageData,
          series: [...reportData?.languageData.series],
          options: {
            ...chartData.languageData.options,
            labels: [...reportData?.languageData.labels]
          }
        },
        qualStageData: {
          ...chartData.qualStageData,
          series: [...reportData?.qualStageData.series],
          options: {
            ...chartData.qualStageData.options,
            labels: [...reportData?.qualStageData.labels]
          }
        },
        skillData: {
          ...chartData.skillData,
          series: [...reportData?.skillData.series],
          options: {
            ...chartData.skillData.options,
            labels: [...reportData?.skillData.labels]
          }
        }
      })
    }
  }, [reportData])

  const handlePrint = useCallback(async() => {
    setPrintIdCardLoading(true);
    if (printRef.current === null) {
        return
    }
    setTimeout(() => {
        toJpeg(printRef.current, { cacheBust: true })
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'Users Stats';
                link.href = dataUrl;
                link.click();
                setPrintIdCardLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setPrintIdCardLoading(false);
            });
    }, 500);
  }, [printRef]);

  return (
    <div className='main-wrapper'>
      {
        printIdCardLoading &&
        <div className='loader-wrapper overlay'>
            <Spinner animation="border" variant="success" />
            <p style={{ color: '#fff' }}>Loading...</p>
        </div>
      }
        <div className="container ">
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <PageTitle link='/dashboard' title='USER STATISTICS' />
              <button className='icon-btn dark' style={{ backgroundColor: `${themeColor.primaryColor}`, display:'flex', marginTop: '0' }} onClick={handlePrint}> <TbFileExport />{printIdCardLoading ? 'Print...' : "Print"}</button>
            </div>
            {loader ?
              <div className='loader-wrapper'>
                  <Spinner animation="border" variant="success" />
              </div>
              :
              <div className={`general-chart-wrapper profile ${printIdCardLoading && 'print'}`} ref={printRef}>
                  <div className="column flex">
                      <div className="inner-col">
                          <div className="chart-box">
                              <h4>Profile completion </h4>
                              <button className='back-btn zoom' onClick={()=> setZoomInChart({
                                  type: 'radialBar',
                                  show: true,
                                  data: chartData?.profileCompletionPercent,
                                  title: 'Profile completion '
                                })}><FiZoomIn /></button>
                              <div className="chart-container">
                                  <ApexChartsComponent series={chartData?.profileCompletionPercent.series} options={chartData?.profileCompletionPercent.options} type='radialBar' height='100%' width='100%' />
                              </div>
                          </div>
                      </div>
                      <div className="inner-col">
                          <div className="chart-box">
                              <h4>Languages</h4>
                              <button className='back-btn zoom' onClick={()=> setZoomInChart({
                                  type: 'pie',
                                  show: true,
                                  data: chartData?.languageData,
                                  title: 'Languages'
                                })}><FiZoomIn /></button>
                              <div className="chart-container">
                                  <ApexChartsComponent series={chartData?.languageData.series} options={chartData?.languageData.options} type='pie' height='100%' width='100%' />
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="column">
                    <div className="chart-box">
                        <h4>Roles Summary </h4>
                        <button className='back-btn zoom' onClick={()=> setZoomInChart({
                          type: 'radialBar',
                          show: true,
                          data: chartData?.roleData,
                          title: 'Roles Summary'
                        })}><FiZoomIn /></button>
                        <div className="chart-container">
                            <ApexChartsComponent series={chartData?.roleData.series} options={chartData?.roleData.options} type='radialBar' height='100%' width='100%' />
                        </div>
                      </div>
                      
                  </div>
                  <div className="column flex">
                    <div className='inner-col full-height'>
                      <div className="chart-box">
                          <h4>Skillset </h4>
                          <button className='back-btn zoom' onClick={()=> setZoomInChart({
                            type: 'polarArea',
                            show: true,
                            data: chartData?.skillData,
                            title: 'Skillset'
                          })}><FiZoomIn /></button>
                          <div className="chart-container">
                              <ApexChartsComponent series={chartData?.skillData.series} options={chartData?.skillData.options} type='polarArea' height='100%' width='100%' />
                          </div>
                      </div>
                    </div>
                    <div className="inner-col full-height">
                      <div className="chart-box">
                          <h4>Asbaaq Summary</h4>
                          <button className='back-btn zoom' onClick={()=> setZoomInChart({
                              type: 'pie',
                              show: true,
                              data: chartData?.asbaaqData,
                              title: 'Asbaaq Summary'
                            })}><FiZoomIn /></button>
                          <div className="chart-container">
                              <ApexChartsComponent series={chartData?.asbaaqData.series} options={chartData?.asbaaqData.options} type='pie' height='100%' width='100%' />
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="column flex">
                    <div className='inner-col full-height'>
                      <div className="chart-box">
                          <h4>Email Verification</h4>
                          <button className='back-btn zoom' onClick={()=> setZoomInChart({
                            type: 'donut',
                            show: true,
                            data: chartData?.isEmailVerified,
                            title: 'Email Verification'
                          })}><FiZoomIn /></button>
                          <div className="chart-container">
                              <ApexChartsComponent series={chartData?.isEmailVerified.series} options={chartData?.isEmailVerified.options} type='donut' height='100%' width='100%' />
                          </div>
                        </div>
                    </div>
                    <div className="inner-col full-height">
                      <div className="chart-box">
                          <h4>Qualification Stage Summary </h4>
                          <button className='back-btn zoom' onClick={()=> setZoomInChart({
                              type: 'polarArea',
                              show: true,
                              data: chartData?.qualStageData,
                              title: 'Qualification Stage Summary'
                            })}><FiZoomIn /></button>
                          <div className="chart-container">
                              <ApexChartsComponent series={chartData?.qualStageData.series} options={chartData?.qualStageData.options} type='polarArea' height='100%' width='100%' />
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
            }
        </div>
        {zoomInChart.show ?
            <div className="popup-wrapper">
              <div className="popup-container chart-popup">
                <div className="popup-head">
                  <h4 style={{ textAlign: 'left' }}>{zoomInChart.title}</h4>
                </div>
                <div style={{ height: 'calc(100% - 64px)' }}>
                  <ApexChartsComponent type={zoomInChart.type}
                  height='100%'
                  width='100%'
                  // sx={{position:'absolute',top:'0px',display:'flex',justifyContent:'center'}}
                  series={zoomInChart.data.series} 
                  options={zoomInChart.data.options} />
                </div>
                <button className='close-btn' onClick={() => setZoomInChart({
                  show: false,
                  type: '',
                  data: '',
                  title: ''
                })}><GrClose /></button>
              </div>
            </div>
          : ''
        }
    </div>
  )
}

export default ProfileStatistics