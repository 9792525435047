import { ALL_PRODUCTS, PRODUCTS_LOADING, SINGLE_PRODUCT } from "../actions/Inventory"

const initialState = {
    productsData: [],
    singleProduct: {},
    loading: false,
    deleteProduct: false,
}

export default (state = initialState, action) =>{
    switch (action.type) {
        case PRODUCTS_LOADING:
        return {
          ...state,
          loading: true,
          deleteProduct: false,
        };

        case ALL_PRODUCTS:
        return {
            ...state,
            productsData: action.payload,
            loading: false,
        };

        case SINGLE_PRODUCT:
            return {
                ...state,
                singleProduct: action.payload,
                loading: false,
            }

        default: {
            return state;
        }   
}}