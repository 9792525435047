import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl } from "../config"
import { setLogin } from "../store/actions/login";

export const GetFetch = (url, dispatch, navigate) => {
  var token = "";
  if (Cookies.get("token")) {
    token = Cookies.get("token");
  }

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Cookie", "ci_session=u182h3aavvsd7vcidsseh11ngvsmdpci");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const response = fetch(`${baseUrl}${url}`, requestOptions).then((res) => {
      return new Promise((resolve, reject) => {
        if (res.status === 200) {
          res.json().then((json) => {
            resolve({
              status: res.status,
              data: json,
            })
          })

        } else if (res.status === 204) {
          resolve({
            status: res.status,
            data: {
              msg: 'No data found'
            },
          })

        } else {
          if(res.status === 401){
            navigate('/login', {replace: true});
            dispatch(setLogin(false));
          } else{
            res.json().then((json) => {
              reject({
                status: res.status,
                data: json,
              })
            })
          }
          // const contentType = res.headers.get("content-type");
          // if (contentType && contentType.indexOf("application/json") !== -1) {
          //   res.json().then((json) =>
          //     resolve({
          //       status: res.status,
          //       data: json,
          //     })
          //   );
          // } else {
          //   throw `${res.status} ${res.statusText}`;
          // }
        }
      });
    });

    return Promise.resolve(response);
  } catch (error) {
    console.log("er", error);
    return Promise.reject(error);
  }
};

export const PostFetch = async (url, updateDetails, method, navigate, dispatch) => {
  var token = "";
  if (Cookies.get("token")) {
    token = Cookies.get("token");
  }

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Cookie", "ci_session=rreogi50iph7307q4leov9ansfsepqh9");

  var raw = updateDetails ? JSON.stringify(updateDetails) : null;

  var requestOptions = {
    method: method,
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = fetch(`${baseUrl}${url}`, requestOptions).then((res) => {
      return new Promise((resolve, reject) => {
        if (res.status === 200 || res.status === 202) {
          res.json().then((json) => {
            resolve({
              status: res.status,
              data: json,
            })
          })

        } else {
          if(res.status === 401){
            navigate('/login', {replace: true});
            dispatch(setLogin(false));
          }
          res.json().then((json) => {
            reject({
              status: res.status,
              data: json,
            })
          })
          // const contentType = res.headers.get("content-type");
          // if (contentType && contentType.indexOf("application/json") !== -1) {
          //   res.json().then((json) =>{

          //     }

          //   )
          // }
          // else {
          //   // throw `${res.status} ${res.statusText}`;
          //   console.log(`${res.status} ${res.statusText}`)
          // }
        }
      });
    }).catch((error) => {
      return Promise.reject(error);
    })

    return Promise.resolve(response);
  } catch (error) {
    console.log("er", error);
    return Promise.reject(error);
  }
};