import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputField from '../../../../components/inputField';
import { ALERT_ERROR } from '../../../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { FormHelperText } from '@mui/material';


export default function CheckBoxInput({ handleChange, singleMiqaat, options, isRequired, type, value, maxLimit, otherFieldValue, id, setOtherFieldValue }) {


    const [maxSelection, setMaxSelection] = useState(false)
    const [fIndex, setFIndex] = useState();
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        let fVal = [...otherFieldValue];
        fVal[fIndex].val = e;
        setOtherFieldValue(fVal);
    }

    useEffect(() => {
        if (options && options.length > 0) {
            let fVal = [...otherFieldValue]
            options.map((option) => {
                if (option.value && option.value === true) {
                    if (value?.toLowerCase().includes('other') && fVal.some(item => item.id === id) !== true) {
                        fVal = [
                            ...fVal,
                            {id: id, val: value?.replace('other: ', '').replace(/(true|false)(,|$)/g, '')}
                        ]
                    }else if(option.placeholder === 'Other' && fVal.some(item => item.id === id) !== true){
                        fVal = [
                            ...fVal,
                            {id: id, val: ''}
                        ]
                    }
                }
            })
            setOtherFieldValue(fVal)
        }
    }, [singleMiqaat])

    useEffect(()=>{
        if(otherFieldValue){
            const index = otherFieldValue.findIndex(item => item.id === id);
            setFIndex(index)
        }
    }, [otherFieldValue])

    useEffect(() => {
        if (options?.length) {
            let len = []
            options.map((item, i) => {
                if (item.value == true || item.value == 'true') {
                    len.push(true)
                }
            })

            if (len?.length == maxLimit) {
                if (maxLimit === 0) {
                    setMaxSelection(false)
                } else {
                    setMaxSelection(true)
                }
            } else {
                setMaxSelection(false)
            }
        }
    }, [handleChange, options, type])

    const checkSelction = (val, i, type, option) => {
        if (maxSelection) {
            if (option.value == 'true' || option.value == true) {
                handleChange(val, i, type)
            } else {
                dispatch({
                    type: ALERT_ERROR,
                    payload: `You can select maximum ${maxLimit} options`
                })
            }
        } else {
            handleChange(val, i, type)
        }
    }
    // useEffect(() => {
    //     if (options && options.length > 0) {
    //         options.map((option) => {
    //             if (value && typeof value === 'string') {
    //                 if (value?.toLowerCase().includes('other')) {
    //                     setOtherFieldValue(value.replace('other: ', ''))
    //                 }
    //                 else {
    //                     setOtherFieldValue('')
    //                 }
    //             }
    //         })
    //     } else {
    //         setOtherFieldValue('')
    //     }
    // }, [options])

    // useEffect(() => {
    //     let fieldVal = otherFieldValue;
    //     if (otherFieldValue) {
    //         fieldVal = fieldVal.replace(/(true|false)(,|$)/g, '');
    //         setOtherFieldValue(fieldVal? fieldVal : '');
    //     }
    // }, [otherFieldValue])

    return (
        <div>
            <FormGroup>

                {options?.map((option, i) => (<>
                    < FormControlLabel control={
                        <>

                            {/* < Checkbox checked={typeof option.value === 'string' ? option.value === 'true' : option.value} onChange={(val) => maxSelection == true &&  option.value == false || 'false' ?  null :  handleChange(val, i, type) } /> */}
                            < Checkbox checked={typeof option.value === 'string' ? option.value === 'true' || option.value?.toLowerCase().includes('other') : option.value} onChange={(val) => checkSelction(val, i, type, option)} />

                        </>
                    } label={option?.placeholder} />
                    {
                        option.placeholder.toLowerCase() === "other" &&
                        <>
                            {
                                option.value == false || option.value === 'false' || !option.value ?
                                    ''
                                    : <InputField handleChange={(e) => { handleChange('other', i, type, e.target.value); handleInputChange(e.target.value) }} val={otherFieldValue[fIndex]?.val ? otherFieldValue[fIndex].val : ''} fieldType={'text'} />
                            }
                        </>
                    }
                </>))}
                {isRequired ? <FormHelperText
                    sx={{ color: '#d32f2f' }}
                >
                    This field is required
                </FormHelperText> : null}
            </FormGroup>
        </div>
    );
}