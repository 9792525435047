import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { themeColor } from '../../config';
import TextareaField from '../../components/TextareaField';
import { PostFetch } from '../../utils/fetchUrl';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import Checkbox from '../../components/Checkbox';
import PageTitle from '../../components/PageTitle';

const SyncUserByITS = () => {
    const [syncUserStatus, setSyncUserStatus] = useState();
    const [itsId, setItsId] = useState();
    const [ITSIds, setITSIds] = useState({
        ITSIds: [],
    });
    const [successPopup, setSuccessPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [idError, setIdError] = useState(false)
    const [openITSDropDown, setOpenITSDropDown] = useState({
        untagged: false,
        invalid: false,
        skipped: false,
        tagged: false
    });

    const dispatch = useDispatch();

    const syncUser = () => {
        if (ITSIds.ITSIds.length > 0) {
            setLoader(true);
            PostFetch('/api/user/syncByITS', ITSIds, "POST").then((response) => {
                if (response.status === 200) {
                    setSyncUserStatus(response.data);
                    setSuccessPopup(true);
                    showSuccessAlert(`Sucessfully Synced`);
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    setLoader(false);
                }
                setLoader(false);
            }).catch((error) => {
                console.log('error', error);
                showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
                setLoader(false);
            })
        }
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    const schema = yup
        .object({
            ItsIds: yup.string()
                .required('This field is required')
                .matches(/^[0-9,'\n' ]+$/, "Must be only digits")
        })
        .required("required");
    const { handleSubmit, register, reset, watch, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const submitAction = (data) => {
        setIdError(false)
        { itsId == "" ? setIdError(true) : setIdError(false) }
        const arr = itsId.split("\n");
        let numArr = []
        arr && arr.length && arr.map((str, i) => {
            if (str.length > 8 || str.length < 8) {
                setIdError(true)
            }
            else if (str.length == 8) {
                numArr.push(parseInt(str))
                setIdError(false)
            }
        })
        setITSIds({
            ITSIds: numArr,
        });
    }
    useEffect(() => {
        if (!idError) {
            if (ITSIds.ITSIds && ITSIds.ITSIds.length > 0) {
                syncUser();
                setItsId('');
            }
        }

    }, [ITSIds.ITSIds])

    return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7}>
                        <PageTitle link='/sync-user' title='BY ITS ID(s)' />
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <TextareaField fieldPlaceholder={"Enter ITS ID(s)"}
                                    fieldLabel={'ITS ID(s):'} name={'ItsIds'} classNm={'no-margin'}
                                    error={errors.ItsIds?.message} register={{ ...register("ItsIds") }}
                                    value={itsId} handleChng={(e) => setItsId(e.target.value)}
                                />
                                <br />
                                {idError ?
                                    <h6 className="note" style={{ color: "red" }}>ITS Ids should be of 8 digits</h6> : ''
                                }
                                <h6 className="note" style={{ color: "green" }}>Press Enter After Every ITS ID</h6>
                                <div className="btn-wrapper">
                                    <Link to='/sync-user'><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type='submit' disabled={loader} >Sync{
                                        loader ?
                                            <div className='loader-wrapper btn-loader'>
                                                <Spinner animation="border" variant="white" />
                                            </div> : ''
                                    }</Button>
                                </div>
                            </form>
                        </div>
                        {successPopup && !loader ?
                            syncUserStatus ?
                                <div className="status-wrapper" style={{ color: `${themeColor.secondaryColor}` }}>
                                    <div className="status-container">
                                        {syncUserStatus.syncedSuccessfulCount > 0 ?
                                            <div className="details">
                                                <p><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.syncedSuccessfulCount}</b> {syncUserStatus.syncedSuccessfulCount > 1 ? 'Members' : 'Member'} Successfully Synced</p>
                                            </div>
                                            : ''}

                                        {syncUserStatus.taggedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, tagged: !openITSDropDown.tagged })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.taggedCount}</b> Tagged {syncUserStatus.taggedCount > 1 ? 'ITS Ids' : 'ITS Id'} {openITSDropDown.tagged ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.tagged && 'open'}`}>
                                                    <p>
                                                        {syncUserStatus?.taggedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""}

                                        {syncUserStatus.untaggedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, untagged: !openITSDropDown.untagged })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.untaggedCount}</b> {syncUserStatus.untaggedCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Untagged {openITSDropDown.untagged ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.untagged && 'open'}`}>
                                                    <p>
                                                        {syncUserStatus?.untaggedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""}
                                        {syncUserStatus.invalidCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, invalid: !openITSDropDown.invalid })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.invalidCount}</b> {syncUserStatus.invalidCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid {openITSDropDown.invalid ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.invalid && 'open'}`}>
                                                    <p>
                                                        {syncUserStatus?.invalidITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""}
                                        {syncUserStatus.skippedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, skipped: !openITSDropDown.skipped })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.skippedCount}</b> {syncUserStatus.skippedCount > 1 ? 'ITS Ids' : 'ITS Id'} Skipped {openITSDropDown.skipped ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.skipped && 'open'}`}>
                                                    <p>
                                                        {syncUserStatus?.skippedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""}
                                        {syncUserStatus.errorCount > 0 ?
                                            <div className="details">
                                                <p><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.errorCount}</b> {syncUserStatus.errorCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid</p>
                                            </div>
                                            : ""}
                                        {syncUserStatus.errorCount === 0 && syncUserStatus.untaggedCount === 0 && syncUserStatus.syncedSuccessfulCount === 0 && syncUserStatus.skippedCount === 0 && syncUserStatus.taggedCount === 0 ?
                                            <div className="details">
                                                <p>No ITS Id Added</p>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                                : 'Something went wrong'
                            : ''
                        }
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default SyncUserByITS