import React, { useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { themeColor } from '../../../config'
import TextareaField from '../../../components/TextareaField'
// import { CancelRegistrationSchema } from '../../../utils/Schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import TextEditor from '../../../components/TextEditor'
import PageTitle from '../../../components/PageTitle'

const CancelRegistration = () => {
  const [btnLoader, setBtnLoader] = useState(false)

  const { handleSubmit, register, reset, formState: { errors }, control, setValue } = useForm({
    // resolver: yupResolver(CancelRegistrationSchema)
  })

  const submitAction = (data) => {
    setBtnLoader(true)
    reset()
    setValue('Equipment', '')
  }

  return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7} sm={12}>
                      <PageTitle link='/view-miqaat/1' title='Cancel Registration' />
                        {/* <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <TextareaField fieldPlaceholder={'Enter Reason'}
                                fieldLabel={'Reason:'} name={'Reason'}
                                error={errors.Reason?.message} register={{ ...register('Reason') }} />

                                <TextEditor
                                placeholder={'Enter Message'}
                                name={'Message'}
                                // val={fieldValues.Message}
                                label={'Message :'}
                                register={{ ...register('Message') }}
                                error={errors.Message?.message}
                                control={control}
                                />

                                <div className="btn-wrapper">
                                    <Link to='/view-miqaat/1'><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type='submit' disabled={btnLoader} >Save{
                                        btnLoader
                                          ? <div className='loader-wrapper btn-loader'>
                                            <Spinner animation="border" variant="white" />
                                        </div>
                                          : ''
                                    }</Button>
                                </div>
                            </form>
                        </div> */}
                    </Col>
                </Row>
            </div>
        </div>
  )
}

export default CancelRegistration
