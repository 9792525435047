import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import {MdModeEdit} from 'react-icons/md';
import {MdDeleteOutline} from 'react-icons/md';
import {IoMdAddCircle} from 'react-icons/io';
import DataTable from 'react-data-table-component';
import InputField from '../../components/inputField';
import { themeColor } from '../../config';
import AppModal from '../../components/Modal';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { Spinner } from 'react-bootstrap';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { dateFormatFunction } from '../../utils/dateFormat';
import PageTitle from '../../components/PageTitle';

const Template = () => {
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState();
  const [showModal,setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [templateDetails, setTemplateDetails] = useState();
  const [deleteStatus,setDeleteStatus] = useState();
  const [showDeleteAlert,setShowDeleteAlert] = useState();
  const [deleteTemplatemplateId,setDeleteTemplatemplateId] = useState();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchTemplate = () => {
    setLoader(true);
    GetFetch(`/api/EmailTemplates`, dispatch, navigate).then((response) => {
        if (response.status == 200){
            setTemplateDetails(response.data);
        }
        else {
          showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
        }
        setLoader(false);
    }).catch((err)=>{
        console.log('error', err);
        showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
        setLoader(false);
    })
  }

  const DeleteTemplate = (id) => {
    return new Promise((resolve, reject)=>{
      PostFetch(`/api/EmailTemplates/${id}`,"",'DELETE').then((response) => {
      if (response.status == 200){
          setDeleteStatus(response.data);
          showSuccessAlert("Successfully Deleted");
          resolve();
      }
      else {
          showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
          reject();
      }
      setLoader(false);
    }).catch((err)=>{
        console.log('error', err);
        showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
        setLoader(false);
        reject();
    })
  })
  }

  const showErrorAlert = (msg) => {
    dispatch({
        type: ALERT_ERROR,
        payload: msg
    })
}
const showSuccessAlert = (msg) => {
  dispatch({
      type: ALERT_SUCCESS,
      payload: msg
  })
}

  const deleteEmailTemplate = (id) => {
    setShowModal(true);
    setDeleteTemplatemplateId(id);
  }

  const confirDelete = () => {
    setShowModal(false);
    DeleteTemplate(deleteTemplatemplateId).then(()=>{
      fetchTemplate();
    })
  }

  const columns = [
      {
        name: 'Template Name',
        selector: (row) => row.name ? row.name : '-',
        minWidth: '240px',
      },
      {
          name: 'Created On',
          selector: (row) => row.insertedAt ? dateFormatFunction(row.insertedAt) : '-',
          minWidth: '140px',
          maxWidth: '200px'
      },
      {
          name: 'Created By',
          selector: (row) => row.insertedById ? row.insertedById : '-',
          minWidth: '140px',
          maxWidth: '200px'
      },
      {
          name: 'Actions',
          cell: (row) => <p className='action-btn-wrapper'><Link className='action-btn' style={{color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}`}} to={`/template/edit/${row.id}`}><MdModeEdit/></Link> <button className='action-btn' style={{color: 'red', background: `${themeColor.lightGreen}`}} onClick={()=>deleteEmailTemplate(row.id)}><MdDeleteOutline/></button></p>,
          minWidth: '130px',
          maxWidth: '180px'
      },
    ]
    
    const searchFilter =(e)=>{
      setSearch(e)
      if(e == ""){
        setFilteredData(templateDetails); 
      }else{
        let filterResult =  filteredData.filter(item => item.name.toLowerCase().includes(e.toLowerCase())) 
        setFilteredData(filterResult); 
      }
    }

    useEffect(()=>{
      fetchTemplate();
    }, [])

    useEffect(()=>{
      setFilteredData(templateDetails);
    }, [templateDetails])

    if (loader) {
      return(
          <>
              <div className='loader-wrapper'>
                  <Spinner animation="border" variant="success" />
              </div>
          </>
      )
  }
else{
  return (
    <div className='main-wrapper'>
        <div className="container">
          <PageTitle link='/dashboard' title='Email Template' />
            <div className="table-wrapper action-table">
                <DataTable data={filteredData} columns={columns}
                fixedHeader fixedHeaderScrollHeight='482px' 
                subHeader pagination
                subHeaderComponent={
                  <div>
                      <InputField className={'search-field'} fieldPlaceholder={"Search Template"} fieldType={'text'} value={search}
                      handleChange={(e)=>searchFilter(e.target.value)}  />
                      <Link to={'/template/add'} className='icon-btn dark' style={{background: `${themeColor.primaryColor}`}}><IoMdAddCircle />Create Template</Link>
                  </div>} subHeaderAlign="left"/>
            </div>
            <AppModal handleClose={()=>setShowModal(false)} showModal={showModal} title={'Confirm Delete'} 
          handleAction={confirDelete} actionBtnText={"Confirm"}/>
        </div>
    </div>
  )
}
  
}

export default Template