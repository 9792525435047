import React from 'react'
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const Card = ({ cardDetails }) => {
  const params = useParams();
  return (
    <div className={`app-card ${cardDetails.childrens && cardDetails.childrens.length > 0 ? 'hoverable' : ''}`}
    style={{ background: `${cardDetails.isDisabled ? cardDetails.disabledBgColor : cardDetails.lightColorCode}` }}>
        {cardDetails.childrens && cardDetails.childrens.length > 0
          ? <div className='card-content-wrapper'>
                <div className='card-content'>
                    <i className={cardDetails.icon} style={{ color: `${cardDetails.isDisabled ? cardDetails.disabledIconColor : cardDetails.darkColorCode}` }}></i>
                    <h4 style={{ color: `${cardDetails.isDisabled ? cardDetails.disabledIconColor : cardDetails.darkColorCode}` }}>{cardDetails.name}</h4>
                    {cardDetails.isDisabled ? <p className="comming-soon-style">Coming Soon</p> : null}
                </div>
                <div className="card-action-wrapper">
                    {cardDetails.childrens.length && cardDetails.childrens.map((action) => {
                      if (action.allow) {
                        return <Link key={action.name} to={!cardDetails.isDisabled ? action.link : null} style={{ color: `${cardDetails.isDisabled ? cardDetails.disabledIconColor : cardDetails.darkColorCode}` }}><i className={action.icon}></i> {action.name} </Link>
                      } else {
                        return null
                      }
                    })}
                </div>
            </div>
          : <Link to={!cardDetails.isDisabled ? cardDetails.firstLink? `/${cardDetails.firstLink}/${params.id}/${cardDetails.secondLink}` : cardDetails.link : null} className='linked-card'>
                <div>
                    <i className={cardDetails.icon} style={{ color: `${cardDetails.isDisabled ? cardDetails.disabledIconColor : cardDetails.darkColorCode}` }}></i>
                    <h4 style={{ color: `${cardDetails.isDisabled ? cardDetails.disabledIconColor : cardDetails.darkColorCode}` }}>{cardDetails.name}</h4>
                    {cardDetails.isDisabled ? <p className="comming-soon-style">Coming Soon</p> : null}
                </div>
            </Link>
        }

    </div>
  )
}

export default Card

Card.propTypes = {
  cardDetails: PropTypes.object
}
