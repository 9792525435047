import React from 'react'
import { MuiTelInput } from 'mui-tel-input';
import { Controller } from 'react-hook-form';

const PhoneInput = ({fieldLabel, name, placeholder, error, register, control,value, handleChange}) => {
  return (
    <div className="field">
        {fieldLabel ? <label>{fieldLabel}</label> : ''}
        {!handleChange &&  control ? 
          <>
            <Controller
            name={name}
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
            })=>(
            //   <Select options={options} className='select-field' placeholder={placeholder}
            //   value={value} onChange={onChange} isDisabled={disable} isMulti={isMulti ? true : false} name={name}
            //   />
              <MuiTelInput value={value} defaultCountry='IN' onChange={onChange} name={name} placeholder={placeholder} />
            )}
            />
            {error?<p className='error-message'>{error}</p>:''}
          </>
          :
            // <Select 
            // onClick={(e)=> e.stopPropagation()}
            // options={options} className='select-field' placeholder={placeholder}
            // value={value} onChange={handleChange} isDisabled={disable} isMulti={isMulti ? true : false} name={name}
            // />
            <>
              <MuiTelInput value={value} defaultCountry='IN' onChange={handleChange} placeholder={placeholder} name={name} />
              {error?<p className='error-message'>{error}</p>:''}
            </>
        }
        
    </div>
  )
}

export default PhoneInput