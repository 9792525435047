import { ALL_TICKET, TICKET_LOADING, TICKET_FAIL, SINGLE_TICKET, DELETE_TICKET} from "../actions/ticketAction";


const initialState = {
    ticketData: [],
    singleTicket: {},
    loading: false,
    deleteVenue: false,
}


export default (state = initialState, action) =>{
    switch (action.type) {
        case TICKET_LOADING:
        return {
          ...state,
          loading: true,
          deleteVenue: false,
        };

        case ALL_TICKET:
            return {
                ...state,
                ticketData: action.payload,
                loading: false,
              };
        // case ADD_tick: 
        //     return {
        //         ...state,
        //         teamData: action.payload,
        //         loading: false,
        //     }
        
        case SINGLE_TICKET:
            return {
                ...state,
                singleTicket: action.payload,
                loading: false,
            }    

        case TICKET_FAIL:
            return {
            ...state,
            loading: false,
            };

        case DELETE_TICKET:
            return {
                ...state,
                loading: false,
                deleteVenue: true,
            };    

        case "USER_LOGOUT":
            return (state = initialState);
        default: {
            return state;
        }      
    }
}