export const themeColor = {
    primaryColor: '#155835',
    secondaryColor: '#696969',
    lightGreen: '#F2F6EE',
}
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const profileUrl = process.env.REACT_APP_PROFILE_URL;

export const API_KEY = process.env.REACT_APP_API_KEY;
