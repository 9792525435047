import React, { useEffect, useState } from 'react';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText, Grid } from '@mui/material';
import dayjs from "dayjs";
import { capitalize } from 'lodash';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

export default function TimePickerComponent({ options, type, isRequired, handleChange, setDisableNext, ...rest }) {
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const [duration, setDuration] = useState();
    const [error, setError] = useState();

    const parseTimeValue = (val) => {
        if (val) {
            const [datePart, timePart] = val.split(' ');
            const [day, month, year] = datePart.split('/').map(Number);
            const [hours, minutes] = timePart.split(':').map(Number);
            return dayjs(new Date(year, month - 1, day, hours, minutes));
        } else {
            return null;
        }
    }

    useEffect(()=>{
        if(timeFrom){
            const from = new Date(timeFrom);
            const durationInMillis = duration * 1000 * 60 * 60;
            const fromDAteWithDuration = new Date(from.getTime() + durationInMillis);
            const to = new Date(timeTo);
            if(to < fromDAteWithDuration) {
                setTimeTo(dayjs(fromDAteWithDuration));
                handleChange(to, 1, type);
            }
            setError('')
            setDisableNext(false);
        }
    }, [timeFrom])

    useEffect(()=>{
        if(timeFrom && timeTo){
            const to = new Date(timeTo);
            const from = new Date(timeFrom);
            const durationInMillis = duration * 1000 * 60 * 60;
            const fromDAteWithDuration = new Date(from.getTime() + durationInMillis);
            
            if(from > to){
                setError('Time from must be Less than Time to.')
                setDisableNext(true);
            } else if(to < fromDAteWithDuration){
                setError(`Your khidmat is needed for atleast ${duration} hours`)
                setDisableNext(true);
            } else{
                setError('')
                setDisableNext(false);
            }
        } else if(timeTo && !timeFrom){
            setError(`Please select Time from first`);
            setDisableNext(true);
        }
    }, [timeTo])

    useEffect(() => {
        setDuration(options[2].min);
        if(!timeFrom){
            if(options[0].value){
                setTimeFrom(dayjs(new Date(parseTimeValue(options[0].value))))
            } 
        }
        if(!timeTo){
            if(options[1].value){
                setTimeTo(dayjs(new Date(parseTimeValue(options[1].value))))
            }
        }
    }, [])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}  >
            <DemoContainer components={['TimePicker']}>
                <Grid spacing={2}>
                    {options?.map((option, i) => (
                    <>
                        {
                            option.placeholder !== "Duration" &&
                            <>
                                <TimePicker
                                    sx={{
                                        width: '100%',
                                        mt: '12px'
                                    }}
                                    label={capitalize(option.placeholder)}
                                    value={i ? timeTo : timeFrom}
                                    onChange={(val) => {
                                        handleChange(val, i, type);
                                        if (i) setTimeTo(val)
                                            else setTimeFrom(val)
                                    }}
                                    format="HH:mm"
                                    ampm={false}
                                    slotProps={{
                                        textField: {
                                            helperText: (
                                                isRequired && option?.required && (!option.value || option.value === 'Invalid date') ? <FormHelperText
                                                sx={{ color: '#d32f2f', marginLeft: 0 }}
                                                >
                                                This field is required
                                                </FormHelperText>
                                                : null
                                            ),
                                        },
                                    }}
                                />
                            </>
                        }
                        </>
                    ))}
                    {error && <FormHelperText sx={{ color: '#d32f2f', background: '#ffeaea', padding: '8px 12px', borderRadius: '8px' }} >{error}</FormHelperText>}
                </Grid>
            </DemoContainer>
        </LocalizationProvider >
    );
}