import React, { useEffect, useState } from 'react';
import {BiLogOut} from 'react-icons/bi';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {CgProfile} from 'react-icons/cg';
import Card from '../components/Card';
import { themeColor } from '../config';
import './style.css';
import dashboardBg from '../assets/images/dashboard_bg.jpg';
import { setDashboardData, setLogin } from '../store/actions/login';
import AppModal from '../components/Modal';
import { Spinner } from 'react-bootstrap';
import { GetFetch } from '../utils/fetchUrl';
import { ALERT_ERROR } from '../store/reducers/alert';
import MiqaatCard from './MIQAAT/QUESTIONAIRE/MiqaatCard';

const Dashboard = () => {
  const [showModal,setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dashboardD,setDashboardD] = useState([])
  const [loader, setLoader] = useState(false);
  
  const showErrorAlert = (msg) => {
    dispatch({
      type: ALERT_ERROR,
      payload: msg
    })
  }
  useEffect(()=>{
    setLoader(true);
    GetFetch(`/api/Dashboard`, dispatch, navigate).then((response) => {
      if (response?.status && response?.status == 200){
          setDashboardD(response?.data);
          dispatch(setDashboardData(response?.data));
      }
      else {
        showErrorAlert(response?.data?.msg ? response?.data?.msg : "Something Went Wrong");
      }
      setLoader(false);
    }).catch((err)=>{
      console.log('error', err);
      showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
      setLoader(false);
    })
  },[])

  const logout = () => {
    Cookies.remove('token');
    Cookies.remove('userItsId');
    dispatch(setLogin(false));
    navigate('/', {replace: true});
    setShowModal(false);
  }
  
  useEffect(()=>{
    if(dashboardD.length > 0){
      setLoader(false);
    }
  },[dashboardD])

  return (
      <div className='main-wrapper dashboard_wrapper' style={{background: `url(${dashboardBg})`}}>
        <div className="container">
            <div className="app-head" style={{justifyContent: 'space-between', marginBottom: '20px'}}>
              <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>Dashboard</h2>
              <div className="btn-wrapper">
                <Link className='icon-btn dark ' to="/profile" style={{background: `${themeColor.primaryColor}`, marginRight: '20px'}}><CgProfile /> Profile</Link>
                <button className='icon-btn ' style={{color: `${themeColor.primaryColor}`}} onClick={()=>setShowModal(true)}><BiLogOut />Logout</button>
              </div>
            </div>

            {loader ? 
              <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
              </div>
              : <div className="card-wrapper">
              {dashboardD?.length > 0 && dashboardD.map((cardInfo)=>{
                if(cardInfo.allow){
                  return <Card key={cardInfo.optionId} cardDetails={cardInfo}/>
                }
              })
              }
            </div>}
            
          </div>
          <AppModal handleClose={()=>setShowModal(false)} showModal={showModal} title={'Confirm Logout'} 
          handleAction={logout} actionBtnText={"Logout"}/>
      </div>
  )
}

export default Dashboard;