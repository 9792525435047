import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { MdModeEdit } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';
import { IoMdAddCircle } from 'react-icons/io';
import DataTable from 'react-data-table-component';
import InputField from '../../components/inputField';
import { themeColor } from '../../config';
import AppModal from '../../components/Modal';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { Button, Spinner } from 'react-bootstrap';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { dateFormatFunction } from '../../utils/dateFormat';
import PageTitle from '../../components/PageTitle';
import SelectField from '../../components/SelectField';
import Select from 'react-select';
import { FiPrinter } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {  AddEditSkill } from '../../utils/Schemas';

const SkillSetList = () => {
    const [addEditLoader, setAddEditLoader] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [deleteSkillSetId, setDeleteSkillSetId] = useState();
    const [skillSets, setAllSkillSets] = useState([])
    const [filteredLanguages, setFilteredSkillSets] = useState([])
    const [editSkillSetId, setEditSkillSetId] = useState('');
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showAddSection, setShowAddSection] = useState(false);


    const { handleSubmit, register, reset, formState: { errors }, control, setValue, watch } = useForm({
        resolver: yupResolver(AddEditSkill),
        defaultValues: {
            Name: ''
        }

    });

    const fetchSkillSets = () => {
        GetFetch('/api/Skills/GetAll').then((response) => {
            if (response.status == 200) {
                setAllSkillSets(response.data);
                setFilteredSkillSets(response.data)
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            }
        })
    }

    const DeleteTemplate = (id) => {
        return new Promise((resolve, reject) => {
            PostFetch(`/api/Skills/Delete?${id}`, "", 'DELETE').then((response) => {
                if (response.status == 200) {
                    showSuccessAlert("Successfully Deleted");
                    resolve();
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    reject();
                }
                setLoader(false);
            }).catch((err) => {
                showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
                setLoader(false);
                reject();
            })
        })
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }

    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    const deleteCity = (id) => {
        setShowModal(true);
        setDeleteSkillSetId(id);
    }

    const confirDelete = () => {
        setShowModal(false);
        DeleteTemplate(deleteSkillSetId).then(() => {
            fetchSkillSets()
        })
    }

    const editCity = (city) => {
        setEditSkillSetId(city.id)
        setValue('Name', city.name)
    }

    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name ? row.name : '-',
            // minWidth: '240px',
            sortable: true,
        },

        {
            name: 'Actions',
            cell: (row) =>
                row.id ?
                <p className='action-btn-wrapper'>
                    <button
                        className='action-btn'
                        style={{ color: '#696969', background: `${themeColor.lightGreen}` }}
                        onClick={() => editCity(row)}>
                        <MdModeEdit />
                    </button>
                    <button
                        className='action-btn'
                        style={{ color: 'red', background: `${themeColor.lightGreen}` }}
                        onClick={() => deleteCity(row.id)}>
                        <MdDeleteOutline />
                    </button>
                </p> : '',
            // minWidth: '130px',
            // maxWidth: '180px'
        },
    ]


    useEffect(() => {
        fetchSkillSets();
    }, [])


    const onSubmit = async (values) => {
        setAddEditLoader(true);

        if (editSkillSetId) {
            const body = { Name: values.Name, id: editSkillSetId }
            await PostFetch(`/api/Skills/Edit`, body, "PUT").then((response) => {
                if (response.status === 200 && response?.data?.success) {
                    showSuccessAlert('Skill Updated Successfully');
                    fetchSkillSets()
                    reset();
                    setEditSkillSetId('')
                    setSearch('')
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    reset();
                    setSearch('')
                    setEditSkillSetId('')
                    setAddEditLoader(false);
                }
                setAddEditLoader(false);
            }).catch((error) => {
                showErrorAlert(error.data.msg ? error.data.msg : "Something Went Wrong");
                setAddEditLoader(false);
            })
        }
        else {
            const body = { Name: values.Name }
            await PostFetch('/api/Skills/Add', body, "POST").then((response) => {
                if (response.status === 200 && response?.data?.success) {
                    showSuccessAlert('Skill Added Successfully');
                    setSearch('')
                    fetchSkillSets()
                    reset();
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    setAddEditLoader(false);
                    setSearch('')
                    reset();
                }
                setAddEditLoader(false);
            }).catch((error) => {
                console.log('error', error);
                showErrorAlert(error.data.msg ? error.data.msg : "Something Went Wrong");
                setAddEditLoader(false);
            })
        }
    }

    const cancel = () => {
        setEditSkillSetId('')
        setValue('Name', '')
        setShowAddSection(false)
    }
    const onSearch = (e) => {
        setSearch(e.target.value);
        filterCities(e.target.value);
    }
    const filterCities = (searchTerm) => {
        if (skillSets && skillSets?.length) {
            const filterData = skillSets?.filter((item) => {
                return Object.keys(item).some((key) =>
                    item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
                );
            });
            if (filterData.length) {
                setFilteredSkillSets(filterData)
            } else {
                setFilteredSkillSets([{ "name": "No Data Found" }])
            }
        }
    }

    return (
        <>
            <div className='main-wrapper' >
                <div className='container'>
                    <PageTitle link='/dashboard' title='Skills' />
                    <div className="two_col" >
                        <div className="table-wrapper action-table">
                            <DataTable
                                data={filteredLanguages || [{ "name": "No Data Found" }]}
                                columns={columns}
                                subHeader
                                progressPending={loader}
                                progressComponent={<Spinner animation="border" variant="success" />}
                                fixedHeader fixedHeaderScrollHeight='482px'
                                pagination
                                subHeaderComponent={
                                    <div>
                                        <InputField
                                            fieldPlaceholder={"Search by Name"}
                                            fieldType={'text'}
                                            value={search}
                                            handleChange={onSearch}
                                        />
                                        <button
                                            className="icon-btn dark"
                                            style={{ background: `${themeColor.primaryColor}` }}
                                            onClick={() => setShowAddSection(true)}
                                        >
                                            <IoMdAddCircle /> Add Skill
                                        </button>
                                    </div>
                                }
                                subHeaderAlign="left"
                            />
                        </div>
                        <AppModal handleClose={() => setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
                            handleAction={confirDelete} actionBtnText={"Confirm"} />

                        <div className={`details ${showAddSection && 'active'}`}>
                            <h4 className='primary-heading'> {editSkillSetId ? 'Edit Skill' : 'Add Skill'}</h4>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <InputField
                                    fieldPlaceholder={'Enter Skill Name'}
                                    name={'Name'}
                                    fieldType={'text'}
                                    fieldLabel={'Name:'}
                                    error={errors.Name?.message}
                                    register={{ ...register('Name') }} />
                                <Button
                                    variant="success"
                                    type='submit'
                                    style={{ marginTop: '24px', marginRight: '10px' }}
                                    disabled={addEditLoader}
                                >
                                    Submit
                                    {
                                        addEditLoader
                                            ? <div className='loader-wrapper btn-loader'>
                                                <Spinner animation="border" variant="white" />
                                            </div>
                                            : ''
                                    }
                                </Button>
                                <Button
                                    variant="danger"
                                    color='error'
                                    onClick={cancel}
                                    style={{ marginTop: '24px' }}
                                >

                                    Cancel
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default SkillSetList