import React from 'react'
import { Link } from 'react-router-dom'
import { themeColor } from '../config'
import { IoIosArrowBack } from 'react-icons/io'

const PageTitle = ({title,link,onClick}) => {
  return (
    <div className="app-head">
        <Link to={link} className='back-btn' style={{color: `${themeColor.primaryColor}`}} onClick={onClick}><IoIosArrowBack/></Link>
        <h2 className='primary-heading' style={{color: `${themeColor.primaryColor}`}}>{title}</h2>
    </div>
  )
}

export default PageTitle