import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from '../../components/inputField';
import SelectField from '../../components/SelectField';
import TextareaField from '../../components/TextareaField';
import { themeColor } from '../../config';
import TextEditor from '../../components/TextEditor';
import { GetFetch, PostFetch } from '../../utils/fetchUrl';
import { SendEmailItsSchema } from '../../utils/Schemas';
import { useDispatch } from 'react-redux';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { convertItsToArray, replaceBackSlash, replaceStringEmail } from '../../utils/helper';
import PageTitle from '../../components/PageTitle';

const ItsId = () => {
    const [sendEmailStatus, setSendEmailStatus] = useState();
    const [loader, setLoader] = useState(false);
    const [itsId, setItsId] = useState();
    const [formData, setFormData] = useState();
    const [templateDetails, setTemplateDetails] = useState();
    const [templates, setTemplates] = useState();
    const [idError, setIdError] = useState(false);
    const [templateValue, setTemplateValue] = useState();
    const [templateFieldDetails, setTemplateFieldDetails] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { handleSubmit, register, reset, setValue, watch, formState: { errors }, control } = useForm({
        resolver: yupResolver(SendEmailItsSchema),
    });

    const fetchTemplate = () => {
        GetFetch(`/api/EmailTemplates`, dispatch, navigate).then((response) => {
            if (response.status == 200) {
                setTemplateDetails(response.data);
            }
        }).catch((err) => {
            console.log('error', err);
        })
    }

    const sendEmail = async () => {
        setLoader(true);
        const message = await replaceBackSlash(formData.htmlBody);
        const body = { ...formData, htmlBody: message }
        PostFetch('/api/Communications/sendEmailByITS', body, "POST").then((response) => {
            if (response.status === 200) {
                setSendEmailStatus(response.data);
                showSuccessAlert('Successfull');
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                setLoader(false);
            }
            setLoader(false);
        }).catch((error) => {
            console.log('error', error);
            showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
            setLoader(false);
        })
    }

    const submitAction = async (data) => {
        setIdError(false)
        if (data?.Subject.charAt(0) === " ") {
            showErrorAlert("Subject should not start with space");
        } else {
            setIdError(false)
            { itsId == "" ? setIdError(true) : setIdError(false) }
            const numArr = await convertItsToArray(itsId, setIdError);
            setFormData({
                'ITSIds': numArr,
                'subject': data.Subject,
                'htmlBody': data.Message,
            })
        }
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    useEffect(() => {
        fetchTemplate();
    }, [])

    useEffect(() => {
        if (templateValue) {
            GetFetch(`/api/EmailTemplates/${templateValue.value}`).then((response) => {
                if (response.status == 200) {
                    setTemplateFieldDetails(response.data);
                }
            }).catch((err) => {
                console.log('error', err);
            })
        }
    }, [templateValue])

    useEffect(() => {
        if (templateFieldDetails) {
            setValue('Subject', templateFieldDetails.subject);
            setValue('Message', templateFieldDetails.htmlBody);
        }
    }, [templateFieldDetails])

    useEffect(() => {
        if (templateDetails) {
            setTemplates(
                templateDetails.map((template) => (
                    { value: template.id, label: template.name }
                ))
            )
        }
    }, [templateDetails])

    useEffect(() => {
        if (!idError) {
            if (formData && formData.ITSIds && formData.ITSIds.length > 0) {
                sendEmail();
                setItsId('');
                setTemplateValue('')
                reset()
                setValue('Message', '')
                setValue("Template", { value: 0, label: 'Select Template' })
            }
        }
    }, [formData])

    return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7} sm={12}>
                        <PageTitle link='/dashboard' title='ITS ID(s)' />
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <TextareaField fieldPlaceholder={"Enter ITS ID(s)"}
                                    fieldLabel={'ITS ID(s):'} name={'ItsIds'}
                                    error={errors.ItsIds?.message} register={{ ...register("ItsIds") }}
                                    value={itsId} handleChng={(e) => setItsId(e.target.value)} />
                                {idError ?
                                    <h6 className="note" style={{ color: "red" }}>ITS Ids should be 8 of digits</h6>
                                    : ''
                                }
                                <h6 className="note" style={{ color: "green" }}>Press Enter After Every ITS ID</h6>

                                <SelectField fieldLabel={'Template:'} options={templates} name={'Template'}
                                    placeholder={'Select Options'} value={templateValue} handleChange={(e) => setTemplateValue(e)} />

                                <InputField fieldPlaceholder={"Enter Subject"} name={'Subject'} fieldType={'text'} fieldLabel={'Subject:'}
                                    error={errors.Subject?.message} register={{ ...register("Subject") }} />

                                <TextEditor placeholder={'Enter Message'} name={'Message'}
                                    label={'Message:'} register={{ ...register("Message") }}
                                    error={errors.Message?.message} control={control} onChange={(e) => setValue('Message', e)} />

                                <div className="btn-wrapper">
                                    <Link to='/dashboard'><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type='submit' disabled={loader} >Send{
                                        loader ?
                                            <div className='loader-wrapper btn-loader'>
                                                <Spinner animation="border" variant="white" />
                                            </div> : ''
                                    }</Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ItsId