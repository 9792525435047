import React from 'react';
import { useForm } from "react-hook-form";

const TextareaField = ({fieldPlaceholder, rows, fieldLabel,disable, name, error, register, value, handleChng,onKeyUp, classNm}) => {

  return (
    <div className={`field ${classNm? classNm : ''}`}>
        <label>{fieldLabel}</label>
        <textarea rows={rows?rows:'5'} placeholder={fieldPlaceholder} value={value} disabled={disable}
         className={`textarea-field`} name={name} {...register} onKeyUp={onKeyUp} onChange={handleChng} />
        {error?<p className='error-message'>{error}</p>:''}
    </div>
  )
}

export default TextareaField