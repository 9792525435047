import { ALL_VENUES, VENUE_LOADING, ADD_VENUE, VENUE_FAIL, SINGLE_VENUE, DELETE_VENUE} from "../actions/venueAction";


const initialState = {
    venusData: [],
    singleVenue: {},
    loading: false,
    deleteVenue: false,
}


export default (state = initialState, action) =>{
    switch (action.type) {
        case VENUE_LOADING:
        return {
          ...state,
          loading: true,
          deleteVenue: false,
        };

        case ALL_VENUES:
            return {
                ...state,
                venusData: action.payload,
                loading: false,
              };
        case ADD_VENUE: 
            return {
                ...state,
                teamData: action.payload,
                loading: false,
            }
        
        case SINGLE_VENUE:
            return {
                ...state,
                singleVenue: action.payload,
                loading: false,
            }    

        case VENUE_FAIL:
            return {
            ...state,
            loading: false,
            };

        case DELETE_VENUE:
            return {
                ...state,
                loading: false,
                deleteVenue: true,
            };    

        case "USER_LOGOUT":
            return (state = initialState);
        default: {
            return state;
        }      
    }
}