import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { themeColor } from '../../../../config';

export default function RangeSlider({ handleChange, options, value, isRequired }) {
    return (
        <Box sx={{ width: 300 }}>
            {options.map((option, i) => {
                return (<>
                    <Grid m={2}>
                        <Typography variant='subtitile1' sx={{ textTransform: 'capitalize' }}>{option.placeholder} </Typography>
                        <Slider aria-label={option.placeholder}
                            defaultValue={[option.min, option.max]}
                            min={parseInt(option.min)}
                            // color={themeColor.primaryColor}
                            max={parseInt(option.max)}
                            valueLabelDisplay="auto"
                            value={option.value} onChange={(e, val) => handleChange(val, i, 'slider')} />
                        {
                            isRequired && option?.required && !option.value ? <FormHelperText
                                sx={{ color: '#d32f2f' }}
                            >
                                This field is required
                            </FormHelperText> : null
                        }
                        {/* {/ <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" value={option.value} onChange={(e, val) => handleChange(val, i, 'slider')} /> /} */}
                    </Grid>
                </>
                )
            })}
        </Box>
    );
}