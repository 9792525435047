import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { Button, ButtonGroup, Spinner } from 'react-bootstrap'
import { MdModeEdit, MdDeleteOutline } from 'react-icons/md'
import { IoIosArrowBack, IoMdAddCircle } from 'react-icons/io'
import { GrFormView } from 'react-icons/gr'
import InputField from '../../../components/inputField'
import { themeColor } from '../../../config'
import { ticketArray } from '../../../appContent'
import AppModal from '../../../components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { AllTicketAction, DeleteTicketAction } from '../../../store/actions/ticketAction'
import { dateFormatFunction, dateTimeFormatFunction } from '../../../utils/dateFormat'
import FilterData from '../../../components/FilterData'
import { GetTeamAndVenuesAction } from '../../../store/actions/miqaatAction'
import PageTitle from '../../../components/PageTitle'

const Template = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [showModal, setShowModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [active, setActive] = useState('All')
  const [ticket, setTicket] = useState('')
  const [ticketId, setTicketId] = useState('')
  const [filterTicketData, setFilterTicketData] = useState([])
  const [filterValues, setFilterValue] = useState({});
  const [isDisabledClearBtn, setIsDisabledClearBtn] = useState(true)
  const ticketData = useSelector((state) => state.ticketreducer);
  const dropDownOptions = useSelector((state) => state.miqaatreducer.dropdownOptions);
  const [filterOptions, setFilterOptions] = useState({
    priority:[
      {value: 'None', label: 'None'},
      {value: 'Low', label: 'Low'},
      {value: 'Medium', label: 'Medium'},
      {value: 'High', label: 'High'},
    ],
    status: [
      {value: 'Pending', label: 'Pending'},
      {value: 'Assigned', label: 'Assigned'},
      {value: 'InProgress', label: 'InProgress'},
      {value: 'Completed', label: 'Completed'},
    ]
  });

  const deleteTicket = (id) => {
    setShowModal(true)
    setTicketId(id)
  }
  const confirDelete = () => {
    setShowModal(false)
    let id = {
      Id: ticketId
    }
    dispatch(DeleteTicketAction(id, params?.id))
  }
  const getFilteredData = (filterData) => {
    setFilterTicketData(filterData)
  }
  const handleFilterChange = (keyName, value) => {
    setFilterValue({
      ...filterValues,
      [keyName]: value,
    });
  };

  const handleClearFilter = () => {
    setFilterValue({})
    setIsDisabledClearBtn(true)
    document.querySelectorAll('input').forEach((input) => (input.value = null));
    document.querySelectorAll('select').forEach((select) => (select.value = ''));
  }

  const columns2 = [
    {
      // name: 'Ticket Title',
      name: <FilterData columnName='Ticket Title' keyName='name' filterType='text' handleFilterChange={handleFilterChange} />,
      selector: (row) => row?.name ? row?.name : ' ',
      sortable: true,
      minWidth: '220px',
      maxWidth: '220px'
    },
    {
      name: 'Created On',
      selector: (row) => row?.insertedAt ? dateTimeFormatFunction(row?.insertedAt) : ' ',
      sortable: true,
      minWidth: '200px',
      maxWidth: '200px'
    },
    {
      name: 'Created By',
      selector: (row) => row?.generatedByDetail?.fullName ? row?.generatedByDetail?.fullName : ' ',
      sortable: true,
      minWidth: '330px',
      maxWidth: '330px'
    },
    {
      name: 'Additional Info ',
      selector: (row) => row?.additionalInfo ? row?.additionalInfo : ' ',
      minWidth: '250px',
      maxWidth: '250px'
    },
    {
      name: <FilterData columnName='Assign To' keyName='teamName' filterType='select' handleFilterChange={handleFilterChange} options={dropDownOptions.teams}  />,
      selector: (row) => row?.teamName ? row?.teamName : ' ',
      minWidth: '220px',
      maxWidth: '220px'
    },
    {
      name: <FilterData columnName='Priority' keyName='priority' filterType='select' handleFilterChange={handleFilterChange} options={filterOptions.priority}  />,
      selector: (row) =>
        (
          row.priority ?
          <p className = 'ticket-action' style={{ backgroundColor: `${row.priority === 'Low' ? '#ccc900' : row.priority === 'High' ? 'red' : row.priority ==='None' ? '#e6d532': '#42ba06'}` }} >
            {row.priority}
          </p> : ' '
        ),
      minWidth: '150px',
      maxWidth: '150px'
    },
    {
      name: <FilterData columnName='Status' keyName='status' filterType='select' handleFilterChange={handleFilterChange} options={filterOptions.status} />,
      selector: (row) =>
        (row.status ?
          <p className = 'ticket-action' style={{ backgroundColor: `${row.status === 'Approve' ? '#ccc900' : row.status === 'Open' ? '#0bbfad' : row.status === 'Closed' ? 'red' : '#42ba06'}` }} >
            {row.status}
          </p>: ' '
        ),
      minWidth: '150px',
      maxWidth: '150px'
    },
    {
      name: 'Action',
      selector: (row) =>
        (row.insertedAt ?
          <div style={{ display: 'flex' }}>
            <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}`, marginRight: '6px' }} to={`/view-miqaat/${params?.id}/ticket/edit/${row.id}`} ><MdModeEdit/></Link>
            <button className='action-btn note-error' style={{ color: 'red', background: `${themeColor.lightGreen}`, marginRight: '6px' }} onClick={() => deleteTicket(row?.id)} ><MdDeleteOutline/></button>
            <Link className='action-btn' style={{ color: `${themeColor.secondaryColor}`, background: `${themeColor.lightGreen}` }} to={`/view-miqaat/${params?.id}/ticket/${row?.id}`} ><GrFormView/></Link>
          </div> : ' '),
      minWidth: '140px',
      maxWidth: '140px'
    }
  ]

  useEffect(()=>{
    if(params?.id){
      dispatch(AllTicketAction(params?.id, navigate))
      dispatch(GetTeamAndVenuesAction(params?.id, setLoader))
    }
  }, [params])

  useEffect(() => {
    if (ticketData?.ticketData?.length > 0) {
      setFilterTicketData(ticketData?.ticketData)
    } else {
      setFilterTicketData([])
    }
  }, [ticketData])

  useEffect(() => {
    const isFilterEmpty = Object.keys(filterValues).every(key => filterValues[key].trim() === "");
    setIsDisabledClearBtn(isFilterEmpty);
  }, [filterValues]);

  useEffect(() => {
    if (ticket.length > 0) {
      const result = ticketData?.ticketData?.filter(data => Object.keys(data).some(key => {
        return String(data['name']).toLowerCase().includes(ticket.toLowerCase())
      }))
      setFilterTicketData(result)
    } else {
      setFilterTicketData(ticketData?.ticketData)
    }
  }, [ticket])

  if (ticketData?.loading) {
    return (
          <>
              <div className='loader-wrapper'>
                  <Spinner animation="border" variant="success" />
              </div>
          </>
    )
  } else {
    return (
    <>
    <div className='main-wrapper'>
        <div className="container">
          <PageTitle link={`/view-miqaat/${params?.id}`} title='Ticket' />
            <div className="table-wrapper miqaat action-table">
              <div style={{borderBottom: '1px solid #cccccc'}}>
                <div className='table-head' style={{justifyContent: 'flex-end'}}>
                  {/* <InputField className={'search-field'} fieldPlaceholder={'Search Ticket'} fieldType={'text'} value={ticket}
                  handleChange={(e) => setTicket(e.target.value)} /> */}
                  <div>
                    <button className='icon-btn dark' disabled={isDisabledClearBtn} style={{ backgroundColor: isDisabledClearBtn ? '#f5f5f5' : `${themeColor.primaryColor}`, color: isDisabledClearBtn ? '#ccc' : '' }} onClick={handleClearFilter}>Clear Filter</button>
                    <Link to={`/view-miqaat/${params?.id}/ticket/add`} className='icon-btn dark' style={{ background: `${themeColor.primaryColor}`, height: 'fit-content' }}><IoMdAddCircle />Add Ticket</Link>
                  </div>
                </div>
                <FilterData
                    data={ticketData?.ticketData ? ticketData?.ticketData : []}
                    columns={columns2}
                    filterValues={filterValues}
                    getFilteredData={getFilteredData}
                    noDataColKey='name'
                  />
                    </div>
                    {
                      filterTicketData?.length > 0 &&
                      <div className='table-footer'>
                        <p className="note">To scroll the report horizontally, press the Shift key and scroll using your mouse's scroll wheel.</p>
                        <span>rows: {filterTicketData.length}</span>
                      </div>
                    }
              </div>
            <AppModal handleClose={() => setShowModal(false)} showModal={showModal} title={'Confirm Delete'}
          handleAction={confirDelete} actionBtnText={'Confirm'}/>
        </div>
    </div>
    </>
    )
  }
}

export default Template
