
import React, { useState } from "react";
import { Box, FormHelperText, TextField } from "@mui/material";

const InputField = ({ options, handleChange, type, isRequired }) => {
    return (<>
        {options?.map((option, i) => (
            <Box sx={{ marginBottom: '16px' }}>
                <TextField
                    type={type || "text"}
                    value={option.value}
                    label={option.placeholder}
                    onChange={(val) => handleChange(val, i, type)}
                    variant="outlined"
                    fullWidth
                    size='medium'
                    sx={{ marginBottom: '0 !important' }}
                />
                {
                    isRequired && option?.required && !option.value ? <FormHelperText
                        sx={{ color: '#d32f2f' }}
                    >
                        This field is required
                    </FormHelperText> : null
                }
                {type === 'number' && option.min && option.max ? <FormHelperText
                    sx={{ color: '#3a2f2f' }}
                >
                    Please enter character between {option.min} and {option.max}
                </FormHelperText> : null}
            </Box>
        ))}

    </>
    );
};

export default InputField;