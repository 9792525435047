import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';

export default function BasicDateTimePicker({label, handleOnChange, value, defaultValue, control, name, error, disablePast, minDateTime, maxDateTime, showError, setShowError, maxDateError, minDateError}) {
  const [errorMsg, setErrorMsg] = React.useState();

  const errorMessage = React.useMemo(() => {
    // console.log(errorMsg)
    switch (errorMsg) {
      case 'maxDate':{
        return maxDateError
      }
      case 'minDate': {
        return minDateError
      }

      case 'invalidDate': {
        return 'Your date is not valid';
      }

      case 'disablePast': {
        return 'Date or Time Belongs to past';
      }

      default: {
        return '';
      }
    }
  }, [errorMsg]);

  React.useEffect(()=>{
    if(setShowError){
      if(errorMessage){
        setShowError(true)
      }else{
        setShowError(false)
      }
    }
  }, [errorMessage])

  return (
    <div className="field date-time-picker" onClick={(e)=> e.stopPropagation()}>
        {label ? <label>{label}</label> : ''}
        {!handleOnChange &&  control ? 
          <>
            <Controller
            name={name}
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
            })=>(
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                        <DateTimePicker 
                        name={name}
                        ampm={false}
                        value={value}
                        onChange={onChange}
                        disablePast={disablePast}
                        views={['day', 'month', 'year', 'hours', 'minutes']}
                        format="DD/MM/YYYY/HH/mm"
                        minDateTime={minDateTime}
                        maxDateTime={maxDateTime}
                        onError={(newError) => setErrorMsg(newError)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            )}
            />
            {error?<p className='error-message'>{error}</p> : errorMessage ? <p className='error-message'>{errorMessage}</p> : ''}
          </>
          :
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker 
                  defaultValue={defaultValue}
                  value={value}
                  onChange={handleOnChange}
                  disablePast={disablePast}
                  ampm={false}
                  views={['day', 'month', 'year', 'hours', 'minutes']}
                  format="DD/MM/YYYY/HH/mm"
                  minDateTime={minDateTime}
                  maxDateTime={maxDateTime}
                  onError={(newError) => setErrorMsg(newError)}
                />
            </DemoContainer>
        </LocalizationProvider>
        }
        
    </div>
    
  );
}