import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../components/PageTitle";
import FilterData from "../../../components/FilterData";
import moment from "moment";
import { get } from 'lodash';
import { exportExcelFile } from "../../../components/ExportExcelFile";
import { themeColor } from "../../../config";
import ReactDatePicker from "react-datepicker";
import { GetSingleMiqaatAction, GetTeamAndVenuesAction } from "../../../store/actions/miqaatAction";

const TaskReport = () => {
    const params = useParams();
    const printRef = useRef();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true)
    const [isDisabledClearBtn, setIsDisabledClearBtn] = useState(true)
    const [filterValues, setFilterValue] = useState({});
    const [startDate, setDate] = useState()
    const [processedData, setProcessedData] = useState([]);
    const [miqaatDates, setMiqaatDates] = useState({
        startDate: '',
        endDate: ''
    })
    const [reportData, setReportData] = useState([]);
    const miqaatInfo = useSelector((state) => state.miqaatreducer.singleMiqaat);
    const dropDownOptions = useSelector((state) => state.miqaatreducer.dropdownOptions)
    const { teams, venues } = dropDownOptions

    useEffect(() => {
        setMiqaatDates({
            startDate: get(miqaatInfo, 'dateFrom') || '',
            endDate: get(miqaatInfo, 'dateTo') || '',
        })
    }, [miqaatInfo])

    useEffect(() => {
        if (params?.id) {
          dispatch(GetSingleMiqaatAction(params?.id))
          dispatch(GetTeamAndVenuesAction(params?.id))
        }
      }, [params])

    const handleClearFilter = () => {
        setFilterValue({})
        setIsDisabledClearBtn(true)
        document.querySelectorAll('input').forEach((input) => (input.value = null));
        document.querySelectorAll('select').forEach((select) => (select.value = ''));
    }

    const handlePrevDate = () => {
        const previousDate = new Date(startDate);
        previousDate.setDate(startDate.getDate() - 1);
        if (previousDate >= new Date(miqaatDates.startDate)) {
          setDate(previousDate);
        }
    }
    
    const handleNextDate = () => {
        const nextDate = new Date(startDate);
        nextDate.setDate(startDate.getDate() + 1);
        const miqaatEndDate = new Date(miqaatDates.endDate);
        miqaatEndDate.setHours(23, 59, 59, 999);
        if (nextDate <= miqaatEndDate) {
          setDate(nextDate);
        }
    }

    const getFilteredData = (filterData) => {
        setProcessedData(filterData)
      }

      const filterDataConfig = (columnName, keyName, filterType, handleFilterChange, options = null, width = '100%') => (
        <FilterData
          columnName={columnName}
          keyName={keyName}
          filterType={filterType}
          handleFilterChange={handleFilterChange}
          options={options}
          width={width}
        />
      );

      const handleFilterChange = (keyName, value, type) => {
        setFilterValue({
          ...filterValues,
          [keyName]: type ? { value, type } : value,
        });
      };
    
      const columns = [
        {
            name: filterDataConfig('Teams', 'team', 'select', handleFilterChange, teams, '100%'),
            selector: (row) => row.teams ? row.teams.join(', ') : ' ',
            minWidth: '250px',
            maxWidth: '300px',
        },
        {
            name: filterDataConfig('Zone', 'venue', 'select', handleFilterChange, venues, '100%'),
            selector: (row) => row.venue || ' ',
            minWidth: '200px',
            maxWidth: '200px',
        },
        {
          name: filterDataConfig('Tasks', 'task', 'select', handleFilterChange),
          selector: (row) => row.task || ' ',
          minWidth: '140px',
          maxWidth: '150px',
          sortable: true,
        },
        {
          name: filterDataConfig('Percentage', 'percentage', 'text', handleFilterChange),
          selector: (row) => row.percentage || ' ',
          minWidth: '130px',
          maxWidth: '140px',
        },
        {
          name: filterDataConfig('Date & Time', 'date_time', 'text', handleFilterChange),
          selector: (row) => row.date_time ? moment(row.date_time).format('hh:mm A') : ' ',
          minWidth: '130px',
          maxWidth: '140px',
        },
        
      ];

      const excelColumns = [
        { header: 'Teams', key: 'team', width: 30 },
        { header: 'Zone', key: 'venue', width: 30 },
        { header: 'Tasks', key: 'task', width: 30 },
        { header: 'Percentage', key: 'percentage', width: 15 },
        { header: 'Date & Time', key: 'date_time', width: 30 },
      ]

    return (
        <div className="main-wrapper">
        <style>
            {`@media print {
                @page {
                    size: landscape; / Set page size to landscape /
                    }
                    .input-field {
                        display: none;
                    }
                    .rdt_TableCell{
                        min-width: 100px;
                        max-width: 160px;
                        padding: 6px 10px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12)
                    }
                    .rdt_TableRow{
                        border: none !important;
                    }
                    .rdt_TableCol{
                        min-width: 100px;
                        max-width: 160px;
                    }
                    img {
                        height: 50px;
                        width: 50px
                    }
                    .print-page{
                        page-break-before: always;
                        page-break-after: always;
                    }
            }`}
        </style>

        <div className="container">
            <PageTitle link={`/view-miqaat/${params?.id}`} title="Task Report" />
            {loader ? (
            <div className="loader-wrapper">
                <Spinner animation="border" variant="success" />
            </div>
            )
             : new Date() >= new Date(miqaatDates.startDate) ?
            (
            <div className="table-wrapper miqaat attandees attandance">
                <div style={{ borderBottom: "1px solid #cccccc" }}>
                <header className="table-header">
                    <div>
                    <div className="customDatePickerWidth">
                        <i
                        className="fa-solid fa-angle-left"
                        onClick={handlePrevDate}
                        style={{
                            visibility:
                            moment(miqaatDates.startDate).format("YYYY-MM-DD") ===
                            moment(startDate).format("YYYY-MM-DD")
                                ? "hidden"
                                : null,
                        }}
                        ></i>
                        <ReactDatePicker
                        className="input-field "
                        selected={startDate}
                        onChange={(d) => setDate(d)}
                        minDate={new Date(miqaatDates.startDate)}
                        maxDate={
                            new Date() > new Date(miqaatDates.endDate)
                            ? new Date(miqaatDates.endDate)
                            : new Date()
                        }
                        todayButton={"Today"}
                        dateFormat="dd/MM/yyyy"
                        />
                        <i
                        className="fa-solid fa-angle-right"
                        onClick={handleNextDate}
                        style={{
                            visibility:
                            moment().format("YYYY-MM-DD") ===
                                moment(startDate).format("YYYY-MM-DD") ||
                            moment(miqaatDates.endDate).format("YYYY-MM-DD") ===
                                moment(startDate).format("YYYY-MM-DD")
                                ? "hidden"
                                : null,
                        }}
                        ></i>
                    </div>
                    <div className="bn-wrapper">
                        {!isDisabledClearBtn && (
                        <button
                            className="icon-btn dark"
                            disabled={isDisabledClearBtn}
                            style={{
                            backgroundColor: isDisabledClearBtn
                                ? "#f5f5f5"
                                : `${themeColor.primaryColor}`,
                            color: isDisabledClearBtn ? "#070707" : "",
                            }}
                            onClick={handleClearFilter}
                        >
                            Clear Filter
                        </button>
                        )}
                        {/* <button
                        className="icon-btn"
                        style={{ color: `${themeColor.primaryColor}` }}
                        onClick={() => handlePrint()}
                        >
                        {" "}
                        <FiPrinter />
                        Print
                        </button>
                        <button
                        className="icon-btn dark"
                        style={{ backgroundColor: `${themeColor.primaryColor}` }}
                        onClick={() =>
                            exportExcelFile(
                            processedData,
                            excelColumns,
                            `attendance-report-${moment(startDate).format(
                                "DD/MM/YYYY"
                            )}`
                            )
                        }
                        >
                        {" "}
                        <TbFileExport />
                        Download
                        </button> */}
                    </div>
                    </div>
                </header>
                <div ref={printRef} className="print-page">
                    <FilterData
                    data={reportData}
                    // columns={columns}
                    filterValues={filterValues}
                    getFilteredData={getFilteredData}
                    />
                </div>
                </div>
                <div className="table-footer">
                <p className="note">
                    To scroll the report horizontally, press the Shift key and
                    scroll using your mouse's scroll wheel.
                </p>
                <span>rows: {processedData.length}</span>
                </div>
            </div>
            ) 
            : ( <div className="no-data-heading">This Miqaat is yet to start.</div> )
            }
        </div>
        </div>
    );
};

export default TaskReport;
