import { GetFetch, PostFetch } from "../../utils/fetchUrl";
import { ALERT_SUCCESS, ALERT_ERROR } from "../reducers/alert";
// import { CANCEL_EDIT_FORM } from "../reducers/questions";

export const AllRegisteredUsers = (miqaatId,isShowPhoto) => async (dispatch) => {
    dispatch({
        type: LOADING,
       
    });
    GetFetch(`/api/MiqaatReg/getAllRegistered?miqaatId=${miqaatId}&withPhoto=${isShowPhoto}`)
        .then((response) => {
            dispatch({
                type: LOADING_FALSE,
            });
            var data = response.data;
            if (response.status === 200) {
                dispatch({
                    type: ALL_REGISTERED,
                    payload: data,
                });
            } else {
                // dispatch({
                //   type: REQUEST_FAIL,
                // });
                dispatch({
                    type: ALERT_ERROR,
                    payload: "Something went wrong. Please try again later.",
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: LOADING_FALSE,
            });
            dispatch({
                type: ALERT_ERROR,
                payload: "Something went wrong. Please try again later.",
            });
        });
};

export const removeReportData = () => (dispatch) => {
    dispatch({
      type: REMOVE_REPORT_DATA
    })
  }

export const ALL_REGISTERED = "ALL_REGISTERED";
export const REMOVE_REPORT_DATA = 'REMOVE_REPORT_DATA';
export const LOADING_FALSE= "LOADING_FALSE";
export const LOADING= "LOADING";