import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {IoIosArrowBack} from 'react-icons/io';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale,BarElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { themeColor } from '../../config';
import { GetFetch } from '../../utils/fetchUrl';
import { Spinner } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';

const GenderWiseReports = () => {
  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale,BarElement);
  const options = {
    responsive: true,
  }

  const [genderData,setGenderData] = useState(null)
  const [loader,setLoader] = useState(false);

  const fetchGender = () => {
    setLoader(true)
    GetFetch(`/api/Reports/GenderWise`).then((response) => {
        if (response.status == 200){
          setGenderData(response.data);
        }
        else {
            console.log('Something went wrong');
          }
        setLoader(false);
    }).catch((err)=>{
        console.log('error', err);
        setLoader(false);
    })
  }

  useEffect(()=>{
    fetchGender()
  },[])

  return ( 
    <div className='main-wrapper'>
         {loader ?
            <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
            </div>
            :
            <div className="container ">
              <PageTitle link='/dashboard' title='GENDER WISE REPORT' />
                <div className="chart-wrapper">
                <div className='chart-report' style={{background: `${themeColor.lightGreen}`,marginRight:"20px"}}>
                                            <h5 style={{color: `${themeColor.secondaryColor}`}}>REPORT :</h5 >
                                            <div  className='gender-count'>
                                              {genderData && genderData.labels ?
                                              genderData.labels.map((label,i)=>(
                                                <div className='chart-count'   style={{color: `${themeColor.secondaryColor}`}}>
                                                  <b>{label || '-'} :  </b>
                                                  <p style={{marginLeft:"10px"}}>{genderData.datasets[0].data[i] }</p>
                                              </div>
                                              ))  : null
                                              }
                                            </div>
                </div>
                      <div className="country-bar chart">
                          {genderData && <Pie data={genderData} options={options} />}
                      </div>
                </div> 
            </div>
          }
    </div>
  )
}

export default GenderWiseReports;