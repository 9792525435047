import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { FormHelperText, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { yupResolver } from "@hookform/resolvers/yup";
import PageTitle from '../../../components/PageTitle'
import TextareaField from '../../../components/TextareaField'
import { useForm } from 'react-hook-form';
import InputField from '../../../components/inputField';
import { TaskSchema } from '../../../utils/Schemas';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { GetFetch, PostFetch } from '../../../utils/fetchUrl';
import moment from 'moment';
import dayjs from 'dayjs';

const Task = () => {
    const params = useParams();
    const navigate = useNavigate()
    const [itsId, setItsId] = useState();
    const [timeFrom, setTimeFrom] = useState();
    const [timeTo, setTimeTo] = useState();
    const [error, setError] = useState({
        timeFrom: '',
        timeTo: '',
    });
    const [ITSIds, setITSIds] = useState({
        ITSIds: [],
    });
    const [loader, setLoader] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const [idError, setIdError] = useState(false);
    const dispatch = useDispatch();

    const { handleSubmit, register, reset, formState: { errors }, control, setValue, watch } = useForm({
        resolver: yupResolver(TaskSchema)
    })

    const fetchData = () => {
        setLoader(true);
        GetFetch(`/api/tasklink?miqaatId=${params.id}`, dispatch, navigate).then((response) => {
          if (response.status === 200) {
            setTimeFrom(response.data.submitStartTime)
            setTimeTo(response.data.submitEndTime)
            setValue('link', response.data.link)
            if(response.data.itsids){
                const formattedNumbers = response.data.itsids.join('\n');
                setItsId(formattedNumbers);
                setValue('ItsIds', formattedNumbers)
            }
        } else if(response.status === 204) {
            setLoader(false);
        }
        else {
            showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
            setLoader(false);
        }
        setLoader(false);
        }).catch((err) => {
          console.log('error', err);
          showErrorAlert(err.data.title ? err.data.title : "Something Went Wrong");
          setLoader(false);
        })
    }

    const AddTask = () => {
        if (ITSIds.ITSIds.length > 0) {
            setBtnLoader(true);
            const body = {
                MiqaatId: params?.id,
                Link: watch('Link'),
                ITSIDs: ITSIds.ITSIds,
                SubmitStartTime: timeFrom,
                SubmitEndTime: timeTo,
            }
            PostFetch('/api/tasklink', body, "POST").then((response) => {
                if (response.status === 200) {
                    showSuccessAlert(response.data.msg || `Sucessfully Added`);
                }
                else {
                    showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                    setBtnLoader(false);
                }
                setBtnLoader(false);
            }).catch((error) => {
                console.log('error', error);
                showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
                setBtnLoader(false);
            })
        }
    }

    const parseTimeValue = (val) => {
        if (val) {
            const [time, milliSec] = val.split('.');
            const [hour, min, sec] = time.split(':').map(Number);
            let currentDate = new Date();
            currentDate.setHours(hour);
            currentDate.setMinutes(min);
            currentDate.setSeconds(sec);
            return dayjs(currentDate);
        } else {
            return null;
        }
    }

    const submitAction = (data) => {
        if(!timeTo && !timeFrom){
            setError({
                timeFrom: 'This field is required',
                timeTo: 'This field is required'
            })
        } else if(!timeFrom){
            setError({
                ...error,
                timeFrom: 'This field is required'
            })
        } else if(!timeTo){
            setError({
                ...error,
                timeTo: 'This field is required'
            })
        } else {
            setError({
                timeFrom: '',
                timeTo: '',
            })
            setIdError(false)
            if(itsId === ""){
                setIdError(true);
            } else {
                setIdError(false);
            }
             
            const arr = itsId?.split("\n");
            let numArr = []
            arr && arr?.length && arr?.map((str, i) => {
                if (str.length > 8 || str.length < 8) {
                    setIdError(true)
                }
                else if (str.length == 8) {
                    numArr.push(parseInt(str))
                    setIdError(false)
                }
            })
            setITSIds({
                ITSIds: numArr,
            });
        }
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    const handleChangeTime = (val, type) => {
        const time = moment(new Date(val)).format('HH:mm:ss.SSSSSSS')
        if(type === 'from'){
            setTimeFrom(time)
            setError({
                ...error,
                timeFrom: ''
            })
        } else {
            setTimeTo(time)
            setError({
                ...error,
                timeTo: ''
            })
        }
    }

    useEffect(() => {
        if (!idError || !error.timeFrom || !error.timeTo) {
            if (ITSIds.ITSIds && ITSIds.ITSIds.length > 0) {
                AddTask();
            }
        }
    }, [ITSIds.ITSIds])

    useEffect(() => {
        fetchData()
    },[])

    return (
        <div className='main-wrapper'>
            {loader ?
                <div className='loader-wrapper'>
                <Spinner animation="border" variant="success" />
                </div>
                :
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7}>
                        <PageTitle link={`/view-miqaat/${params?.id}/`} title='Tasks' />
                        <div className="form-wrapper">
                            <form onSubmit={handleSubmit(submitAction)}>
                                <InputField fieldPlaceholder={'Enter link'} name={'link'} fieldType={'text'} fieldLabel={'Embedded link for Task form'}
                                error={errors.link?.message} register={{ ...register('link') }} />
                                <div className='time_field'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['TimePicker']}>
                                            <TimePicker
                                                sx={{
                                                    width: '100%',
                                                    mt: '12px'
                                                }}
                                                label="Time From"
                                                value={parseTimeValue(timeFrom)}
                                                maxTime={parseTimeValue(timeTo)}
                                                onChange={(val) => {
                                                    handleChangeTime(val, 'from')
                                                }}
                                                format="HH:mm"
                                                ampm={false}
                                                slotProps={{
                                                    textField: {
                                                        helperText: (
                                                            error.timeFrom && <FormHelperText sx={{ color: '#d32f2f' }} >{error.timeFrom}</FormHelperText>
                                                        ),
                                                    },
                                                }}
                                            />
                                            
                                            <TimePicker
                                                sx={{
                                                    width: '100%',
                                                    mt: '12px'
                                                }}
                                                label="Time To"
                                                value={parseTimeValue(timeTo)}
                                                minTime={parseTimeValue(timeFrom)}
                                                onChange={(val) => {
                                                    handleChangeTime(val, 'to')
                                                }}
                                                format="HH:mm"
                                                ampm={false}
                                                slotProps={{
                                                    textField: {
                                                        helperText: (
                                                            error.timeTo && <FormHelperText sx={{ color: '#d32f2f' }} >{error.timeTo}</FormHelperText>
                                                        ),
                                                    },
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider >
                                </div>

                                <TextareaField fieldPlaceholder={"Enter ITS Numbers"}
                                    fieldLabel={'Enter ITS Numbers for Embedded Form Display:'} name={'ItsIds'} classNm={'no-margin'}
                                    error={errors.ItsIds?.message} register={{ ...register("ItsIds") }}
                                    value={itsId} handleChng={(e) => setItsId(e.target.value)}
                                />
                                <br />
                                {idError ?
                                    <h6 className="note" style={{ color: "red" }}>ITS Ids should be of 8 digits</h6> : ''
                                }
                                <h6 className="note" style={{ color: "green" }}>Press Enter After Every ITS ID</h6>
                                <div className="btn-wrapper">
                                    <Link to={`/view-miqaat/${params?.id}/`}><Button variant="danger">Cancel</Button></Link>
                                    <Button variant="success" type='submit' disabled={btnLoader} >Save{
                                        btnLoader ?
                                            <div className='loader-wrapper btn-loader'>
                                                <Spinner animation="border" variant="white" />
                                            </div> : ''
                                    }</Button>
                                </div>
                            </form>
                        </div>
                    </Col>
                </Row>
            </div>
            }
        </div>
    )
}

export default Task