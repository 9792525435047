import html2pdf from 'html2pdf.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TbFileExport } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { toJpeg } from 'html-to-image';
import placeholderUserImg from "../../../assets/images/profile.png";
import { themeColor } from '../../../config';
import { ALERT_ERROR } from '../../../store/reducers/alert';
import { PostFetch } from '../../../utils/fetchUrl';
import CardFooter from './images/card-footer.png';
import CardHeader from './images/card_header.jpg';
import ProfileBg from './images/img-back.png';

const ExportCardPDF = ({ closeCardView, userData, miqaatId }) => {
    const dispatch = useDispatch();
    const printRef = useRef();
    const [loading, setLoading] = useState(false);
    const [printIdCardLoading, setPrintIdCardLoading] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        if (userData && userData.length > 0) {
            let body = { itsids: userData.map((user) => user.itsid) || [], miqaatId }
            if (body.itsids && body.itsids.length > 0 && miqaatId) {
                setLoading(true);
                PostFetch('/api/MiqaatReg/GetIdCards', body, "POST").then((response) => {
                    if (response.status === 200) {
                        setData(response.data)
                    }
                    else {
                        showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                        setLoading(false);
                    }
                    setLoading(false);
                }).catch((error) => {
                    console.log('error', error);
                    showErrorAlert(error.data.title ? error.data.title : "Something Went Wrong");
                    setLoading(false);
                })
            } else {
                showErrorAlert("No ITS found");
            }
        }
    }, [])

    const handlePrint = useCallback(() => {
        setPrintIdCardLoading(true);
        if (printRef.current === null) {
            setPrintIdCardLoading(false);
            return
        }
        setTimeout(() => {
            toJpeg(printRef.current, { cacheBust: true, pixelRatio: 3 })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = 'AttendeesStats';
                    link.href = dataUrl;
                    link.click();
                    setPrintIdCardLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setPrintIdCardLoading(false);
                });
        }, 500);
    }, [printRef]);

    const exportCardPDFAction = () => {
        setPrintIdCardLoading(true);
        setTimeout(() => {
            const element = document.getElementById('idcards-container');
            const options = {
                filename: 'IDCards.pdf',
            };
            html2pdf(element, options);
            setPrintIdCardLoading(false);
        }, (1000));
    };

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }

    return (
        loading ? <div className="loader-wrapper">
            <Spinner animation="border" variant="success" />
        </div> :

            <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <button
                        className='icon-btn dark'
                        style={{ backgroundColor: `${themeColor.primaryColor}`, marginRight: 0 }}
                        onClick={() => exportCardPDFAction()}
                    >
                        <TbFileExport /> {printIdCardLoading ? 'Print....' : 'Print Pdf'}
                    </button>
                    <button
                        className='icon-btn dark'
                        style={{ backgroundColor: `${themeColor.primaryColor}` }}
                        onClick={handlePrint}
                    >
                        <TbFileExport /> {printIdCardLoading ? 'Print....' : 'Print JPG'}
                    </button>
                    <button
                        className='icon-btn dark'
                        style={{ backgroundColor: `red` }}
                        onClick={() => closeCardView()}
                    >
                        Cancel
                    </button>
                </div>
                <div style={{ height: '62vh', overflowY: 'auto', position: 'relative', margin: '16px 0' }}>
                    <div ref={printRef} style={{ width: '793.7px', marginTop: 13, paddingBottom: 10, paddingLeft: 10, display: 'flex', alignItems: 'start', flexWrap: 'wrap', visibility: 'none' }} id='idcards-container'>
                        {data && data.members && data.members.length > 0 ?
                            data.members.map((member, index) => (
                                <div style={{ width: 380, height: '553.75px', margin: '0 7px 7px 0' }} key={index}>
                                    <div style={{ background: '#EEF1DC', height: '550px', marginTop: '-10px' }}>
                                        <img src={CardHeader} style={{ width: '100%' }} />
                                        <div style={{ padding: 8, backgroundImage: `url(${ProfileBg})`, backgroundSize: '80%' }}>
                                            <p style={{ fontSize: 15, color: '#29643d', textAlign: 'center', lineHeight: '18px', fontWeight: 600, maxHeight: 36, overflow: 'hidden', width: '100%' }}>
                                                {data.miqaatName && data.miqaatName.length > 70
                                                    ? data.miqaatName.slice(0, 70) + '...'
                                                    : (data.miqaatName || '-')}
                                            </p>
                                            <div style={{ textAlign: 'center', marginBottom: 10, }}>
                                                <img
                                                    style={{ width: '80px' }}
                                                    src={member.photo ? `data:image/png;base64,${member.photo}` : placeholderUserImg} alt="profile" />
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: 6 }}>
                                                <b style={{ width: '30%', fontSize: 12 }}>ITS No</b>
                                                <p style={{ fontSize: 12, width: '70%', marginBottom: 0 }}>{member.itsid || '-'}</p>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: 6, height: 18, overflowY: 'hidden' }}>
                                                <b style={{ width: '30%', fontSize: 12 }}>Name</b>
                                                <p style={{ fontSize: 12, width: '70%', marginBottom: 0 }}>{member.fullName || '-'}</p>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: 6, height: 18, overflowY: 'hidden' }}>
                                                <b style={{ width: '30%', fontSize: 12 }}>Jamaat</b>
                                                <p style={{ fontSize: 12, width: '70%', marginBottom: 0 }}>{member.jamaat || '-'}</p>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: 6, height: 18, overflowY: 'hidden' }}>
                                                <b style={{ width: '30%', fontSize: 12 }}>Designation</b>
                                                <p style={{ fontSize: 12, width: '70%', marginBottom: 0 }}>{member.designation || '-'}</p>
                                            </div>
                                            <div style={{ display: 'flex', marginBottom: 6, height: 36 }}>
                                                <b style={{ width: '30%', fontSize: 12 }}>Zone</b>
                                                <p style={{ fontSize: 12, width: '70%', marginBottom: 0 }}>
                                                    {
                                                        member.venueName && member.venueName.length > 70
                                                            ? member.venueName.slice(0, 70) + '...'
                                                            :
                                                            (member.venueName || '-')}
                                                </p>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: 5,
                                                backgroundImage: `url(${CardFooter})`,
                                                backgroundSize: '74%',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                height: '39px'
                                            }}
                                            >
                                                <b style={{ width: '31%', fontSize: 11 }}>Authorize signature</b>
                                                <p style={{ marginBottom: 0 }}>
                                                    <img src={`data:image/png;base64,${data.signature}`} style={{ width: 80 }} />
                                                </p>
                                            </div>
                                        </div>
                                        {/* <img src={CardFooter} style={{width: '100%'}} /> */}
                                    </div>
                                </div>
                            )) : 'No Data Foind'}
                    </div>
                </div>
            </div>
    )
};

export default ExportCardPDF;