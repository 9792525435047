import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { themeColor } from '../../config';
import TextareaField from '../../components/TextareaField';
import { PostFetch } from '../../utils/fetchUrl';
import { ALERT_ERROR, ALERT_SUCCESS } from '../../store/reducers/alert';
import { useDispatch } from 'react-redux';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import Checkbox from '../../components/Checkbox';
import PageTitle from '../../components/PageTitle';
import AppModal from '../../components/Modal';

const SyncUntaggedMember = () => {
    const [syncUserStatus, setSyncUserStatus] = useState();
    const [successPopup, setSuccessPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [openITSDropDown, setOpenITSDropDown] = useState({
        untagged: false,
        invalid: false,
        skipped: false,
        tagged: false
    });
    const [showModal, setShowModal] = useState(false)

    const dispatch = useDispatch();

    const syncUser = () => {
        PostFetch('/api/user/syncUntagged', null, "POST").then((response) => {
            if (response.status === 200) {
                setSyncUserStatus(response.data);
                setSuccessPopup(true);
                showSuccessAlert(`Sucessfully Synced`);
            }
            else {
                showErrorAlert(response.data.msg ? response.data.msg : "Something Went Wrong");
                setLoader(false);
            }
            setLoader(false);
            setShowModal(false)
        }).catch((error) => {
            if (error) {
                console.log('error', error);
                showErrorAlert(error?.data?.title ? error?.data?.title : "Something Went Wrong");
            }
            setLoader(false);
            setShowModal(false)
        })
    }

    const confirmSync = () => {
        setLoader(true)
        syncUser();
    }

    const showErrorAlert = (msg) => {
        dispatch({
            type: ALERT_ERROR,
            payload: msg
        })
    }
    const showSuccessAlert = (msg) => {
        dispatch({
            type: ALERT_SUCCESS,
            payload: msg
        })
    }

    return (
        <div className='main-wrapper'>
            <div className="container">
                <Row className='justify-content-center'>
                    <Col lg={7}>
                        <PageTitle link='/sync-user' title='SYNC UNTAGGED MEMBER(S)' />
                        <div className="btn-wrapper" style={{ textAlign: 'center', marginBottom: '80px', marginTop: '120px' }}>
                            <Button variant="success" onClick={() => setShowModal(true)} disabled={loader}>Sync{
                                loader ?
                                    <div className='loader-wrapper btn-loader'>
                                        <Spinner animation="border" variant="white" />
                                    </div> : ''
                            }</Button>
                        </div>
                        {successPopup && !loader ?
                            syncUserStatus ?
                                <div className="status-wrapper" style={{ color: `${themeColor.secondaryColor}` }}>
                                    <div className="status-container">
                                        {syncUserStatus.syncedSuccessfulCount > 0 ?
                                            <div className="details">
                                                <p><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.syncedSuccessfulCount}</b> {syncUserStatus.syncedSuccessfulCount > 1 ? 'Members' : 'Member'} Successfully Synced</p>
                                            </div>
                                            : ''}

                                        {syncUserStatus.taggedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, tagged: !openITSDropDown.tagged })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.taggedCount}</b> Tagged {syncUserStatus.taggedCount > 1 ? 'ITS Ids' : 'ITS Id'} {openITSDropDown.tagged ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.tagged && 'open'}`}>
                                                    <p>
                                                        {syncUserStatus?.taggedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""}

                                        {syncUserStatus.untaggedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, untagged: !openITSDropDown.untagged })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.untaggedCount}</b> {syncUserStatus.untaggedCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Untagged {openITSDropDown.untagged ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.untagged && 'open'}`}>
                                                    <p>
                                                        {syncUserStatus?.untaggedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""}
                                        {syncUserStatus.invalidCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, invalid: !openITSDropDown.invalid })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.invalidCount}</b> {syncUserStatus.invalidCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid {openITSDropDown.invalid ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.invalid && 'open'}`}>
                                                    <p>
                                                        {syncUserStatus?.invalidITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""}
                                        {syncUserStatus.skippedCount > 0 ?
                                            <div className="details">
                                                <p style={{ cursor: 'pointer' }} onClick={() => setOpenITSDropDown({ ...openITSDropDown, skipped: !openITSDropDown.skipped })}><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.skippedCount}</b> {syncUserStatus.skippedCount > 1 ? 'ITS Ids' : 'ITS Id'} Skipped {openITSDropDown.skipped ? <FaChevronUp /> : <FaChevronDown />}</p>
                                                <div className={`its-ids ${openITSDropDown.skipped && 'open'}`}>
                                                    <p>
                                                        {syncUserStatus?.skippedITSIds?.map((item) => (
                                                            <span>{item}, </span>
                                                        ))}
                                                    </p>
                                                </div>
                                            </div>
                                            : ""}
                                        {syncUserStatus.errorCount > 0 ?
                                            <div className="details">
                                                <p><b style={{ color: `${themeColor.primaryColor}` }}>{syncUserStatus.errorCount}</b> {syncUserStatus.errorCount > 1 ? 'ITS Ids' : 'ITS Id'} Found Invalid</p>
                                            </div>
                                            : ""}
                                        {syncUserStatus.errorCount === 0 && syncUserStatus.untaggedCount === 0 && syncUserStatus.syncedSuccessfulCount === 0 && syncUserStatus.skippedCount === 0 && syncUserStatus.taggedCount === 0 ?
                                            <div className="details">
                                                <p>No ITS Id Added</p>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                                : 'Something went wrong'
                            : ''
                        }
                    </Col>
                </Row>
            </div>
            <AppModal handleAction={confirmSync} handleClose={() => setShowModal(false)} hasBody={true} content='Do you want to continue' showModal={showModal} title={'Confirm Sync Untagged Member'} actionBtnText={"Yes"} />
        </div>
    )
}

export default SyncUntaggedMember