import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {IoIosArrowBack} from 'react-icons/io';
import { themeColor } from '../../config';
import Card from '../../components/Card';
import PageTitle from '../../components/PageTitle';

const SyncUserInfo = () => {

  const [dashboardD,setDashboardD] = useState([])
  const [pageLoader,setPageLoader] = useState(false);
  const dashboardData = useSelector((state) => state.dashboard);
  
  useEffect(()=>{
    setPageLoader(true)
    if(dashboardData.length > 0){
      setDashboardD(dashboardData[0].childrens[2].childrens);
      setPageLoader(false);
    }
  },[])

  return (
    <>
    {pageLoader ? 
      <div className='loader-wrapper'>
          <Spinner animation="border" variant="success" />
      </div>
      : 
      <div className='main-wrapper'>
          <div className='container'>
            <PageTitle link='/dashboard' title='SYNC USER' />
            <div className="card-wrapper">
                {dashboardD.map((cardInfo)=>{
                  if(cardInfo.allow) {
                    return <Card key={cardInfo.optionId} cardDetails={cardInfo} /> 
                  }
                  })}
            </div>
          </div>
      </div>
    }
  </>
  )
}

export default SyncUserInfo