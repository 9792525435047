import { useDispatch, useSelector } from "react-redux"
// import { getMiqaatData } from "../store/actions/getMiqaatData"
import { useEffect, useState } from "react"
import { GetFetch } from "../../../utils/fetchUrl"
import { cancelRegistration, getMiqaatData } from "../../../store/actions/getMiqaatData"
import { isEmpty, get } from 'lodash'
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { themeColor } from "../../../config"
import PersonIcon from '@mui/icons-material/Person';
import moment from "moment"
import { ALERT_ERROR } from "../../../store/reducers/alert"
import { CgProfile } from "react-icons/cg"
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Stack } from "@mui/material"
import AppModal from "../../../components/Modal"
import InputField from "../../../components/inputField"
import TextAreaComponent from "./input/textArea"

const MiqaatCard = ({ token, userItsId, currentMiqaats }) => {
    const dispatch = useDispatch()
    const [showModal,setShowModal] = useState(false);
    const [cancelReason,setCancelReason] = useState('');
    const [cancelId,setCancelId] = useState('');
    const [cancelPopup,setCancelPopup] = useState(false)

    const confirmCancel =()=>{
        let obj = {
            "miqaatId":cancelId,
            "reason":cancelReason,
            "itsid":+userItsId
        }
        dispatch(cancelRegistration(obj))
        setShowModal(false)
    }

    const openModal =(id)=>{
        setCancelReason("")
        setCancelId(id)
        setShowModal(true)
    }

    const openCancelModal =(res)=>{
        setCancelReason(res)
        setCancelPopup(true)
    }

    return (
        <>
            {currentMiqaats.length ? currentMiqaats?.map((miqaat) => (
                <>
                    <div className="main-container user-info-wrapper" style={{ backgroundColor: 'rgb(239 244 237)',display:'flex',justifyContent:'space-between'}} >
                        <div style={{width: '100%'}}>
                            <div className="second-container"  >
                                <h6 style={{ textTransform: 'capitalize', color: `${themeColor.primaryColor}` }}>{miqaat?.name}</h6>
                            </div>
                            <div className="second-row" >
                                <p style={{ width: '100%' }} >
                                    Start Date : {moment(miqaat?.regStartDate).format('ll')}
                                </p>
                                <p style={{ width: '100%' }}>
                                    End Date : {moment(miqaat?.regEndDate).format('ll')}
                                </p>
                                {/* {miqaat.cancelled ? 
                                    <p style={{ width: '100%',color:'red',cursor:'pointer' }} onClick={()=>openCancelModal(miqaat.cancelReason)}> Registration cancelled</p> : null
                                } */}
                            </div>
                        </div>
                        {miqaat.cancelled ? 
                            <p className="reg-cancel" style={{ width: '40%',color:'red',cursor:'pointer',fontSize:'14px',textAlign:'center' }} onClick={()=>openCancelModal(miqaat.cancelReason)}> Registration cancelled</p>
                        :
                        new Date(miqaat.regEndDate) < new Date &&  !miqaat.registered ?
                            <p className="reg-cancel" style={{ width: '40%',color:'red',cursor:'pointer',fontSize:'14px',textAlign:'center' }}> Registration ended</p>
                        :
                        <Stack direction='column' gap={1}>
                            <Link className={`icon-btn dark ${new Date < new Date(miqaat.regStartDate) ? 'no-hover' : ''}`} to={new Date < new Date(miqaat.regStartDate) ? '' : `/miqaat-registration/${miqaat.id}`} style={{ backgroundColor: new Date < new Date(miqaat.regStartDate) ? '#f5f5f5' : `${themeColor.primaryColor}`, color:  new Date < new Date(miqaat.regStartDate) ? '#070707' : '' }}><CgProfile />{miqaat.registered ? 'Edit Info' : 'Register'}</Link>
                            {miqaat.registered ?  <Button style={{marginRight: '6px', fontWeight: '500'}} className="icon-btn " variant="danger" onClick={()=>openModal(miqaat.id,)}><DoDisturbIcon /> Cancel</Button>: null } 
                        </Stack>
                        }
                    </div>
                </>
            )) : null
            }

            <AppModal 
                handleClose={()=>setCancelPopup(false)}  
                hasBody={true} 
                content={<TextAreaComponent  disable={true} placeholder={'Your reason . . .'} value={cancelReason} type={'text-area'} /> }
                showModal={cancelPopup} title={'Reason for cancel registration.'} 
                handleAction={confirmCancel}
                cancelText={'Close'}
                // actionBtnText={"Submit"}
            />
            <AppModal 
                handleClose={()=>setShowModal(false)}  
                hasBody={true} 
                // content={ <InputField handleChange={(e)=>setCancelReason(e.target.value,)} fieldPlaceholder={'Write your reason here . . .'} val={cancelReason} fieldType={'textArea'} />}
                content={<TextAreaComponent handleChange={(e)=>setCancelReason(e.target.value)} placeholder={'Write your reason here . . .'} value={cancelReason} type={'text-area'} /> }
                showModal={showModal} title={'Reason for cancel registration  ?'} 
                handleAction={confirmCancel}
                actionBtnText={"Submit"}
                cancelModal={true}
                disable = {cancelReason}
            />
        </>
    )
}
export default MiqaatCard