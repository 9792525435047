import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {Navigate, Outlet, } from 'react-router-dom';

const ProtectedRoutes = ({route}) => {
    let loginState = useSelector((state)=>state.login);
    // let allowed = false;

    // useEffect(()=>{
    //   if(dashboardData?.length > 0){
    //     for(let i=0; i < dashboardData?.length; i++){
    //         if(dashboardData[i].childrens?.length > 0){
    //             for(let j=0; j < dashboardData[i].childrens?.length; j++){
    //                 if(dashboardData[i].childrens[j]?.link && dashboardData[i].childrens[j]?.allow && dashboardData[i].childrens[j]?.link === route){
    //                   if(dashboardData[i].childrens[j]?.allow){
    //                     allowed = true;
    //                   }
    //                   if(dashboardData[i].childrens[j].childrens?.length > 0){
    //                     for(let k=0; k < dashboardData[i].childrens[j].childrens?.length; k++){
    //                       if(dashboardData[i].childrens[j].childrens[k]?.link && dashboardData[i].childrens[j].childrens[k]?.allow && dashboardData[i].childrens[j].childrens[k]?.link.match(route)){
    //                         allowed = true;
    //                       }
    //                     }
    //                   }
    //                 }
    //             }
    //         }
    //     }
    //   }
    // }, [dashboardData])

    if(loginState){
      return (
         <Outlet/>
      )
    }
    else{
      return(
        <Navigate to='/' replace={true}/> 
      )
    }
}

export default ProtectedRoutes